$body-background-color: $white;

html,
body {
  position: relative;
  width: 100%;
  background-color: $body-background-color;
}

body {
  // Helper class to be used with full page scrollable overlays, such as the document viewer.
  &.no-scroll {
    overflow: hidden;
  }
}
