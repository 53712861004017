/*
 * The &[data-popper-placement='{position}'] selectors used below
 * are selecting wrapper-elements generated by the Popper.js plugin
 */

@mixin bubble($prefix, $offset) {
  .#{$prefix} {
    z-index: index($z-index-scale, #{$prefix});
    display: none;

    &.#{$prefix}--visible {
      display: block;
    }

    & > .#{$prefix}-content {
      position: relative;
      @content;
    }

    //
    // POSITIONING PROPERTIES
    //

    &[data-popper-placement='top'] > .#{$prefix}-content {
      top: -$offset;
    }

    &[data-popper-placement='bottom'] > .#{$prefix}-content {
      bottom: -$offset;
    }

    &[data-popper-placement='left'] > .#{$prefix}-content {
      left: -$offset;
    }

    &[data-popper-placement='right'] > .#{$prefix}-content {
      right: -$offset;
    }
  }
}
