/***
 * # Footer
 *
 * A structural element that is placed at the bottom of every page.
 *
 * See the design pattern pages for an example use cases.
 *
 * ATTENTION:
 * 1. There can only be one `footer` element per page
 * 2. The `<footer role="contentinfo" class="footer"></footer>` must be placed right before `</wrapper>`
 * 3. The following HTML template must be used (see further down for more information on `.footer__element` and styling) :
 *
 * ```html
 * <footer role="contentinfo" class="footer">
 *   <div class="container">
 *     <div class="footer__content">
 *       <div class="footer__column">
 *         <div class="footer__element"></div>
 *         …
 *       </div>
 *       <div class="footer__column">
 *         <div class="footer__element"></div>
 *         <div class="footer__element"></div>
 *         <div class="footer__element"></div>
 *       </div>
 *       <!-- Optional logo -->
 *       <div class="footer__element">
 *         <img src="" alt="" class="footer__logo"/>
 *       </div>
 *     </div>
 *   </div>
 * </footer>
 * ```
 */

$footer-border-color:     color(grey, 5);
$footer-text-color:       color(grey, 3);
$footer-link-top-margin:  8px;

$footer-height:           50px;
$mobile-footer-height:    70px;
$footer-element-margin:   15px;
$footer-logo-height:      70px;
$footer-logo-width:       100px;
$screen-min-width:        320px;

/**
 * Basic footer:
 *
 * `.footer`
 *
 * A site footer.
 *
 * A footer consists of two `.footer__column`s.
 *
 * Each `.footer__column` contains a number of `.footer__element`s.
 *
 * Each `.footer__element` may contain e.g. a language selector, a group of links, a logo, etc.
 *
 * Use `footer__column--align-right` to right-align the `.footer__element`s of a `.footer__column`.
 *
 * Use `ul.footer__menu` to add `li.footer__menu-item a.footer__menu-link`s to a footer (see example HTML).
 *
 * Use `.footer__menu--align-right` to right-align the `a.footer__menu-link`s of a `.footer__menu`.
 *
 *   @example
 *   include ./code-samples/basic-footer.pug
 */
@include block('footer') {
  width: 100%;
  flex-shrink: 1; // Works in combination with .layout__content-container {display: flex; flex-direction: column;}.

  @include element('content') {
    position: relative;
    @include rem(border-top, 1px solid $footer-border-color);
    @include clearfix;
    @include rem(padding-top, $footer-element-margin);
    @include rem(min-height, $footer-height);
    height: auto;
    margin-top: $ukg-spacing-m;

    > * {
      @include rem(font-size, $small-font-size);
      @include rem(line-height, $base-line-height);
      color: $footer-text-color;
      margin-top: 0;
    }
  }

  /**
   * A footer with a max-width:
   *
   * Using the [container](../docs/helpers-container.html) layout helper
   *
   * `.container.container--small`
   *
   * `.container.container--medium`
   *
   * `.container.container--large`
   *
   * `.container.container--extra-large`
   *
   * A footer that will only expand up to a certain width.
   * <br><br>
   * Using `container--small` in the current example.
   *
   *   @example
   *   include ./code-samples/footer-max-width.pug
   */

  /**
   * A footer with a logo:
   *
   * `.footer__logo`
   *
   *   @example
   *   include ./code-samples/footer-with-logo.pug
   */

  /**
   * A footer with a small logo:
   *
   * `.footer__logo--small`
   *
   *   @example
   *   include ./code-samples/footer-with-small-logo.pug
   */
  @include element('column') {
    float: left;
    width: 50%;
    text-align: left;

    @include modifier('align-right') {
      text-align: right;

      @include element('element') {
        @include rem(border-left, $footer-element-margin solid transparent);
        border-right-width: 0;
      }
    }
  }

  @include element('element') {
    @include rem(border-right, $footer-element-margin solid transparent);
    display: inline-block;
    vertical-align: top;
    max-width: 100%;

    > * {
      margin-top: 0;
      @include rem(margin-bottom, $footer-element-margin);
    }
  }

  @include element('menu') {
    text-align: left;
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: inherit;

    @include element('menu-item') {
      margin: 0;
      padding: 0;
      display: inline-block;
      @include rem(margin-bottom, $footer-element-margin);
      @include rem(margin-right, $footer-element-margin);

      &:last-child {
        margin-right: 0;
      }
    }

    @include element('menu-link') {
      white-space: nowrap;
      display: inline-block;

      &:not(:hover):not(:focus):not(:active) {
        color: $footer-text-color;
      }
    }

    @include modifier('align-right') {
      text-align: right;

      @include element('menu-item') {
        @include rem(margin-left, $footer-element-margin);
        margin-right: 0;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  @include element('logo') {
    margin-bottom: 0;
    @include rem(min-width, $footer-logo-width);
    @include rem(max-width, $footer-logo-width);
    width: 100%; // Fix bug in IE11

    @include modifier('small') {
      @include rem(min-width, 70px);
      @include rem(max-width, 70px);
    }
  }
}

@media all and (max-width: $mobile) {
  @include block('footer') {
    @include element('column') {
      width: 100%;
    }

    @include element('menu') {
      width: 100%;
    }
  }
}

@media all and (max-width: ($mobile - 200)) {
  @include block('footer') {
    @include element('column') {
      @include element('element') {
        width: 100%;

        > * {
          width: 100%;
        }

        @include element('menu') {
          @include modifier('align-right') {
            text-align: left;

            @include element('link') {
              @include rem(margin-right, $footer-element-margin);
              margin-left: 0;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }

      @include modifier('align-right') {
        @include element('element') {
          border-left-width: 0;
        }
      }
    }
  }
}
