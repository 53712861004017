@import 'shadow';

// FORMS

// Get form field colors from map
@function form-field-color($color, $type) {
  @return map-get(map-get($form-field-colors, $color), $type);
}

// Generate a border, text and focus color scheme for each field type
@mixin form-field-color-variation($color) {
  border-color: form-field-color($color, border);
  color: form-field-color($color, text);
  background-color: $white;

  &:focus,
  &.focus {
    color: form-field-color($color, focus-color);
    @include focus-outline($outline-color: form-field-color($color, focus-border));
  }

  &::placeholder {
    color: form-field-color($color, placeholder);
  }
}

// Returns styling for a standard form field.
@mixin form-field {
  @include form-field-color-variation(standard);
  @include rem(height, $form-field-height);
  @include rem(padding, $form-field-padding);
  @include rem(font-size, $base-font-size);
  @include rem(border-radius, $form-field-border-radius);
  @include rem(border-width, $form-field-border-width);
  line-height: 1;
  border-style: solid;
  outline: 0; // Only remove this because we have a focus state
  vertical-align: top; // Make the inputs align with each other & buttons
  width: 100%;
}

// MODIFIERS

// Returns styling for a large field.
@mixin large-field {
  @include rem(height, $large-form-field-height);
  @include rem(padding, $large-form-field-padding);
  @include rem(font-size, $base-font-size * 1.1);
}

// Returns styling for a dark field (for using on grey backgrounds)
@mixin dark-form-field {
  @include form-field-color-variation(dark);
}

// Returns styling for an invalid field.
@mixin invalid-form-field {
  @include form-field-color-variation(invalid);
}

// Returns styling for a valid field.
@mixin valid-form-field {
  @include form-field-color-variation(valid);
}

// Required asterisk
@mixin required-field-marker {
  content: '\00A0*'; // non-breaking space before an asterisk
  font-weight: $bold;
  color: $font-color-primary-on-white;
}
