/***
 * # Selects
 *
 * A select is a form element where the user can select a single or multiple
 * options from a dropdown list. Selects can optionally include headings,
 * sub headings and search inputs.
 *
 * As selects rely on a list of radios or checkboxes to work, it is essential
 * that no two options have the same ID.
 *
 * ## When to use a select
 *
 * Selects should be used when you want the user to choose between a list of
 * options.
 *
 * ## When not to use a select
 *
 * Selects should not be used for just a few options - in this situation, try
 * to use a button group or radios instead.
 *
 * ## Position and style
 *
 * Like other form elements, selects should have `valid` and `invalid`
 * modifiers applied to them depending on their state.
 *
 * The default position of the select is below the trigger. You can use the [modifier `top`](#top-select) if your
 * select is close to the bottom of the page, as for example inside of the [footer](../docs/blocks-footer.html) component.
 *
 * ## Accessibility
 * Add `aria-expanded="false" role="button" tabindex="0"` to the `.select__header`. If the select
 * is initially open, use `aria-expanded="true"` instead.
 *
 * You can associate a select with a text label. See [single select with a label](#single-select-with-a-label) for an example.
 *
 * Make sure to provide accessible text to the `button.select-clear-button` by
 * adding `aria-labelledby='uniqueID-clear-button uniqueID-select-header'`. The button
 * contains an [informative SVG icon](blocks-icon.html#accessibility). The code looks as follows:
 *
 * ```html
 * <button id="uniqueID-clear-button" type="button" aria-labelledby="uniqueID-clear-button uniqueID-select-header" class="select__clear-button">
 *   <svg role="img" aria-labelledby="uniqueID-icon-title" focusable="false" class="button__icon icon">
 *     <title id="uniqueID-icon-title">Clear</title>
 *     <use xlink:href="../icons.svg#close" href="../icons.svg#close"></use>
 *   </svg>
 * </button>
 * ```
 *
 * The `button.select__clear-button` is placed after the `.select__body` in the markup to
 * ensure a logical tab order.
 *
 * See the accessibility notes for [disabled](#disabled-select) and [required](#required-select) selects.
 *
 * ## Keyboard interaction
 *
 * With focus on the select header:
 * - <kbd>Enter</kbd> or <kbd>Space</kbd>: opens the select, and moves focus to the first item, or to the selected item (if there is one).
 *
 * With focus inside of the select:
 * - <kbd>↓</kbd>: moves focus to the next option. If focus is on the last option, moves focus to the first.
 * - <kbd>↑</kbd>: moves focus to the previous option. If focus is on the first option, moves focus to the last.
 * - <kbd>Esc</kbd>: closes the select, and moves focus to the select header.
 *
 * ## Initialization
 *
 * <div class="alert alert--danger top-margin top-margin--small">
 *   <p>This component will auto-instantiate unless it has the <code>[data-no-auto]</code> attribute at page load (DOMContentLoaded) or on <code>init.pdui</code> event recept on an ancestor.<p>
 *   <p>If the component is added to the page dynamically, trigger the <code>init.pdui</code> event on a parent node to instantiate it.</p>
 *   <p>If the component is not instantiated, it will auto-instantiate as soon as a method of that component is called programmatically, regardless of the presence of <code>[data-no-auto]</code>.</p>
 *   <p>If you don't set the component to auto-instantiate and you wish to instantiate it manually (pass options to it etc.) do:<br><code>$(el).select();</code></p>
 * </div>
 *
 * ## Options
 *
 * The select component needs to know the path to the SVG icons. You have two ways to declare the icons path on the component level:
 * - Using the `iconsPath` option at initialization
 * - Setting the `data-icons-path` attribute on the element `.select`
 * If none of the above is found, the select component will use the path from the `data-icons-path` attribute on the `<body>` element.
 *
 */

$select-header-padding:           0 56px 0 5px;
$select-line-height:              21px;
$select-min-height:               32px;
$select-large-min-height:         40px;
$select-max-height:               200px;

$select-offset:                   3px;
$select-body-horizontal-padding:  5px;

$large-select-padding:            0 53px 0 5px;

$clear-icon-right-offset:         24px;

$select-short-head-width:         $short-form-field-width - 2;
$select-medium-head-width:        $medium-form-field-width - 2;

$select-body-width:               $form-field-width - ($select-body-horizontal-padding * 2) + ($form-field-border-width * 2);
$select-short-body-width:         $short-form-field-width - ($form-field-border-width * 2) - ($select-body-horizontal-padding * 2);
$select-medium-body-width:        $medium-form-field-width - ($form-field-border-width * 2) - ($select-body-horizontal-padding * 2);
$select-large-body-width:         $form-field-width - ($form-field-border-width * 2) - ($select-body-horizontal-padding * 2);
$select-long-body-width:          $long-form-field-width - ($form-field-border-width * 2) - ($select-body-horizontal-padding * 2);

$select-body-max-width:           calc(100% - (#{$select-body-horizontal-padding} * 2));

$select-label-padding:            8px $select-body-horizontal-padding * 2 8px ($select-body-horizontal-padding * 2) - $button-focus-outline-width;
$select-option-padding:           8px;

/*
  Variables not listed above can be found in src/settings/_form.scss
  Mixins can be found in src/tools/_form.scss
*/

/**
 * Single select:
 * `.select`
 *
 * A select where you can select only one option.
 *
 *     @example
 *     fieldset.select.select--closed#single-select-demo-unchecked
 *       .select__header#select-header-0(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
 *         span.select__title Select temperature
 *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
 *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
 *       .select__body
 *         ul.select__option-container
 *           li.select__option.radio
 *             input(type='radio' name='temperature-3' value='hot' id='hot-0').radio__input
 *             label.select__label.radio__label(for='hot-0')
 *               span.radio__control
 *               span.select__display.radio__display Hot
 *           li.select__option.radio
 *             input(type='radio' name='temperature-3' value='cold' id='cold-0').radio__input
 *             label.select__label.radio__label(for='cold-0')
 *               span.radio__control
 *               span.select__display.radio__display Cold
 *       button.select__clear-button.hidden#select-clear-button-0(type='button' aria-labelledby='select-clear-button-0 select-header-0')
 *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-0' focusable='false')
 *           title#select-clear-icon-0 Clear
 *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
 */

/**
 * Single select with a label:
 *
 * A select with an associated label text.
 *
 * Note that in this case, we don't use the HTML element `<label>`, but a `<p>` element with the class `label` associated with the element `.select_header` via the `aria-labelledby` attribute.
 *
 * <div class="card card--standout top-margin">
 *   <div class="card__body">
 *     <p>
 *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title1" focusable="false">
 *         <title id="title1">Information</title>
 *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
 *       </svg>
 *       <strong>Accessibility</strong>
 *     </p>
 *     <p>Add <code>aria-labelledby="label-ID button-ID"</code> to the element <code>.select__header</code>.</p>
 *     <p>This way we make sure that screen readers announce both, the label text and the content of the select when <code>.select__header</code> receives focus.</p>
 *   </div>
 * </div>
 *
 * This technique can be used as well with the `.horizontal-form` layout. See [complex horizontal form](../templates/complex-horizontal-form.html) for a full example.
 *
 *     @example
 *     p.label#select-label-1 Temperature
 *     fieldset.select.select--closed#single-select-with-label-demo
 *       .select__header#select-header-1(data-toggle='select' aria-expanded='false' aria-labelledby='select-label-1 select-header-1' role='button' tabindex=0)
 *         span.select__title Select temperature
 *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
 *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
 *       .select__body
 *         ul.select__option-container
 *           li.select__option.radio
 *             input(type='radio' name='temperature-3' value='hot' id='hot-1').radio__input
 *             label.select__label.radio__label(for='hot-1')
 *               span.radio__control
 *               span.select__display.radio__display Hot
 *           li.select__option.radio
 *             input(type='radio' name='temperature-3' value='cold' id='cold-1').radio__input
 *             label.select__label.radio__label(for='cold-1')
 *               span.radio__control
 *               span.select__display.radio__display Cold
 *       button.select__clear-button.hidden#select-clear-button-1(type='button' aria-labelledby='select-clear-button-1 select-header-1')
 *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-1' focusable='false')
 *           title#select-clear-icon-1 Clear
 *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
 */

/**
 * Single select with option pre-selected:
 * `.select`
 *
 * A select where you can select only one option, with one pre-selected.
 *
 * <br>
 * <div class="alert alert--warning">If you wish for a select to be initialised with pre-selected options then you need to add the <strong>[checked="checked"]</strong> attribute to the relevant <strong>inputs</strong> as well as explicitly intialize the component: <strong>$(el).select();</strong><br><br>This is the case for all types of Selects.</div>
 *
 *     @example
 *     fieldset.select.select--closed#single-select-demo
 *       .select__header#select-header-2(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
 *         span.select__title Select temperature
 *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
 *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
 *       .select__body
 *         ul.select__option-container
 *           li.select__option.radio
 *             input(type='radio' checked='checked' name='temperature-5' value='hot' id='hot').radio__input
 *             label.select__label.radio__label(for='hot')
 *               span.radio__control
 *               span.select__display.radio__display Hot
 *           li.select__option.radio
 *             input(type='radio' name='temperature-5' value='cold' id='cold').radio__input
 *             label.select__label.radio__label(for='cold')
 *               span.radio__control
 *               span.select__display.radio__display Cold
 *       button.select__clear-button.hidden#select-clear-button-2(type='button' aria-labelledby='select-clear-button-2 select-header-2')
 *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-2' focusable='false')
 *           title#select-clear-icon-2 Clear
 *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
 */

/**
 * Closed select:
 * `.select--closed`
 *
 * Use this modifier to start off with a closed select.
 *
 *     @example
 *     fieldset.select.select--closed#single-select-closed
 *       .select__header#select-header-3(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
 *         span.select__title Select temperature
 *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
 *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
 *       .select__body
 *         ul.select__option-container
 *           li.select__option.radio
 *             input(type='radio' checked='checked' name='temperature-2' value='hot' id='hot-closed').radio__input
 *             label.select__label.radio__label(for='hot-closed')
 *               span.radio__control
 *               span.select__display.radio__display Hot
 *           li.select__option.radio
 *             input(type='radio' name='temperature-2' value='cold' id='cold-closed').radio__input
 *             label.select__label.radio__label(for='cold-closed')
 *               span.radio__control
 *               span.select__display.radio__display Cold
 *       button.select__clear-button.hidden#select-clear-button-3(type='button' aria-labelledby='select-clear-button-3 select-header-3')
 *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-3' focusable='false')
 *           title#select-clear-icon-3 Clear
 *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
 */

/**
 * Select with an icon:
 * `.select`
 *
 * A select with an icon next to the title.
 *
 *     @example
 *     fieldset.select.select--closed#select-icon
 *       .select__header#select-header-with-icon(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
 *         svg.select__icon.icon(aria-hidden='true' focusable='false')
 *           use(xlink:href='../icons.svg#language' href='../icons.svg#language')
 *         span.select__title Select language
 *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
 *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
 *       .select__body
 *         ul.select__option-container
 *           li.select__option.radio
 *             input(type='radio' name='language-icon' value='de' id='icon-0').radio__input
 *             label.select__label.radio__label(for='icon-0')
 *               span.radio__control
 *               span.select__display.radio__display Deutsch
 *           li.select__option.radio
 *             input(type='radio' name='language-icon' value='fr' id='icon-1').radio__input
 *             label.select__label.radio__label(for='icon-1')
 *               span.radio__control
 *               span.select__display.radio__display French
 *           li.select__option.radio
 *             input(type='radio' name='language-icon' value='en-gb' id='icon-2').radio__input
 *             label.select__label.radio__label(for='icon-2')
 *               span.radio__control
 *               span.select__display.radio__display English (UK)
 *       button.select__clear-button.hidden#select-clear-button-with-icon(type='button' aria-labelledby='select-clear-button-with-icon select-header-with-icon')
 *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-with-icon' focusable='false')
 *           title#select-clear-icon-with-icon Clear
 *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
 */

/**
 * Multiple select:
 * `.select`
 *
 * A select where you can select more than one option.
 *
 *     @example
 *     fieldset.select.select--closed#select-multiple-select
 *       .select__header#select-header-4(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
 *         span.select__title Select foods
 *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true', focusable='false')
 *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
 *       .select__body
 *         ul.select__option-container
 *           each food in ['Cake', 'Chocolate', 'Pizza', 'Cheese', 'Salad', 'Pie', 'Chips']
 *             - var food_lc = food.toLowerCase();
 *             li.select__option.checkbox
 *               input(type='checkbox' name='foods' value=`${food_lc}` id=`${food_lc}`).checkbox__input
 *               label.select__label.checkbox__label(for=`${food_lc}`)
 *                 span.checkbox__control
 *                   svg.checkbox__icon.icon
 *                     use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
 *                 span.select__display.checkbox__display #{food}
 *       button.select__clear-button.hidden#select-clear-button-4(type='button' aria-labelledby='select-clear-button-4 select-header-4')
 *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-4' focusable='false')
 *           title#select-clear-icon-4 Clear
 *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
 */
@include block('select') {
  width: 100%;
  min-width: 1rem; // fieldset has a default min-width
  max-width: rem($form-field-width);
  font-size: rem($base-font-size);
  display: inline-flex;
  vertical-align: top;
  text-align: left;
  position: relative;

  @include modifier('closed') {
    @include element('body') {
      display: none;
    }
  }

  @include element('icon') {
    display: inline-block;
    width: rem(20px);
    height: rem(20px);
    margin: rem(0 8px 0 3px);
  }

  @include element('header') {
    padding: rem($select-header-padding);
    min-height: rem($select-min-height);
    border: rem($form-field-border-width) solid form-field-color(standard, border);
    @include form-field-color-variation(standard);
    border-radius: rem($form-field-border-radius);
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    max-width: 100%;
    outline: 0;
    cursor: pointer;
  }

  @include element('title') {
    line-height: rem($select-line-height);
    display: inline-block;
    word-wrap: break-word;
    overflow-wrap: break-word;

    @include modifier('hidden') {
      display: none;
    }
  }

  @include element('clear-button') {
    @include seamless-button($icon-size: 16px, $position: absolute);
    right: rem($clear-icon-right-offset);
    top: 0;
  }

  @include element('toggle-icon') {
    position: absolute;
    top: rem(3px);
    right: rem(4px);
    height: rem(24px);
    width: rem(24px);
    @include rotate-half;

    @include modifier('closed') {
      @include rotate-reset;
    }
  }

  @include element('display') {
    @include hyphens;
    width: calc(100% - #{$select-option-padding});
    padding-right: rem($select-option-padding);
  }

  @include mixie('selected') {
    line-height: rem(19px);
    padding: rem(0 4px);
    margin: rem($select-offset 4px ($select-offset - 1px) 0);
    word-break: break-all;

    .icon {
      vertical-align: middle;
      margin-left: rem(1px);
      margin-top: rem(-1px);
      cursor: pointer;
    }
  }

  @include element('body') {
    position: absolute;
    width: rem($select-body-width);
    max-height: rem($select-max-height);
    padding: $ukg-spacing-xs rem($select-body-horizontal-padding);
    max-width: 100%;
    min-width: 100%;
    background-color: $white;
    box-shadow: $ukg-elevation-08;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: index($z-index-scale, select-overlay);
    top: 100%;
    left: 0;
    margin: $ukg-spacing-xxs 0 0 0; // needed as in some cases we use body as an `<ul>` element. Eg: .calendar, .viewer
    border-radius: $border-radius-medium;
  }

  @include element('option-container') {
    margin: 0;
    list-style: none;
  }

  @include mixie('option') {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;

    @include modifier('hidden') {
      display: none;
    }
  }

  @include mixie('label') {
    padding: rem($select-label-padding);
    width: calc(100% + 10px);
    transform: translateX(-5px);
    transition: background-color 0.1s linear;
    border-radius: 0;
    @include subtle-focus-indicator($indicator-color: transparent); // Prevents visual "jump" when the element receives focus
  }

  [class$='__input'] { // We want this to work for both .radio__input and .checkbox__input
    &:focus,
    &:active {
      + [class$='__label'] {
        @include subtle-focus-indicator($text-color: $font-color-primary-on-white);

        [class$='__control']::before {
          display: none;
        }

        .icon {
          border-color: $font-color-primary-on-white;
        }
      }
    }
  }

  /**
   * Single select without clear button:
   * `.select--no-clear-button`
   *
   * A single select without the clear button. An option must be always selected and it can not be cleared.
   *
   * **Do not use this modifier with the multiple select**.
   *
   *     @example
   *     fieldset.select.select--closed.select--no-clear-button#select-no-clear-button
   *       .select__header(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
   *         span.select__title Select temperature
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         ul.select__option-container
   *           li.select__option.radio
   *             input(type='radio' name='temperature-clear' value='hot' id='hot-clear-0').radio__input
   *             label.select__label.radio__label(for='hot-clear-0')
   *               span.radio__control
   *               span.select__display.radio__display Hot
   *           li.select__option.radio
   *             input(type='radio' name='temperature-clear' value='cold' id='cold-clear-1').radio__input
   *             label.select__label.radio__label(for='cold-clear-1')
   *               span.radio__control
   *               span.select__display.radio__display Cold
   */

  @include modifier('no-clear-button') {
    @include element('header') {
      padding-right: rem(21px); // Leave space only for the arrow
    }
  }

  /**
   * Searchable select:
   * `.select`
   *
   * A select where you can search for results.
   *
   * <div class="card card--standout top-margin">
   *   <div class="card__body">
   *     <p>
   *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title2" focusable="false">
   *         <title id="title2">Information</title>
   *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *       </svg>
   *       <strong>Accessibility</strong>
   *     </p>
   *     <p>Make sure you always include the <code>aria-label="Search"</code> attribute on the <code>.select__search</code> element.</p>
   *   </div>
   * </div>
   *
   * `span.select__highlight` is used to highlight the search term.
   *
   *     @example
   *     fieldset.select.select--closed
   *       .select__header#select-header-5(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
   *         span.select__title Select animal
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         input.select__search.input(type='text' placeholder='Search animals' data-filter aria-label='Search')
   *         ul.select__option-container
   *           each display, id in {'pig': 'Pig', 'dog': 'Dog', 'cat': 'Cat', 'buffalo': 'Buffalo', 'panthera': 'Panthera', 'fox': 'Fox', 'bandicoot': 'Bandicoot', 'hedgehog': 'Hedgehog', 'heron': 'Heron', 'crane': 'Crane', 'bobcat': 'Bobcat'}
   *             li.select__option.radio
   *               input(type='radio' name='animals' value=id, id=`searchable-select-${id}`).radio__input
   *               label.select__label.radio__label(for=`searchable-select-${id}`)
   *                 span.radio__control
   *                 span.select__display.radio__display= display
   *       button.select__clear-button.hidden#select-clear-button-5(type='button' aria-labelledby='select-clear-button-5 select-header-5')
   *           svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-5' focusable='false')
   *             title#select-clear-icon-5 Clear
   *             use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   */
  @include element('search') {
    margin-bottom: $ukg-spacing-xxs;
    display: block;
  }

  /**
   * Custom searchable select:
   * `.select`
   *
   * A select where the developer can define their own search method during initialization.
   *
   * In this example, the developer will only accept a sequence of characters that occur more than once in an option and the matches will be highlighted with the color red.
   *
   *     @example
   *     fieldset.select.select--closed#select-custom-search-select(data-no-auto)
   *       .select__header#select-header-6(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
   *         span.select__title Select animal
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         input.select__search.input(type='text' placeholder='Search animals' data-filter aria-label='Search')
   *         ul.select__option-container
   *           each display, id in {'machie machie':'Machie machie', 'pig': 'Pig', 'dog': 'Dog', 'cat': 'Cat', 'buffalo': 'Buffalo', 'panthera': 'Panthera', 'fox': 'Fox', 'bandicoot': 'Bandicoot', 'hedgehog': 'Hedgehog', 'heron': 'Heron', 'crane': 'Crane', 'bobcat': 'Bobcat'}
   *             li.select__option.radio
   *               input(type='radio' name='animals' value=id, id=`custom-searchable-select-${id}`).radio__input
   *               label.select__label.radio__label(for=`custom-searchable-select-${id}`)
   *                 span.radio__control
   *                 span.select__display.radio__display= display
   *       button.select__clear-button.hidden#select-clear-button-6(type='button' aria-labelledby='select-clear-button-6 select-header-6')
   *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-6' focusable='false')
   *           title#select-clear-icon-6 Clear
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   *     script.
   *        $('#select-custom-search-select').select({
   *          match: function (input, option) {
   *            let re = new RegExp(input,'gi');
   *            return {
   *              hasMatch: option.match(re) && option.match(re).length > 1,
   *              html: option.replace(re, `<span style='color: red;'>${input}</span>`) //optional
   *            };
   *          }
   *        });
   */

  @include element('highlight') {
    @include highlighted-text;
  }

  /**
   * Dynamic multiple select:
   * `.select`
   *
   * A **multiple** select where the options are populated dynamically via user input.
   *
   * `data-input-name=''` is used to indicate what the `name` attribute
   * of the `<input>` element of the dynamically added option(s) will be.
   *
   * `button.button.button--thin.button--hidden(data-add-new-option)` is used by the user to add the new option(s).
   *
   * If the `data-input-name` attribute is missing, the above button will not be activated.
   *
   * In the current example, `formatNewOption()` allows the user to format how the new option will be displayed (e.g. all uppercase)
   *
   * `userInputIsValid()` allows the developer to validate the new user-defined option and either accept or reject it
   *
   *     @example
   *     fieldset.select.select--closed#select-dynamic-select(data-input-name='email', data-no-auto)
   *       .select__header#select-header-7(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
   *         span.select__title Add email addresses
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true', focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         input.select__search.input(type='text' placeholder='Add email addresses' aria-label='Search')
   *         button.button.button--thin.button--hidden.select__add-option-button(data-add-new-option type='button') Add
   *         ul.select__option-container
   *       button.select__clear-button.hidden#select-clear-button-7(type='button' aria-labelledby='select-clear-button-7 select-header-7')
   *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-7' focusable='false')
   *           title#select-clear-icon-7 Clear
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   *     script.
   *        $('#select-dynamic-select').select({
   *          formatNewOption: function (label) {
   *            return label.toUpperCase();
   *          },
   *          userInputIsValid: function (input) {
   *            return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(input);
   *          },
   *          i18n : {
   *            addButton: 'Add'
   *          }
   *        });
   */
  @include element('add-option-button') {
    word-break: break-all;
    margin-top: $ukg-spacing-xxs;
  }

  /**
   * Mixed multiple select:
   * `.select`
   *
   * A mix of a standard and a dynamic **multiple** select
   *
   * A set of pre-existing options is required.
   *
   * The `data-input-name` attribute is no longer needed in this case and it will be ignored.
   * The `name` attribute of the `<input>` elements of the pre-existing options will be used
   * for the dynamically added options as well.
   *
   * If no pre-existing options are found, the `data-add-new-option` button will not be activated.
   *
   *     @example
   *     fieldset.select.select--closed#select-mixed-select(data-no-auto)
   *       .select__header#select-header-8(data-toggle='select' aria-expanded='false' role='button' tabindex='0')
   *         span.select__title Select drinks
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true', focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         input.select__search.input(type='text' placeholder='Search or add drinks' data-filter aria-label='Search')
   *         button.button.button--thin.button--hidden.select__add-option-button(data-add-new-option type='button') Add
   *         ul.select__option-container
   *           - var idx = 0;
   *           each drink in ['Vodka', 'Whiskey', 'Rum', 'Gin']
   *             - idx++;
   *             - var drink_lc = drink.toLowerCase();
   *             li.select__option.checkbox
   *               if (idx < 4)
   *                 input(type='checkbox' name='drinks' value=`${drink_lc}` id=`select-mixed-select-${drink_lc}` checked).checkbox__input
   *               else
   *                 input(type='checkbox' name='drinks' value=`${drink_lc}` id=`select-mixed-select-${drink_lc}`).checkbox__input
   *               label.select__label.checkbox__label(for=`select-mixed-select-${drink_lc}`)
   *                 span.checkbox__control
   *                   svg.checkbox__icon.icon
   *                     use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *                 span.select__display.checkbox__display #{drink}
   *       button.select__clear-button.hidden#select-clear-button-8(type='button' aria-labelledby='select-clear-button-8 select-header-8')
   *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-8' focusable='false')
   *           title#select-clear-icon-8 Clear
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   *     script.
   *        $('#select-mixed-select').select({
   *          i18n : {
   *            addButton: 'Add'
   *          }
   *        });
   */

  /**
   * Select with section headings:
   * `.select`
   *
   * A select where the options are listed under headings.
   *
   *     @example
   *     fieldset.select.select--closed
   *       .select__header#select-header-9(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
   *         span.select__title Select character
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         span.select__section-title DC
   *         ul.select__option-container
   *           each hero in ['Superman', 'Batman', 'Wonder woman']
   *             - var hero_formatted = hero.toLowerCase().replace(/\s/g,'-');
   *             li.select__option.radio
   *               input(type='radio' name='character' value=`${hero_formatted}` id=`select-headings-${hero_formatted}`).radio__input
   *               label.select__label.radio__label(for=`select-headings-${hero_formatted}`)
   *                 span.radio__control
   *                 span.select__display.radio__display #{hero}
   *         span.select__section-title Marvel
   *         ul.select__option-container
   *           each hero in ['Hulk', 'Spider man', 'Storm']
   *             - var hero_formatted = hero.toLowerCase().replace(/\s/g,'-');
   *             li.select__option.radio
   *               input(type='radio' name='character' value=`${hero_formatted}` id=`select-headings-${hero_formatted}`).radio__input
   *               label.select__label.radio__label(for=`select-headings-${hero_formatted}`)
   *                 span.radio__control
   *                 span.select__display.radio__display #{hero}
   *       button.select__clear-button.hidden#select-clear-button-9(type='button' aria-labelledby='select-clear-button-9 select-header-9')
   *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-9' focusable='false')
   *           title#select-clear-icon-9 Clear
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   */
  @include element('section-title') {
    padding: $ukg-spacing-xs 0;
    margin-bottom: $ukg-spacing-xxxs;
    border-bottom: rem($form-field-border-width) solid hsla(var(--grey-level-5-h), var(--grey-level-5-s), var(--grey-level-5-l), 0.4);
    display: block;
    font-weight: $bold;
  }

  /**
   * Select with section sub headings:
   * `.select`
   *
   * A select where the options are listed under headings and sub headings.
   *
   *     @example
   *     - var groups = [{name:'Français', sub:[{name:'Tetine',forms: 1},{name:'CE',forms: 2}]}];
   *     - groups.push({name:'English', sub:[{name:'Payslips',forms: 2},{name:'Remuneration',forms: 1}]});
   *     fieldset.select.select--closed
   *       .select__header#select-header-10(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
   *         span.select__title Select form
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         each group in groups
   *           - var gname = group.name;
   *           span.select__section-title #{gname}
   *           each subgroup in group.sub
   *             - var sname = subgroup.name
   *             span.select__section-subtitle #{sname}
   *             ul.select__option-container
   *               - for (var i=1;i<=subgroup.forms;i++) {
   *                 - var optionID = gname.toLowerCase() +"-" + sname.toLowerCase() + "-form-" + i
   *                 li.select__option.radio
   *                   input(type='radio' name='form' value=`${optionID}` id=`select-subheadings-${optionID}`).radio__input
   *                   label.select__label.radio__label(for=`select-subheadings-${optionID}`)
   *                     span.radio__control
   *                     span.select__display.radio__display Form #{i}
   *               - }
   *       button.select__clear-button.hidden#select-clear-button-10(type='button' aria-labelledby='select-clear-button-10 select-header-10')
   *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-10' focusable='false')
   *           title#select-clear-icon-10 Clear
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   */
  @include element('section-subtitle') {
    @include h6;
    padding: $ukg-spacing-xs 0;
    margin: rem(0 $select-body-horizontal-padding);
    color: $ukg-text-low-emphasis-on-light;
    display: block;
  }

  /**
   * Select static:
   * `.select--static`
   *
   * A select where, when opened, it breaks the flow of the document and pushes
   * down other content
   *
   *     @example
   *     fieldset.select.select--static.select--closed
   *       .select__header#select-header-11(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
   *         span.select__title Select brother
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         ul.select__option-container
   *           li.select__option.radio
   *             input(type='radio' name='brother' value='mario' id='select-static-mario').radio__input
   *             label.select__label.radio__label(for='select-static-mario')
   *               span.radio__control
   *               span.select__display.radio__display Mario
   *           li.select__option.radio
   *             input(type='radio' name='brother' value='luigi' id='select-static-luigi').radio__input
   *             label.select__label.radio__label(for='select-static-luigi')
   *               span.radio__control
   *               span.select__display.radio__display Luigi
   *       button.select__clear-button.hidden#select-clear-button-11(type='button' aria-labelledby='select-clear-button-11 select-header-11')
   *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-11' focusable='false')
   *           title#select-clear-icon-11 Clear
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   */
  @include modifier('static') {
    position: relative;

    @include element('body') {
      position: static;
      width: auto;
    }
  }

  /**
   * Top select:
   * `.select--top`
   *
   * A select that appears above of the trigger.
   *
   * A typical use of this modifier is with a select inside of the [footer](../docs/blocks-footer.html) component.
   *
   *     @example
   *     fieldset.select.select--closed.select--top#top-select-demo
   *       .select__header#top-select-header(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
   *         span.select__title Select temperature
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         ul.select__option-container
   *           li.select__option.radio
   *             input(type='radio' name='top-select-options' value='hot' id='top-select-option-1').radio__input
   *             label.select__label.radio__label(for='top-select-option-1')
   *               span.radio__control
   *               span.select__display.radio__display Hot
   *           li.select__option.radio
   *             input(type='radio' name='top-select-options' value='cold' id='top-select-option-2').radio__input
   *             label.select__label.radio__label(for='top-select-option-2')
   *               span.radio__control
   *               span.select__display.radio__display Cold
   *       button.select__clear-button.hidden#top-select-clear-button(type='button' aria-labelledby='top-select-clear-button top-select-header')
   *         svg.button__icon.icon(role='img' aria-labelledby='top-select-clear-icon' focusable='false')
   *           title#top-select-clear-icon Clear
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   */
  @include modifier('top') {
    @include element('body') {
      top: 0;
      transform: translateY(-100%);
      border-bottom: 0;
      border-top: rem($form-field-border-width) solid form-field-color(standard, border);
      border-radius: rem($form-field-border-radius $form-field-border-radius 0 0);
    }

    @include and-modifier('open') {
      @include element('header') {
        border-radius: rem(0 0 $form-field-border-radius $form-field-border-radius);
      }
    }
  }

  /**
   * Large select:
   * `.select--large`
   *
   * A large select.
   *
   *     @example
   *     fieldset.select.select--large.select--closed
   *       .select__header#select-header-12(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
   *         span.select__title Select icecream
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         ul.select__option-container
   *           li.select__option.radio
   *             input(type='radio' name='icecream' value='vanilla' id='large-select-vanilla').radio__input
   *             label.select__label.radio__label(for='large-select-vanilla')
   *               span.radio__control
   *               span.select__display.radio__display Vanilla
   *           li.select__option.radio
   *             input(type='radio' name='icecream' value='strawberry' id='large-select-strawberry').radio__input
   *             label.select__label.radio__label(for='large-select-strawberry')
   *               span.radio__control
   *               span.select__display.radio__display Strawberry
   *       button.select__clear-button.hidden#select-clear-button-12(type='button' aria-labelledby='select-clear-button-12 select-header-12')
   *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-12' focusable='false')
   *           title#select-clear-icon-12 Clear
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   */
  @include modifier('large') {

    @include element('toggle-icon') {
      top: rem(7px);
    }

    @include element('clear-button') {
      top: rem(4px);
    }

    @include element('header') {
      padding: rem($large-select-padding);
      border-width: rem($form-field-border-width);
      min-height: rem($select-large-min-height);
    }

    @include mixie('selected') {
      // Align selected buttons
      position: relative;
      top: rem(1px);
    }

    @include element('body') {
      border-top-width: rem($form-field-border-width);
      width: rem($select-large-body-width);
    }
  }

  /**
   * Short select:
   * `.select--short`
   *
   * A short select.
   *
   *     @example
   *     fieldset.select.select--short.select--closed
   *       .select__header#select-header-13(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
   *         span.select__title Month
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         ul.select__option-container
   *           - var months = ['Jan', 'Feb'];
   *             each month in months
   *               - var month_lowercase = month.toLowerCase();
   *               li.select__option.radio
   *                 input(type='radio' name='month' value=`${month_lowercase}` id=`short-select-${month_lowercase}`).radio__input
   *                 label.select__label.radio__label(for=`short-select-${month_lowercase}`)
   *                   span.radio__control
   *                   span.select__display.radio__display #{month}
   *       button.select__clear-button.hidden#select-clear-button-13(type='button' aria-labelledby='select-clear-button-13 select-header-13')
   *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-13' focusable='false')
   *           title#select-clear-icon-13 Clear
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   */
  @include modifier('short') {
    max-width: rem($short-form-field-width);

    @include element('body') {
      width: rem($select-short-body-width);
    }
  }

  /**
   * Medium select:
   * `.select--medium`
   *
   * A medium select.
   *
   *     @example
   *     fieldset.select.select--medium.select--closed
   *       .select__header#select-header-14(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
   *         span.select__title Zoom
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         ul.select__option-container
   *           - var arr = [{text: 'half', val:'50%'}, {text: 'full', val:'100%'}];
   *           each o in arr
   *             - var text = o.text;
   *             - var val = o.val;
   *             li.select__option.radio
   *               input(type='radio' name='zoom' value=`${text}` id=`medium-select-${text}`).radio__input
   *               label.select__label.radio__label(for=`medium-select-${text}`)
   *                 span.radio__control
   *                 span.select__display.radio__display #{val}
   *       button.select__clear-button.hidden#select-clear-button-14(type='button' aria-labelledby='select-clear-button-14 select-header-14')
   *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-14' focusable='false')
   *           title#select-clear-icon-14 Clear
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   */
  @include modifier('medium') {
    max-width: rem($medium-form-field-width);

    @include element('body') {
      width: rem($select-medium-body-width);
    }
  }

  /**
   * Long select:
   * `.select--long`
   *
   * A long select.
   *
   *     @example
   *     fieldset.select.select--long.select--closed
   *       .select__header#select-header-15(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
   *         span.select__title Year
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         ul.select__option-container
   *           - for (var y=2000;y<2006;y++) {
   *           li.select__option.radio
   *             input(type='radio' name='year' value=`year-${y}` id=`long-select-year-${y}`).radio__input
   *             label.select__label.radio__label(for=`long-select-year-${y}`)
   *               span.radio__control
   *               span.select__display.radio__display #{y}
   *           - }
   *       button.select__clear-button.hidden#select-clear-button-15(type='button' aria-labelledby='select-clear-button-15 select-header-15')
   *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-15' focusable='false')
   *           title#select-clear-icon-15 Clear
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   */
  @include modifier('long') {
    max-width: rem($long-form-field-width);

    @include element('body') {
      width: rem($select-long-body-width);
      overflow-x: auto;
    }
  }

  /**
   * Full width select:
   * `.select--full-width`
   *
   * A full width select.
   *
   *     @example
   *     fieldset.select.select--full-width.select--closed
   *       .select__header#select-header-16(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
   *         span.select__title Soup
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         ul.select__option-container
   *           each soup in ['Mushroom','Onion', 'Pumpkin']
   *             - var lc = soup.toLowerCase();
   *             li.select__option.radio
   *               input(type='radio' name='soup' value=`${lc}` id=`full-width-select-${lc}`).radio__input
   *               label.select__label.radio__label(for=`full-width-select-${lc}`)
   *                 span.radio__control
   *                 span.select__display.radio__display #{soup}
   *       button.select__clear-button.hidden#select-clear-button-16(type='button' aria-labelledby='select-clear-button-16 select-header-16')
   *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-16' focusable='false')
   *           title#select-clear-icon-16 Clear
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   */
  @include modifier('full-width') {
    max-width: none;

    @include element('header') {
      width: 100%;
    }

    @include element('body') {
      width: calc(100% - #{rem($select-body-horizontal-padding)} * 2);
    }
  }

  /**
   * Fixed width select:
   * `.select--fixed-width`
   *
   * A non-responsive select of a fixed width.
   *
   * Can be used by itself or in conjunction with the `select--short`, `select--medium` or `select--long` modifiers
   *
   *     @example
   *     fieldset.select.select--fixed-width.select--closed
   *       .select__header#select-header-17(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
   *         span.select__title Soup
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         ul.select__option-container
   *           each soup in ['Mushroom','Onion', 'Pumpkin']
   *             - var lc = soup.toLowerCase();
   *             li.select__option.radio
   *               input(type='radio' name='soup' value=`${lc}` id=`fixed-width-select-${lc}`).radio__input
   *               label.select__label.radio__label(for=`fixed-width-select-${lc}`)
   *                 span.radio__control
   *                 span.select__display.radio__display #{soup}
   *       button.select__clear-button.hidden#select-clear-button-17(type='button' aria-labelledby='select-clear-button-17 select-header-17')
   *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-17' focusable='false')
   *           title#select-clear-icon-17 Clear
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   */
  @include modifier('fixed-width') {
    width: rem($form-field-width);

    @include and-modifier('short') {
      width: rem($short-form-field-width);
    }

    @include and-modifier('medium') {
      width: rem($medium-form-field-width);
    }

    @include and-modifier('long') {
      width: rem($long-form-field-width);
    }
  }

  /**
   * Auto-expand height:
   * `.select--auto-expand-height`
   *
   * A select where the height of the dropdown auto-expands to fit all of its content.
   *
   *     @example
   *     fieldset.select.select--closed.select--auto-expand-height#select-custom-search-select-height-auto(data-no-auto)
   *       .select__header#select-header-18(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
   *         span.select__title Select animal
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         input.select__search.input(type='text' placeholder='Search animals' data-filter aria-label='Search')
   *         ul.select__option-container
   *           each display, id in {'machie machie':'Machie machie', 'pig': 'Pig', 'dog': 'Dog', 'cat': 'Cat', 'buffalo': 'Buffalo', 'panthera': 'Panthera', 'fox': 'Fox', 'bandicoot': 'Bandicoot', 'hedgehog': 'Hedgehog', 'heron': 'Heron', 'crane': 'Crane', 'bobcat': 'Bobcat'}
   *             li.select__option.radio
   *               input(type='radio' name='animals' value=id, id=id).radio__input
   *               label.select__label.radio__label(for=id)
   *                 span.radio__control
   *                 span.select__display.radio__display= display
   *       button.select__clear-button.hidden#select-clear-button-18(type='button' aria-labelledby='select-clear-button-18 select-header-18')
   *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-18' focusable='false')
   *           title#select-clear-icon-18 Clear
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   *     script.
   *        $('#select-custom-search-select-height-auto').select({
   *          match: function (input, option) {
   *            let re = new RegExp(input,'gi');
   *            return {
   *              hasMatch: option.match(re) && option.match(re).length > 1,
   *              html: option.replace(re, `<span style='color: red;'>${input}</span>`) //optional
   *            };
   *          }
   *        });
   */
  @include modifier('auto-expand-height') {
    @include element('body') {
      max-height: none;
    }
  }

  /**
   * Auto-expand width:
   * `.select--auto-expand-width`
   *
   * A select that will auto-expand horizontally to fit its content.
   *
   * You may use `[data-max-width={pixels}]` to limit the horizontal expansion.
   *
   * <div class="alert alert--warning top-margin top-margin--small"><code>[data-max-width={pixels}]</code> can only be used with <code>.select--auto-expand-width</code></div>
   *
   *     @example
   *     fieldset.select.select--auto-expand-width.select--closed#auto-expand-width-demo
   *       .select__header#select-header-19(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
   *         span.select__title Select some realy really really long value
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         ul.select__option-container
   *           li.select__option.radio
   *             input(type='radio' name='temperature-4' value='some realy really really long value' id='test-value-1-auto-expand-width').radio__input
   *             label.select__label.radio__label(for='test-value-1-auto-expand-width')
   *               span.radio__control
   *               span.select__display.radio__display some realy really really long value
   *           li.select__option.radio
   *             input(type='radio' name='temperature-4' value='some realy really really really long value' id='test-value-2-auto-expand-width').radio__input
   *             label.select__label.radio__label(for='test-value-2-auto-expand-width')
   *               span.radio__control
   *               span.select__display.radio__display some realy really really really long value
   *       button.select__clear-button.hidden#select-clear-button-19(type='button' aria-labelledby='select-clear-button-19 select-header-19')
   *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-19' focusable='false')
   *           title#select-clear-icon-19 Clear
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   *     br
   *     br
   *     span with a custom max-width set to 300px:
   *     br
   *     br
   *     fieldset.select.select--closed.select--auto-expand-width#maximum-width-demo(data-max-width=300)
   *       .select__header#select-header-20(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
   *         span.select__title Select some realy really really long value
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         ul.select__option-container
   *           li.select__option.radio
   *             input(type='radio' name='temperature-1' value='some realy really really long value' id='test-value-1-max-width').radio__input
   *             label.select__label.radio__label(for='test-value-1-max-width')
   *               span.radio__control
   *               span.select__display.radio__display some realy really really long value
   *           li.select__option.radio
   *             input(type='radio' name='temperature-1' value='some realy really really really long value' id='test-value-2-max-width').radio__input
   *             label.select__label.radio__label(for='test-value-2-max-width')
   *               span.radio__control
   *               span.select__display.radio__display some realy really really really long value
   *       button.select__clear-button.hidden#select-clear-button-20(type='button' aria-labelledby='select-clear-button-20 select-header-20')
   *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-20' focusable='false')
   *           title#select-clear-icon-20 Clear
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   */
  @include modifier('auto-expand-width') {
    width: auto;
    max-width: none;

    @include element('body') {
      width: auto;
      max-width: none;
    }
  }

  /**
   * Disabled select:
   * `.select[disabled]`
   *
   * A disabled select.
   * Removing or adding the attribute `disabled` enables or disables the select component.
   *
   * <div class="alert alert--warning top-margin">
   *   <strong>Deprecated:</strong>
   *   The CSS class <code>.select--disabled</code> is deprecated and replaced by the attribute <code>disabled</code>.
   * </div>
   *
   * <div class="alert alert--warning top-margin">
   *   <strong>Deprecated:</strong>
   *   The <code>.select</code> element must be a <code>&lt;fieldset&gt;</code> to disable natively every children.<br/>
   *   Otherwise, we would have to disable each <code>&lt;input&gt;</code> element inside of the select dropdown (search input, radios or checkboxes) manually with the HTML attribute <code>disabled</code>.
   * </div>
   *
   *     @example
   *     fieldset.select.select--closed(disabled)
   *       .select__header#select-header-21(data-toggle='select' aria-expanded='false' aria-disabled='true' role='button' tabindex=-1)
   *         span.select__title Select color
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         ul.select__option-container
   *           each colour in ['Red','Green']
   *             - var lc = colour.toLowerCase();
   *             li.select__option.radio
   *               input(type='radio' name='color' value=`${lc}` id=`disabled-select-${lc}`).radio__input
   *               label.select__label.radio__label(for=`disabled-select-${lc}`)
   *                 span.radio__control
   *                 span.select__display.radio__display #{colour}
   *       button.select__clear-button.hidden#select-clear-button-21(type='button' aria-labelledby='select-clear-button-21 select-header-21')
   *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-21' focusable='false')
   *           title#select-clear-icon-21 Clear
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   *
   *     fieldset.fieldset(disabled)
   *       legend It inherits from a disabled fieldset too
   *       fieldset.select.select--closed
   *         .select__header#select-header-22(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
   *           span.select__title Select color
   *           svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *             use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *         .select__body
   *           ul.select__option-container
   *             each colour in ['Red','Green']
   *               - var lc = colour.toLowerCase();
   *               li.select__option.radio
   *                 input(type='radio' name='color' value=`${lc}` id=`disabled-select-2-${lc}`).radio__input
   *                 label.select__label.radio__label(for=`disabled-select-2-${lc}`)
   *                   span.radio__control
   *                   span.select__display.radio__display #{colour}
   *         button.select__clear-button.hidden#select-clear-button-22(type='button' aria-labelledby='select-clear-button-22 select-header-22')
   *           svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-22' focusable='false')
   *             title#select-clear-icon-22 Clear
   *             use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   */
  &:disabled {
    @include disabled;

    .select__header {
      cursor: default;
    }

    .select__clear-button {
      display: none;
    }

    .select__selected {
      @include block('icon') {
        display: none;
      }
    }
  }

  /**
   * Required select:
   *
   * A required select.
   *
   * <div class="card card--standout top-margin">
   *   <div class="card__body">
   *     <p>
   *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title1" focusable="false">
   *         <title id="title1">Information</title>
   *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *       </svg>
   *       <strong>Accessibility</strong>
   *     </p>
   *     <p>Make sure to add <code>aria-required="true"</code> to the <code>.select__header</code> so that this information is conveyed to assistive technologies.</p>
   *   </div>
   * </div>
   *
   *     @example
   *     fieldset.select.select--closed#required-select-demo
   *       legend.label.label--required#select-label-31 Temperature
   *       .select__header#select-header-31(data-toggle='select' aria-required="true" aria-expanded='false' aria-labelledby='select-label-31 select-header-31' role='button' tabindex=0)
   *         span.select__title Select temperature
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         ul.select__option-container
   *           li.select__option.radio
   *             input(type='radio' name='temperature-required' value='hot' id='hot-31' required).radio__input
   *             label.select__label.radio__label(for='hot-31')
   *               span.radio__control
   *               span.select__display.radio__display Hot
   *           li.select__option.radio
   *             input(type='radio' name='temperature-required' value='cold' id='cold-31' required).radio__input
   *             label.select__label.radio__label(for='cold-31')
   *               span.radio__control
   *               span.select__display.radio__display Cold
   *       button.select__clear-button.hidden#select-clear-button-31(type='button' aria-labelledby='select-clear-button-31 select-header-31')
   *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-31' focusable='false')
   *           title#select-clear-icon-31 Clear
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   */

  /**
   * Dark select:
   * `.select--dark`
   *
   * A dark select.
   *
   *     @example
   *     fieldset.select.select--dark.select--closed
   *       .select__header#select-header-22(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
   *         span.select__title Select drink
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         ul.select__option-container
   *           each drink in ['Beer','Wine']
   *             - var lc = drink.toLowerCase();
   *             li.select__option.radio
   *               input(type='radio' name='drink' value=`${lc}` id=`dark-select-${lc}`).radio__input
   *               label.select__label.radio__label(for=`dark-select-${lc}`)
   *                 span.radio__control
   *                 span.select__display.radio__display #{drink}
   *       button.select__clear-button.hidden#select-clear-button-22(type='button' aria-labelledby='select-clear-button-22 select-header-22')
   *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-22' focusable='false')
   *           title#select-clear-icon-22 Clear
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   */
  @include modifier('dark') {
    .select__header,
    .select__body {
      @include dark-form-field;
    }
  }

  /**
   * Valid select:
   * `.select--valid`
   *
   * A valid select.
   *
   *     @example
   *     fieldset.select.select--valid.select--closed
   *       .select__header#select-header-23(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
   *         span.select__title Select hero
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *         ul.select__option-container
   *           each hero in ['Voltron','Megazord']
   *             - var lc = hero.toLowerCase();
   *             li.select__option.radio
   *               input(type='radio' name='hero' value=`${lc}` id=`valid-select-${lc}`).radio__input
   *               label.select__label.radio__label(for=`valid-select-${lc}`)
   *                 span.radio__control
   *                 span.select__display.radio__display #{hero}
   *       button.select__clear-button.hidden#select-clear-button-23(type='button' aria-labelledby='select-clear-button-23 select-header-23')
   *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-23' focusable='false')
   *           title#select-clear-icon-23 Clear
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   */
  @include modifier('valid') {
    .select__header {
      @include valid-form-field;
    }
  }

  /**
   * Invalid select:
   * `.select--invalid`
   *
   * An invalid select.
   *
   *     @example
   *     fieldset.select.select--invalid.select--closed
   *       .select__header#select-header-24(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
   *         span.select__title Select villain
   *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
   *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
   *       .select__body
   *          ul.select__option-container
   *            each villain in ['Shredder','Texas Pete']
   *              - var lc = villain.toLowerCase().replace(/\s/g, '-');
   *              li.select__option.radio
   *                input(type='radio' name='villain' value=`${lc}` id=`invalid-select-${lc}`).radio__input
   *                label.select__label.radio__label(for=`invalid-select-${lc}`)
   *                  span.radio__control
   *                  span.select__display.radio__display #{villain}
   *       button.select__clear-button.hidden#select-clear-button-24(type='button' aria-labelledby='select-clear-button-24 select-header-24')
   *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-24' focusable='false')
   *           title#select-clear-icon-24 Clear
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   */
  @include modifier('invalid') {
    .select__header {
      @include invalid-form-field;
    }
  }
}

/**
 * Add options to a populated select, after initialization:
 *
 * Options can be passed as an array of objects, where `label` and `value` are explicitly defined:
 * <br><br>
 *
 * ```
 * $(el).select('addOptions',
 *  [{
 *    label: 'Enterprise',
 *    value: 'enterprise'
 *  }, {
 *    label: 'Delorean',
 *    value: 'delorean'
 *  }]
 * );
 * ```
 *
 * <div class="alert alert--warning top-margin top-margin--small bottom-margin bottom-margin--small">If the <code>label</code> key is omitted then the value of the <code>value</code> key will be used for <code>label</code>.</div>
 *
 * ```
 * $(el).select('addOptions',
 *  [{
 *    value: 'enterprise'
 *  }, {
 *    label: 'Delorean',
 *    value: 'delorean'
 *  }]);
 * ```
 *
 * An array of strings can be passed instead, in which case the value of each string will be used for both `value` and `label`:
 * <br><br>
 *
 * ```
 * $(el).select('addOptions', [
 *   'enterprise',
 *   'delorean'
 * ]);
 * ```
 *
 * A mixed array can also be used:
 * <br><br>
 *
 * ```
 * $(el).select('addOptions',
 *  [{
 *    label: 'Enterprise',
 *    value: 'enterprise'
 *   }, {
 *    value: 'falcon'
 *   },
 *   'delorean'
 *  ]);
 * ```
 *
 * <div class="alert alert--warning top-margin top-margin--small">The <code>[name]</code> and <code>[type]</code> of the new inputs will be picked up from the existing ones.</div>
 * <div class="alert alert--warning top-margin top-margin--small">All of the above types of arrays can also be used on all of the below <code>addOptions</code> examples</div>
 *
 *     @example
 *     fieldset.select.select--closed#select-add-options-to-populated-select(data-no-auto)
 *       .select__header#select-header-25(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
 *         span.select__title Select vehicle
 *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
 *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
 *       button.select__a11y-clear-all.button.hidden Clear all options
 *       .select__body
 *         ul.select__option-container
 *           li.select__option.checkbox
 *             input(type='checkbox' name='vehicle-1' value='motorcycle' id='select-add-options-to-populated-select-motorcycle').checkbox__input
 *             label(for='select-add-options-to-populated-select-motorcycle').select__label.checkbox__label
 *               span.checkbox__control
 *                 svg.checkbox__icon.icon
 *                   use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
 *               span.select__display.checkbox__display Motorcycle
 *       button.select__clear-button.hidden#select-clear-button-25(type='button' aria-labelledby='select-clear-button-25 select-header-25')
 *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-25' focusable='false')
 *           title#select-clear-icon-25 Clear
 *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
 *     script.
 *       $('#select-add-options-to-populated-select').select();
 *       $('#select-add-options-to-populated-select').select('addOptions', [{
 *         label: 'Enterprise',
 *         value: 'enterprise'
 *       }, {
 *         label: 'Delorean',
 *         value: 'delorean'
 *       }]);
 */

/**
 * Add options to an unpopulated select, after initialization:
 *
 * <br>
 *
 * ```
 * $(el).select('addOptions', {
 *   inputName: 'vehicle',
 *   multiple: true,
 *   inputs: [{
 *     label: 'Enterprise',
 *     value: 'enterprise'
 *   }]
 * });
 * ```
 *
 * <div class="alert alert--warning top-margin top-margin--small">If <code>multiple</code> is omitted, it will default to false (i.e. <code>&lt;input type="radio"/&gt;</code>).</div>
 *
 * <div class="alert alert--danger top-margin top-margin--small"><code>inputName</code> can be omitted only if <code>[data-input-name]</code> is being used (see example #5).</div>
 *
 *     @example
 *     fieldset.select.select--closed#select-add-options-to-unpopulated-select(data-no-auto)
 *       .select__header#select-header-26(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
 *         span.select__title Select vehicle
 *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
 *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
 *       .select__body
 *         ul.select__option-container
 *       button.select__clear-button.hidden#select-clear-button-26(type='button' aria-labelledby='select-clear-button-26 select-header-26')
 *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-26' focusable='false')
 *           title#select-clear-icon-26 Clear
 *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
 *     script.
 *       $('#select-add-options-to-unpopulated-select').select();
 *       $('#select-add-options-to-unpopulated-select').select('addOptions', {
 *         inputName: 'vehicle-2',
 *         multiple: true,
 *         inputs: [{
 *           label: 'Enterprise',
 *           value: 'enterprise'
 *         }, {
 *           label: 'Delorean',
 *           value: 'delorean'
 *         }]
 *       });
 */

/**
 * Add options to a populated select, during initialization:
 *
 * <br>
 *
 * ```
 * $(el).select({inputs: [{
 *   label: 'Enterprise',
 *   value: 'enterprise'
 * }]});
 * ```
 *
 * <div class="alert alert--warning top-margin top-margin--small">The <code>[name]</code> and <code>[type]</code> of the new inputs will be picked up from the existing ones.</div>
 *
 *     @example
 *     fieldset.select.select--closed#select-add-options-to-populated-during-select(data-no-auto)
 *       .select__header#select-header-27(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
 *         span.select__title Select vehicle
 *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
 *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
 *       button.select__a11y-clear-all.button.hidden Clear all options
 *       .select__body
 *         ul.select__option-container
 *           li.select__option.checkbox
 *             input(type='checkbox' name='vehicle-3' value='motorcycle' id='select-add-options-to-populated-select-during-motorcycle').checkbox__input
 *             label(for='select-add-options-to-populated-select-during-motorcycle').select__label.checkbox__label
 *               span.checkbox__control
 *                 svg.checkbox__icon.icon
 *                   use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
 *               span.select__display.checkbox__display Motorcycle
 *       button.select__clear-button.hidden#select-clear-button-27(type='button' aria-labelledby='select-clear-button-27 select-header-27')
 *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-27' focusable='false')
 *           title#select-clear-icon-27 Clear
 *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
 *     script.
 *       $('#select-add-options-to-populated-during-select').select({inputs: [{
 *         label: 'Enterprise',
 *         value: 'enterprise'
 *       }, {
 *         label: 'Delorean',
 *         value: 'delorean'
 *       }]});
 */

/**
 * Add options to an unpopulated select, during initialization:
 *
 * <br>
 *
 * ```
 * $(el).select({
 *   inputName: 'vehicle',
 *   multiple: true,
 *   inputs: [{
 *     label: 'Enterprise',
 *     value: 'enterprise'
 *   }]
 * });
 * ```
 *
 * <div class="alert alert--warning top-margin top-margin--small">If <code>multiple</code> is omitted, it will default to false (i.e. <code>&lt;input type="radio"/&gt;</code>).</div>
 *
 * <div class="alert alert--danger top-margin top-margin--small"><code>inputName</code> can be omitted only if <code>[data-input-name]</code> is being used (see example #5).</div>
 *
 *     @example
 *     fieldset.select.select--closed#select-add-options-to-unpopulated-during-select(data-no-auto)
 *       .select__header#select-header-28(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
 *         span.select__title Select vehicle
 *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
 *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
 *       .select__body
 *         ul.select__option-container
 *       button.select__clear-button.hidden#select-clear-button-28(type='button' aria-labelledby='select-clear-button-28 select-header-28')
 *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-28' focusable='false')
 *           title#select-clear-icon-28 Clear
 *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
 *     script.
 *       $('#select-add-options-to-unpopulated-during-select').select({
 *         inputName: 'vehicle-4',
 *         multiple: true,
 *         inputs: [{
 *           label: 'Enterprise',
 *           value: 'enterprise'
 *         }, {
 *           label: 'Delorean',
 *           value: 'delorean'
 *         }]
 *       });
 */

/**
 * Remove options:
 * `$(el).select('removeOptions', ['pizza', 'salad', 'cake']);`
 *
 * A method to remove an array of options based on their unique `[value]`.
 *
 *     @example
 *     fieldset.select.select--closed(id='select-remove-1')
 *       .select__header#select-header-29(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
 *         span.select__title Select colour
 *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
 *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
 *       .select__body
 *         ul.select__option-container
 *           li.select__option.radio
 *             input(type='radio' name='colour' value='red' id='select-remove-1-red').radio__input
 *             label.select__label.radio__label(for='select-remove-1-red')
 *               span.radio__control
 *               span.select__display.radio__display red
 *           li.select__option.radio
 *             input(type='radio' name='colour' value='blue' id='select-remove-1-blue').radio__input
 *             label.select__label.radio__label(for='select-remove-1-blue')
 *               span.radio__control
 *               span.select__display.radio__display blue
 *           li.select__option.radio
 *             input(type='radio' name='colour' value='white' id='select-remove-1-white').radio__input
 *             label.select__label.radio__label(for='select-remove-1-white')
 *               span.radio__control
 *               span.select__display.radio__display white
 *       button.select__clear-button.hidden#select-clear-button-29(type='button' aria-labelledby='select-clear-button-29 select-header-29')
 *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-29' focusable='false')
 *           title#select-clear-icon-29 Clear
 *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
 *     br
 *     br
 *     button.button(id='select-remove-1-button') Remove "blue"
 *     script.
 *       $('#select-remove-1-button').on('click', function () {
 *         if (/remove/i.test($(this).text())) {
 *           $('#select-remove-1').select('removeOptions', ['blue']);
 *           $(this).text('add "blue"');
 *         } else {
 *           $('#select-remove-1').select('addOptions', [{label: 'Blue', value: 'blue'}]);
 *           $(this).text('remove "blue"');
 *         }
 *       });
 */

/**
 * Remove All Options:
 * `$(el).select('removeOptions');`
 *
 * A method to remove all options from a `select`.
 *
 *     @example
 *     fieldset.select.select--closed(id='select-remove-2')
 *       .select__header#select-header-30(data-toggle='select' aria-expanded='false' role='button' tabindex=0)
 *         span.select__title Select colour
 *         svg.select__toggle-icon.select__toggle-icon--closed.icon(aria-hidden='true' focusable='false')
 *           use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
 *       .select__body
 *         ul.select__option-container
 *           li.select__option.radio
 *             input(type='radio' name='colour' value='red' id='select-remove-2-red').radio__input
 *             label.select__label.radio__label(for='select-remove-2-red')
 *               span.radio__control
 *               span.select__display.radio__display red
 *           li.select__option.radio
 *             input(type='radio' name='colour' value='blue' id='select-remove-2-blue').radio__input
 *             label.select__label.radio__label(for='select-remove-2-blue')
 *               span.radio__control
 *               span.select__display.radio__display blue
 *           li.select__option.radio
 *             input(type='radio' name='colour' value='white' id='select-remove-2-white').radio__input
 *             label.select__label.radio__label(for='select-remove-2-white')
 *               span.radio__control
 *               span.select__display.radio__display white
 *       button.select__clear-button.hidden#select-clear-button-30(type='button' aria-labelledby='select-clear-button-30 select-header-30')
 *         svg.button__icon.icon(role='img' aria-labelledby='select-clear-icon-30' focusable='false')
 *           title#select-clear-icon-30 Clear
 *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
 *     br
 *     br
 *     button.button(id='select-remove-2-button' type='button') Remove all options
 *     script.
 *       $('#select-remove-2-button').on('click', function () {
 *         $('#select-remove-2').select('removeOptions');
 *       });
 */
