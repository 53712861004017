/***
 * # Standout text
 *
 * A pairing of large text to be placed directly against the page background.
 * Contains a primary size and secondary size.
 *
 * Use for displaying important text. Do not use inside containers, e.g. cards
 * or headers.
 */

$standout-text-color:           color(grey, 4);
$standout-text-primary-size:    60px;
$standout-text-secondary-size:  40px;
$standout-text-line-height:     1.1;

/**
 * Basic standout text:
 * `.standout-text`
 *
 * A text block for displaying standout text.
 *
 *     @example
 *     .standout-text
 *       h1.standout-text__primary 404
 *       h2.standout-text__secondary Page not found
 */
@include block('standout-text') {
  color: $standout-text-color;

  @include element('primary') {
    @include rem(font-size, $standout-text-primary-size);
    line-height: $standout-text-line-height;
    text-transform: uppercase;
    color: inherit;
    margin: 0;
  }

  @include element('secondary') {
    @include rem(font-size, $standout-text-secondary-size);
    line-height: $standout-text-line-height;
    color: inherit;
    margin: 0;
  }
}
