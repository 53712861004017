/***
 * # Textareas
 *
 * A form input for long content, with an optional placeholder.
 *
 * ## When to use textareas
 *
 * Textareas should be used for collecting long-form information.
 * They should always be paired with a label to show the user what content
 * to submit. Placeholders can also be used, but should never act as the only
 * label for the field.
 *
 * ## When not to use textareas
 *
 * Do not use textareas for short form information - use an input instead.
 *
 * ## Position and style
 *
 * Textareas can optionally be styled with `dark`, `valid` and `invalid`
 * modifiers. The dark modifier should be applied when the textarea is placed
 * directly against the page background. The invalid and valid modifiers should
 * be applied to indicate data validity.
 *
 * ## Initialization
 *
 * <div class="alert alert--danger top-margin top-margin--small">
 *   <p>This component will auto-instantiate unless it has the <code>[data-no-auto]</code> attribute at page load (DOMContentLoaded) or on <code>init.pdui</code> event recept on an ancestor.<p>
 *   <p>If the component is added to the page dynamically, trigger the <code>init.pdui</code> event on a parent node to instantiate it.</p>
 *   <p>If the component is not instantiated, it will auto-instantiate as soon as a method of that component is called programmatically, regardless of the presence of <code>[data-no-auto]</code>.</p>
 *   <p>If you don't set the component to auto-instantiate and you wish to instantiate it manually (pass options to it etc.) do:<br><code>$(el).textarea();</code></p>
 * </div>
 */

$textarea-width:                $long-form-field-width;
$textarea-height:               150px;

/*
  Variables not listed above can be found in src/settings/_form.scss
  Mixins can be found in src/tools/_form.scss
*/

/**
 * Basic textarea:
 * `.textarea`
 *
 * Just a standard textarea.
 *
 *     @example
 *     textarea.textarea
 */
@include block('textarea') {
  @include form-field;
  padding: $ukg-spacing-xs;
  @include rem(max-width, $textarea-width);
  @include rem(min-height, $textarea-height);
  line-height: 1.2;
  resize: vertical; // Prevent textarea from resizing horizontally

  /**
   * Disabled textarea:
   * `.textarea[disabled]`
   *
   * A disabled textarea.
   *
   *     @example
   *     textarea.textarea(disabled)
   *
   */
  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    @include disabled;
  }

  /**
   * Full width textarea:
   * `.textarea--full-width`
   *
   * A full width textarea.
   *
   *     @example
   *     textarea.textarea.textarea--full-width
   */
  @include modifier('full-width') {
    width: 100%;
    max-width: 100%;
  }

  /**
   * Dark textarea:
   * `.textarea--dark`
   *
   * A dark textarea (for using on grey backgrounds).
   *
   *     @example
   *     textarea.textarea.textarea--dark
   */
  @include modifier('dark') {
    @include dark-form-field;
  }

  /**
   * Valid textarea:
   * `.textarea--valid`
   *
   * A valid itextarea
   *
   *     @example
   *     textarea.textarea.textarea--valid
   */
  @include modifier('valid') {
    @include valid-form-field;
  }

  /**
   * Invalid textarea:
   * `.textarea--invalid`
   *
   * An invalid textarea.
   *
   *     @example
   *     textarea.textarea.textarea--invalid
   */
  @include modifier('invalid') {
    @include invalid-form-field;
  }

  /**
   * Auto height textarea:
   * `.textarea--auto-height`
   *
   * A textarea with auto height. You can use this modifier in combination with the HTML attribute `rows` to customize the height of the textarea.
   *
   *     @example
   *     textarea.textarea.textarea--auto-height(rows=3)
   */
  @include modifier('auto-height') {
    min-height: 0;
    height: auto;
  }
}
