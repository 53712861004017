/***
 * # Button Groups
 *
 * As the name suggests - a button group is a group of buttons.
 *
 * ## When to use button groups
 *
 *  - To expose several grouped options at once (without the need for a dropdown)
 *  - To allow users to toggle between options
 *
 * ## When not to use button groups
 *
 * In general, button groups become less usable when more options are added.
 * Try to keep the number of buttons to no more than 5.
 * If you have more options than this, consider using a dropdown menu instead.
 *
 * When using a button group, make sure that the buttons make sense to be grouped together.
 *
 * ## Position and style
 *
 * Button groups can be positioned anywhere in the user interface.
 * Colors should be used to indicate hierarchy and convey meaning.
 *
 * ## Deprecation warnings
 *
 * - [The HTML code for single select button group has changed](#single-select-button-group-old-code).
 * - [The HTML code for multiple select button group has changed](#multiple-select-button-group-old-code).
 */

/*
  Variables can be found in src/settings/_button.scss
  Mixins can be found in src/tools/_button.scss
*/

/**
 * Basic button group:
 * `.button-group`
 *
 * A standard button group.
 *
 * Also used to build pagination components
 *
 *     @example
 *     include ./code-samples/basic-button-group.pug
 */

@mixin button-group-border-left-radius($large: false) {
  @if $large {
    border-top-left-radius: rem($button-large-border-radius);
    border-bottom-left-radius: rem($button-large-border-radius);
  } @else {
    border-top-left-radius: rem($button-border-radius);
    border-bottom-left-radius: rem($button-border-radius);
  }
}

@mixin button-group-border-right-radius($large: false) {
  @if $large {
    border-top-right-radius: rem($button-large-border-radius);
    border-bottom-right-radius: rem($button-large-border-radius);
  } @else {
    border-top-right-radius: rem($button-border-radius);
    border-bottom-right-radius: rem($button-border-radius);
  }
}

@include block('button-group') {
  display: inline-flex; // 1
  flex-wrap: wrap;

  @include mixie('button') {
    /*
      Make our buttons appear as a group by:

      1. Making them flex items
      2. Removing the border radius' on joining borders
      3. Exposing the active button's border (on hover, on active or when it is pressed)
      4. Exposing the focus ring when adjacent button is hovered
    */
    flex: 0 1 auto; // 1
    border-radius: 0; // 2
    position: relative; // 3
    z-index: index($z-index-scale, button-group-button);

    &:first-child {
      @include button-group-border-left-radius; // 2
    }

    &:last-child {
      @include button-group-border-right-radius; // 2
    }

    &.button--large {
      border-radius: 0; // 2

      &:first-child {
        @include button-group-border-left-radius($large: true); // 2
      }

      &:last-child {
        @include button-group-border-right-radius($large: true); // 2
      }
    }

    ~ .button,
    ~ .dropdown,
    ~ .button-group__button,
    ~ .button-group__dropdown {
      /*
        Leave this without rem mixin, as Internet Explorer is struggling to
        render negative margins in rem.
      */
      margin-left: -1px;
    }

    &:focus {
      z-index: index($z-index-scale, button-group-button-on-focus); // 4
    }

    &:hover,
    &:active {
      z-index: index($z-index-scale, button-group-button-active); // 3
    }

    @include modifier('noshrink') {
      flex-shrink: 0;
    }
  }

  .button--pressed {
    z-index: index($z-index-scale, button-group-button-active); // 3
  }

  // Account for buttons contained inside a 'dropdown' div
  @include mixie('dropdown') {
    ~ .button,
    ~ .dropdown {
      /*
        Leave this without rem mixin, as Internet Explorer is struggling to
        render negative margins in rem.
      */
      margin-left: -1px;
    }

    > .button {
      border-radius: 0; // 2

      &.button--large {
        border-radius: 0; // 2;
      }
    }

    &:first-child {
      > .button {
        @include button-group-border-left-radius; // 2

        &.button--large {
          @include button-group-border-left-radius($large: true); // 2
        }
      }
    }

    &:last-child {
      > .button {
        @include button-group-border-right-radius; // 2

        &.button--large {
          @include button-group-border-right-radius($large: true); // 2
        }
      }
    }
  }

  @include modifier('selectable') {
    /**
     * Single select button group:
     * `.button-group--selectable`
     *
     * Returns a selectable button group. Uses radios.
     *
     *   @example
     *   include ./code-samples/group-button-single-select.pug
     */

    /**
     * Multi select button group:
     * `.button-group--selectable`
     *
     * Returns a multi select button group. Uses checkboxes.
     *
     *   @example
     *   include ./code-samples/multi-select-group-button.pug
     */
    @include element('selectable-item') {
      position: relative; // Ensure that tooltips are positoned correctly

      ~ .button-group__selectable-item {
        margin-left: -1px;
      }

      @include mixie('button') {
        border-radius: 0; // 2
      }

      &:first-child {
        @include mixie('button') {
          @include button-group-border-left-radius; // 2

          &.button--large {
            @include button-group-border-left-radius($large: true); // 2
          }
        }
      }

      &:last-child {
        @include mixie('button') {
          @include button-group-border-right-radius; // 2

          &.button--large {
            @include button-group-border-right-radius($large: true); // 2
          }
        }
      }
    }

    @include mixie('input') {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      z-index: index($z-index-scale, button-group-input);
      border-radius: 0; // 2
      cursor: pointer;

      &:checked + {
        @include mixie('button') {
          @include std-pressed;
          z-index: index($z-index-scale, button-group-button-active);
        }
      }

      &:hover + {
        @include mixie('button') {
          @include std-hover;
        }
      }

      &:focus + {
        @include mixie('button') {
          @include std-focus-indicator;
          z-index: index($z-index-scale, button-group-button-on-focus);
        }
      }

      &:disabled,
      &[aria-disabled='true'] {
        + {
          @include mixie('button') {
            @include disabled;
          }
        }

        &:active + {
          @include mixie('button') {
            @include hide-focus-indicator;
          }
        }

        &:not(:checked):hover + {
          @include mixie('button') {
            @include std-disabled;
          }
        }
      }
    }

    /**
     * Selectable button group - colored:
     * `.button-group--selectable > .button--primary`
     *
     * Returns a selectable button group with colored buttons.
     *
     *   @example
     *   include ./code-samples/selectable-group-button-colored.pug
     */
    @each $color in $color-buttons {
      @include mixie('input') {
        &:checked + .button--#{$color} {
          @include color-pressed($color);
        }

        &:hover + .button--#{$color} {
          @include color-hover($color);

          @if $color = 'primary' {
            color: $font-color-on-primary;
          } @else {
            color: $white;
          }
        }

        &:focus + .button--#{$color} {
          @include color-focus-indicator;
          z-index: index($z-index-scale, button-group-button-on-focus);
        }

        &:disabled,
        &[aria-disabled='true'] {
          &:not(:checked):hover + .button--#{$color} {
            @include color-base($color);
          }
        }
      }
    }

    /**
     * Selectable button group on primary background:
     * `.button-group--selectable > button--on-primary`
     *
     * Returns a selectable button group to use on primary background.
     *
     *   @example
     *   include ./code-samples/selectable-group-button-on-primary.pug
     */
    @include mixie('input') {
      &:checked + .button--on-primary {
        @include on-color-pressed('primary');
      }

      &:hover + .button--on-primary {
        @include on-color-hover('primary');
      }

      &:focus + .button--on-primary {
        @include on-color-focus-indicator('primary');
        z-index: index($z-index-scale, button-group-button-on-focus);
      }

      &:disabled,
      &[aria-disabled='true'] {
        &:not(:checked):hover + .button--on-primary {
          @include on-color-base('primary');
        }
      }
    }

    /**
     * Selectable button group on dark grey background:
     * `.button-group--selectable > button--on-dark-grey`
     *
     * Returns a selectable button group to use on dark grey background.
     *
     *   @example
     *   include ./code-samples/selectable-group-button-on-dark-grey.pug
     */
    @include mixie('input') {
      &:checked + .button--on-dark-grey {
        @include on-dark-grey-pressed;
      }

      &:hover + .button--on-dark-grey {
        @include on-dark-grey-hover;
      }

      &:focus + .button--on-dark-grey {
        @include on-dark-grey-focus-indicator;
        z-index: index($z-index-scale, button-group-button-on-focus);
      }
    }
  }

  /**
   * Color button group:
   * `.button-group > .button--primary`
   *
   *     @example
   *     include ./code-samples/colored-button-group.pug
   */

  /**
   * Button group for on a colored background:
   * `.button-group > .button--on-mycolor`
   *
   *     @example
   *     include ./code-samples/button-group-colored-background.pug
   */

  /**
   * Disabled button group:
   * `button[disabled]`
   *
   *     @example
   *     include ./code-samples/disabled-button-group.pug
   */

  /**
   * Nowrap button group:
   * `.button-group--nowrap > .button-group__button--noshrink`
   *
   * The `.button-group--nowrap` class prevents the group from wrapping items.
   * You can add the `.button-group__button--noshrink` class to a button if
   * you don't want it to shrink like the others.
   *
   *     @example
   *     include ./code-samples/button-group-nowrap.pug
   */

  @include modifier('nowrap') {
    flex-wrap: nowrap;
  }

  /**
   * Use case: dropdown button:
   * `.button-group`
   *
   *     @example
   *     include ./code-samples/dropdown-button.pug
   */

  /**
   * Use case: large button group:
   * `.button-group`
   *
   *     @example
   *     include ./code-samples/large-button-group.pug
   */

  /** Use case: Button group with tooltip:
   *
   * **Important:** the tooltips are not an adjacent sibling of the trigger element, so they won't work automatically.
   * Use the `data-tooltip` attribute to explicitely connect the tooltip with its trigger, as shown in the examples.
   *
   * **Warning**: A disabled element should have its `data-toggle` attribute set on its parent, not the input itself.
   *
   *     @example
   *     // Basic button group
   *     .button-group
   *       button.button-group__button.button(type='button' aria-describedby='uniqueID-tooltip1' data-toggle='tooltip tooltip_name_duck') Button 1
   *       span.button-group__button.tooltip__disabled-wrapper(data-toggle="tooltip tooltip_name_goose")
   *        button.button(type='button' aria-describedby='uniqueID-tooltip2' disabled) Button 2
   *       button.button-group__button.button(type='button' aria-describedby='uniqueID-tooltip3' data-toggle='tooltip tooltip_name_swan') Button 3
   *     .tooltip(id='uniqueID-tooltip1' role='tooltip' data-tooltip='tooltip_name_duck') Additional information 1
   *     .tooltip(id='uniqueID-tooltip2' role='tooltip' data-tooltip='tooltip_name_goose') Additional information 2
   *     .tooltip(id='uniqueID-tooltip3' role='tooltip' data-tooltip='tooltip_name_swan') Additional information 3
   *     br
   *     br
   *     |
   *     |
   *     // Single select button group
   *     .button-group.button-group--selectable
   *       .button-group__selectable-item
   *         input(class='button-group__input' type='radio' name='cheese' value='ricotta' id='uniqueID-ricotta' aria-describedby='uniqueID-ricotta-tooltip' data-toggle='tooltip tooltip_name_ricotta' checked)
   *         label(class='button-group__button button' for='uniqueID-ricotta') Ricotta
   *       .button-group__selectable-item.tooltip__disabled-wrapper(data-toggle='tooltip tooltip_name_parmesan')
   *         input(class='button-group__input' type='radio' name='cheese' value='parmesan' id='uniqueID-parmesan' aria-describedby='uniqueID-parmesan-tooltip' disabled)
   *         label(class='button-group__button button' for='uniqueID-parmesan') Parmesan
   *       .button-group__selectable-item
   *         input(class='button-group__input' type='radio' name='cheese' value='cheddar' id='uniqueID-cheedar' aria-describedby='uniqueID-cheedar-tooltip' data-toggle='tooltip tooltip_name_cheedar')
   *         label(class='button-group__button button' for='uniqueID-cheedar') Cheddar
   *     .tooltip(id='uniqueID-ricotta-tooltip' role='tooltip' data-tooltip='tooltip_name_ricotta') Additional information 1
   *     .tooltip(id='uniqueID-parmesan-tooltip' role='tooltip' data-tooltip='tooltip_name_parmesan') Additional information 2
   *     .tooltip(id='uniqueID-cheedar-tooltip' role='tooltip' data-tooltip='tooltip_name_cheedar') Additional information 3
   */

  .tooltip__disabled-wrapper {
    .button {
      border-radius: 0; // 2
    }

    &:first-child {
      .button {
        @include button-group-border-left-radius; // 2

        &.button--large {
          @include button-group-border-left-radius($large: true); // 2
        }
      }
    }

    &:last-child {
      .button {
        @include button-group-border-right-radius; // 2

        &.button--large {
          @include button-group-border-right-radius($large: true); // 2
        }
      }
    }
  }

  /**
   * ## Deprecations
   */

  /**
   * Single select button group (old code):
   *
   * <div class="alert alert--warning top-margin">
   *   The HTML code in this example has been deprecated.
   *   Its support will be remove in a feature version of PDUI.
   * </div>
   *
   * `input` and `label` elements must be wrapper in a parent `.button-group__selectable-item`,
   * as shown in the example [single select button group](#single-select-button-group).
   *
   * Please update to the new HTML code as soon as possible.
   *
   *     @example
   *     .button-group.button-group--selectable
   *       input(class='button-group__input' type='radio' name='fowers' value='tulip' id='uniqueID-tulip')
   *       label(class='button-group__button button' for='uniqueID-tulip') Tulip
   *       input(class='button-group__input' type='radio' name='fowers' value='poppy' id='uniqueID-poppy' checked)
   *       label(class='button-group__button button' for='uniqueID-poppy') Poppy
   *       input(class='button-group__input' type='radio' name='fowers' value='rose' id='uniqueID-rose')
   *       label(class='button-group__button button' for='uniqueID-rose') Rose
   */

  /**
   * Multiple select button group (old code):
   *
   * <div class="alert alert--warning top-margin">
   *   The HTML code in this example has been deprecated.
   *   Its support will be remove in a feature version of PDUI.
   * </div>
   *
   * `input` and `label` elements must be wrapper in a parent `.button-group__selectable-item`,
   * as shown in the example [multiple select button group](#multiple-select-button-group).
   *
   * Please update to the new HTML code as soon as possible.
   *
   *     @example
   *     .button-group.button-group--selectable
   *       input(class='button-group__input' type='checkbox' value='lily' id='uniqueID-lily')
   *       label(class='button-group__button button' for='uniqueID-lily') Lily
   *       input(class='button-group__input' type='checkbox' value='orchid' id='uniqueID-orchid' checked)
   *       label(class='button-group__button button' for='uniqueID-orchid') Orchid
   *       input(class='button-group__input' type='checkbox' value='daisy' id='uniqueID-daisy')
   *       label(class='button-group__button button' for='uniqueID-daisy') Daisy
   */
  @include modifier('selectable') {
    > {
      @include mixie('input') {
        @include sr-only;

        &:first-child + {
          @include mixie('button') {
            @include button-group-border-left-radius; // 2

            &.button--large {
              @include button-group-border-left-radius($large: true); // 2
            }
          }
        }
      }
    }
  }
}
