/***
 * # Draggable indicators
 *
 * A draggable indicator is a small block that can be added to other blocks
 * to indicate that the user can drag the item.
 *
 * Draggable indicators can be added to:
 * - Tabs
 * - Alerts
 * - Cards
 *
 * ## When to use draggable indicators
 *
 * Draggable indicators should be used to illustrate that an item can be
 * dragged and dropped.
 *
 * ## When not to use draggable indicators
 *
 * - To add decorative style to an item.
 *
 * ## Position and style
 *
 * Draggable indicators should be positioned on the left of the item it is nested inside.
 */

$draggable-shadow-width:          3px;
$draggable-block-width:           10px;

/**
 * Draggable block:
 *
 * `.draggable`
 *
 * A draggable indicator.
 *
 *   @example
 *   .card.draggable
 *     .card__body
 *       p Draggable card
 */
@include block('draggable') {
  position: relative;

  &::before {
    content: '';
    @include rem(width, $draggable-block-width);
    height: calc(100% - #{$ukg-spacing-xs});
    position: absolute;
    left: $ukg-spacing-xxs;
    top: $ukg-spacing-xxs;
    background: url('images/grabber.svg') repeat-y 0 0;
    opacity: 0.3;
  }

  &:hover {
    cursor: grab;

    &::before {
      opacity: 0.7;
    }
  }

  &:active {
    @include internal-shadow(color(grey, 5), $draggable-shadow-width);
    cursor: move;

    &::before {
      opacity: 1;
    }
  }

  /**
   * Dark draggable block:
   *
   * `.draggable--dark`
   *
   * A draggable indicator to use on dark blocks.
   *
   *   @example
   *   .horizontal-section.horizontal-section--dark.draggable.draggable--dark(style='padding: 0 1rem 1rem;')
   *     p Draggable dark section
   */
  @include modifier('dark') {
    &::before {
      background-image: url('images/grabber-white.svg');
    }

    &:active {
      @include internal-shadow(transparentize($white, 0.7), $draggable-shadow-width);
    }
  }
}
