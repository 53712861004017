/***
 * # Progress bar
 *
 * A progress bar is a visual indicator of the percentage progress of an item.
 *
 * ## When to use a progress Bar
 *
 * Progress bars should be used to indicate that a task is in progress.
 *
 * ## When not to use a progress Bar
 *
 * Progress bars should not be used as decorative elements.
 *
 * ## Position and style
 *
 * The tone of the progress bar can be controlled by using different colors.
 * For example, a warning progress bar could be shown with either an
 * orange (warning) or red (danger) background, depending on the severity.
 *
 * ## Accessibility
 *
 * The element `.progress-bar__percent` requires the following role and aria attributes:
 * - `role="progressbar"`
 * - `aria-valuemin="0"` and `aria-valuemax="100"`
 * - `aria-valuenow` indicating the current value of the progress bar in a range from 0 to 100.
 *
 * <div class="alert top-margin">
 *  <svg role="img" aria-labelledby="accessibility-warning" focusable="false" class="alert__icon icon">
 *    <title id="accessibility-warning">Warning</title>
 *    <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
 *  </svg>
 *  <p>Everytime that the progress changes, both the value of <code>aria-valuenow</code> and the inline CSS width must be updated.</p>
 * </div>
 *
 */

$progress-bar-height:               10px;
$progress-bar-max-width:            150px;
$progress-bar-border-radius:        border-radius-full($progress-bar-height);
$progress-bar-background-color:     $white;
$progress-bar-border-color:         color(grey, 5);
$progress-bar-fill:                 color(grey, 4);
$progress-bar-duration:             0.3s;

// Small progress bar
$small-progress-bar-height:         $progress-bar-height / 2;
$small-progress-bar-max-width:      $progress-bar-max-width / 3;
$small-progress-bar-border-radius:  border-radius-full($small-progress-bar-height);

// If you change me, make sure you also change the docs!
$progress-bar-colors: 'primary', 'warning', 'danger', 'success', 'info';

/**
 * Basic progress bar:
 * `.progress-bar`
 *
 * A standard progress bar.
 *
 *     @example
 *     .progress-bar
 *       .progress-bar__percent(role='progressbar' style='width: 60%;' aria-valuenow='60' aria-valuemin='0' aria-valuemax='100')
 */
@include block('progress-bar') {
  box-shadow: inset rem(0 0 0 1px) $progress-bar-border-color;
  border-radius: rem($progress-bar-border-radius);
  background-color: $progress-bar-background-color;
  width: 100%;
  max-width: rem($progress-bar-max-width);
  overflow: hidden;

  @include element('percent') {
    height: rem($progress-bar-height);
    border-radius: rem($progress-bar-border-radius);
    background-color: $progress-bar-fill;
    transition: width $progress-bar-duration ease;
  }

  @include element('text') {
    @include sr-only; // For screen readers only
  }

  /**
   * Small progress bar:
   * `.progress-bar--small`
   *
   * A small progress bar.
   *
   *     @example
   *     .progress-bar.progress-bar--small
   *       .progress-bar__percent(role='progressbar' style='width: 10%;' aria-valuenow='10' aria-valuemin='0' aria-valuemax='100')
   */
  @include modifier('small') {
    border-radius: rem($small-progress-bar-border-radius);
    max-width: rem($small-progress-bar-max-width);

    @include element('percent') {
      height: rem($small-progress-bar-height);
      border-radius: rem($small-progress-bar-border-radius);
    }
  }

  /**
   * Full width progress bar:
   * `.progress-bar--full-width`
   *
   * A full width progress bar.
   *
   *     @example
   *     .progress-bar.progress-bar--full-width
   *       .progress-bar__percent(role='progressbar' style='width: 80%;' aria-valuenow='80' aria-valuemin='0' aria-valuemax='100')
   */
  @include modifier('full-width') {
    max-width: 100%;
  }

  /**
   * Colored progress bar:
   * `.progress-bar--color`
   *
   * A colored progress bar.
   *
   *     @example
   *     each color in ['primary', 'warning', 'danger', 'success', 'info']
   *       .progress-bar(class=`progress-bar--${color}`)
   *         .progress-bar__percent(role='progressbar' style='width: 40%;' aria-valuenow='40' aria-valuemin='0' aria-valuemax='100')
   *       br
   */
  @each $color in $progress-bar-colors {
    @include modifier($color) {
      @include element('percent') {
        background-color: color($color, dark);
      }
    }
  }

  /**
   * Use case: Progress bar with a label:
   * `p.top-margin.top-margin--tiny`
   *
   * Progress bars are always based on a percentage.
   *
   * You can show the user what that percentage means by adding informative text underneath.
   *
   *     @example
   *     .progress-bar.progress-bar--primary
   *       .progress-bar__percent(role='progressbar' style='width: 33.333%;' aria-valuenow='33.333' aria-valuemin='0' aria-valuemax='100')
   *     p.top-margin.top-margin--tiny 1 of 3 complete
   */
}
