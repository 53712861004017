/***
 * # Search
 *
 * A search is an input and button set styled to indicate that a user can
 * conduct a search. Search inputs can optionally include placeholder text.
 *
 * ## When to use a search input
 *
 * Search inputs should be used to indicate to a user that a page or section is
 * searchable.  Search inputs should always include placeholder text explaining
 * the types of search parameters that can be used. For example, an employee
 * search might say "Search by name, email or registration reference".
 *
 * ## When not to use search inputs
 *
 * Do not use search inputs for collecting data other than search terms.
 * If you want to replicate the style of a search input, use a large input
 * instead.
 *
 * ## Position and style
 *
 * Search inputs should be placed directly above the page or list they are
 * applied to.
 *
 * ## Accessibility
 *
 * Make sure you include the `aria-label="Search"` and `aria-label="Clear"` attributes as shown in the examples below.
 *
 */

/*
   Other variables not listed here can be found in src/settings/_form.scss
*/

@mixin colored-search-field($color) {
  @include modifier($color) {
    @include element('submit') {
      color: form-field-color($color, text);
    }

    @include mixie('input') {
      @include form-field-color-variation($color);
    }
  }
}

/**
 * Basic search:
 * `.search`
 *
 * A standard search bar.
 *
 *     @example
 *     .search(role='search')
 *       input.search__input.input(aria-label='Search')
 *       button.search__clear-button(type='button' aria-label='Clear')
 *         svg.button__icon.icon(aria-hidden='true', focusable='false')
 *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
 *       button.search__submit(type='button' aria-label='Search')
 *         svg.search__icon.icon(aria-hidden='true', focusable='false')
 *           use(xlink:href='../icons.svg#search' href='../icons.svg#search')
 *     <!-- ignore line below - for demo purposes only -->
 *     div(style='display: inline-block; width: 100%; height: 1px;')
 *     <!-- ignore line above - for demo purposes only -->
 */

/**
 * Disabled search with tooltip:
 *
 * A standard disabled search bar with a tooltip over the submit button.
 *
 * <div class="alert alert--warning top-margin">
 *    <p>`.tooltip__disabled-wrapper` must have the class `.search__submit-wrapper`.</p>
 * </div>
 *
 *     @example
 *     .search(role='search')
 *       input.search__input.input(aria-label='Search')
 *       button.search__clear-button(type='button' aria-label='Clear')
 *         svg.button__icon.icon(aria-hidden='true', focusable='false')
 *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
 *       span.tooltip__disabled-wrapper.search__submit-wrapper(data-toggle='tooltip')
 *        button.search__submit(type='button' aria-label='Search' aria-describedby='tooltip-disabled' disabled)
 *          svg.search__icon.icon(aria-hidden='true', focusable='false')
 *            use(xlink:href='../icons.svg#search' href='../icons.svg#search')
 *       .tooltip#tooltip-disabled(role='tooltip') This PDF templare cannot be deleted because it is being used in an ongoing process or a process template.
 *     <!-- ignore line below - for demo purposes only -->
 *     div(style='display: inline-block; width: 100%; height: 1px;')
 *     <!-- ignore line above - for demo purposes only -->
 */
@include block('search') {
  position: relative;
  display: inline-block; // or float: left;
  color: $base-font-color;

  @include mixie('input') {
    @include large-field;
    padding-right: rem(60px); // Make the text go closer to the icon
  }

  @include element('submit-wrapper') {
    position: absolute;
    min-height: rem(40px);
    min-width: rem(40px);
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  @include element('submit') {
    @include seamless-button($position: absolute);
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  @include element('clear-button') {
    @include seamless-button($icon-size: 16px, $position: absolute);
    display: none;
    top: 50%;
    right: rem(36px);
    transform: translateY(-50%);
  }

  /**
   * Populated search:
   * `.search--populated`
   *
   * This modifier will be added/removed automatically whenever the input is populated/cleared <strong>via user interaction</strong>.
   * <br>
   * <br>
   * You may add it manually, as well,  whenever you want to force the clear button to show, regardless of user interaction.
   * <br>
   * <br>

   * <div class="alert alert--warning">Clicking on the clear button will clear the input and hide the button.</div>
   *
   *     @example
   *     .search.search--populated(role='search')
   *       input.search__input.input(value='Some search term' aria-label='Search')
   *       button.search__clear-button(type='button' aria-label='Clear')
   *         svg.button__icon.icon(aria-hidden='true', focusable='false')
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   *       button.search__submit(type='button' aria-label='Search')
   *         svg.search__icon.icon(aria-hidden='true', focusable='false')
   *           use(xlink:href='../icons.svg#search' href='../icons.svg#search')
   *     <!-- ignore line below - for demo purposes only -->
   *     div(style='display: inline-block; width: 100%; height: 1px;')
   *     <!-- ignore line above - for demo purposes only -->
   */
  @include modifier('populated') {
    @include element('clear-button') {
      display: flex;
    }
  }

  /**
   * Jumbo search:
   * `.search--jumbo`
   *
   * A jumbo search bar.
   *
   *     @example
   *     .search.search--jumbo(role='search')
   *       input.search__input.input(aria-label='Search')
   *       button.search__clear-button(type='button' aria-label='Clear')
   *         svg.button__icon.icon(aria-hidden='true', focusable='false')
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   *       button.search__submit(type='button' aria-label='Search')
   *         svg.search__icon.icon(aria-hidden='true', focusable='false')
   *           use(xlink:href='../icons.svg#search' href='../icons.svg#search')
   *     <!-- ignore line below - for demo purposes only -->
   *     div(style='display: inline-block; width: 100%; height: 1px;')
   *     <!-- ignore line above - for demo purposes only -->
   */
  @include modifier('jumbo') {
    @include mixie('input') {
      height: rem(50px);
      border-radius: rem(30px);
      font-size: rem($base-font-size * 1.3);
      padding: rem(10px 63px 10px 20px);
    }

    @include element('clear-button') {
      right: rem(40px);
    }

    @include element('submit-wrapper') {
      right: rem(5px);

      > .search__submit {
        right: 0;
      }
    }

    @include element('submit') {
      right: rem(5px);
    }
  }

  /**
   * Full width search:
   * `.search--full-width`
   *
   * A full width search bar.
   *
   *     @example
   *     .search.search--full-width(role='search')
   *       input.search__input.input(aria-label='Search')
   *       button.search__clear-button(type='button' aria-label='Clear')
   *         svg.button__icon.icon(aria-hidden='true', focusable='false')
   *           use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   *       button.search__submit(type='button' aria-label='Search')
   *         svg.search__icon.icon(aria-hidden='true', focusable='false')
   *           use(xlink:href='../icons.svg#search' href='../icons.svg#search')
   *     <!-- ignore line below - for demo purposes only -->
   *     div(style='display: inline-block; width: 100%; height: 1px;')
   *     <!-- ignore line above - for demo purposes only -->
   */
  @include modifier('full-width') {
    width: 100%;

    @include mixie('input') {
      width: 100%;
    }
  }

  /**
   * Colored search:
   * `.search--colored`
   *
   * A colored search bar.
   *
   *     @example
   *     each color in ['dark', 'valid', 'invalid']
   *       .search(class=`search--${color}` role='search').bottom-margin
   *         input.search__input.input(aria-label='Search')
   *         button.search__clear-button(type='button' aria-label='Clear')
   *           svg.button__icon.icon(aria-hidden='true', focusable='false')
   *             use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   *         button.search__submit(type='button' aria-label='Search')
   *           svg.search__icon.icon(aria-hidden='true', focusable='false')
   *             use(xlink:href='../icons.svg#search' href='../icons.svg#search')
   *       <!-- ignore line below - for demo purposes only -->
   *       div(style='display: inline-block; width: 100%; height: 1px;')
   *       <!-- ignore line above - for demo purposes only -->
   */
  @include colored-search-field(dark);
  @include colored-search-field(valid);
  @include colored-search-field(invalid);
}
