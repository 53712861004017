/***
 * # Typography helpers
 *
 * A series of helpers that can be applied directly to typographical elements
 * to control their appearance and positioning.
 *
 * ## Deprecation warnings
 * - [The helper `.dotted` has been deprecated](#dotted-underline)
 */

$icon-without-color-modifier: ':not([class*='icon--primary']):not([class*='icon--warning']):not([class*='icon--danger']):not([class*='icon--success']):not([class*='icon--info']):not(.icon--dark-grey):not(.icon--grey):not(.icon--light-grey):not(.icon--white)';

/**
 * Text alignment:
 * `.align-left` `.align-right` `.centered`
 *
 * Aligns text to the left right and middle.
 * Can also be used on parent containers to align child elements.
 *
 *     @example
 *     p.align-left A left aligned paragraph
 *     br
 *     p.align-right A right aligned paragraph
 *     br
 *     p.centered A center aligned paragraph
 */
.align-left {
  text-align: left !important;
}

.align-right {
  text-align: right !important;
}

.centered {
  text-align: center !important;
}

$text-colors: 'primary', 'warning', 'danger', 'success', 'info';

/**
 * Colored text:
 * `.primary` `.warning` `.danger` `.success` `.info` `.white`, `.dark-grey`, `.light-grey`
 *
 * Helper to add color to text and associated elements.
 * You can always overwrite the icon coloring by modifying the icon directly.
 *
 * <div class="alert alert--info top-margin">
 *  <svg class="alert__icon icon" role="img" aria-labelledby="colored-text-title" focusable="false">
 *    <title id="colored-text-title">Information</title>
 *    <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
 *  </svg>
 *  <p>There is intentionally no info text color. This is done to avoid confusing info blue with a link.</p>
 * </div>
 *
 *     @example
 *     p
 *       svg.icon.icon--small.right-margin.right-margin--tiny(role='img' aria-labelledby='icon-label1', focusable='false')
 *         title#icon-label1 Document
 *         use(xlink:href='../icons.svg#document' href='../icons.svg#document')
 *       | text with icon
 *     p(class='dark-grey')
 *       svg.icon.icon--small.right-margin.right-margin--tiny(role='img' aria-labelledby='icon-label1', focusable='false')
 *         title#icon-label1 Document
 *         use(xlink:href='../icons.svg#document' href='../icons.svg#document')
 *       | dark grey text with icon
 *     each color in ['primary', 'warning', 'danger', 'success', 'info']
 *      p(class=`${color}`)
 *        svg.icon.icon--small.right-margin.right-margin--tiny(role='img' aria-labelledby=`icon-${color}-label`, focusable='false')
 *          title(id=`icon-${color}-label`) Document
 *          use(xlink:href='../icons.svg#document' href='../icons.svg#document')
 *        | #{color} text with icon
 *     .horizontal-section.horizontal-section--dark.top-margin.top-margin--small(style='padding-bottom: 1.25rem')
 *       p(class='white')
 *         svg.icon.icon--small.right-margin.right-margin--tiny(role='img' aria-labelledby='icon-label2', focusable='false')
 *           title#icon-label2 Document
 *           use(xlink:href='../icons.svg#document' href='../icons.svg#document')
 *         | white text with icon
 *       p(class='light-grey')
 *         svg.icon.icon--small.right-margin.right-margin--tiny(role='img' aria-labelledby='icon-label2', focusable='false')
 *           title#icon-label2 Document
 *           use(xlink:href='../icons.svg#document' href='../icons.svg#document')
 *         | light grey text with icon
 */
@each $color in $text-colors {
  .#{$color} {
    @if $color == 'primary' {
      color: $font-color-primary-on-white;
      border-color: $font-color-primary-on-white;
      fill: $font-color-primary-on-white;
    } @else if $color == 'info' {
      border-color: color($color, mid);
      fill: color($color, dark);

      @include block('icon') {
        &#{$icon-without-color-modifier} {
          color: color($color, dark);
        }
      }
    } @else {
      color: color($color, deep);
      border-color: color($color, mid);
      fill: color($color, dark);

      @include block('icon') {
        &#{$icon-without-color-modifier} {
          color: color($color, dark);
        }
      }
    }
  }
}

.white {
  color: $white;
  border-color: $white;
}

.light-grey {
  color: $light-font-color;
  border-color: $light-font-color;
}

.dark-grey {
  color: $dark-font-color;
  border-color: $dark-font-color;
}

/**
 * On primary text:
 * `.on-primary`
 *
 * Useful when the text is placed on a primary color background.
 *
 * This helper is relevant for [theming](base-colors.html#themes): if the primary color is dark, the text
 * will be white. If it is light, the text will be dark grey. **It helps you to ensure an accessible color contrast**.
 *
 *     @example
 *     .horizontal-section.horizontal-section--primary.top-margin.top-margin--small(style='padding-bottom: 1.25rem;')
 *       p(class='on-primary')
 *         svg.icon.icon--small.right-margin.right-margin--tiny(role='img' aria-labelledby='icon-label2', focusable='false')
 *           title#icon-label2 Document
 *           use(xlink:href='../icons.svg#document' href='../icons.svg#document')
 *         | on primary text with icon
 */

.on-primary {
  color: $font-color-on-primary;
  border-color: $font-color-on-primary;
}

/**
 * Cursors:
 * `.cursor-default`
 * <br>
 * `.cursor-pointer`
 * <br>
 * `.cursor-not-allowed`
 *
 * Override an element's default cursor.
 *
 *     @example
 *     a.cursor-default(href='#') I should've been a pointer
 *     br
 *     .cursor-pointer I should've been an arrow
 *     br
 *     .cursor-not-allowed Not allowed to click me
 *
 */
.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

/**
 * Italic text:
 * `.italic`
 *
 *     @example
 *     span.italic Some italic text
 */
.italic {
  font-style: italic;
}

/**
 * Bold text:
 * `.bold`
 *
 *     @example
 *     span.bold Some bold text
 */
.bold {
  font-weight: $bold;
}

/**
 * Underlined text:
 * `.underlined`
 *
 *     @example
 *     span.underlined Some underlined text
 *     br
 *     span.underlined.primary Some underlined text
 *     br
 *     .horizontal-section.horizontal-section--dark.top-margin.top-margin--small(style="padding: 1rem 0;")
 *       span.underlined.white Some underlined text
 */
.underlined {
  text-decoration: underline;
}

/**
 * Line through text:
 * `.line-through`
 *
 * <div class="alert alert--warning top-margin">
 *    <svg role="img" aria-labelledby="alert-title-1" focusable="false" class="alert__icon icon">
 *      <title id="alert-title-1">Warning</title>
 *      <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
 *    </svg>
 *    <p>The <code>line-through</code> helper doesn't bring any semantic to the element.</p>
 *    <p>You should always add some information about the state of your element for screen readers
 *    with a <code>.sr-only</code> for example.</p>
 * </div>
 *
 *     @example
 *     span.line-through A line through a text
 *      span.sr-only removed element
 *     br
 *     a(href="#").line-through A line through a link
 *      span.sr-only removed element
 */
.line-through {
  &,
  &:link {
    @include line-through;
  }
}

/**
 * Underlined title:
 * `.underlined-title`
 *
 * Adds an underline (with padding and margin applied).
 * Use for headings.
 *
 *     @example
 *     h1.underlined-title Underlined h1
 *     h2.underlined-title Underlined h2
 *     h3.underlined-title Underlined h3
 *     h4.underlined-title Underlined h4
 *     h5.underlined-title Underlined h5
 *     h6.underlined-title Underlined h6
 */
.underlined-title {
  @include rem(padding-bottom, $type-margin / 1.5);
  @include rem(border-bottom, 1px solid $block-border-color);
  @include rem(margin-bottom, $type-margin);
}

/**
 * Disabled link:
 * `a.disabled`
 *
 * Reduces the opacity and disables the hover state of links.
 * For buttons, use the attribute `aria-disabled="true"` instead.
 *
 * <div class="alert alert--warning top-margin">
 *    <svg role="img" aria-labelledby="alert-title-1" focusable="false" class="alert__icon icon">
 *      <title id="alert-title-1">Warning</title>
 *      <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
 *    </svg>
 *    <p>This helper alone won't disable the link. <strong>Make sure to remove the <code>href</code> attribute</strong> to cancel the hyperlinking behavior.</p>
 * </div>
 *
 *     @example
 *     p
 *      a.disabled Disabled Link
 */
.disabled {
  @include disabled;

  &:hover {
    color: color(primary, dark);
    text-decoration: underline;
    background-color: transparent;
  }
}

/**
 * Muted:
 * `.muted`
 *
 * A helper that reduces the opacity of an element.
 *
 *     @example
 *     p This it a normal paragraph.
 *     p.muted This paragraph is muted.
 */
.muted {
  @include muted;
}

/**
 * Button spacing:
 * `.button-spaced`
 *
 * A helper that adds the vertical padding and line height of a button to
 * another element. Use on paragraphs.
 *
 * Formerly it was used on links but now it could be replaced by a [text button](./blocks-button.html#text-button).
 *
 *     @example
 *     p.button-spaced Paragraph
 */
.button-spaced {
  display: inline-block;
  vertical-align: top;
  font-size: rem($base-font-size);
  line-height: rem($button-line-height);
  padding: rem($button-padding);
  padding-left: 0;
  padding-right: 0;
}

/**
 * Break word:
 * `.break-word`
 *
 * Helper to wrap long links inside divs.
 * Use on long file names, etc.
 *
 *     @example
 *     div(style='width: 100px;')
 *       a.break-word Thisisareallylongfilenamethisisareallylongfilenamethisisareallylongfilenamethisisareallylongfilename.pdf
 */
.break-word {
  display: inline-block;
  word-break: break-all;
}

/**
 * Hyphens:
 * `.hyphens`
 *
 * Helper to break long words with an hyphen.
 *
 * <div class="alert alert--warning top-margin">
 *    <svg role="img" aria-labelledby="alert-title-2" focusable="false" class="alert__icon icon">
 *      <title id="alert-title-2">Warning</title>
 *      <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
 *    </svg>
 *    <p>This feature is not properly supported by the Chrome engine yet.</p>
 *    <ul>
 *      <li>Chrome v55-87 : Support only on Android and MacOS</li>
 *      <li>Chrome v88+ : Support on every platform</li>
 *    </ul>
 *    <p>Any other engine supports this feature. See the <a href="https://caniuse.com/css-hyphens">CanIUse chart</a> for more information</p>
 * </div>
 *
 *     @example
 *     div(style='width: 50px;')
 *       span.hyphens I have an extraordinally long word.
 */
.hyphens {
  @include hyphens;
}

/**
 * No wrap:
 * `.nowrap`
 *
 * Helper to ensure text remains on one line.
 *
 *     @example
 *     div(style='max-width: 950px; overflow: hidden;')
 *       h5 white-space: nowrap
 *       p.nowrap This is a really long piece of text, this is a really long piece of text this is a really long piece of text, this is a really long piece of text this is a really long piece of text.
 *       h5 white-space: normal
 *       p This is a really long piece of text, this is a really long piece of text this is a really long piece of text, this is a really long piece of text this is a really long piece of text.
 */
.nowrap {
  white-space: nowrap;
}

/**
 * Unstyled list:
 * `ol.unstyled-list` `ul.unstyled-list`
 *
 * Helper to remove styling from list elements.
 *
 *     @example
 *     ul.unstyled-list
 *       li List item
 *       li List item
 *       li List item
 */
.unstyled-list {
  @include unstyled-list;
}

/**
 * Inline list:
 * `ol.inline-list` `ul.inline-list`
 *
 * Helper to position the list items inline.
 *
 * Use in combination with `.unestyled-list`.
 *
 *     @example
 *     ul.inline-list.unstyled-list
 *       li
 *         span.badge.badge--non-interactive
 *           svg.icon.icon--warning-dark.badge__icon(aria-hidden='true' focusable='false')
 *             use(xlink:href='../icons.svg#dot' href='../icons.svg#dot')
 *           span.badge__label Badge 1
 *       li
 *         span.badge.badge--non-interactive
 *           svg.icon.icon--success-dark.badge__icon(aria-hidden='true' focusable='false')
 *             use(xlink:href='../icons.svg#dot' href='../icons.svg#dot')
 *           span.badge__label Badge 2
 *       li
 *         span.badge.badge--non-interactive
 *           svg.icon.icon--primary-dark.badge__icon(aria-hidden='true' focusable='false')
 *             use(xlink:href='../icons.svg#dot' href='../icons.svg#dot')
 *           span.badge__label Badge 3
 */
.inline-list {
  @include inline-list;
}

/**
 * Unpadded list:
 * `ol.unpadded-list` `ul.unpadded-list`
 *
 * Helper to remove the padding from list items.
 *
 *     @example
 *     ul.unpadded-list
 *       li List item
 *       li List item
 *       li List item
 */
.unpadded-list {
  @include unpadded-list;
}

/**
 * Font-size inherit:
 * `.font-size-inherit`
 *
 * A helper to unset an element's font-size and use font-size inheritance instead.
 *
 *     @example
 *     p Normal text
 *     p(style="font-size: 20px;")
 *       span.font-size-inherit Inherited text
 */
.font-size-inherit {
  font-size: inherit;
}

/**
* ## Deprecations
*/

/**
 * Dotted underline:
 * `.dotted`
 *
 * Adds a dotted underline when applied.
 * <br>
 * <br>
 * <s>Use it to indicate popover content.</s>
 *
 * <div class="alert alert--warning top-margin">
 *    <svg aria-hidden="true" focusable="false" class="alert__icon icon">
 *      <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
 *    </svg>
 *    <strong>Deprecated</strong>
 *    <p>This helper has been deprecated and it will be removed in a future version of PDUI.</p>
 *    <p>See <a href="../docs/blocks-popover.html">PDUI popovers</a> for more information about how to build accessible popovers.</p>
 * </div>
 *
 *     @example
 *     span.dotted popover trigger
 *     br
 *     span.dotted.primary popover trigger
 */
.dotted {
  border-bottom-width: rem(1px);
  border-bottom-style: dotted;
  cursor: pointer;
}

/**
 * Plain text button:
 * `.text-button`
 *
 * Makes a `<button/>` look like a link.
 *
 * <div class="alert alert--warning top-margin">
 *    <svg aria-hidden="true" focusable="false" class="alert__icon icon">
 *      <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
 *    </svg>
 *    <strong>Deprecated</strong>
 *    <p>This helper has been deprecated and it will be removed in a future version of PDUI.</p>
 *    <p>In most cases, it should be replaced by a <a href="./blocks-button.html#text-button">text button</a>.</p>
 *    <p>If it's not possible you can safely replace it by <a href="./blocks-link.html#a-button-that-looks-like-a-link"><code>.link</code></a>.</p>
 * </div>
 *
 *     @example
 *     button.text-button I'm a button
 */
.text-button {
  @include std-link;
  appearance: none;
  display: inline-block;
  background: none;
  border: 0;
  padding: 0;
  font-size: inherit;
}
