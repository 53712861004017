@import '@ukgepic/hrsd-pdui-theme/src/settings/color';
@import 'typography';
@import 'radius';
@import '../tools/radius';

// BUTTONS

$button-border-radius:                border-radius-full(32px);
$button-large-border-radius:          border-radius-full(40px);

$button-line-height:                  18px;
$button-padding:                      6px 14px;

$large-button-padding:                10px 14px;

$responsive-button-margin:            10px;

$button-background-color:             $white;
$button-text-color:                   color(grey, 3);
$button-border-color:                 color(grey, 4);

$button-hover-background-color:       color(grey, 5);
$button-hover-background-color-hsla:  hsla(var(--grey-level-1-h), var(--grey-level-1-s), var(--grey-level-1-l), 0.11);
$button-hover-border-color:           color(grey, 4);
$button-hover-color:                  color(grey, 1);

$button-active-border-color:          color(primary, deep);

$button-focus-outline-offset:         1px;
$button-focus-outline-width:          3px;

$button-colored-focus-outline-color:  color(grey, 3);

// SEAMLESS BUTTONS
$seamless-button-icon-size: 24px;
$seamless-button-padding: 8px;

/*
  List the color buttons you want to generate
  If you change this list, update the example docs, including the .md file.
*/
$color-buttons: 'primary', 'danger';
