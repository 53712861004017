/***
 * # Aspect ratio
 *
 * A helper that allows to maintain an aspect ratio of children whatever the available space.
 */

/**
 * Aspect ratio:
 * `.aspect-ratio`
 *
 * The default ratio is 16:9.
 *
 *     @example
 *     .aspect-ratio
 *       iframe(width="560" height="315" src="https://www.youtube.com/embed/OFiE-MbFpHE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen)
 */

/**
 * Custom ratio:
 *
 * **Important:** the value given to the CSS Custom Property `--aspect-ratio`
 * must be either the ratio calculation or its result.
 *
 * E.g., for the *16:9* format, the value must be `9 / 16` or `0.5625`.
 * For the *4:3* format, the value must be `3 / 4` or `0.75`.
 *
 *     @example
 *     .aspect-ratio(style='--aspect-ratio: 3 / 4')
 *      div(style='background-color:rgba(0,0,0,0.2);')
 *      iframe(width='560', height='315', src='https://www.youtube.com/embed/OFiE-MbFpHE', frameborder='0', allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture', allowfullscreen='allowfullscreen')
 */
.aspect-ratio {
  position: relative;
  padding-bottom: calc(var(--aspect-ratio, 0.5625) * 100%); // Default ratio is 16:9
  height: 0;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
