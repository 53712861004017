/* SEAMLESS BUTTON
   A button that looks like a plain icon, without border or background-color.
   Examples: close button, search button, back arrow button in document viewer.
*/

@mixin seamless-button($icon-size: $seamless-button-icon-size, $position: relative) {
  @include rem(padding, $seamless-button-padding);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: $position;
  background-color: transparent;
  color: currentColor;
  border-radius: 50%;
  transition: border-color 0.1s linear;
  border: 0;
  min-width: rem($icon-size + ($seamless-button-padding * 2));
  cursor: pointer;

  &:hover,
  &.button--pressed {
    background-image: initial;
    background-color: $button-hover-background-color-hsla;
  }

  &:focus,
  &:active {
    @include focus-outline-pseudo-element($position: $position, $gap: 1px, $border-radius: 50%);
    background-color: $button-hover-background-color-hsla;
  }

  &:hover,
  &:active,
  &:focus {
    color: currentColor;
  }

  &:disabled,
  &[aria-disabled='true'] {
    @include disabled;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
    }

    &:focus,
    &:active {
      @include hide-focus-indicator;
    }
  }

  .icon,
  .icon.button__icon {
    @include rem(height, $icon-size);
    @include rem(width, $icon-size);
    margin: 0;
  }
}
