/***
 * # Document viewer
 *
 * A document viewer is an interactive component for viewing documents.
 * Using the document viewer, users can scroll or toggle through a document,
 * jump to a particular page, zoom in to view the document details and toggle
 * to full page mode.
 *
 * ## Position and style
 *
 * Document viewers are a high level component and should be positioned
 * directly against the application background. Do not nest the document
 * viewer inside a card or other high-level component.
 *
 * You can also use the document viewer as the only element of the page,
 * directly on full page mode. See the template
 * [document viewer - full page](../templates/doc-viewer-full-page.html) for an example.
 *
 * ## Initialization
 *
 * <div class="alert alert--danger top-margin top-margin--small">
 *   <p>This component will auto-instantiate unless it has the <code>[data-no-auto]</code> attribute at page load (DOMContentLoaded) or on <code>init.pdui</code> event recept on an ancestor.<p>
 *   <p>If the component is added to the page dynamically, trigger the <code>init.pdui</code> event on a parent node to instantiate it.</p>
 *   <p>If the component is not instantiated, it will auto-instantiate as soon as a method of that component is called programmatically, regardless of the presence of <code>[data-no-auto]</code>.</p>
 *   <p>If you don't set the component to auto-instantiate and you wish to instantiate it manually (pass options to it etc.) do:<br><code>$(el).viewer();</code></p>
 * </div>
 *
 * ## Keyboard interaction
 *
 * In full page mode:
 * - <kbd>Esc</kbd>: exits full page mode, and moves focus to the "Full page" button.
 *
 * ## Options
 *
 * The document viewer component needs to know the path to the SVG icons. You have two ways to declare the icons path on the component level:
 * - Using the `iconsPath` option at initialization
 * - Setting the `data-icons-path` attribute on the element `.viewer`
 * If none of the above is found, the document viewer component will use the path from the `data-icons-path` attribute on the `<body>` element.
 *
 */

$viewer-padding:                                      10px;
$viewer-compact-padding:                              6px;
$viewer-controls-horizontal-spacing:                  6px;
$viewer-controls-height:                              67px;
$viewer-page-input-width:                             36px;
$viewer-controls-wrap-threshold:                      1000px;
$viewer-full-page-controls-height:                    80px;
$viewer-full-page-hover-controls-width:               268px;
$viewer-full-page-hover-controls-height:              36px;
$viewer-full-page-hover-controls-margin:              20px;
$viewer-full-page-controls-rem-spacing:               $ukg-spacing-s;
$viewer-full-page-hover-controls-transition-duration: 0.4s;
$viewer-back-button-width:                            32px;
$viewer-page-container-bg:                            color(grey, 6);
$viewer-full-page-hover-controls-bg:                  hsla(var(--grey-level-1-h), var(--grey-level-1-s), var(--grey-level-1-l), 0.9);
$viewer-focus-indicator-width:                        4px;

/**
 * Basic document viewer:
 * `.viewer`
 *
 * A document viewer with its default set of controls.
 *
 * **Note:** The full page footer is optional. You can omit the element `.viewer__footer` if there are no bottom actions.
 *
 *     @example
 *     include ./code-samples/basic-document-viewer.pug
 */
@include block('viewer') {
  @include card;

  &:not(.viewer--full-page) {
    .viewer__controls {
      @include rem(padding, $viewer-padding $viewer-padding 0);

      > * {
        @include rem(padding-bottom, $viewer-padding);
      }
    }
  }

  &.viewer--compact:not(.viewer--full-page) {
    .viewer__controls::before {
      content: '';
      flex: 0 1 33%;
    }
  }

  /**
   * Compact document viewer:
   * `.viewer--compact`
   *
   * A document viewer optimized for narrow screens or spaces.
   *
   *     @example
   *     include ./code-samples/compact-document-viewer.pug
   */
  @include modifier('compact') {
    @include element('controls') {
      padding: rem($viewer-compact-padding $viewer-compact-padding 0);
      flex-wrap: nowrap;

      > * {
        padding-bottom: rem($viewer-compact-padding);
      }
    }

    @include element('nav-controls') {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;

      > * {
        margin: 0 rem($viewer-compact-padding / 2);
      }
    }

    @include element('zoom-controls') {
      flex: 0 1 33%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > * {
        margin: 0 rem($viewer-compact-padding / 2);
      }
    }

    @include element('zoom') {
      width: rem(90px);
    }
  }

  @include modifier('full-page') {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    // When full page mode is enabled, the <body> visibility is set to hidden
    // to be out of AOM (Accessibility Object Model).
    // So the document-viewer container should stay visible and accessible.
    visibility: visible;
    top: 0;
    left: 0;
    z-index: index($z-index-scale, document-viewer);
    display: flex;
    flex-direction: column;
    border-radius: 0;

    @include element('controls') {
      background-color: $white;
      @include rem(padding, $viewer-padding);
      justify-content: center;
      @include rem(height, $viewer-controls-height);
      flex-wrap: nowrap;
      flex: none;
    }

    @include element('full-page-control') {
      display: block;
    }

    @include element('nav-controls') {
      display: none;
    }

    @include element('zoom-controls') {
      display: none;
    }

    @include element('full-page-hover-controls') {
      display: flex;
    }

    @include element('page-container') {
      position: relative;
      flex: auto;
      border-radius: 0;
    }

    @include element('footer') {
      display: block;
      flex: none;
    }
  }

  @include element('controls') {
    width: 100%;
    @include rem(border-bottom, solid 1px $block-border-color);
  }

  @include element('full-page-control') {
    display: none;
    @include rem(padding, 0 $viewer-controls-horizontal-spacing 0 0);
  }

  @include element('nav-controls') {

    @include element('page-index') {
      @include rem(max-width, $viewer-page-input-width);
      padding-right: 0;
      @include rem(margin-left, $viewer-controls-horizontal-spacing);
    }

    @include element('page-separator') {
      @include rem(line-height, $form-field-height);
      @include rem(font-size, $base-font-size);
    }

    @include element('page-total') {
      @include rem(line-height, $form-field-height);
      @include rem(margin-right, $viewer-controls-horizontal-spacing);
      @include rem(font-size, $base-font-size);
    }
  }

  @include element('document-info') {
    overflow: hidden;
    position: relative;
    @include rem(padding, 1px $viewer-controls-horizontal-spacing + $viewer-padding 1px $viewer-back-button-width + $viewer-controls-horizontal-spacing + $viewer-controls-horizontal-spacing + $viewer-padding);

    > * {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  @include element('document-title') {
    @include h3;
    margin-top: 0;
  }

  @include element('document-subtitle') {
    @include rem(font-size, $base-font-size);
    margin-top: 0;
  }

  @include element('back-button') {
    @include seamless-button($position: absolute);
    left: $viewer-controls-horizontal-spacing;
    top: 50%;
    transform: translateY(-50%);
  }

  @include element('page-container') {
    padding: 0;
    overflow-y: scroll;
    cursor: move;
    background-color: $viewer-page-container-bg;
    border-radius: 0 0 rem($border-radius-large $border-radius-large);

    @include element('page-wrapper') {
      width: 800px;
      max-width: 100%;
      margin: 0 auto;
      @include rem(padding-top, $viewer-padding);
    }

    @include element('page') {
      position: relative;
      @include rem(padding, $viewer-padding);

      img {
        display: block;
        width: 100%;
        margin: 0 auto;
        @include rem(border, 1px solid $block-border-color);
      }

      @include element('loading-spinner') {
        position: absolute;
        top: 50%;
        left: 50%;
        @include rem(margin-top, -16px);
        @include rem(margin-left, -16px);
      }
    }

    @include element('page-caption') {
      @include rem(padding-bottom, $viewer-padding);
      @include rem(font-size, $base-font-size);
    }
  }

  @include element('full-page-hover-controls') {
    @include rem(border-radius, $border-radius-small);
    display: none;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    @include rem(bottom, $viewer-full-page-hover-controls-margin);
    left: 50%;
    margin-left: -($viewer-full-page-hover-controls-width / 2);
    width: $viewer-full-page-hover-controls-width;
    height: $viewer-full-page-hover-controls-height;
    background-color: $viewer-full-page-hover-controls-bg;
    transition: opacity $viewer-full-page-hover-controls-transition-duration linear;
    opacity: 0;
    z-index: index($z-index-scale, base);

    @include modifier('visible') {
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }
  }

  @include element('full-page-hover-controls-container') {
    display: flex;
    width: calc(50% - #{$viewer-full-page-controls-rem-spacing});
  }

  @include element('full-page-hover-controls-element') {
    width: 33.33%;
    text-align: center;
    @include rem(font-size, $h6-size);
    line-height: 1;
    color: $white;
    user-select: none;
  }

  @include element('footer') {
    display: none;
    padding: $ukg-spacing-s;
    text-align: center;
    background-color: $white;
    @include rem(border-top, solid 1px $block-border-color);

    > * {
      display: inline-block;
    }
  }
}

@media all and (max-width: $mobile) {
  @include block('viewer') {
    @include element('document-title') {
      @include rem(font-size, $h5-size); // Change only font-size. Line-height stays as on desktop.
    }
  }
}
