/***
 * # Timeline
 *
 * A timeline is a vertical component used to display a list of items.
 *
 * ## When to use timelines
 *
 * - Whenever you have to display a list historically-sorted items
 *
 * ## When not to use timelines
 *
 * - Whenever you are to provide bulk actions on the listed items
 *
 * ## Position and style
 *
 * A timeline can belong within a card component.
 *
 * Items displayed in a timeline should be sorted so that the most recent item is at the top.
 *
 * Items should remain simple, so that the timeline is readable. If need be, they can contain a link to the specific item detail.
 */

$timeline-left-column-width:    80px;
$timeline-line-width:           2px;
$timeline-line-color:           color(grey, 5);
$timeline-compact-ratio:        3;

/**
 * Timeline with header:
 * `.timeline`
 *
 *     @example
 *     .timeline
 *       .timeline__header
 *         h1.no-top-margin Timeline header
 *       .timeline__entry
 *         .timeline__left-column
 *           .timeline__image
 *             img.avatar(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/avatar-female-1.png' alt='User Name')
 *         .timeline__content
 *           h2.h4 Entry title
 *           p 12 November 2020 · 2:37 pm
 *       .timeline__entry
 *         .timeline__left-column
 *           .timeline__image.timeline__image--circle
 *             svg.icon(role='img' aria-labelledby='timeline-icon-label1', focusable='false')
 *               title#timeline-icon-label1 Upload
 *               use(xlink:href='../icons.svg#upload' href='../icons.svg#upload')
 *         .timeline__content
 *           h2.h4 Entry title
 *           p 12 November 2020 · 10:28 am
 */

/**
 * Timeline without header:
 * `.timeline`
 *
 *     @example
 *     .timeline
 *       .timeline__entry
 *         .timeline__left-column
 *           .timeline__image
 *             img.avatar(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/avatar-male-1.png' alt='User Name')
 *         .timeline__content
 *           h2.h4 Entry title
 *           p Added on <strong>11 November 2020</strong> at <strong>9:43 am</strong> by Patrice KELLY
 *       .timeline__entry
 *         .timeline__left-column
 *           .timeline__image.timeline__image--circle
 *             svg.icon(role='img' aria-labelledby='timeline-icon-label2', focusable='false')
 *               title#timeline-icon-label2 Upload
 *               use(xlink:href='../icons.svg#upload' href='../icons.svg#upload')
 *         .timeline__content
 *           h2.h4 Entry title
 *           p Added on <strong>10 November 2020</strong> at <strong>10:12 am</strong> by Jimmie CLARK
 */
@include block('timeline') {
  @include element('header') {
    padding: $ukg-spacing-xxl 0 $ukg-spacing-s rem($timeline-left-column-width);
    position: relative;

    &::before {
      content: '';
      height: calc(100% + #{$ukg-spacing-l * 2});
      width: rem($timeline-line-width);
      background-color: $timeline-line-color;
      position: absolute;
      top: 0;
      left: rem($timeline-left-column-width / 2 - $timeline-line-width / 2);
      z-index: index($z-index-scale, timeline-line);
    }
  }

  @include element('entry') {
    @include clearfix;
    padding: $ukg-spacing-l 0;
    position: relative;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
    }

    &:not(:only-of-type) {
      &::before {
        content: '';
        height: 100%;
        width: rem($timeline-line-width);
        background-color: $timeline-line-color;
        position: absolute;
        left: rem($timeline-left-column-width / 2 - $timeline-line-width / 2);
        z-index: index($z-index-scale, timeline-line);
      }
    }

    &:first-of-type::before {
      top: $ukg-spacing-l;
    }

    &:last-of-type::before {
      height: $ukg-spacing-s;
    }
  }

  @include element('left-column') {
    float: left;
    width: rem($timeline-left-column-width);
    text-align: center;
  }

  @include element('image') {
    position: relative;
    z-index: index($z-index-scale, timeline-image);

    @include modifier('circle') {
      display: inline-block;
      line-height: 0;
      background-color: $body-background-color;
      border-radius: 50%;
      padding: $ukg-spacing-xxs;
      border: rem(2px) solid $timeline-line-color;
    }
  }

  @include element('content') {
    float: right;
    width: calc(100% - #{rem($timeline-left-column-width)});
    padding-top: $ukg-spacing-xxs;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  /**
   * Compact timeline with header:
   * `.timeline--compact`
   *
   *     @example
   *     .timeline.timeline--compact
   *       .timeline__header
   *         h3.no-top-margin Timeline header
   *       .timeline__entry
   *         .timeline__left-column
   *           .timeline__image
   *             img.avatar.avatar--small(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/avatar-female-2.png' alt='User Name')
   *         .timeline__content
   *           h4.h5 Entry title
   *           p 12 November 2020 · 2:37 pm
   *       .timeline__entry
   *         .timeline__left-column
   *           .timeline__image.timeline__image--circle
   *             svg.icon.icon--tiny(role='img' aria-labelledby='timeline-icon-label3', focusable='false')
   *               title#timeline-icon-label3 Settings
   *               use(xlink:href='../icons.svg#settings' href='../icons.svg#settings')
   *         .timeline__content
   *           h4.h5 Entry title
   *           p 12 November 2020 · 10:28 am
   */

  /**
   * Compact timeline without header:
   * `.timeline--compact`
   *
   *     @example
   *     .timeline.timeline--compact
   *       .timeline__entry
   *         .timeline__left-column
   *           .timeline__image
   *             img.avatar.avatar--small(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/avatar-male-2.png' alt='User Name')
   *         .timeline__content
   *           h3.h5 Entry title
   *           p Added on <strong>11 November 2020</strong> at <strong>9:43 am</strong> by Patrice KELLY
   *       .timeline__entry
   *         .timeline__left-column
   *           .timeline__image.timeline__image--circle
   *             svg.icon.icon--tiny(role='img' aria-labelledby='timeline-icon-label4', focusable='false')
   *               title#timeline-icon-label4 Settings
   *               use(xlink:href='../icons.svg#settings' href='../icons.svg#settings')
   *         .timeline__content
   *           h3.h5 Entry title
   *           p Added on <strong>10 November 2020</strong> at <strong>10:12 am</strong> by Jimmie CLARK
   */
  @include modifier('compact') {
    @include element('header') {
      padding: 0 0 $ukg-spacing-l / $timeline-compact-ratio rem($timeline-left-column-width / $timeline-compact-ratio) + $ukg-spacing-s;
      position: relative;

      &::before {
        left: rem(($timeline-left-column-width / 2 - $timeline-line-width / 2) / $timeline-compact-ratio);
      }
    }

    @include element('entry') {
      padding: $ukg-spacing-l / $timeline-compact-ratio 0;

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        padding-bottom: 0;
      }

      &::before {
        left: rem(($timeline-left-column-width / 2 - $timeline-line-width / 2) / $timeline-compact-ratio);
      }

      &:first-of-type::before {
        top: $ukg-spacing-l / $timeline-compact-ratio;
      }
    }

    @include element('left-column') {
      width: rem($timeline-left-column-width / $timeline-compact-ratio);
    }

    @include element('content') {
      width: calc(100% - #{rem($timeline-left-column-width / $timeline-compact-ratio)});
      padding-left: $ukg-spacing-s;
      padding-top: $ukg-spacing-xxs / $timeline-compact-ratio;
    }
  }

  /**
   * Use case: single timeline item:
   * `.timeline`
   *
   *     @example
   *     .timeline
   *       .timeline__entry
   *         .timeline__left-column
   *           .timeline__image
   *             img.avatar(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/avatar-male-1.png' alt='User Name')
   *         .timeline__content
   *           h4 Entry title
   *           p 12 November 2020 · 10:28 am
   */

  /**
   * Use case: single compact timeline item:
   * `.timeline`
   *
   *     @example
   *     .timeline.timeline--compact
   *       .timeline__entry
   *         .timeline__left-column
   *           .timeline__image
   *             img.avatar.avatar--small(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/avatar-male-1.png' alt='User Name')
   *         .timeline__content
   *           p Pellentesque eget commodo nulla. Donec a est sapien.
   */

  /**
   * Use case: compact timeline with day blocks:
   * `.timeline`
   *
   * You can group timeline items by day (or other criteria).
   *
   * See this use case in context in the pattern [HR dashboard](../templates/dashboard.html).
   *
   *     @example
   *     .timeline.timeline--compact
   *       .timeline__entry
   *         .timeline__left-column
   *           .timeline__image.timeline__image--circle
   *             svg.icon.icon--small(role='img' aria-hidden='true', focusable='false')
   *               use(xlink:href='../icons.svg#dot' href='../icons.svg#dot')
   *         .timeline__content
   *           h2.h6.top-margin.top-margin--extra-tiny 19 June 2020
   *       .timeline__entry
   *         .timeline__left-column
   *           .timeline__image.timeline__image--circle
   *             svg.icon.icon--small(role='img' aria-hidden='true', focusable='false')
   *               use(xlink:href='../icons.svg#document' href='../icons.svg#document')
   *         .timeline__content
   *           h3.h5
   *             a(href='#') Document name
   *           p Created at <strong>10:26 am</strong> by Rachel HAYS
   *       .timeline__entry
   *         .timeline__left-column
   *           .timeline__image.timeline__image--circle
   *             svg.icon.icon--small(role='img' aria-hidden='true', focusable='false')
   *               use(xlink:href='../icons.svg#document' href='../icons.svg#document')
   *         .timeline__content
   *           h3.h5
   *             a(href='#') Document name
   *           p Created at <strong>10:12 am</strong> by Rachel HAYS
   *       .timeline__entry
   *         .timeline__left-column
   *           .timeline__image.timeline__image--circle
   *             svg.icon.icon--small(role='img' aria-hidden='true', focusable='false')
   *               use(xlink:href='../icons.svg#dot' href='../icons.svg#dot')
   *         .timeline__content
   *           h2.h6.top-margin.top-margin--extra-tiny 18 June 2020
   *       .timeline__entry
   *         .timeline__left-column
   *           .timeline__image.timeline__image--circle
   *             svg.icon.icon--small(role='img' aria-hidden='true', focusable='false')
   *               use(xlink:href='../icons.svg#document' href='../icons.svg#document')
   *         .timeline__content
   *           h3.h5
   *             a(href='#') Document name
   *           p Created at <strong>3:15 pm</strong> by Rachel HAYS
   *       .timeline__entry
   *         .timeline__left-column
   *           .timeline__image.timeline__image--circle
   *             svg.icon.icon--small(role='img' aria-hidden='true', focusable='false')
   *               use(xlink:href='../icons.svg#document' href='../icons.svg#document')
   *         .timeline__content
   *           h3.h5
   *             a(href='#') Document name
   *           p Created at <strong>2:02 pm</strong> by Jimmie CLARK
   */
}
