$rotate-reset: rotate(0deg);
$rotate-half: rotate(180deg);
$rotate-full: rotate(360deg);
$rotate-clockwise: rotate(90deg);
$rotate-counterclockwise: rotate(-90deg);
$flip-horizontal: scale(-1, 1);
$flip-vertical: scale(1, -1);

@mixin rotate-reset {
  transform: $rotate-reset;
}

@mixin rotate-half {
  transform: $rotate-half;
}

@mixin rotate-full {
  transform: $rotate-full;
}

@mixin rotate-clockwise {
  transform: $rotate-clockwise;
}

@mixin rotate-counterclockwise {
  transform: $rotate-counterclockwise;
}

@mixin flip-horizontal {
  transform: $flip-horizontal;
}

@mixin flip-vertical {
  transform: $flip-vertical;
}
