/***
 * # Modals
 *
 * A modal is a focused container that appears above all other content on the page.
 *
 * All modals should contain a header, body and footer.
 *
 * ## When to use modals
 *
 *  - To focus the user's attention on an important task, without losing the context of the rest of the page.
 *  - To ask the user to confirm an important action. Be careful with this pattern though - it is often better to provide an 'undo' option (with an alert) instead.
 *
 * Be very careful when choosing to use a modal, as studies suggest that many users do not read the content, especially if it feels 'out of context'.
 *
 * To help increase continuity, make sure that:
 *
 *  - The modal title matches the link that the user clicked on to launch the modal.
 *  - The modal buttons re-enfoce the modal action. For example, a modal to confirm deleting a user might have two buttons: 'Cancel' and 'Delete User'.
 *
 * ## When not to use modals
 *
 *  - To display messages to the user that do not require an action - use an alert instead.
 *  - To display a lot of content, or a complex UI, unless you are using the modifier [full-page modal](#full-page-modal).
 *  - To display content that should be linked to (as the URL does not change).
 *
 * Never launch a modal without the user first clicking on a trigger button. This is bad because it takes control of the interface away from the user.
 *
 * ## Position and style
 *
 * Modals are positioned in the center of the screen on top of a grey overlay.
 *
 * With the variation [full-page modal](#full-page-modal), the modal takes the total of the space available and the overlay is not visible.
 *
 * The modal footer buttons are by default aligned to the right. It is possible to override this behaviour with [text aligment helpers](./../docs/helpers-typography.html#text-alignment).
 *
 * ## Keyboard interaction
 *
 * With focus on the trigger button:
 * - <kbd>Enter</kbd> or <kbd>Space</kbd>: opens the modal, and moves focus to the first focusable element. Usually, the first focusable element is the close modal button.
 *
 * With focus inside of the modal:
 * - <kbd>Esc</kbd>: closes the modal, and moves focus to the trigger button.
 * - When the modal is opened, the focus is contained within the modal and cannot reach other elements in the page.
 *
 */

$modal-min-width:                 $min-width;
$modal-max-width:                 $wrapper-large;

$modal-footer-background-color:   color(grey, 6);
$modal-background-color:          $block-background-color;
$modal-border-radius:             $border-radius-large;
$modal-header-radius:             $border-radius-large - 1;
$modal-inner-border-color:        $block-border-color;
$modal-drop-shadow-color:         hsla(var(--grey-level-1-h), var(--grey-level-1-s), var(--grey-level-1-l), 0.2);
$modal-drop-shadow-length:        3px;

$modal-tabs-navigation-height:    62px;

// If you change me, make sure you also change the docs!
$header-colors: 'primary', 'warning', 'danger', 'success', 'info';

@mixin modal-row() {
  background-color: $white;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  pointer-events: auto;
}

// vex z-index override to respect the z-index scale
.vex {
  z-index: index($z-index-scale, modal);
}

/**
 * Basic modal:
 *
 * A simple modal. The contents (with the class `modal`)
 * is the element following the toggle button (with `data-toggle="modal"`).
 *
 *     @example
 *     include ./code-samples/basic-modal.pug
 */

/**
 * Modal with alternative HTML structure:
 *
 * You can also bind the toggle button and the contents by using
 * data attributes (with `data-toggle="modal modal-name"`).
 * This allows for more flexible layouts.
 *
 *     @example
 *     include ./code-samples/modal-alt-structure.pug
 */

/**
 * Modal containing a form:
 *
 *     @example
 *     include ./code-samples/form-modal.pug
 */
@include block('modal') {
  padding: $ukg-spacing-s;
  font-size: rem($base-font-size);
  display: none;
  z-index: index($z-index-scale, modal);
  background-color: transparent;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=1, M12=0, M21=0, M22=1, SizingMethod='auto expand')";
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  @include modifier('visible') {
    display: flex;
  }

  @include element('wrapper') {
    min-width: rem($modal-min-width);
    max-width: rem($modal-max-width);
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: $modal-border-radius;
    box-shadow: $ukg-elevation-24;

    > * {
      flex: 0 0 auto;
    }
  }

  @include element('header') {
    @include modal-row;
    border-radius: rem($modal-border-radius $modal-border-radius) 0 0;
    border-bottom: rem(1px) solid hsla(var(--grey-level-5-h), var(--grey-level-5-s), var(--grey-level-5-l), 0.4);
    padding: $ukg-spacing-s;
    display: flex;
    align-items: flex-start;
  }

  @include element('title') {
    @include h2;
    align-self: center;
    margin: 0;
    padding-right: $ukg-spacing-s;
    line-height: 1;
  }

  @include mixie('close-button') {
    @include seamless-button;
    margin-left: auto;
    order: 1; // Position close button always to the right regardless of source order
  }

  @include element('body') {
    @include no-first-child-margin;
    @include modal-row;
    padding: $ukg-spacing-s;
    overflow-y: auto;
    flex-shrink: 1;

    /**
    * Unpadded body modal:
    * `.modal__body--unpadded`
    *
    * A modal width an unpadded `.modal__body`.
    *
    *     @example
    *     include ./code-samples/unpadded-modal.pug
    */
    @include modifier('unpadded') {
      padding: 0;
    }
  }

  @include element('footer') {
    @include modal-row;
    @include no-child-margin;
    border-top: rem(1px) solid $modal-inner-border-color;
    border-radius: 0 0 rem($modal-border-radius $modal-border-radius);
    background-color: $modal-footer-background-color;
    padding: $ukg-spacing-s;
    // Always align modal buttons to the right.
    text-align: right;

    > .button {
      margin-right: $ukg-spacing-xxxs;
      margin-left: $ukg-spacing-xxxs;
    }
  }

  /**
  * Full-width modal:
  * `.modal--full-width`
  *
  * A modal that takes always the maximum horizontal space, regardless of the width of its content.
  *
  *     @example
  *     include ./code-samples/full-width-modal.pug
  */
  @include modifier('full-width') {
    @include element('wrapper') {
      width: 100vw;
    }
  }

  /**
  * Full-height modal:
  * `.modal--full-height`
  *
  * A modal that takes always the maximum vertical space, regardless of the height of its content.
  *
  * Typically used in [combination with full-width](#use-case-full-widthheight-modal).
  *
  *     @example
  *     include ./code-samples/full-height-modal.pug
  */
  @include modifier('full-height') {
    @include element('wrapper') {
      height: 100%;
    }

    @include element('body') {
      height: 100%;
    }
  }

  /**
  * Full-page modal:
  * `.modal--full-page`
  *
  * A modal that takes the total of space available on the viewport, without grey overlay.
  *
  *     @example
  *     include ./code-samples/full-page-modal.pug
  */
  @include modifier('full-page') {
    padding: 0;

    @include element('wrapper') {
      min-width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
    }

    @include element('header') {
      border-radius: 0;
    }

    @include element('body') {
      width: 100%;
      height: 100%;
    }

    @include element('footer') {
      border-radius: 0;
    }
  }

  /**
  * Modal with fixed tabs:
  * `.modal--fixed-tabs`
  *
  * Modal with a child [tabs](../docs/blocks-tabs.html) component.
  *
  * The element `.tabs__header` stays fixed at the top of the `.modal__body`.
  *
  * In the case of long content, a scrollbar will appear within `.tabs__content` elements.
  *
  *     @example
  *     include ./code-samples/fixed-tabs-modal.pug
  */
  @include modifier('fixed-tabs') {

    @include element('wrapper') {
      height: 100%; //Needed for the fix tabs to work
      box-shadow: none; // Incompatible with fix height 100% when the modal is shorter than the viewport
    }

    @include element('body') {
      display: flex;
      flex-direction: column;
      flex: 0 1 auto;
    }

    @include block('tabs') {
      display: flex;
      flex-direction: column;
      height: 100%;
      flex: 0 1 100%;
      -ms-flex: 1 0 auto; // IE compatible
    }
  }

  /**
   * Use case: modal with a global alert:
   *
   * It's possible to add an alert before the `.modal__body` element to display global messages.
   * This is specially useful in modals with [fixed tabs](#modal-with-fixed-tabs).
   *
   * <div class="alert top-margin">
   *   <svg role="img" aria-labelledby="basic-text-editor-alert-icon" focusable="false" class="alert__icon icon">
   *     <title id="basic-text-editor-alert-icon">Information</title>
   *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *   </svg>
   *   <strong>Scrollable area</strong>
   *   <p>This section is not part of the modal scrollable area.</p>
   *   <p>Keep the content of the alert as short as possible to not reduce too much the scrollable area of the modal.</p>
   * </div>
   *
   *     @example
   *     include ./code-samples/modal-with-global-alert.pug
   */

  /**
   * Use case: full-width/height modal:
   * `.modal--full-width.modal--full-height`
   *
   * A modal that takes always the maximum horizontal and vertical space, regardless of its content.
   *
   *     @example
   *     include ./code-samples/full-width-height-modal.pug
   */
}
