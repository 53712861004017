/***
 * # Overflow helpers
 *
 * A set of helpers that allow the content, of the element to which they
 * are applied, to overflow.
 */

/**
 * Horizontally scrollable container:
 * `.scroll-x`
 *
 * A helper that allows you to scroll horizontally when content does not fit in its parent
 * container.
 *
 * Typically used with [dynamic-lists](../docs/blocks-dynamic-list.html) and [tables](../docs/blocks-table.html).
 *
 *     @example
 *     include ./code-samples/scroll-x.pug
 */
.scroll-x {
  overflow-x: auto;
}
