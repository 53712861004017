// Hides the block or element on everything *except* for screen readers.
// It's hidden, so every value here should be in px
@mixin sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
}

// Reset all the properties to their initial value
@mixin sr-only-reset {
  clip: auto;
  clip-path: none;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: normal;
}
