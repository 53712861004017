/***
 * <div class="alert alert--info bottom-margin">
 *   <svg class="alert__icon icon" aria-hidden="true" focusable="false">
 *     <use xlink:href="../icons.svg#info" href="../icons.svg#info">
 *   </svg>
 *   <p class="h4">Important information</p>
 *   <p>This is <strong>version 2</strong> of the vertical menu component. [Information to migrate from v1](#how-to-migrate-from-vertical-menu-v1)</p>
 *   <p>In order to use version 2, you need to add the `data-version="2"` attribute to the `.vertical-menu` element.</p>
 * </div>
 *
 * # Vertical menus
 *
 * A menu where links are stacked on top of each other. Each link may optionally
 * include a corresponding icon or avatar.
 *
 * Vertical menus may also include submenus, badges and dropdown icons.
 *
 * ## When to use vertical menus
 *
 * Vertical menu can be used directly in the user interface or inside dropdowns.
 *
 * ## When not to use vertical menus
 *
 * Long vertical menus can be problematic, particularly when combined with
 * dropdowns. In this case, try to break the list into several lists, or
 * consider a different UI altogether.
 *
 * ## Position and style
 *
 * Vertical menus can be positioned anywhere in the UI. Dark and lined
 * modifiers can be used to control constrast and style.
 *
 * ## How to migrate from vertical menu v1
 *
 * - Add the attribute `data-version="2"` to the `.vertical-menu` element.
 * - The modifier `.vertical-menu--large` does not exist in v2 and should be removed.
 * - Vertical menu v2 doesn't support the old `.vertical-menu__marker` HTML markup. If you are still using the old code, please update it as follows:
 * - If you want to keep the old behaviour where only one submenu is open at a time, add <code>data-single-expand="true"</code>.
 * [See example here](#vertical-menu-with-submenu-single-expand).
 *
 * **❌ Old code:**
 *
 * ```html
 * <svg class="vertical-menu__marker icon icon--danger" role="img" aria-labelledby="uniqueID" focusable="false">
 *   <title id="uniqueID">Has notification</title>
 *   <use xlink:href="../icons.svg#dot" href="../icons.svg#dot"></use>
 * </svg>
 * ```
 *
 * **✅ New code:**
 *
 * ```html
 * <span class="vertical-menu__marker">
 *   <span class="sr-only">Has notifications</span>
 * </span>
 * ```
 */

// General Settings
$menu-text-color:                         $base-font-color;
$menu-border-color:                       $block-border-color;
$menu-border-width:                       1px;
$menu-selected-color:                     $dark-font-color;
$menu-hover-background-color:             $block-darker-background-color;
$menu-selected-background-color:          color(grey, 5);
$menu-background-color:                   $block-background-color;

// Menu Items
$menu-item-font-size:                     14px;
$menu-item-line-height:                   20px;
$menu-item-padding:                       12px 16px 12px 16px;
$menu-item-gap:                           8px;
$closed-item-horizontal-padding:          10px;

// Menu Titles
$menu-title-font-size:                    20px;
$menu-title-line-height:                  30px;
$menu-title-padding:                      18px 16px 8px 16px;

// Menu Icons
$menu-icon-size:                          20px;

// Dark Menu
$dark-menu-background-color:              color(grey, 3);
$dark-menu-text-color:                    rgba($white, 0.88);
$dark-menu-border-color:                  rgba($white, 0.2);
$dark-menu-title-color:                   $white;
$dark-menu-hover-background-color:        rgba($white, 0.15);
$dark-menu-selected-background-color:     color(grey, 2);
$dark-menu-selected-color:                $white;
$dark-menu-focus-indicator-color:         rgba($white, 0.65);

// Submenu
$sub-menu-item-padding:                   12px 16px 12px 44px;
$sub-menu-title-font-size:                18px;
$sub-menu-title-line-height:              26px;
$sub-menu-title-padding-left:             44px;

// Submenu triangle size and position
$triangle-size:                           12px;
$triangle-left-offset:                    20px;

// Timing
$vertical-menu-very-fast:                 0.1s;
$vertical-menu-fast:                      0.15s;
$vertical-menu-slower:                    0.5s;

// Easing
$vertical-menu-ease-opening:              ease-in-out;
$vertical-menu-ease-closing:              cubic-bezier(0, 1.01, 0.85, 1.12);

@mixin menu-hover {
  background-color: $menu-hover-background-color;
}

@mixin menu-selected {
  background-color: $menu-selected-background-color;
  color: $menu-selected-color;
  font-weight: $bold;
}

@mixin dark-menu-hover {
  background-color: $dark-menu-hover-background-color;
}

@mixin dark-menu-selected {
  background-color: $dark-menu-selected-background-color;
  color: $dark-menu-selected-color;
}

/**
 * Basic vertical menu:
 * `.vertical-menu`
 *
 * A standard vertical menu.
 *
 * Each menu item should be wrapped in a 'link-title'.
 *
 *     @example
 *     include ./code-samples/vertical-menu-basic.pug
 */
@include block('vertical-menu', 2) {
  font-size: rem($menu-font-size);
  color: $menu-text-color;
  background-color: $menu-background-color;
  white-space: normal;

  ul {
    margin: 0;
    list-style: none;
    font-size: inherit;
  }

  li {
    padding: 0;
    margin: 0;
  }

  @include element('link') {
    padding: rem($menu-item-padding);
    display: flex;
    align-items: center;
    font-size: rem($menu-item-font-size);
    line-height: rem($menu-item-line-height);
    width: 100%;
    text-align: left;
    background-color: transparent;
    cursor: pointer;
    color: currentColor;
    position: relative;
    text-decoration: none;
    border: 0;
    transition: background-color $vertical-menu-very-fast linear;

    &:hover {
      text-decoration: none;
      @include menu-hover;
    }

    &:focus,
    &:active {
      @include focus-outline-pseudo-element($gap: -3px);
    }

    @include modifier('sub-link-selected') {
      font-weight: $bold;
    }

    /**
     * Avatar:
     * `.vertical-menu__link--with-avatar`
     *
     * A link with an avatar.
     *
     * Always list the avatar before the link title.
     *
     *     @example
     *     include ./code-samples/vertical-menu-avatar.pug
     */
    @include block('avatar') {
      max-width: rem($menu-icon-size);
      max-height: rem($menu-icon-size);
      margin-right: rem($menu-item-gap);
      flex: none;
      align-self: flex-start;
      transform: scale(1.25);
    }

    @include modifier('selected') {
      @include menu-selected;
    }

    @include modifier('opened') {
      .vertical-menu__marker {
        display: none;
      }
    }
  }

  /**
   * Vertical menu with titles:
   * `.vertical-menu__title`
   *
   * A vertical menu that includes titles.
   *
   *     @example
   *     include ./code-samples/vertical-menu-titles.pug
   */
  @include element('title') {
    padding: rem($menu-title-padding);
    font-size: rem($menu-title-font-size);
    line-height: rem($menu-title-line-height);
    color: $menu-text-color;
    display: block;
  }

  li:not(:first-child) > .vertical-menu__title {
    margin-top: rem(16px);
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - #{rem(8px)});
      height: rem(1px);
      background-color: $menu-border-color;
    }
  }

  /**
   * Vertical menu with icons:
   * `.vertical-menu__icon`
   *
   * A vertical menu containing icons.
   *
   * Always list the icons before the link title.
   *
   * <div class="card card--standout top-margin">
   *   <div class="card__body">
   *     <p>
   *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title1" focusable="false">
   *         <title id="title1">Information</title>
   *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *       </svg>
   *       <strong>Accessibility</strong>
   *     </p>
   *     <p>These icons are decorative as the text provides all the necessary information. Make sure you add <code>aria-hidden="true"</code> to the <code>svg</code> element.</p>
   *   </div>
   * </div>
   *
   *     @example
   *     include ./code-samples/vertical-menu-icons.pug
   */
  @include mixie('icon') {
    width: rem($menu-icon-size);
    height: rem($menu-icon-size);
    margin-right: rem($menu-item-gap);
    flex: none;
    align-self: flex-start;
  }

  /**
   * Vertical menu with dropdown icon:
   * `.vertical-menu__dropdown-icon`
   *
   * A vertical menu with dropdown arrow (at right).
   *
   * Always list the dropdown icon before the link title in the HTML (even
   * though it appears on the right on the design).
   *
   *     @example
   *     include ./code-samples/vertical-menu-dropdown-icon.pug
   */
  @include element('dropdown-icon') {
    width: rem(16px);
    height: rem(16px);
    flex: none;
    order: 2;
    align-self: flex-start;
    margin-left: auto; // Align to the right within parent flex container
    transition: transform $vertical-menu-fast linear;

    &.icon--flipped-vertical {
      transform: rotate(-180deg);
    }
  }

  /**
   * Vertical menu with badge:
   * `.badge.badge--danger`
   *
   * List the badge before the link title in the HTML (even though
   * it appears on the right on the design).
   *
   *     @example
   *     include ./code-samples/vertical-menu-badge.pug
   */
  @include block('badge') {
    flex: none;
    order: 1;
    margin-left: rem($menu-item-gap);
  }

  /**
   * Vertical menu with marker:
   * `.vertical-menu__marker`
   *
   * A marker can be used to highlight an item that contains
   * a submenu with badges. In that regard, it can be seen as
   * an abbreviated badge.
   *
   * When using with a dropdown-icon,
   * add after the icon.
   *
   * Always list the marker before the link title in the HTML (even though
   * it appears on the right on the design).
   *
   *     @example
   *     include ./code-samples/vertical-menu-marker.pug
   */

  @include element('marker') {
    width: rem(10px);
    height: rem(10px);
    margin-left: rem($menu-item-gap);
    background-color: color(danger, dark);
    border: rem(2px) solid $white;
    border-radius: 50%;
    flex: none;
    order: 1;
  }

  /**
   * Vertical menu with submenu:
   * `.vertical-menu__sub-menu`
   *
   * A vertical menu with a submenu.
   *
   * <div class="card card--standout top-margin">
   *   <div class="card__body">
   *     <p>
   *       <svg class="icon icon--small right-margin right-margin--tiny" focusable="false">
   *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *       </svg>
   *       <strong>Accessibility</strong>
   *     </p>
   *     <p>Add <code>aria-expanded="false"</code> and <code>aria-controls="submenu_id"</code> to the toggle element.</p>
   *     <p>If the initial state of the submenu is open, set <code>aria-expanded</code> to <code>"true"</code> instead.</p>
   *     <p>The submenu must be properly labelled. To this end, use <code>aria-labelledby="toggle_id"</code>.</p>
   *   </div>
   * </div>
   *
   *     @example
   *     include ./code-samples/vertical-menu-sub-menu.pug
   */

  /**
   * Vertical menu with submenu (single expand):
   * `[data-single-expand]`
   *
   * <p>By default, the user has the capability to open multiple submenus at a time.</p>
   *
   * <p>Optionally, if single expand is enabled, only one submenu may be open at a time.</p>
   *
   * <p>Single expand can be enabled with the option `singleExpand` or by adding the HTML attribute `data-single-expand`.</p>
   *
   *     @example
   *     include ./code-samples/vertical-menu-sub-menu-singleexpand.pug
   */
  @include element('sub-menu') {
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;

    @include modifier('opened') {
      visibility: visible;
      opacity: 1;
      height: auto;
    }

    .vertical-menu__title {
      font-size: rem($sub-menu-title-font-size);
      line-height: rem($sub-menu-title-line-height);
      padding-left: rem($sub-menu-title-padding-left);
    }
  }

  @include element('sub-link') {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: rem($sub-menu-item-padding);
    color: $menu-text-color;
    font-size: rem($menu-item-font-size);
    line-height: rem($menu-item-line-height);
    width: 100%;
    text-decoration: none;
    background-color: transparent;
    border: 0;

    &:hover {
      text-decoration: none;
      @include menu-hover;
    }

    &:focus,
    &:active {
      @include focus-outline-pseudo-element($gap: -3px);
      background-color: transparent; // Overwrite default link style
    }

    @include modifier('selected') {
      @include menu-selected;
    }
  }

  /**
   * Vertical menu disabled:
   * `[data-disabled="open"]`,<br>
   * `[data-disabled="close"]`,<br>
   * `[data-disabled="open close"]`
   *
   * The `[data-disabled]` attribute disables the open/close functionality of a submenu, according to what value is passed to it ("open", "close", "open close").
   *
   * You may attach the `[data-disabled]` attribute either to `.vertical-menu`, in order to disable all submenus,
   * or to specific `[data-trigger='submenu']` elements in order to disable only them.
   * <div class="card card--standout top-margin">
   *   <div class="card__body">
   *     <p>
   *       <svg class="icon icon--small right-margin right-margin--tiny" focusable="false">
   *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *       </svg>
   *       <strong>Accessibility</strong>
   *     </p>
   *     <p>If the initial state of the open/close functionality is disabled, add <code>aria-disabled="true"</code> to the toggle element.</p>
   *   </div>
   * </div>
   *
   *     @example
   *     include ./code-samples/vertical-menu-disabled.pug
   */

  /**
   * Vertical menu with dividing lines:
   * `.vertical-menu--lined`
   *
   * A vertical menu with lines dividing each item.
   *
   *     @example
   *     include ./code-samples/vertical-menu-lined.pug
   */
  @include modifier('lined') {
    @include element('link') {
      border-bottom: rem($menu-border-width) solid $menu-border-color;
    }

    @include element('sub-menu') {
      border-bottom: rem($menu-border-width) solid $menu-border-color;
    }

    li:not(:first-child) > .vertical-menu__title {
      &::before {
        display: none;
      }
    }

    @include and-modifier('dark') {
      @include element('link', 'sub-menu') {
        border-color: $dark-menu-border-color;
      }
    }
  }

  /**
   * Dark vertical menu:
   * `.vertical-menu--dark`
   *
   * A dark vertical menu.
   *
   *     @example
   *     include ./code-samples/vertical-menu-dark.pug
   */
  @include modifier('dark') {
    background-color: $dark-menu-background-color;
    color: $dark-menu-text-color;

    li {
      color: $dark-menu-text-color;
    }

    @include element('link') {
      &:hover {
        @include dark-menu-hover;
      }

      &:active,
      &:focus {
        &::before {
          border-color: $dark-menu-focus-indicator-color;
        }
      }

      @include modifier('selected') {
        @include dark-menu-selected;

        &:hover {
          @include dark-menu-selected;
        }
      }
    }

    @include block('badge') {
      @extend .badge--on-dark;
    }

    @include element('title') {
      color: $dark-menu-title-color;
    }

    li:not(:first-child) > .vertical-menu__title {
      &::before {
        background-color: $dark-menu-border-color;
      }
    }

    @include element('sub-link') {
      color: $dark-menu-text-color;
      position: relative;

      &:hover {
        @include dark-menu-hover;
      }

      &:active,
      &:focus {
        &::before {
          border-color: $dark-menu-focus-indicator-color;
        }
      }

      @include element('badge') {
        ~ .vertical-menu__link-title {
          display: block;
          padding-right: 35px;
        }
      }

      @include modifier('selected') {
        @include dark-menu-selected;
      }
    }

    @include element('marker') {
      width: rem(9px);
      height: rem(9px);
      box-shadow: 0 0 0 rem(1px) $dark-menu-background-color;
    }
  }

  /**
   * Closed vertical menu:
   * `.vertical-menu--closed`
   *
   * A vertical menu that appears 'closed' on tablet resolutions and below - with padding reduced and icons/avatars removed.
   *
   *     @example
   *     include ./code-samples/vertical-menu-closed.pug
   */
  @include modifier('closed') {
    @media screen and (max-width: $tablet) {
      .vertical-menu__link,
      .vertical-menu__link--with-avatar {
        padding-right: rem($closed-item-horizontal-padding);
        padding-left: rem($closed-item-horizontal-padding);
      }

      .avatar {
        display: none;
      }

      @include mixie('icon') {
        display: none;
      }

      @include element('sub-link') {
        padding-right: rem($closed-item-horizontal-padding);
        padding-left: rem($closed-item-horizontal-padding);
      }
    }
  }
}

/**
 * Use case: everything combined:
 * `.vertical-menu.vertical-menu--dark`
 *
 * A large, dark, lined menu that contains submenus.
 * Note the badge color matrix.
 *
 *     @example
 *     include ./code-samples/use-case-everything-combined.pug
 */
