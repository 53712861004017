/***
 * # Droppable indicators
 *
 * A droppable indicator is a block that should be used to indicate that
 * something can be dropped into the space it holds.
 *
 * ## When to use droppable indicators
 *
 * Droppable indicators should be used to illustrate a target for drag and drop
 * items.
 *
 * ## When not to use droppable indicators
 *
 * Never use a droppable indicator to add decorative style to an item.
 *
 * ## Position and style
 *
 * Droppable indicators can be positioned anywhere in the UI, although given
 * that they have a card like appearance, they look best positioned directly
 * against the background of the page.
 *
 * You can include a message inside the droppable indicator, using headings or other
 * type tags.
 */

$droppable-border-width:        $target-border-width;
$droppable-border-color:        $target-border-color;
$droppable-padding:             $target-padding;
$droppable-inner-border-width:  $target-inner-border-width;
$droppable-inner-border-color:  $target-inner-border-color;
$droppable-hover-color:         $target-inner-hover-color;
$droppable-inner-margin:        $target-inner-margin;

/**
 * Droppable target:
 *
 * `.droppable`
 *
 *     @example
 *     .droppable Drop files here
 */
@include block('droppable') {
  @include form-field-color-variation(standard);
  padding: rem($droppable-padding);
  font-size: rem($base-font-size);
  border: rem($droppable-inner-margin) solid $white;
  box-shadow: 0 0 0 rem($droppable-border-width) $droppable-border-color;
  border-radius: rem($form-field-border-radius);
  text-align: center;
  background-color: $white;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    border: rem($droppable-inner-border-width) dotted $droppable-inner-border-color;
    border-radius: rem($border-radius-small);
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  &:hover {
    background-color: $droppable-hover-color;
  }

  > * {
    margin-top: 0;
  }

  /**
   * Small droppable target:
   *
   * `.droppable--small`
   *
   * Use for small targets, e.g. inside grids.
   *
   *   @example
   *   include ./code-samples/small-target.pug
   */
  @include modifier('small') {
    padding: rem($droppable-padding / 2);
  }
}
