$upload-area-max-width: $wrapper-medium;
$upload-area-background-color: color(grey, 6);

$upload-area-dropzone-padding: $target-padding;
$upload-area-dropzone-border-width: $target-border-width;
$upload-area-dropzone-border-color: color(grey, 4);
$upload-area-dropzone-background-color: $white;
$upload-area-dropzone-height: 215px;

@include block('upload-area') {
  font-size: rem($base-font-size);
  max-width: rem($upload-area-max-width);

  @include element('button') {
    display: inline-block;
    position: relative;
  }

  @include element('list') {
    @include unstyled-list;

    > * {
      margin-top: $ukg-spacing-xs;
    }
  }

  @include element('list-name') {
    outline: 0;
  }

  @include element('dropzone') {
    position: relative;
    min-height: rem($upload-area-dropzone-height);
    padding: rem($upload-area-dropzone-padding);
    border: rem($upload-area-dropzone-border-width) dashed $upload-area-dropzone-border-color;
    border-radius: rem($border-radius-large);
    background-color: $upload-area-dropzone-background-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &.dz-drag-hover { // Added by Dropzone when a user drags a file over the form target
      background-color: $upload-area-background-color;

      @include element('dropzone-instructions') {
        display: none;

        @include modifier('on-hover') {
          display: block;
          pointer-events: none;
        }
      }

      @include element('dropzone-button') {
        display: none;
      }

      @include element('dropzone-icon') {
        pointer-events: none;
      }
    }
  }

  @include modifier('disabled') {

    @include element('dropzone') {
      pointer-events: none;
      border-color: hsla(var(--grey-level-4-h), var(--grey-level-4-s), var(--grey-level-4-l), 0.4);
    }

    @include element('dropzone-icon') {
      opacity: 0.4;
    }

    @include element('dropzone-instructions') {
      opacity: 0.4;
    }
  }

  @include element('dropzone-icon') {
    margin-bottom: $ukg-spacing-s;
  }

  @include element('dropzone-instructions') {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;

    @include modifier('on-hover') {
      display: none;
    }
  }

  @include element('dropzone-button') {
    margin-top: $ukg-spacing-xxs;
  }

  @include mixie('alert') {
    margin-top: $ukg-spacing-s;
  }
}
