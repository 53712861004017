@import '@ukgepic/hrsd-pdui-theme/src/settings/color';
@import 'radius';

// BLOCK SETTINGS
// Used by cards, modals, etc.

$block-padding:                 20px;
$block-background-color:        $white;
$block-darker-background-color: color(grey, 6);
$block-border-color:            color(grey, 5);
$block-darker-border-color:     color(grey, 4);

// LIST / THUMBNAIL SETTINGS

$item-vertical-spacing:         5px;
