/***
 * # Buttons
 *
 * Buttons are clickable elements used to perform an action.
 *
 * ## When to use buttons
 *
 * - To create new objects, cancel an action, edit an item, delete an item.
 * - To publish forms, articles and processes.
 * - To display a [dropdown](./blocks-dropdown.html) menu of actions.
 * - To close/complete [modals](./blocks-modal.html).
 * - To prompt any other action.
 *
 * Links should only be decorated as buttons if they require a strong 'call to action'. For example, a link to create new users or upload documents.
 *
 * ## When not to use buttons
 *
 * Never use a button as a decorative item. Make sure that when a button is pressed/clicked, an action occurs.
 *
 * ## Visual weight
 *
 * Use the [primary button](#primary-button) to indicate the most important action. To maintain clarity and hierarchy, try to include only one [primary button](#primary-button) in a view.
 *
 * The colour of the button should be chosen according to the intent of the action:
 * - To create, submit, publish, save you should use the [primary button](#primary-button).
 * - To communicate any critical action (e.g. a definitive deletion) you should use the [danger button](#danger-button).
 *
 * A view can show more than one button at a time. In that case a [primary button](#primary-button) can be accompanied by [basic buttons](#basic-button) and [text buttons](#text-button) to perform less important actions.
 *
 * ## Position
 *
 * When coupled with [basic buttons](#basic-button), position the [primary button](#primary-button) on the right to reduce eye-fixations as we read left to right. Placing the primary button to the right also supports existing cultural design language that actions on the left go back and actions on the right go forward.
 *
 * When buttons or a group of buttons is being used in the context of a form (to either submit or cancel) they should be placed on the left (or vertically aligned with the form fields) in order to be placed in the continuity of the reading direction.
 *
 * When they're being used in a small view context (such as modals) they should be placed on the right.
 *
 * ## Label
 *
 * Buttons should be clearly and accurately labeled, and lead with a strong, actionable verb that expresses what action will occur when the user interacts with it.
 *
 * ## Code
 *
 * When placing a HTML `<button>` element that should not act like a submit button, inside a `<form>`, you need to explicitly define its `[type]` as `[type="button"]`. Otherwise your `<button>` will act as a `[type="submit"]` button by default.
 *
 * ## Deprecation warnings:
 * - [The class `.button--previous` has been deprecated](#previous-button)
 * - [The class `.button--next` has been deprecated](#next-button)
 * - [The classes `.button--on-warning`, `.button--on-danger`, `.button--on-success` and `.button--on-info` have been deprecated](#button-on-colored-backgrounds)
 *
 */

/*
  Variables can be found in src/settings/_button.scss
  Mixins can be found in src/tools/_button.scss
*/

/**
 * Basic button:
 * `.button`
 *
 * A standard button.
 *
 *     @example
 *     include ./code-samples/basic-button.pug
 */
@include block('button') {
  @include std-button;

  // Overrides Ignite CSS
  &:active,
  &:visited,
  &:link,
  &:hover {
    color: inherit;
    font-weight: $bold;
    font-size: $h6-size;
    line-height: rem($button-line-height);
    text-decoration: none;
  }

  &:hover,
  &:focus {
    @include std-hover;
  }

  &:focus,
  &:active {
    @include std-focus-indicator;
  }

  &:active {
    @include std-active;
  }

  /**
   * Primary button:
   * `.button--primary`
   *
   * A primary button.
   *
   *     @example
   *     button.button.button--primary(type='button') Primary button
   */

  /**
   * Danger button:
   * `.button--danger`
   *
   * A danger button.
   *
   *     @example
   *     button.button.button--danger(type='button') Danger button
   */
  @each $color in $color-buttons {
    @include modifier($color) {
      @include color-button($color);
    }
  }

  /**
   * Text button:
   * `.button--text`
   *
   * A low emphasis button without border and background.
   *
   *     @example
   *     button.button.button--text(type='button') Text button
   *     button.button.button--text(type='button' disabled) Text button
   */
  @include modifier('text') {
    padding-left: rem(8px);
    padding-right: rem(8px);
    font-size: rem(16px);
    background: none;
    font-weight: $regular;
    border-color: transparent;
    color: $font-color-primary-on-white;

    &:hover,
    &:active {
      background-color: $button-hover-background-color-hsla;
      color: $font-color-deep-primary-on-white;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 rem(3px) color(primary, mid);
    }

    &:disabled,
    &[aria-disabled='true'] {
      @include disabled;

      &,
      &:hover { // override :disabled:hover default buttons
        color: inherit;
        background: none;
        border-color: transparent;
      }
    }
  }

  /**
   * Text button on colored background:
   * `.button--text.button--on-color`
   *
   * In a container with a background color, with this modifier class,
   * the text button inherits the current text color.
   *
   * <div class="alert alert--warning top-margin top-margin--small">
   *   This modifier is <strong>only</strong> compatible with <a href="#text-button">text buttons</a>. For other buttons, see <a href="#button-on-primary">button on primary</a> and <a href="#button-on-dark-grey">button on dark grey</a>.
   * </div>
   *
   *     @example
   *     .horizontal-section.horizontal-section--primary(style='padding: 1rem;')
   *       button.button.button--text.button--on-color(type='button') Text button
   *       button.button.button--text.button--on-color(type='button' disabled) Text button
   *     .horizontal-section.horizontal-section--dark(style='padding: 1rem;')
   *       button.button.button--text.button--on-color(type='button') Text button
   *       button.button.button--text.button--on-color(type='button' disabled) Text button
   */
  @include modifier('on-color') {
    color: inherit;

    &:hover,
    &:active {
      color: inherit;
    }

    &:focus {
      box-shadow: 0 0 0 rem(3px) currentColor;
    }
  }

  /**
   * Button on primary:
   * `.button--on-primary`
   *
   * A button for using on primary backgrounds.
   *
   *     @example
   *     include ./code-samples/button-on-primary.pug
   */
  @include modifier('on-primary') {
    @include on-color-button('primary');
  }

  /**
   * Button on dark grey:
   * `.button--on-dark-grey`
   *
   * A button for using on dark grey backgrounds.
   *
   *     @example
   *     include ./code-samples/button-on-dark-grey.pug
   */

  @include modifier('on-dark-grey') {
    @include on-dark-grey-button;
  }

  /**
   * Disabled button:
   * `.button[disabled]`
   *
   * A disabled button.
   *
   *     @example
   *     include ./code-samples/disabled-button.pug
   */
  &:disabled,
  &[aria-disabled='true'] {
    &,
    &:hover {
      @include std-disabled;
    }

    &:active {
      @include hide-focus-indicator;
    }
  }

  /**
   * Link as disabled button:
   * `a.button[aria-disabled="true"]`
   *
   * The `disabled` attribute is not valid on links. If you are styling a link to look
   * like a disable button, use `aria-disabled="true"` instead.
   *
   * **Warning:** `aria-disabled="true"` alone won't disable the link. **Make sure to remove
   * the `href` attribute** to cancel the hyperlinking behavior.
   *
   *     @example
   *     include ./code-samples/link-as-disabled-button.pug
   */

  /**
   * Pressed button:
   * `.button--pressed`,
   * `button[aria-pressed="true"]`,
   * `button[aria-checked="true"]`
   *
   * A 'pressed' button.
   *
   *     @example
   *     button.button.button--pressed(type='button') Pressed button
   */
  &.button--pressed,
  &[aria-pressed='true'],
  &[aria-checked='true'] {
    @include std-pressed;

    &:disabled,
    &[aria-disabled='true'] {
      &:hover {
        @include std-pressed;
      }
    }

    /**
     * Pressed color button:
     * `.button--mycolor.button--pressed`
     *
     * A color button that is pressed.
     *
     *     @example
     *     include ./code-samples/pressed-button-colored.pug
     */
    @each $color in $color-buttons {
      &.button--#{$color} {
        @include color-pressed($color);

        &:disabled,
        &[aria-disabled='true'] {
          &:hover {
            @include color-pressed($color);
          }
        }
      }
    }

    /**
     * Pressed button on primary:
     * `.button--on-primary.button--pressed`
     *
     * A pressed button for using on primary backgrounds.
     *
     *     @example
     *     include ./code-samples/pressed-button-on-primary.pug
     */
    &.button--on-primary {
      @include on-color-pressed('primary');

      &:disabled,
      &[aria-disabled='true'] {
        &:hover {
          @include on-color-pressed('primary');
        }
      }
    }

    /**
     * Pressed button on dark-grey:
     * `.button--on-dark-grey.button--pressed`
     *
     * A pressed button for using on dark-grey backgrounds.
     *
     *     @example
     *     include ./code-samples/pressed-button-on-dark-grey.pug
     */
    @include and-modifier('on-dark-grey') {
      @include on-dark-grey-pressed;

      &:disabled,
      &[aria-disabled='true'] {
        &:hover {
          @include on-dark-grey-pressed;
        }
      }
    }
  }

  /**
   * Large button:
   * `.button--large`
   *
   * A button with larger padding. Use for call's to action and to align with
   * large inputs
   *
   *     @example
   *     button.button.button--large(type='button') Large button
   */
  @include modifier('large') {
    padding: rem($large-button-padding);
    border-radius: $button-large-border-radius;
  }

  /**
   * Thin button:
   * `.button--thin`
   *
   * A button with less horizontal padding. Use for dropdown buttons.
   *
   *     @example
   *     include ./code-samples/thin-button.pug
   */
  @include modifier('thin') {
    padding-left: rem(6px);
    padding-right: rem(6px);
  }

  /**
   * Full width button:
   * `.button--full-width`
   *
   * A full width button. Use inside small containers.
   *
   *     @example
   *     button.button.button--full-width(type='button') Full width button
   */
  @include modifier('full-width') {
    display: block;
    width: 100%;
  }

  /**
   * Hidden button:
   * `.button--hidden`, `[hidden]`
   *
   * A hidden button.
   *
   *     @example
   *     button.button.button--hidden(type='button') Hidden button
   */
  &[hidden],
  &.button--hidden {
    display: none;
  }

  /**
   * Button with an icon:
   * `.button__icon`
   *
   * The button can have an icon do not combine icons with text.
   *
   * <div class="card card--standout top-margin">
   *   <div class="card__body">
   *     <p>
   *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title1" focusable="false">
   *         <title id="title1">Information</title>
   *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *       </svg>
   *       <strong>Accessibility</strong>
   *     </p>
   *     <p>Make sure you add a descriptive <a href="./blocks-tooltip.html">tooltip</a> and link its content to the button with an <code>aria-labelledby</code> to the tooltip's <code>id<code>.</p>
   *     <p>The <code>svg</code> element has <code>aria-hidden="true"</code>.</p>
   *   </div>
   * </div>
   *
   *     @example
   *     include ./code-samples/button-with-icon.pug
   */
  @include mixie('icon') {
    height: rem(16px);
    width: rem(16px);
    margin: rem(0 2px);
    vertical-align: bottom;

    /**
     * Button with left margin:
     * `.button__icon--offset`
     *
     * When a button has two icons, offset the second.
     *
     * <div class="card card--standout top-margin">
     *   <div class="card__body">
     *     <p>
     *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title2" focusable="false">
     *         <title id="title2">Information</title>
     *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
     *       </svg>
     *       <strong>Accessibility</strong>
     *     </p>
     *     <p>In this case, the content of the <a href="./blocks-tooltip.html">tooltip</a> must represent the meaning of both icons. For example, <code>&lt;div class="tooltip"&gt;Show user options&lt;/div&gt;</code.</p>
     *   </div>
     * </div>
     *
     *     @example
     *     include ./code-samples/button-with-left-margin.pug
     */
    @include modifier('offset') {
      margin-left: rem(3px);
    }
  }

  /**
   * Icon-only button:
   * `.button--icon`
   *
   * An icon-only button.
   *
   * <div class="card card--standout top-margin">
   *   <div class="card__body">
   *     <p>
   *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title1" focusable="false">
   *         <title id="title1">Information</title>
   *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *       </svg>
   *       <strong>Accessibility</strong>
   *     </p>
   *     <p>Make sure you add a descriptive <a href="./blocks-tooltip.html">tooltip</a> and link its content to the button with an <code>aria-labelledby</code> to the tooltip's <code>id</code>.</p>
   *     <p>The <code>svg</code> element has <code>aria-hidden="true"</code>.</p>
   *   </div>
   * </div>
   *
   *     @example
   *     include ./code-samples/icon-only-button.pug
   */
  @include modifier('icon') {
    @include seamless-button;
  }

  /* DEPRECATED CODE
    Provide support to the old "next" and "previous" button with `expand-more` icon:
  */

  @include modifier('previous') {
    @include element('icon') {
      @include rotate-clockwise;
    }
  }

  @include modifier('next') {
    @include element('icon') {
      @include rotate-counterclockwise;
    }
  }

  /* END DEPRECATED CODE */
}

/**
 * Button spacing helper:
 *
 * If you wish to add the vertical padding and line height of a button to
 * another element please see the [button spacing helper](../docs/helpers-typography.html#button-spacing).
 */

/**
 * Use case: align with other elements:
 *
 * Confirm buttons, inputs and selects align.
 *
 *     @example
 *     include ./code-samples/aligned-with-other-elements.pug
 */
