/*
 * The Drop.js plugin is used by the 'datepicker' and 'layout' components
 *
 * This file refers to the CSS counterpart of the Drop.js plugin.
 *
 * Because of the way Drop.js works (moving DOM elements and applying
 * a non-modifiable wrapper), the styling of this block can not follow
 * the BEM conventions in place usually and must comply with the
 * imposed structure.
 *
 * Nonetheless, BEM is still respected for the positioning of the
 * bubble, bcause a modifier is used to indicate Tether/Drop how
 * to position the bubble, as in the examples show.
 */

@mixin drop($prefix) {
  .#{$prefix} {
    display: none;

    &.#{$prefix}-open {
      display: block;
      z-index: index($z-index-scale, #{$prefix});
    }

    & > .#{$prefix}-content {
      position: relative;
      z-index: index($z-index-scale, #{$prefix});

      &::after,
      &::before {
        content: '';
        position: absolute;
      }

      &::before {
        z-index: index($z-index-scale, #{$prefix}-bg);
      }

      &::after {
        z-index: index($z-index-scale, #{$prefix});
      }
    }
  }
}
