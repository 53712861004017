@import 'form';

// CHECKABLE INPUTS

$checkable-input-label-padding:           6px;
$checkable-input-label-spacing:           8px;
$checkable-input-checkbox-size:           18px;
$checkable-input-checkbox-padding:        1px;
$checkable-input-checkbox-border-width:   2px;
$checkable-input-icon-size:               $checkable-input-checkbox-size;

@mixin checkable-input-color($color: inherit, $border-color: inherit, $focus-outline-color: color(primary, mid)) {
  @include element('input') {
    &:focus + {
      @include element('label') {
        @include element('control') {
          &::before {
            border-color: $focus-outline-color;
          }
        }
      }
    }
  }

  @include element('label') {
    color: $color;
  }
}

@mixin checkable-input($name, $control-border-radius, $focus-outline-border-radius: $control-border-radius) {
  @if $name != radio and $name != checkbox {
    @error 'Property #{$name} must be either radio or checkbox.';
  }

  @include block($name) {
    @include checkable-input-color($border-color: form-field-color(standard, text));
    position: relative;

    @include element('input') {
      @include rem(width, 20px);
      height: 100%;
      top: 0;
      left: rem($checkable-input-label-padding);
      opacity: 0;
      position: absolute;
      appearance: none;
      cursor: pointer;
      z-index: index($z-index-scale, hidden-input);

      &:focus + {
        @include element('label') {
          @include element('control') {
            @include focus-outline-pseudo-element($border-radius: $focus-outline-border-radius, $gap: $checkable-input-checkbox-border-width * 2);
          }
        }
      }

      &:checked + {
        @include element('label') {
          @if $name == 'checkbox' {
            @include element('control') {
              background-color: currentColor;
            }

            @include element('icon') {
              display: block;
            }
          }

          @if $name == 'radio' {
            @include element('control') {
              &::after {
                transform: scale(0.7);
                opacity: 1;
              }
            }
          }
        }
      }

      @if $name == 'checkbox' {
        &:indeterminate + {
          @include element('label') {
            @include element('control') {
              background-color: currentColor;

              &::after {
                content: '';
                @include rem(width, 10px);
                @include rem(height, 2px);
                @include rem(border-radius, 1px);
                display: block;
                background-color: $white;
              }
            }

            @include element('icon') {
              display: none;
            }
          }
        }
      }

      &:disabled + {
        @include element('label') {
          @include disabled;
        }
      }

      &:not(:disabled) {
        &:hover + {
          @include element('label') {
            background-color: hsla(var(--grey-level-1-h), var(--grey-level-1-s), var(--grey-level-1-l), 0.04); // Equivalent to color(grey, 6) with transparency, so that it works on colored backgrounds
          }
        }
      }
    }

    @include element('label') {
      @include rem(font-size, $base-font-size);
      @include rem(padding, $checkable-input-label-padding);
      @include rem(border-radius, $border-radius-small);
      display: inline-flex;
      cursor: pointer;
    }

    @include element('control') {
      @include rem(width, $checkable-input-checkbox-size);
      @include rem(height, $checkable-input-checkbox-size);
      @include rem(border, $checkable-input-checkbox-border-width solid currentColor);
      @include rem(margin-top, 1px); // Align with the text
      border-radius: $control-border-radius;
      flex-shrink: 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      @if $name == 'checkbox' {
        background-color: transparent;
      }

      @if $name == 'radio' {
        &::after {
          content: '';
          opacity: 0;
          transform: scale(0);
          display: block;
          width: 100%;
          height: 100%;
          background-color: currentColor;
          border-radius: 50%;
        }
      }
    }

    @include element('icon') {
      @include rem(width, $checkable-input-icon-size);
      @include rem(height, $checkable-input-icon-size);
      @include rem(top, -$checkable-input-checkbox-border-width);
      @include rem(left, -$checkable-input-checkbox-border-width);
      color: $white;
      position: absolute;
      display: none;
      cursor: inherit;
    }

    @include element('display') {
      position: relative;
      @include rem(padding-left, $checkable-input-label-spacing);
    }

    @include modifier('valid') {
      @include checkable-input-color(form-field-color(valid, text), form-field-color(valid, border), color(grey, 3));

      @include element('control') {
        color: form-field-color(valid, border);
      }
    }

    @include modifier('invalid') {
      @include checkable-input-color(form-field-color(invalid, text), form-field-color(invalid, border), color(grey, 3));

      @include element('control') {
        color: form-field-color(invalid, border);
      }
    }

    @include modifier('standalone') {
      display: inline-block;

      @include element('display') {
        padding-left: 0;
      }
    }
  }
}
