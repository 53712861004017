/***
 * <div class="alert alert--warning bottom-margin">
 *   <svg class="alert__icon icon" aria-hidden="true" focusable="false">
 *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning">
 *   </svg>
 *   <p class="h4">Deprecation warning</p>
 *   <p>This version of the vertical menu component has been deprecated and it will be deleted in a future version of PDUI.</p>
 *   <p>Please, migrate to <a href="../docs/blocks-vertical-menu-v2.html">vertical menu v2</a>.</p>
 * </div>
 *
 * # Vertical menus
 *
 * A menu where links are stacked on top of each other. Each link may optionally
 * include a corresponding icon or avatar.
 *
 * Vertical menus may also include sub menus, badges and dropdown icons.
 *
 * ## When to use vertical menus
 *
 * Vertical menu can be used directly in the user interface or inside dropdowns.
 *
 * ## When not to use vertical menus
 *
 * Long vertical menus can be problematic, particularly when combined with
 * dropdowns. In this case, try to break the list into several lists, or
 * consider a different UI altogether.
 *
 * ## Position and style
 *
 * Vertical menus can be positioned anywhere in the UI. Dark and large
 * modifiers can be used to control constrast and style. In general, standard
 * vertical menus should be used in a dropdown with a standard button, while
 * the large modifier should be applied if the dropdown button is also large.
 *
 * ## Deprecation warnings
 *
 * - The HTML markup of the element `.vertical-menu__marker` has been updated and it must be replaced. Support to the old markup will be removed in a future version of PDUI.
 *
 * **Old code:**
 *
 * ```html
 * <svg class="vertical-menu__marker icon icon--danger" role="img" aria-labelledby="uniqueID" focusable="false">
 *   <title id="uniqueID">Has notification</title>
 *   <use xlink:href="../icons.svg#dot" href="../icons.svg#dot"></use>
 * </svg>
 * ```
 *
 * **New code:**
 *
 * ```html
 * <span class="vertical-menu__marker">
 *   <span class="sr-only">Has notifications</span>
 * </span>
 * ```
 */

// General Settings
$menu-text-color:                         $base-font-color;
$menu-border-color:                       $block-border-color;
$menu-border-width:                       1px;
$menu-hover-color:                        $base-font-color;
$menu-hover-background-color:             $block-darker-background-color;
$menu-background-color:                   $block-background-color;

// Menu Items
$menu-item-padding:                       10px 16px 10px 13px;
$menu-item-gap:                           8px;
$menu-title-padding:                      10px;
$closed-item-horizontal-padding:          10px;

// Menu Title
$menu-title-color:                        $base-font-color;
$menu-title-border-color:                 color(grey, 5);

// Menu Icons
$menu-icon-size:                          20px;
$large-icon-size:                         24px;

// Large Menu
$large-menu-item-padding:                 18px 16px 18px 13px;
$large-menu-title-padding:                10px;
$large-menu-title-size:                   $base-font-size;

// Dark Menu
$dark-menu-background-color:              color(grey, 3);
$dark-menu-text-color:                    color(grey, 5);
$dark-menu-border-color:                  color(grey, 3);
$dark-menu-title-color:                   $white;
$dark-menu-title-border-color:            color(grey, 2);
$dark-menu-hover-background-color:        color(grey, 2);
$dark-menu-hover-color:                   $white;
$dark-menu-select-color:                  $white;

// Sub Menu
$sub-menu-size:                           13px;
$sub-menu-item-padding:                   15px 15px 15px 25px;
$sub-menu-item-horizontal-extra-padding:  30px;
$sub-menu-background-color:               color(grey, 6);
$sub-menu-hover-background-color:         color(grey, 5);
$sub-menu-hover-color:                    color(grey, 3);
$dark-sub-menu-hover-background-color:    color(grey, 1);

// Sub Menu triangle size and position
$triangle-size:                           12px;
$triangle-left-offset:                    20px;

@mixin menu-hover-selected {
  background-color: $menu-hover-background-color;
  color: $menu-hover-color;
}

@mixin dark-menu-hover {
  background-color: $dark-menu-hover-background-color;
  color: $dark-menu-hover-color;
}

@mixin dark-menu-selected {
  background-color: $dark-menu-hover-background-color;
  color: $dark-menu-select-color;
}

@mixin sub-menu-hover-selected {
  background-color: $sub-menu-hover-background-color;
  color: $sub-menu-hover-color;
}

@mixin dark-sub-menu-hover-selected {
  background-color: $dark-sub-menu-hover-background-color;
  color: $dark-menu-hover-color;
}

/**
 * Basic vertical menu:
 * `.vertical-menu`
 *
 * A standard vertical menu.
 *
 * Each menu item should be wrapped in a 'link-title'.
 *
 *     @example
 *     include ./code-samples/vertical-menu-basic.pug
 */
@include block('vertical-menu', 1) {
  font-size: rem($menu-font-size);
  color: $menu-text-color;
  background-color: $menu-background-color;
  white-space: normal;

  ul {
    margin: 0;
    list-style: none;
    font-size: inherit;
  }

  li {
    padding: 0;
    margin: 0;
  }

  @include element('link') {
    display: flex;
    align-items: center;
    padding: rem($menu-item-padding);
    line-height: 1.4;
    width: 100%;
    text-align: left;
    background-color: transparent;
    font-weight: $bold;
    cursor: pointer;
    color: currentColor;
    position: relative;
    text-decoration: none;
    border: 0;
    @include subtle-focus-indicator($indicator-color: transparent); // Prevents visual "jump" when the element receives focus
    transition: background-color 0.1s linear;

    &:hover {
      text-decoration: none;
      @include menu-hover-selected;
    }

    &:focus,
    &:active {
      @include subtle-focus-indicator($text-color: $font-color-primary-on-white);
    }

    /**
     * Avatar:
     * `.vertical-menu__link--with-avatar`
     *
     * A link with an avatar.
     *
     * Always list the avatar before the link title.
     *
     *     @example
     *     include ./code-samples/vertical-menu-avatar.pug
     */
    @include block('avatar') {
      max-width: rem($menu-icon-size);
      max-height: rem($menu-icon-size);
      margin-right: rem($menu-item-gap);
      flex: none;
      align-self: flex-start;
    }

    @include modifier('selected') {
      @include menu-hover-selected;

      &:focus,
      &:active {
        color: $font-color-primary-on-white;
      }
    }

    @include modifier('opened') {
      @include menu-hover-selected;
      position: relative;

      &:hover {
        @include sub-menu-hover-selected;
      }

      &:focus,
      &:active {
        color: $font-color-primary-on-white;
      }

      .vertical-menu__marker {
        display: none;
      }
    }
  }

  /**
   * Vertical menu with titles:
   * `.vertical-menu__title`
   *
   * A vertical menu that includes titles.
   *
   *     @example
   *     include ./code-samples/vertical-menu-titles.pug
   */
  @include element('title') {
    padding: rem($menu-title-padding);
    border-bottom: $menu-border-width solid $menu-title-border-color;
    display: block;
    font-weight: $bold;
    color: $menu-title-color;
  }

  /**
   * Vertical menu with icons:
   * `.vertical-menu__icon`
   *
   * A vertical menu containing icons.
   *
   * Always list the icons before the link title.
   *
   * <div class="card card--standout top-margin">
   *   <div class="card__body">
   *     <p>
   *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title1" focusable="false">
   *         <title id="title1">Information</title>
   *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *       </svg>
   *       <strong>Accessibility</strong>
   *     </p>
   *     <p>These icons are decorative as the text provides all the necessary information. Make sure you add <code>aria-hidden="true"</code> to the <code>svg</code> element.</p>
   *   </div>
   * </div>
   *
   *     @example
   *     include ./code-samples/vertical-menu-icons.pug
   */
  @include mixie('icon') {
    width: rem($menu-icon-size);
    height: rem($menu-icon-size);
    margin-right: rem($menu-item-gap);
    flex: none;
    align-self: flex-start;
  }

  /**
   * Vertical menu with dropdown icon:
   * `.vertical-menu__dropdown-icon`
   *
   * A vertical menu with dropdown arrow (at right).
   *
   * Always list the dropdown icon before the link title in the HTML (even
   * though it appears on the right on the design).
   *
   *     @example
   *     include ./code-samples/vertical-menu-dropdown-icon.pug
   */
  @include element('dropdown-icon') {
    width: rem(24px);
    height: rem(24px);
    flex: none;
    order: 2;
    align-self: flex-start;
    margin-left: auto; // Align to the right within parent flex container
  }

  /**
   * Vertical menu with badge:
   * `.badge.badge--danger`
   *
   * List the badge before the link title in the HTML (even though
   * it appears on the right on the design).
   *
   *     @example
   *     include ./code-samples/vertical-menu-badge.pug
   */
  @include block('badge') {
    flex: none;
    order: 1;
    margin-left: rem($menu-item-gap);
  }

  /**
   * Vertical menu with marker:
   * `.vertical-menu__marker`
   *
   * A marker can be used to highlight an item that contains
   * a submenu with badges. In that regard, it can be seen as
   * an abbreviated badge.
   *
   * When using with a dropdown-icon,
   * add after the icon.
   *
   * Always list the marker before the link title in the HTML (even though
   * it appears on the right on the design).
   *
   *     @example
   *     include ./code-samples/vertical-menu-marker.pug
   */

  @include element('marker') {
    width: rem(9px);
    height: rem(9px);
    margin-left: rem($menu-item-gap);
    background-color: color(danger, dark);
    border: rem(1px) solid $white;
    box-shadow: 0 0 0 rem(1px) $white;
    border-radius: 50%;
    flex: none;
    order: 1;
    // DEPRECATION:
    // Remove once `.svg.vertical-menu__marker` is permanentely deleted
    color: transparent;
    // END DEPRECATION.
  }

  /**
   * Vertical menu with sub menu:
   * `.vertical-menu__sub-menu`
   *
   * A vertical menu with a sub menu.
   *
   * <div class="card card--standout top-margin">
   *   <div class="card__body">
   *     <p>
   *       <svg class="icon icon--small right-margin right-margin--tiny" focusable="false">
   *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *       </svg>
   *       <strong>Accessibility</strong>
   *     </p>
   *     <p>Add <code>aria-expanded="false"</code> and <code>aria-controls="submenu_id"</code> to the toggle element.</p>
   *     <p>If the initial state of the sub menu is open, set <code>aria-expanded</code> to <code>"true"</code> instead.</p>
   *     <p>The sub menu must be properly labelled. To this end, use <code>aria-labelledby="toggle_id"</code>.</p>
   *   </div>
   * </div>
   *
   *     @example
   *     include ./code-samples/vertical-menu-sub-menu.pug
   */
  @include element('sub-menu') {
    @include rem(font-size, $sub-menu-size);
    background-color: $sub-menu-background-color;
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;

    @include modifier('opened') {
      visibility: visible;
      opacity: 1;
      height: auto;
    }
  }

  @include element('sub-link') {
    display: flex;
    align-items: center;
    cursor: pointer;
    @include rem(padding, $sub-menu-item-padding);
    color: $menu-text-color;
    text-decoration: none;
    @include subtle-focus-indicator($indicator-color: transparent); // Prevents visual "jump" when the element receives focus

    &:hover {
      text-decoration: none;
      @include sub-menu-hover-selected;
    }

    &:focus,
    &:active {
      @include subtle-focus-indicator($text-color: $font-color-primary-on-white);
      background-color: transparent; // Overwrite default link style
    }

    @include modifier('selected') {
      @include sub-menu-hover-selected;

      &:focus,
      &:active {
        color: $font-color-primary-on-white;
      }
    }
  }

  /**
   * Vertical menu disabled:
   * `[data-disabled="open"]`,<br>
   * `[data-disabled="close"]`,<br>
   * `[data-disabled="open close"]`
   *
   * The `[data-disabled]` attribute disables the open/close functionality of a submenu, according to what value is passed to it ("open", "close", "open close").
   *
   * You may attach the `[data-disabled]` attribute either to `.vertical-menu`, in order to disable all submenus,
   * or to specific `[data-trigger='submenu']` elements in order to disable only them.
   * <div class="card card--standout top-margin">
   *   <div class="card__body">
   *     <p>
   *       <svg class="icon icon--small right-margin right-margin--tiny" focusable="false">
   *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *       </svg>
   *       <strong>Accessibility</strong>
   *     </p>
   *     <p>If the initial state of the open/close functionality is disabled, add <code>aria-disabled="true"</code> to the toggle element.</p>
   *   </div>
   * </div>
   *
   *     @example
   *     include ./code-samples/vertical-menu-disabled.pug
   */

  /**
   * Vertical menu with dividing lines:
   * `.vertical-menu--lined`
   *
   * A vertical menu with lines dividing each item.
   *
   * This modifier does not work in combination with `.vertical-menu--dark`.
   *
   *     @example
   *     include ./code-samples/vertical-menu-lined.pug
   */
  @include modifier('lined') {
    @include element('link') {
      border-bottom: rem($menu-border-width) solid $menu-border-color;
    }

    @include element('sub-menu') {
      border-bottom: rem($menu-border-width) solid $menu-border-color;
    }
  }

  /**
   * Large vertical menu:
   * `.vertical-menu--large`
   *
   * A large vertical menu.
   *
   *     @example
   *     include ./code-samples/vertical-menu-large.pug
   */
  @include modifier('large') {

    @include element('link') {
      @include rem(padding, $large-menu-item-padding);
    }

    @include block('avatar') {
      @include rem(max-width, $large-icon-size);
      @include rem(max-height, $large-icon-size);
    }

    @include element('title') {
      @include rem(padding, $large-menu-title-padding);
      @include rem(font-size, $large-menu-title-size);
    }

    @include mixie('icon') {
      @include rem(width, $large-icon-size);
      @include rem(height, $large-icon-size);
    }
  }

  /**
   * Dark vertical menu:
   * `.vertical-menu--dark`
   *
   * A dark vertical menu.
   *
   *     @example
   *     include ./code-samples/vertical-menu-dark.pug
   */
  @include modifier('dark') {
    background-color: $dark-menu-background-color;
    color: $dark-menu-text-color;
    border-color: $dark-menu-border-color;

    li {
      color: $dark-menu-text-color;
    }

    @include element('link') {
      border-bottom-color: $dark-menu-border-color;

      &:hover {
        @include dark-menu-hover;
      }

      &:focus,
      &:active {
        color: $white;
      }

      @include modifier('selected') {
        @include dark-menu-selected;

        &:hover {
          @include dark-menu-selected;
        }

        &:focus,
        &:active {
          color: $white;
        }
      }

      @include modifier('opened') {
        @include dark-menu-selected;

        &:hover {
          @include dark-sub-menu-hover-selected;
        }

        &:focus,
        &:active {
          color: $white;
        }
      }
    }

    @include block('badge') {
      @extend .badge--on-dark;
    }

    @include element('title') {
      color: $dark-menu-title-color;
      border-color: $dark-menu-title-border-color;
    }

    @include element('sub-menu') {
      background-color: $dark-menu-hover-background-color;
      border-bottom: $menu-border-width solid $dark-menu-hover-background-color;
    }

    @include element('sub-link') {
      color: $dark-menu-text-color;
      position: relative;

      @include element('badge') {
        ~ .vertical-menu__link-title {
          display: block;
          padding-right: 35px;
        }
      }

      &:hover {
        @include dark-sub-menu-hover-selected;
      }

      &:focus,
      &:active {
        color: $white;
      }

      @include modifier('selected') {
        @include dark-sub-menu-hover-selected;

        &:focus,
        &:active {
          color: $white;
        }
      }
    }

    @include element('marker') {
      border: rem(2px) solid $white;
      box-shadow: 0 0 0 rem(1px) $dark-menu-background-color;
    }
  }

  /**
   * Closed vertical menu:
   * `.vertical-menu--closed`
   *
   * A vertical menu that appears 'closed' on tablet resolutions and below - with padding reduced and icons/avatars removed.
   *
   *     @example
   *     include ./code-samples/vertical-menu-closed.pug
   */
  @include modifier('closed') {
    @media screen and (max-width: $tablet) {
      .vertical-menu__link,
      .vertical-menu__link--with-avatar {
        padding-right: rem($closed-item-horizontal-padding);
        padding-left: rem($closed-item-horizontal-padding);
      }

      .avatar {
        display: none;
      }

      @include mixie('icon') {
        display: none;
      }

      @include element('sub-link') {
        padding-right: rem($closed-item-horizontal-padding);
        padding-left: rem($closed-item-horizontal-padding);
      }
    }
  }
}

/**
 * Use case: everything combined:
 * `.vertical-menu.vertical-menu--large.vertical-menu--dark`
 *
 * A large, dark, lined menu that contains sub menus.
 * Note the badge color matrix.
 *
 *     @example
 *     include ./code-samples/use-case-everything-combined.pug
 */
