/***
 * # Width
 *
 * A series of helpers that can be applied to set a fixed width on different elements.
 */

$widths: 10, 20, 25, 30, 40, 50, 60, 70, 75, 80, 90;

/**
 * Percentage width:
 * `.width-{number}-percent`
 *
 * A helper that sets an element to a percentage width.
 * Particularly useful when applied to table columns.
 *
 * Available sizes are `10`, `20`, `25`, `30`, `40`, `50`, `60`, `70`, `75`, `80`, `90`
 *
 *     @example
 *     each val, index in {10:90, 20:80, 25:75, 30:70, 40:60, 50:50}
 *       table.table
 *         tr.table__row
 *           td.table__column(class=`width-${index}-percent`) #{index}%
 *           td.table__column(class=`width-${val}-percent`) #{val}%
 *       br
 */
@each $width in $widths {
  .width-#{$width}-percent {
    width: $width * 1%;
  }
}

/**
 * Input width:
 * `.input-width` `.short-input-width` `.medium-input-width` `.long-input-width`
 *
 * A helper that the width of the item to the same width as an input.
 * This is useful for wrapping help text or error messages directly
 * underneith a form field
 *
 *     @example
 *     input.input
 *     span.form-error.input-width
 *       svg.form-error__icon.icon(role='img' aria-labelledby='icon-label' focusable='false')
 *         title#icon-label Warning
 *         use(xlink:href='../icons.svg#warning' href='../icons.svg#warning')
 *       | The error will wrap to be as long as this input
 *     br
 *     br
 *     input.input.input--short
 *     p.short-input-width The text will wrap too
 *     br
 */

.input-width {
  max-width: $form-field-width;
}

.short-input-width {
  max-width: $short-form-field-width;
}

.medium-input-width {
  max-width: $medium-form-field-width;
}

.long-input-width {
  max-width: $long-form-field-width;
}
