/***
 * # Floats
 *
 * A set of layout helpers that apply CSS floats to the element to which they
 * are applied.
 */

/**
 * Floats:
 * `.float-left, .float-right, .clear`
 *
 * To clear the floats, add .clear to their wrapper.
 *
 * **Important:** Adding a clearfix to a block will create an `:after` psuedo
 * element - this may create conflict with other blocks where the `:after`
 * element is already in use.
 *
 * To get around this, create a new div with the `clearfix` class *inside* the
 * conflicting div.
 *
 *     @example
 *     .card.bottom-margin
 *       .card__body.clear
 *         .button.float-left Left button
 *         .button.float-right Right button
 */
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear {
  @include clearfix;
}
