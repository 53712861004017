/***
 * # Visibility helpers
 *
 * A series of helpers that can be applied to blocks to control their visibility.
 *
 * <div class="alert top-margin">
 *   <svg role="img" aria-labelledby="visibility-helpers-alert" focusable="false" class="alert__icon icon">
 *     <title id="visibility-helpers-alert">Information</title>
 *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
 *   </svg>
 *   <p>We use CSS <code>!important</code> in visibility helper classes to make sure that they can always carry out
 *   their one and very specific purpose, despite having very low specificity.</p>
 * </div>
 */

/**
 * Screen reader only:
 * `.sr-only`
 *
 * Hides the block or element on everything *except* for screen readers.
 *
 *     @example
 *     .sr-only I am hidden, but people using screen readers will still hear about me :)
 */
.sr-only {
  @include sr-only;
}

/**
 * Hide:
 * `.hide, .hidden`
 *
 * Hides the block or element by applying `display: none`.
 *
 *     @example
 *     div.hide Hidden
 *     div.hidden Hidden
 */
.hide {
  display: none !important;
}

.hidden {
  display: none !important;
}

/**
 * Invisible:
 * `.invisible`
 *
 * Hides the block or element by applying `visibility: hidden`.
 *
 *     @example
 *     .invisible Invisible
 */
.invisible {
  visibility: hidden !important;
}

/**
 * Show:
 * `.show`
 *
 * Shows the block or element by applying `display: block`.
 *
 *     @example
 *     .show Show block
 */
.show {
  display: block !important;
}

/**
 * Show inline:
 * `.show-inline`
 *
 * Shows the block or element by applying `display: inline`.
 *
 *     @example
 *     .show-inline Show inline
 */
.show-inline {
  display: inline !important;
}

/**
 * Visible:
 * `.visible`
 *
 * Shows the block or element by applying `visibility: visible`.
 *
 *     @example
 *     .visible Visible
 */
.visible {
  visibility: visible !important;
}

/**
 * Hide above tablet:
 * `.hide-above-tablet`
 *
 * Hides element
 * <br>
 * on screens > 1024px.
 *
 *     @example
 *     span.hide-above-tablet Your screen is less than 1025px
 */
@media all and (min-width: $tablet + 1px) {
  .hide-above-tablet {
    display: none !important;
  }
}

/**
 * Hide below tablet:
 * `.hide-below-tablet`
 *
 * Hides element
 * <br>
 * on screens <= 1024px.
 *
 *     @example
 *     span.hide-below-tablet Your screen is greater than 1024px
 */
@media all and (max-width: $tablet) {
  .hide-below-tablet {
    display: none !important;
  }
}

/**
 * Hide above mobile:
 * `.hide-above-mobile`
 *
 * Hides element
 * <br>
 * on screens > 600px.
 *
 *     @example
 *     span.hide-above-mobile Your screen is less than 601px
 */
@media all and (min-width: $mobile + 1px) {
  .hide-above-mobile {
    display: none !important;
  }
}

/**
 * Hide below mobile:
 * `.hide-below-mobile`
 *
 * Hides element
 * <br>
 * on screens <= 600px.
 *
 *     @example
 *     span.hide-below-mobile Your screen is greater than 600px
 */
@media all and (max-width: $mobile) {
  .hide-below-mobile {
    display: none !important;
  }
}
