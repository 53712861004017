/*
  Maintain the aspect ratio of an individual div
  https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/

  Assumes we will nest a BEM div called 'content' as a child…

  <div class="my-block">
    <div class="my-block__content">
      I will keep my aspect ratio
    </div>
  </div>
*/

@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  @include element('content') {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
}
