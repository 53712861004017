@include drop('datepicker-drop');

@include block('datepicker') {
  position: relative;
  display: inline-block; // so that its width is equal to the width of the contained input
  vertical-align: middle;

  @include element('clear-button') {
    @include seamless-button($icon-size: 16px, $position: absolute);
    display: none;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  @include modifier('populated') {
    @include element('input') {
      @include rem(padding-right, 33px);

      &:disabled + .datepicker__clear-button {
        display: none;
      }
    }

    @include element('clear-button') {
      display: flex;
    }
  }
}
