/***
 * # Ignite's components fix
 *
 * The overwriting styles below are meant to specifically
 * target and fix conflicts of styles on Ignite's components
 * when used with PDUI.
 *
 */

// PDUI's reset from Inuit CSS applies the following style
// `*, *::before, *::after { box-sizing: inherit;}`
// which interferes with the behavior of the calendar
// used in Ignite's date-picker component.
.sc-usg-calendar.usg-day, // ignite@<2
.sc-ukg-calendar.ukg-day {
  box-sizing: content-box;
}
