/***
 * # Labels
 *
 * A label for a form field. All fields should have labels associated with them,
 * e.g. `<label for="my-input"><input id="my-input">`
 *
 * ## When not to use labels
 *
 * Labels should only be used when associated with form fields. Do not use
 * labels as generic headings
 *
 * ## Position and style
 *
 * Labels should be positioned either directly above each field, or, in the
 * case of horizontal forms, to the left.
 *
 * The `valid` and `invalid` modifiers should be applied to labels at the same
 * time as they are applied to fields.
 */

/**
 * Basic label:
 *
 * `label.label`
 *
 * An input label.
 *
 *   @example
 *   label.label(for='my-input') Label
 *   input.input(id='my-input')
 */
@include block('label') {
  @include hyphens;
  display: block;
  margin-top: $ukg-spacing-xs;
  margin-bottom: $ukg-spacing-xxs;
  vertical-align: middle;
  color: color(grey, 3);
  font-size: rem($base-font-size);

  /**
   * Stacked label:
   *
   * `label.label--stacked`
   *
   * A label that has a margin at the top. Use for vertically stacked forms.
   *
   *   @example
   *   input.input
   *   label.label.label--stacked(for='my-input4') Stacked label
   *   input.input(id='my-input4')
   */
  @include modifier('stacked') {
    margin-top: $ukg-spacing-s;
  }

  /**
   * Invalid label:
   *
   * `.label--invalid`
   *
   * A label styled to match invalid inputs.
   *
   *   @example
   *   label.label.label--invalid(for='my-input5') Label
   *   input.input.input--invalid(id='my-input5')
   */
  @include modifier('invalid') {
    color: form-field-color(invalid, text);
  }

  /**
   * Valid label:
   *
   * `.label--valid`
   *
   * A label styled to match valid inputs.
   *
   *   @example
   *   label.label.label--valid(for='my-input6') Label
   *   input.input.input--valid(id='my-input6')
   */
  @include modifier('valid') {
    color: form-field-color(valid, text);
  }

  /**
   * Bold label:
   *
   * `.label--bold`
   *
   * A label styled to be bold.
   *
   *   @example
   *   label.label.label--bold(for='my-input7') Label
   *   input.input(id='my-input7')
   */
  @include modifier('bold') {
    font-weight: $bold;
  }

  /**
   * Required label:
   *
   * `.label--required`
   *
   * A label styled to be required.
   *
   * <div class="card card--standout top-margin">
   *   <div class="card__body">
   *     <p>
   *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title1" focusable="false">
   *         <title id="title1">Information</title>
   *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *       </svg>
   *       <strong>Accessibility</strong>
   *     </p>
   *     <p>In addition to the visual hint in the label, make sure to mark the mandatory `input` element with the attribute `required`.</p>
   *   </div>
   * </div>
   *
   *   @example
   *   label.label.label--required(for='my-input8') Required label
   *   input.input(id='my-input8' required)
   */
  @include modifier('required') {
    &::after {
      @include required-field-marker;
    }

    &.label--valid::after {
      color: form-field-color(valid, text);
    }

    &.label--invalid::after {
      color: form-field-color(invalid, text);
    }
  }
}
