/**
 * # Horizontal menu
 *
 * <div class="alert top-margin">
 *   <svg class="alert__icon icon" role="img" aria-labelledby="horizontal-menu-alert-icon" focusable="false">
 *     <title id="horizontal-menu-alert-icon">Information</title>
 *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
 *   </svg>
 *   <p>See [horizontal menu](../templates/horizontal-menu.html) for an example of this component in action (using the [data-autoclose](#autoclose) attribute).</p>
 * </div>
 *
 * This is an alternative to the [sidebar](../templates/responsive-with-sidebar.html) menu and it replaces the [standard header](../docs/blocks-header.html) HTML structure with the following:
 *
 * ```html
 * <header role="banner" class="horizontal-menu">
 *   <div class="horizontal-menu__top-wrapper container container--unpadded container--centered container--extra-large">
 *     <!-- Logo -->
 *     <div class="horizontal-menu__logo-wrapper container container--centered container--extra-large">
 *       <a href='#' class="horizontal-menu__logo-container">
 *         <img class="horizontal-menu__logo" src="some-logo.png" alt="logo"/>
 *       </a>
 *     </div>
 *     <!-- Navigation links -->
 *     <nav role="navigation" aria-label="Main menu" class="horizontal-menu__nav-wrapper container container--extra-large container--centered">
 *       <ul class="horizontal-menu__nav">
 *         <!-- A standard link -->
 *         <li class="horizontal-menu__nav-item">
 *           <a href="some-url.com" class="horizontal-menu__nav-link">
 *             <svg class="horizontal-menu__nav-icon icon" aria-hidden="true" focusable="false">
 *               <use xlink:href="../icons.svg#xxx" href="../icons.svg#xxx"></use>
 *             </svg>
 *             <span class="horizontal-menu__nav-text">Some Link</span>
 *             <span class="sr-only">New items:</span>
 *             <!-- Numeric badge (optional and may only contain up to 3 digits) -->
 *             <span class="horizontal-menu__badge badge badge--danger">1</span>
 *           </a>
 *         </li>
 *         <!-- More standard links -->
 *         <!-- A button that toggles a submenu -->
 *         <li data-submenu="submenu-id" class="horizontal-menu__nav-item">
 *           <button class="horizontal-menu__nav-link">
 *             <svg class="horizontal-menu__nav-icon icon" aria-hidden="true" focusable="false">
 *               <use xlink:href="../icons.svg#xxx" href="../icons.svg#xxx"></use>
 *             </svg>
 *             <span class="horizontal-menu__nav-text">Some Label</span>
 *             <svg class="horizontal-menu__toggle-icon icon" role="img" aria-labelledby="horizontal-menu-dropdown-icon" focusable="false">
 *               <title id="horizontal-menu-dropdown-icon">Has submenu</title>
 *               <use xlink:href="../icons.svg#expand-more" href="../icons.svg#expand-more"></use>
 *             </svg>
 *           </button>
 *         </li>
 *         <!-- A button that toggles the search bar-->
 *         <li data-toggle="search" class="horizontal-menu__nav-item">
 *           <button type="button" class="horizontal-menu__nav-link" aria-expanded="false" aria-controls="uniqueID-search">
 *             <svg class="horizontal-menu__nav-icon icon" aria-hidden="true" focusable="false">
 *               <use xlink:href="../icons.svg#search" href="../icons.svg#search"></use>
 *             </svg>
 *             <span class="horizontal-menu__nav-text">Search</span>
 *           </button>
 *         </li>
 *       </ul>
 *     </nav>
 *   </div>
 *   <!-- A submenu -->
 *   <div class="horizontal-menu__submenu-wrapper hidden" id="submenu-id" tabindex="-1">
 *     <div class="horizontal-menu__submenu-container container container--extra-large container--centered">
 *       <!-- A sublink with an image -->
 *       <a href="some-url.html" class="horizontal-menu__sublink">
 *         <img src="some-sublink-image.png" class="horizontal-menu__sublink-image"/>
 *         <span>Some Label</span>
 *       </a>
 *       <!-- A sublink button with an icon -->
 *       <button class="horizontal-menu__sublink">
 *         <svg class="horizontal-menu__sublink-icon icon" focusable="false">
 *           <use xlink:href="../icons.svg#xxx" href="../icons.svg#xxx"></use>
 *         </svg>
 *         <span>Some Label</span>
 *       </button>
 *       <!-- More sublinks -->
 *     </div>
 *   </div>
 *   <!-- The search bar -->
 *   <div class="horizontal-menu__search-wrapper hidden">
 *     <div class="horizontal-menu__search-container container container--extra-large container--centered">
 *       <form class="horizontal-menu__search search search--full-width" role="search" id="uniqueID-search">
 *         <input class="search__input input" aria-label="Search"/>
 *         <button type="submit" class="search__submit" aria-label="Search">
 *           <svg class="search__icon icon" aria-hidden="true" focusable="false">
 *             <use xlink:href="../icons.svg#search" href="../icons.svg#search"></use>
 *           </svg>
 *         </button>
 *       </form>
 *     </div>
 *   </div>
 *   <!-- Bottom row containing a "back to previous page" button and an optional CTA -->
 *   <div class="horizontal-menu__bottom-wrapper">
 *     <div class="horizontal-menu__bottom-container container container--unpadded container--centered container--extra-large">
 *     <div class="cells cells--half-spaced">
 *       <!-- "Back to previous page" button -->
 *       <div class="cells__cell">
 *         <a href="some-previous-page.html" class="button button--text button--on-color">
 *           <svg class="button__icon icon icon--flipped-horizontal icon--small" aria-hidden="true" focusable="false">
 *             <use xlink:href="../icons.svg#go-forward" href="../icons.svg#go-forward"></use>
 *           </svg>
 *           <span class="horizontal-menu__back-text">Some Previous Page</span>
 *         </a>
 *       </div>
 *       <!-- Call to action -->
 *       <div class="cells__cell cells__cell--fit-content">
 *         <div class="button-group">
 *           <a href="#" class="button-group__button button button--on-primary">Call to Action</a>
 *           <a href="#" id="some-horizontal-menu-cta-dropdown-id" class="button-group__button button button--thin button--on-primary dropdown-target" aria-label="Show options">
 *             <svg class="button__icon icon" aria-hidden="true" focusable="false">
 *               <use link:href="../icons.svg#menu-caret-down" href="../icons.svg#menu-caret-down"></use>
 *             </svg>
 *           </a>
 *           <div class="button-group__dropdown dropdown dropdown--align-right">
 *             <div class="dropdown__body">
 *               <nav role="navigation" aria-label="Main menu" class="vertical-menu vertical-menu--lined" data-version="2">
 *                 <ul>
 *                   <li>
 *                     <a href="some-link-1.html" class="vertical-menu__link">Link 1</a>
 *                   </li>
 *                   <li>
 *                     <a href="some-link-2.html" class="vertical-menu__link">Link 2</a>
 *                   </li>
 *                   <li>
 *                     <a href="some-link-3.html" class="vertical-menu__link">Link 3</a>
 *                   </li>
 *                 <ul>
 *               </nav>
 *             </div>
 *           </div>
 *         </div>
 *       </div>
 *     </div>
 *   </div>
 * </header>
 * <main role="main" id="content" class="main"> … </main>
 * ```
 *
 * ## Accessibility
 *
 * Use the appropriate HTML element for `.horizontal-menu__nav-link` and `.horizontal-menu__sublink`:
 *
 * - **A HTML `a` element** if the item navigates to other resources (web pages, files, locations in the same page, etc)
 * - **A HTML `button` element** if the item triggers an action within the application
 *
 * <hr>
 * <div class="alert alert--warning" id="autoclose">
 *   If you add the `data-autoclose` attribute to the parent element, a horizontal-menu <strong>submenu</strong> will <strong>close</strong> whenever the user clicks on one of the submenu <strong>links</strong>.
 * </div>
 * <div class="alert alert--danger">
 *   The sidebar component and the standard header component cannot be used alongside this component.
 * </div>
 *
 * ## Keyboard interaction
 *
 * With focus on a menu item with submenu:
 * - <kbd>Enter</kbd> or <kbd>Space</kbd>: opens the submenu, and moves focus to the submenu.
 *
 * With focus on the search button:
 * - <kbd>Enter</kbd> or <kbd>Space</kbd>: opens the search form panel, and moves focus to the search input element.
 *
 * ## Deprecation warnings
 *
 * The HTML code for the back button has changed to use [text button](./blocks-button.html#text-button).
 *
 * `.horizontal-menu__back` and `.horizontal-menu__back__icon` are replaced by `.button.button--text` and `.button__icon`.
 */

$logo-height:                         $ukg-spacing-xxl;
$logo-wrapper-width:                  185px;
$nav-desktop-height:                  rem($logo-height) + $ukg-spacing-m;
$nav-icon-desktop-size:               18px;
$nav-icon-mobile-size:                20px;
$search-small:                        230px;
$search-medium:                       570px;
$badge-size:                          20px;

$horizontal-menu-search-input-border-color:       var(--horizontal-menu-search-input-border-color);
$horizontal-menu-search-input-border-radius:      30px;

$horizontal-menu-background-color:                $topbar-background;
$on-horizontal-menu-background:                   $on-topbar-background;
$horizontal-menu-item-color:                      var(--horizontal-menu-item-color);
$horizontal-menu-dropdown-background-color:       var(--horizontal-menu-background-color);
$horizontal-menu-dropdown-background-color-hover: var(--horizontal-menu-background-color-hover);

$horizontal-menu-bottom-wrapper-color: var(--horizontal-menu-bottom-wrapper-color);

@include block('horizontal-menu') {
  position: relative;
  flex-shrink: 1; // Works in combination with .layout__content-container {display: flex; flex-direction: column;}.
  background-color: $horizontal-menu-background-color;

  @media all and (max-width: $tablet) {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
  }

  @include element('top-wrapper') {
    display: flex;
    padding: 0 $ukg-spacing-m;

    @media all and (max-width: $tablet) {
      flex: 0 0 auto;
    }
  }

  @include element('logo-wrapper') {
    padding: 0;
    width: rem($logo-wrapper-width);
    flex-basis: auto;
  }

  @include element('logo-container') {
    display: inline-block;
    height: rem($logo-height);
    width: 100%;
    position: absolute;
    top: 50%;
    margin-top: rem(-$logo-height / 2);
    background-color: transparent;

    &:focus,
    &:active {
      @include focus-outline-pseudo-element($position: absolute, $gap: 4px);
    }
  }

  @include element('logo') {
    width: auto;
    height: 100%;
  }

  @include element('nav-wrapper') {
    padding: 0 0 0 $ukg-spacing-s;
    flex-grow: 1;
    width: 100%; // Fix issue in IE11, whereby flex children don't wrap if the width of the parent element is unknown
  }

  @include element('nav') {
    margin: 0;
    position: relative;
    color: $white;
    list-style-type: none;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    min-height: $nav-desktop-height;
    height: 100%; // Fix issue in IE11, whereby flex children don't stretch if the parent has no explicit height
  }

  @include element('nav-item') {
    margin: 0;
    padding: 0;
    color: $horizontal-menu-item-color;
    font-weight: $bold;
    border-radius: rem($border-radius-medium $border-radius-medium 0 0);

    &:hover,
    &.horizontal-menu__nav-item--selected {
      background-color: $horizontal-menu-dropdown-background-color;
    }

    &.horizontal-menu__nav-item--selected,
    &.horizontal-menu__nav-item--open {
      .horizontal-menu__toggle-icon {
        @include rotate-half;
      }
    }
  }

  @include element('nav-link') {
    text-decoration: none;
    color: inherit;
    padding: $ukg-spacing-s $ukg-spacing-s;
    border-radius: rem($border-radius-medium $border-radius-medium 0 0);
    display: flex;
    height: 100%;
    align-items: center;
    background: transparent;
    @include inset-focus-indicator(transparent);
    cursor: pointer;

    &:focus,
    &:active {
      @include inset-focus-indicator;
    }

    > * + * {
      margin-left: rem(4px);
    }
  }

  @include element('toggle-icon') {
    position: relative;
    width: rem(16px);
    height: rem(16px);
  }

  @include element('nav-icon') {
    position: relative;
    width: rem($nav-icon-desktop-size);
    height: rem($nav-icon-desktop-size);
    top: rem(-2px);
  }

  @include mixie('badge') {
    color: var(--horizontal-menu-badge-color);
    background-color: var(--horizontal-menu-badge-background-color);

    @each $color in $color-badges {
      &.badge--#{$color} {
        color: var(--horizontal-menu-badge-#{$color}-color);
        background-color: var(--horizontal-menu-badge-background-#{$color}-color);
      }
    }
  }

  @include element('submenu-wrapper') {
    background-color: $horizontal-menu-dropdown-background-color;
    outline: none;

    @media all and (max-width: $tablet) {
      flex: 1 1 auto;
      overflow: auto;
    }
  }

  @include element('submenu-container') {
    padding: ($ukg-spacing-s - $ukg-spacing-xxxs) $ukg-spacing-m; // Substract sublinks horizontal spacing
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  @include element('sublink') {
    color: $horizontal-menu-item-color;
    text-decoration: none;
    background: transparent;
    line-height: $ukg-spacing-m;
    font-size: rem(13px);
    padding: ($ukg-spacing-xs - rem(1px)) $ukg-spacing-s; // Substract 1px border width, total height is 40px
    @include inset-focus-indicator(transparent);
    cursor: pointer;
    border-radius: $ukg-spacing-l;
    margin: $ukg-spacing-xxxs $ukg-spacing-xxs;

    &:hover,
    &.horizontal-menu__sublink--selected {
      background-color: $horizontal-menu-dropdown-background-color-hover;
    }

    &:focus,
    &:active {
      @include inset-focus-indicator;
    }
  }

  @include element('sublink-image') {
    position: relative;
    width: rem(18px);
    top: rem(2px);
    margin-right: rem(1px);
  }

  @include element('sublink-icon') {
    position: relative;
    width: rem(16px);
    height: rem(16px);
    top: rem(-1px);
    margin-right: rem(1px);
  }

  @include element('search-wrapper') {
    background-color: $horizontal-menu-dropdown-background-color;
  }

  @include element('search-container') {
    padding: $ukg-spacing-s;
    height: rem($logo-height) + $ukg-spacing-m;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include mixie('search') {
    max-width: $search-medium;

    .search__input {
      border-radius: rem($horizontal-menu-search-input-border-radius);
      padding-left: rem($horizontal-menu-search-input-border-radius / 2);
      border-color: $horizontal-menu-search-input-border-color;
    }

    .search__submit {
      top: 50%;
      transform: translateY(-50%);
      right: rem(2px);
    }
  }

  @include element('bottom-wrapper') {
    background-color: var(--theme-background-color);
    color: $horizontal-menu-bottom-wrapper-color;

    @media all and (max-width: $tablet) {
      flex: 0 0 auto;
    }
  }

  @include element('bottom-container') {
    padding: $ukg-spacing-s $ukg-spacing-m;

    .cells__cell > * {
      margin-right: rem(16px);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  /* DEPRECATED CODE
    Provide support to the old back link replaced by a .button.button--text
  */
  @include element('back') {
    @include on-color-link('primary');
    position: relative;
    display: inline-block;
    margin-top: rem(6px);
    margin-bottom: rem(6px);
  }

  @include element('back-icon') {
    fill: currentColor;
    margin-right: $ukg-spacing-s;
    display: inline-block;
  }

  /* END OF DEPRECATED CODE */

  @include modifier('sticky') {
    position: fixed;
    z-index: index($z-index-scale, 'sidebar');
    height: auto;
    width: 100%;
    box-shadow: rem(1px -2px 10px 1px) $darkest-grey;

    @include element('logo-wrapper') {
      display: none;
    }
  }
}

@include block('layout') {
  @media all and (min-width: $tablet) {
    @include block('horizontal-menu') {
      @include element('logo-wrapper') {
        padding-right: 0;
      }
    }
  }

  &:not(.layout--semi-responsive) {
    @media all and (max-width: $tablet) {
      @include block('horizontal-menu') {
        @include element('top-wrapper') {
          &.container {
            padding: 0;
            flex-wrap: wrap;
          }
        }

        @include element('logo-wrapper') {
          padding: $ukg-spacing-s $ukg-spacing-m;
        }

        @include element('logo-container') {
          top: 0;
          margin-top: 0;
          text-align: center;
          position: relative;
        }

        @include element('logo') {
          top: 0;
          margin-top: 0;
        }

        @include element('nav-wrapper') {
          &.container {
            padding: 0;
          }
        }

        @include element('nav') {
          justify-content: center;
        }

        @include element('submenu-container') {
          justify-content: center;
        }

        @include element('bottom-container') {
          padding: $ukg-spacing-s;
        }
      }
    }

    @media all and (max-width: $mobile) {
      @include block('horizontal-menu') {
        @include element('nav-link') {
          position: relative;
        }

        @include element('nav-icon') {
          width: rem($nav-icon-mobile-size);
          height: rem($nav-icon-mobile-size);
        }

        @include element('nav-text') {
          @include sr-only;
        }

        @include mixie('badge') {
          position: absolute;
          top: calc(50% - #{rem($nav-icon-mobile-size / 2)});
          right: 0;
          transform: translate3d(-15%, -50%, 0);
        }

        @include element('submenu-container') {
          display: block;
          padding: $ukg-spacing-xs 0;
        }

        @include element('sublink') {
          display: block;
          width: 100%;
          margin: 0;
          text-align: left;
          border-radius: 0;
        }
      }
    }
  }
}

/**
 * ## Themes
 *
 * For applications using [PDUI themes](base-colors.html#themes), we have created the following helpers that apply
 * the horizontal menu background color, and on horizontal menu background color to other elements in the interface.
 *
 * <div class="alert alert--warning top-margin">
 *   <svg class="alert__icon icon" role="img" aria-labelledby="horizontal-menu-color-alert-icon" focusable="false">
 *     <title id="horizontal-menu-color-alert-icon">Warning</title>
 *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
 *   </svg>
 *   <p>Horizontal menu color helpers are purposeful only when used in custom theme interfaces.</p>
 * </div>
 */

/**
 * Horizontal menu background:
 * `.horizontal-menu-background`
 *
 * Apply horizontal menu color to other elements in the interface.
 *
 *     @example
 *     .horizontal-menu-background …
 */

.horizontal-menu-background {
  background: $horizontal-menu-background-color;
}

/**
 * On horizontal menu background:
 * `.on-horizontal-menu-background`
 *
 * Apply on horizontal menu text color to other elements in the interface.
 *
 *     @example
 *     .horizontal-menu-background
 *       p.on-horizontal-menu-background Text content.
 */

.on-horizontal-menu-background {
  color: $on-horizontal-menu-background;
}
