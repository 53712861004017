/***
 * # Calendar
 *
 * A calendar is a complete container with informations about events.
 * It displays a month at a time, with the user able to change the month and
 * year via dropdowns.
 *
 * ## When to use a calendar
 *
 * Calendars should be used to display events.
 *
 * ## When not to use a calendar
 *
 * Calendars should not be confused with date pickers.
 * They should not be used to select a date in a form.
 *
 * ## Position and style
 *
 * Calendars can be positioned anywhere in the interface, although generally,
 * it is best not to add them inside cards or other containers.
 *
 * ## Initialization
 *
 * <div class="alert alert--danger top-margin top-margin--small">
 *   <p>This component will auto-instantiate unless it has the <code>[data-no-auto]</code> attribute at page load (DOMContentLoaded) or on <code>init.pdui</code> event recept on an ancestor.<p>
 *   <p>If the component is added to the page dynamically, trigger the <code>init.pdui</code> event on a parent node to instantiate it.</p>
 *   <p>If the component is not instantiated, it will auto-instantiate as soon as a method of that component is called programmatically, regardless of the presence of <code>[data-no-auto]</code>.</p>
 *   <p>If you don't set the component to auto-instantiate and you wish to instantiate it manually (pass options to it etc.) do: <br><code>$(el).calendar();</code></p>
 * </div>
 *
 * ## Options
 *
 * The calendar component needs to know the path to the SVG icons. You have two ways to declare the icons path on the component level:
 * - Using the `iconsPath` option at initialization
 * - Setting the `data-icons-path` attribute on the element `.calendar`
 * If none of the above is found, the calendar component will use the path from the `data-icons-path` attribute on the `<body>` element.
 *
 */

// General Settings
$calendar-border-color:           color(grey, 5);
$calendar-border-width:           1px;
$calendar-column-width:           14.285714286%; // === 1/7

// Header
$calendar-title-height:           35px;
$calendar-header-padding:         10px;
$calendar-select-offset:          3px; // move select down to align it properly
$calendar-nav-size:               35px; // round navigation buttons
$calendar-nav-icon-size:          16px; // icons inside round navigation buttons
$calendar-nav-margin:             10px;  // nav button distance from the header border

$calendar-header-bg-color:        color(grey, 6);
$calendar-nav-bg-color:           color(grey, 5);
$calendar-nav-color:              color(grey, 3); // i.e. icon color

// Sub Header
$calendar-sub-header-padding:     10px 0 8px;
$calendar-sub-header-bg-color:    $white;

// Cells
$calendar-bg-color:               $white;
$calendar-hover-color:            $highlight-range;
$calendar-focus-color:            color(primary, dark);
$calendar-focus-text-color:       $font-color-on-primary;
$calendar-selected-color:         $calendar-focus-color;
$calendar-selected-text-color:    $calendar-focus-text-color;
$calendar-today-text-color:       $font-color-primary-on-white;

$calendar-today-indicator-size:   5px;
$calendar-adjacent-month-color:   color(grey, 6);

$calendar-date-label-padding:     7px 7px 5px;

$calendar-event-padding:          2px 4px;
$calendar-event-margin:           2px;
$calendar-event-color:            color(info, dark);

// Datepicker
$datepicker-width:                $form-field-width;
$datepicker-margin-top:           4px;
$datepicker-header-padding:       5px 0;
$datepicker-font-size:            85%;

$datepicker-nav-size:             25px;
$datepicker-center-border:        34px;

$datepicker-sub-header-padding:   6px 0 4px 0;
$datepicker-date-label-padding:   4px;

/*
 List the color events you want to generate
 If you change this list, be sure to update the example docs!
*/
$event-colors: 'primary', 'warning', 'danger', 'success', 'info';

/**
 * Basic calendar:
 *
 * `.calendar`
 *
 * A standard calendar.
 *
 *   @example
 *   .calendar(id='demo-calendar')
 */

/**
 * Calendar with shorter inputs:
 *
 * <br>
 * ```
 *   $(container).calendar({
 *     inputSize: 'short'
 *   });
 * ```
 *
 * A calendar with shorter inputs and shortened month names.
 *
 *   @example
 *   include ./code-samples/calendar-short.pug
 */

/**
 * Calendar with no "today" button:
 *
 * <br>
 * ```
 *   $(container).calendar({
 *     todayButton: false
 *   });
 * ```
 *
 *   @example
 *   include ./code-samples/calendar-no-today.pug
 */
@include block('calendar') {
  @include rem(font-size, $base-font-size);

  @include element('header') {
    position: relative;
    @include rem(border, $calendar-border-width solid $calendar-border-color);
    border-bottom: 0;
    background-color: $calendar-header-bg-color;
    float: left;
    width: 100%;
    @include rem(min-height, $calendar-nav-size);
    height: auto;
    border-radius: rem($border-radius-large $border-radius-large 0 0);
  }

  @include element('header-center') {
    float: left;
    height: auto;
    border-style: solid;
    border-color: transparent;
    width: 100%;
    @include rem(border-left-width, $calendar-nav-size + ($calendar-header-padding * 2));
    @include rem(border-right-width, $calendar-nav-size + ($calendar-header-padding * 2));
    text-align: center;
    @include rem(margin-top, $calendar-header-padding);

    > * {
      position: relative;
      display: inline-block;
      vertical-align: top;
      @include rem(margin-bottom, $calendar-header-padding);
    }
  }

  @include element('title') {
    height: auto;
    text-align: center;
    margin-bottom: 0;
    width: auto;

    h1 h2,
    h3,
    h4,
    h5,
    h6 {
      @include h2;
      display: inline-block;
      vertical-align: middle;
      text-transform: uppercase;
      margin-top: 0;
      @include rem(line-height, $calendar-title-height);
    }

    > * {
      @include rem(margin-bottom, $calendar-header-padding);
    }

    [data-selector='month'] {
      .select__title {
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        width: 100%;
        word-break: normal;
        white-space: nowrap;
        @include rem(border-right, 3px solid transparent);
      }

      .select__display.radio__display {
        word-break: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }
    }
  }

  @include mixie('select') {
    text-align: left;
    display: inline-block;
  }

  @include mixie('input') {
    text-align: left;
    display: inline-block;
  }

  @include element('nav') {
    @include std-button;
    @include rem(width, $calendar-nav-size);
    @include rem(height, $calendar-nav-size);
    position: absolute;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    top: 50%;
    @include rem(margin-top, -($calendar-nav-size / 2));

    &:hover,
    &:focus {
      @include std-hover;
    }

    &:focus,
    &:active {
      @include focus-outline-pseudo-element($position: absolute, $border-radius: 50%);
    }

    &:active {
      @include std-active;
    }

    @include and-modifier('disabled') {
      &,
      &:hover {
        @include std-disabled;
      }

      &:active {
        @include hide-focus-indicator;
      }
    }

    @include modifier('previous') {
      @include rem(left, $calendar-header-padding);
      @include rotate-clockwise;
    }

    @include modifier('next') {
      @include rem(right, $calendar-header-padding);
      @include rotate-counterclockwise;
    }

    @include element('nav-icon') {
      @include rem(width, $calendar-nav-icon-size);
      @include rem(height, $calendar-nav-icon-size);
    }
  }

  // Sub Header
  @include element('sub-header') {
    @include clearfix;
    @include h6;
    @include rem(padding, $calendar-sub-header-padding);
    @include rem(border, $calendar-border-width solid $calendar-border-color);
    background-color: $calendar-sub-header-bg-color;
    float: left;
    width: 100%;
    height: auto;
  }

  @include element('day-label') {
    float: left;
    @include rem(width, $calendar-column-width);
    text-align: center;
  }

  @include element('month') {
    @include clearfix;
    @include rem(border-left, $calendar-border-width solid $calendar-border-color);
    background-color: $calendar-bg-color;
    border-radius: rem($border-radius-large);
  }

  // Day Cells
  @include element('day') {
    @include rem(width, $calendar-column-width);
    outline: none;
    display: block;
    float: left;
    appearance: none;
    padding: 0;
    text-align: left;
    border-width: 0;
    @include rem(border-right, $calendar-border-width solid $calendar-border-color);
    @include rem(border-bottom, $calendar-border-width solid $calendar-border-color);
    background-color: $calendar-bg-color;
    overflow: auto;
    @include aspect-ratio(1, 1);
    cursor: pointer;
    transition: background-color 0.1s linear;

    &:nth-last-child(7) {
      border-radius: rem(0 0 0 $border-radius-large);
    }

    &:last-child {
      border-radius: rem(0 0 $border-radius-large 0);
    }

    @include modifier('adjacent-month') {
      background-color: $calendar-adjacent-month-color;
    }

    &:focus {
      @include std-focus-indicator;
      border-radius: rem($border-radius-medium);
      z-index: index($z-index-scale, base);
      border: 0;
    }

    &:not(.calendar__day--disabled) {
      &:hover {
        background-color: $calendar-hover-color;
      }

      &:focus {
        color: $calendar-focus-text-color;
        background-color: $calendar-focus-color;
      }

      &:active {
        @include std-focus-indicator;
        @include rem(border-radius, $border-radius-medium);
        z-index: index($z-index-scale, base);
        border: 0;
      }
    }

    @include modifier('past') {
      @include element('event') {
        opacity: 0.5;
      }
    }

    @include modifier('today') {
      color: $calendar-today-text-color;

      &:not(.calendar__day--disabled) {
        &:hover {
          color: var(--calendar-color);
        }

        &:focus {
          color: $calendar-focus-text-color;
        }
      }

      @include element('date-label') {
        &::after {
          content: '';
          @include rem(width, $calendar-today-indicator-size);
          @include rem(height, $calendar-today-indicator-size);
          @include rem(margin-left, 3px);
          display: block;
          border-radius: 50%;
          background-color: currentColor;
          align-self: center;
        }
      }
    }

    @include modifier('selected') {
      background-color: $calendar-selected-color;
      color: $calendar-selected-text-color;
    }

    @include modifier('disabled') {
      @include element('content') {
        @include disabled;
      }

      @include element('event') {
        > a:hover {
          text-decoration: none;
        }
      }
    }
  }

  @include element('date-label') {
    @include h5;
    @include rem(padding, $calendar-date-label-padding);
    display: flex; // Help us to center the dot next to today's day number
  }

  @include element('event') {
    background-color: $calendar-event-color;
    color: $dark-font-color;
    @include rem(margin, $calendar-event-margin);
    @include rem(padding, $calendar-event-padding);
    @include rem(border-radius, $border-radius-small);
    @include rem(font-size, $small-font-size);
    line-height: 1.1;

    @each $color in $event-colors {
      @include modifier($color) {
        background-color: color($color, dark);
      }
    }

    > a {
      color: $white;
    }
  }

  /**
   * Datepicker:
   *
   * `.calendar--datepicker`
   *
   * A small calendar to use as a datepicker.
   * Please see <a href="blocks-date-picker.html">the datepicker's dedicated doc page</a>.
   *
   */
  @include modifier('datepicker') {
    @include rem(width, $datepicker-width);
    @include rem(min-width, $datepicker-width);
    @include rem(margin-top, $datepicker-margin-top);
    position: absolute;
    z-index: index($z-index-scale, datepicker);

    @include element('header') {
      @include rem(padding, $datepicker-header-padding);
      border-radius: rem($border-radius-medium $border-radius-medium 0 0);
    }

    @include element('header-center') {
      margin-top: 0;
      @include rem(border-left-width, $datepicker-center-border);
      @include rem(border-right-width, $datepicker-center-border);
    }

    @include element('title') {
      > * {
        margin-bottom: 0;
        width: 100%;
        max-width: 100%;
        min-width: 100%;

        + * {
          margin-top: $ukg-spacing-xs;
        }
      }
    }

    @include element('nav') {
      @include rem(width, $datepicker-nav-size);
      @include rem(height, $datepicker-nav-size);
      @include rem(margin-top, - ($datepicker-nav-size / 2));

      @include modifier('previous') {
        @include rem(left, $calendar-header-padding / 2);
      }

      @include modifier('next') {
        @include rem(right, $calendar-header-padding / 2);
      }
    }

    @include element('sub-header') {
      @include rem(padding, $datepicker-sub-header-padding);
    }

    @include element('month') {
      border-radius: rem($border-radius-medium);
    }

    @include element('day') {
      &:nth-last-child(7) {
        border-radius: rem(0 0 0 $border-radius-medium);
      }

      &:last-child {
        border-radius: rem(0 0 $border-radius-medium 0);
      }

      &:focus {
        border-radius: rem($border-radius-small);
      }

      &:not(.calendar__day--disabled) {
        &:active {
          @include rem(border-radius, $border-radius-small);
        }
      }
    }

    @include element('day-label') {
      @include rem(font-size, $datepicker-font-size);
    }

    @include element('date-label') {
      @include rem(font-size, $datepicker-font-size);
      @include rem(padding, $datepicker-date-label-padding);
    }
  }
}

@media all and (max-width: $mobile) {
  @include block('calendar') {
    @include element('title') {
      .select.select--fixed-width {
        @include rem(width, $short-form-field-width);
      }
    }
  }
}
