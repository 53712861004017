/***
 * # Wizard
 *
 * A horizontal bar with steps that indicate where the user is within a
 * multi-step process.
 *
 * ## When to use a wizard
 *
 * Wizards should be used with any multi-step process, even if there are only
 * two steps.
 *
 * ## When not to use a wizard
 *
 * Individual steps from a wizard should not be used as a title or
 * heading for any other element.
 *
 * ## Position and style
 *
 * Wizards should be placed directly above the content, directly
 * on the page background.
 *
 * Do not include a mix of steps with and without descriptions.
 * All steps should either have descriptions, or not.
 *
 * ## Accessibility
 *
 * Each wizard title should have the same level of heading `<h1>`-`<h6>`.
 * `<h3>` is used in the examples below, but you can use as well other levels.
 */

$wizard-step-padding:                     0 15px;
$wizard-line-width:                       2px;

$wizard-step-color:                       color(grey, 4);
$wizard-step-font-color:                  color(grey, 3);

$wizard-incomplete-step-color:            color(grey, 4);

$wizard-complete-step-color:              color(primary, dark);

$wizard-current-step-color:               color(primary, deep);

$wizard-step-circle-size:                 40px;
$wizard-step-icon-size:                   16px;
$wizard-line-position:                    $wizard-step-circle-size / 2 - $wizard-line-width;
$wizard-description-margin:               10px;
$wizard-status-margin:                    15px;
$wizard-title-font-size:                  $base-font-size;

/**
 * Basic wizard:
 * `.wizard`
 *
 * A wizard that keeps track of the step you are on.
 *
 *     @example
 *     .wizard
 *       .wizard__step.wizard__step--complete
 *         h3.wizard__title
 *           a Setup form
 *         p.wizard__description Create, categorize and share your form
 *         .wizard__step-status
 *           svg.wizard__icon.icon(role='img' aria-labelledby='wizard1-label1' focusable='false')
 *             title#wizard1-label1 Complete
 *             use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
 *       .wizard__step.wizard__step--complete
 *         h3.wizard__title
 *           a Define form fields
 *         p.wizard__description Control the fields you want to include on your form
 *         .wizard__step-status
 *           svg.wizard__icon.icon(role='img' aria-labelledby='wizard1-label2' focusable='false')
 *             title#wizard1-label2 Complete
 *             use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
 *       .wizard__step.wizard__step--current
 *         h3.wizard__title Add form validation
 *         p.wizard__description Create custom validation rules between your form fields
 *         .wizard__step-status
 *           svg.wizard__icon.icon(role='img' aria-labelledby='wizard1-label3' focusable='false')
 *             title#wizard1-label3 Current
 *             use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
 *       .wizard__step.wizard__step--incomplete
 *         h3.wizard__title Preview form
 *         p.wizard__description Preview your form from the viewpoint of different users
 *         .wizard__step-status
 *           svg.wizard__icon.wizard__icon--incomplete.icon(role='img' aria-labelledby='wizard1-label4' focusable='false')
 *             title#wizard1-label4 Incomplete
 *             use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
 */

/**
 * Wizard:
 * `.wizard`
 *
 * A wizard without descriptions.
 *
 *     @example
 *     .wizard
 *       .wizard__step.wizard__step--complete
 *         h3.wizard__title
 *           a Setup form
 *         .wizard__step-status
 *           svg.wizard__icon.icon(role='img' aria-labelledby='wizard2-label1' focusable='false')
 *             title#wizard2-label1 Complete
 *             use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
 *       .wizard__step.wizard__step--complete
 *         h3.wizard__title
 *           a Define form fields
 *         .wizard__step-status
 *           svg.wizard__icon.icon(role='img' aria-labelledby='wizard2-label2' focusable='false')
 *             title#wizard2-label2 Complete
 *             use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
 *       .wizard__step.wizard__step--current
 *         h3.wizard__title Add form validation
 *         .wizard__step-status
 *           svg.wizard__icon.icon(role='img' aria-labelledby='wizard2-label3' focusable='false')
 *             title#wizard2-label3 Current
 *             use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
 *       .wizard__step.wizard__step--incomplete
 *         h3.wizard__title Preview form
 *         .wizard__step-status
 *           svg.wizard__icon.wizard__icon--incomplete.icon(role='img' aria-labelledby='wizard2-label4' focusable='false')
 *             title#wizard2-label4 Incomplete
 *             use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
 */
@include block('wizard') {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  text-align: center;
  word-wrap: break-word;
  @include rem(font-size, $base-font-size);

  @include element('step') {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1 1%;
    @include rem(padding, $wizard-step-padding);
    position: relative;

    // Create the line that connects each step
    &::before {
      content: '';
      display: block;
      width: 100%;
      @include rem(height, $wizard-line-width);
      position: absolute;
      left: -50%;
      @include rem(bottom, $wizard-line-position);
      background-color: $wizard-step-color;
    }

    // Remove line on the first step (as each line is positioned *before* the circle)
    &:first-of-type {
      padding-left: 0;

      &::before {
        display: none;
      }
    }

    &:last-of-type {
      padding-right: 0;
    }

    @include modifier('complete') {
      @include element('step-status') {
        background-color: $wizard-complete-step-color;
        color: $font-color-on-primary;
      }
    }

    @include modifier('current') {
      @include element('title') {
        font-weight: $bold;
      }

      @include element('step-status') {
        background-color: $wizard-current-step-color;
        color: $font-color-on-primary;
      }
    }

    @include modifier('incomplete') {
      color: $wizard-step-font-color;

      a {
        color: $wizard-step-font-color;
        text-decoration: underline;
        text-decoration-color: $wizard-step-font-color;
      }

      @include element('step-status') {
        background-color: $wizard-step-color;
      }
    }
  }

  @include element('title') {
    flex: 0 0 auto;
    @include rem(font-size, $wizard-title-font-size);
    line-height: 1.2;
  }

  @include element('description') {
    flex: 0 0 auto;
    max-width: 100%;
    @include rem(margin-top, $wizard-description-margin);
  }

  @include element('step-status') {
    flex: 0 0 auto;
    position: relative;
    display: inline-block;
    @include rem(width, $wizard-step-circle-size);
    @include rem(height, $wizard-step-circle-size);
    background-color: $wizard-step-color;
    color: $white;
    border-radius: 50%;
    @include rem(margin-top, $wizard-status-margin);
    z-index: index($z-index-scale, wizard-status);
  }

  @include mixie('icon') {
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: $wizard-step-icon-size;
    max-width: $wizard-step-icon-size;
    transform: translate(-50%, -50%);

    @include modifier('incomplete') {
      transform: translate(-50%, -50%) $rotate-counterclockwise;
    }
  }

  /**
   * Responsive wizard:
   * `.wizard--responsive`
   *
   * A wizard that keeps track of the step you are on.
   *
   *     @example
   *     .wizard.wizard--responsive
   *       .wizard__step.wizard__step--complete
   *         h3.wizard__title
   *           a Setup form
   *         p.wizard__description Create, categorize and share your form
   *         .wizard__step-status
   *           svg.wizard__icon.icon(role='img' aria-labelledby='wizard3-label1' focusable='false')
   *             title#wizard3-label1 Complete
   *             use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *       .wizard__step.wizard__step--complete
   *         h3.wizard__title
   *           a Define form fields
   *         p.wizard__description Control the fields you want to include on your form
   *         .wizard__step-status
   *           svg.wizard__icon.icon(role='img' aria-labelledby='wizard3-label2' focusable='false')
   *             title#wizard3-label2 Complete
   *             use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *       .wizard__step.wizard__step--current
   *         h3.wizard__title Add form validation
   *         p.wizard__description Create custom validation rules between your form fields
   *         .wizard__step-status
   *           svg.wizard__icon.icon(role='img' aria-labelledby='wizard3-label3' focusable='false')
   *             title#wizard3-label3 Current
   *             use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
   *       .wizard__step.wizard__step--incomplete
   *         h3.wizard__title Preview form
   *         p.wizard__description Preview your form from the viewpoint of different users
   *         .wizard__step-status
   *           svg.wizard__icon.wizard__icon--incomplete.icon(role='img' aria-labelledby='wizard3-label4' focusable='false')
   *               title#wizard3-label4 Incomplete
   *               use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
   */
  @include modifier('responsive') {
    @media screen and (max-width: $mobile) {
      @include element('title', 'description') {
        display: none;
      }
    }
  }
}

// Calculate and apply gradients between wizard steps :)

.wizard__step--complete + .wizard__step--complete {
  &::before {
    background-color: $wizard-complete-step-color;
  }
}

.wizard__step--complete + .wizard__step--current {
  &::before {
    background-image: linear-gradient(to right, $wizard-complete-step-color, $wizard-current-step-color);
  }
}

.wizard__step--complete + .wizard__step--incomplete {
  &::before {
    background-image: linear-gradient(to right, $wizard-complete-step-color, $wizard-incomplete-step-color);
  }
}

.wizard__step--current + .wizard__step--incomplete {
  &::before {
    background-image: linear-gradient(to right, $wizard-current-step-color, $wizard-step-color);
  }
}

.wizard__step--current + .wizard__step--complete {
  &::before {
    background-image: linear-gradient(to right, $wizard-current-step-color, $wizard-complete-step-color);
  }
}
