/***
 * # Alerts
 *
 * An alert is a “standout” block that should be used to provide users with information.
 *
 * ## When to use alerts
 *
 *  - To confirm that an action has been completed
 *  - To warn the user of an error or mistake
 *  - To provide contextual information, for example, that a number of items are selected
 *
 * ## When not to use alerts
 *
 *  - To display an interface that is not a “message” to the user
 *  - To force actions on a user (for example, confirming an action). For this, use a modal instead.
 *  - To display a lot of content, or provide a very long message. Alerts should only contain small snippets of information.Try to keep alerts **less than 130 characters and up to a maximum of 300 characters**. For longer pieces of text, consider a different layout.
 *
 * ## Position and style
 *
 * Alerts can be positioned anywhere in the interface, although ideally, they should be directly against the page background.
 * At most they should be nested one level deep inside a card or other container.
 * Do not nest an alert inside another nested block. Never nest alerts inside other alerts.
 *
 * The tone of the alert's message can be controlled by using different colors.
 * For example, a warning could be shown with either an orange (warning) or red (danger) alert, depending on the severity.
 * If you choose to do this, make sure you also include an icon for colorblind users with the corresponding aria attributes for assistive technologies. See [alert with icon](#alert-with-icon) for details.
 *
 * Alerts can optionally contain a close button so that users can dismiss the message.
 * They can also include action buttons (for example, an 'undo' button), although only for actions that the user can safely ignore.
 *
 * Alerts can also be [floating at the top of the screen](#floating-alerts). Floating alerts are recommended when displaying general success or error messages. Avoid displaying more than one floating alert at a time.
 *
 * #### Autoclosing alerts
 *
 * Success alerts should autoclose by themselves:
 *
 * - A delay of **3.2 seconds** (default) is recommended for alerts with less than 130 characters
 * - A delay of **6.4 seconds** it is recommended for alerts between 130 and 300 characters
 *
 * In addition to this, the user should always be able to close the alert manually. Do not use autoclosing alerts for error messages. See the [Javascript](#javascript) section for details about how to implement autoclosing alerts.
 *
 *
 * ## Accessibility
 *
 * ARIA live regions must be used when an alert message is added dynamically to the page *after*
 * the initial page load, to communicate an important or time-sensitive message to the user.
 *
 * - An empty element with the `live-region` attribute must be present in the initial markup on
 * page load.
 * - The content, e.g., an alert component, should be added dynamically afterwards (without a page
 * reload) so that assistive technologies announce the changes properly.
 * - If the content is present on page load, the use of a live region has no effect.
 *
 * ```html
 * <div aria-live="polite" role="status">
 *   <!-- Assistive technologies will announce dynamic content updates inside of this wrapper. -->
 * </div>
 * ```
 *
 * #### Polite live region
 *
 * Polite live regions are the recommended choice for most cases. With this setting, when the
 * alert  is added to the page, the content should be announced at the next opportunity, for
 * example, after the screen reader finishes the current sentence, or when the user pauses typing.
 *
 * ```html
 * <div aria-live="polite" role="status"></div>
 * ```
 *
 * See [floating alerts](#floating-alerts)
 *
 * #### Assertive live region
 *
 * Assertive live regions are only recommended for alerts containing critical messages. With this
 * setting, when the alert is added to the page, the content should be announced immediately to the
 * user, interrupting any current task if necessary. This setting can be annoying and disorienting to
 * the user. Do not use `assertive` unless the interruption is imperative.
 *
 * ```html
 * <div aria-live="assertive" role="alert"></div>
 * ```
 *
 * ## Deprecation warnings:
 * - [The class `.alert--border-only` has been deprecated](#border-only-alert)
 * - [The class `.alert--primary` has been deprecated](#primary-alert)
 *
 * ## Initialization
 *
 * <div class="alert alert--danger top-margin top-margin--small">
 *   <p>This component will auto-instantiate unless it has the <code>[data-no-auto]</code> attribute at page load (DOMContentLoaded) or on <code>init.pdui</code> event recept on an ancestor.<p>
 *   <p>If the component is added to the page dynamically, trigger the <code>init.pdui</code> event on a parent node to instantiate it.</p>
 *   <p>If the component is not instantiated, it will auto-instantiate as soon as a method of that component is called programmatically, regardless of the presence of <code>[data-no-auto]</code>.</p>
 *   <p>If you don't set the component to auto-instantiate and you wish to instantiate it manually (pass options to it etc.) do: <br><code>$(el).alert();</code></p>
 * </div>
 */

$alert-border-radius:                $border-radius-large;
$alert-padding:                      15px;
$alert-border-color:                 color(grey, 5);
$alert-background-color:             color(grey, 6);
$thin-alert-padding:                 7px;
$alert-p-margin-top:                 8px;

$alert-arrow-size:            $alert-padding; // The actual arrow head size will be half of this value
$alert-arrow-offset:          -$alert-arrow-size / 2;

$alert-icon-size:             18px;
$alert-icon-margin:           2px;

/*
  List the color alerts you want to generate
  If you change this list, be sure to update the example docs!
*/
$alert-colors: 'warning', 'danger', 'success', 'info';

/*
  Arrows:
----------------------------------------------
|   Left top  |   Center top  |   Right top  |
----------------------------------------------
| Left center |               | Right center |
----------------------------------------------
| Left bottom | Center bottom | Right bottom |
----------------------------------------------

 - The first set of lists specifies the position on the div
 - The second specifies what arrows you can attach to that block

 For example, the left top block can have a horizontal arrow (attached to the
 left side) and a vertical arrow (attached to the top side).

 By contrast, the center top block can only have a vertical arrow attached to
 its top side.
*/
$arrows: (
  (
    ('left', 'top'),
    ('horizontal', 'vertical')
  ),
  (
    ('center', 'top'),
    ('vertical')
  ),
  (
    ('right', 'top'),
    ('horizontal', 'vertical')
  ),
  (
    ('right', 'center'),
    ('horizontal')
  ),
  (
    ('right', 'bottom'),
    ('horizontal', 'vertical')
  ),
  (
    ('center', 'bottom'),
    ('vertical')
  ),
  (
    ('left', 'bottom'),
    ('horizontal', 'vertical')
  ),
  (
    ('left', 'center'),
    ('horizontal')
  )
);

@function calculate-arrow-direction($x-axis, $y-axis, $direction) {
  // Work out what direction our arrow points in
  @if $direction == 'vertical' {
    @if $y-axis == 'top' {
      @return 'up';
    } @else {
      @return 'down';
    }
  } @else { // Direction is horizontal
    @if $x-axis == 'left' {
      @return 'left';
    } @else {
      @return 'right';
    }
  }
}

@mixin horizontal-arrow-position($y-axis) {
  /*
    Work out where our horizontal (pointing left or right) arrow
    should be placed vertically.
  */
  @if $y-axis == 'top' {
    @include rem(top, $alert-padding);
  } @else if $y-axis == 'center' {
    @include rem(margin-top, $alert-arrow-offset);
    top: 50%;
  } @else { // y-axis is bottom
    @include rem(bottom, $alert-padding);
  }
}

@mixin vertical-arrow-position($x-axis) {
  /*
    Work out where our vertical (pointing left or right) arrow
    should be placed horizontally.
  */
  @if $x-axis == 'left' {
    @include rem(left, $alert-padding);
  } @else if $x-axis == 'center' {
    @include rem(margin-left, $alert-arrow-offset);
    left: 50%;
  } @else { // x-axis is right
    @include rem(right, $alert-padding);
  }
}

@mixin generate-alert-with-arrow($x-axis, $y-axis, $direction) {
  $arrow-direction: calculate-arrow-direction($x-axis, $y-axis, $direction);
  $arrow-width: if($direction == 'vertical', $alert-arrow-size, $alert-arrow-size / 2);
  $arrow-height: if($direction == 'vertical', $alert-arrow-size / 2, $alert-arrow-size);
  position: relative;

  &::before {
    @include triangle($arrow-direction, $arrow-width, $arrow-height, $alert-border-color);
    content: '';
    position: absolute;

    @if $arrow-direction == 'left' {
      @include horizontal-arrow-position($y-axis);
      @include rem(left, $alert-arrow-offset);
      border-right-color: inherit;
    } @else if $arrow-direction == 'right' {
      @include horizontal-arrow-position($y-axis);
      @include rem(right, $alert-arrow-offset);
      border-left-color: inherit;
    } @else if $arrow-direction == 'up' {
      @include vertical-arrow-position($x-axis);
      @include rem(top, $alert-arrow-offset);
      border-bottom-color: inherit;
    } @else { // arrow direction == down
      @include vertical-arrow-position($x-axis);
      @include rem(bottom, $alert-arrow-offset);
      border-top-color: inherit;
    }
  }
}

/**
 * Basic alert:
 * `.alert`
 * A standard alert.
 *
 * The first element placed inside it will have it's top margin removed.
 *
 *     @example
 *     .alert
 *       h4 Alert title
 *       p Alert content with #[a(href='#') a link].
 *       button.button.top-margin.top-margin--small And a button!
 */

@include block('alert') {
  @include rem(border-radius, $alert-border-radius);
  @include rem(border, 1px solid $alert-border-color);
  @include no-first-child-margin;
  @include rem(padding, $alert-padding);
  @include rem(font-size, $base-font-size);
  @include clearfix;
  background-color: $alert-background-color;
  opacity: 1;
  visibility: inherit; // visible, but respecting hidden by inheritance
  // ALERT FADE-IN TRANSITION
  transition:
    // 1. Make the element visible
    visibility 0s,
    // 2. Quickly expand padding, border and height of the still transparent element
    padding-top 0.1s ease-out,
    padding-bottom 0.1s ease-out,
    border-top-width 0.1s ease-out,
    border-bottom-width 0.1s ease-out,
    // The height property is added inline using JS because we can not transition to height: auto
    height 0.1s ease-out,
    // 3. Once there is space, fade in the element
    opacity 0.3s linear 0.1s;

  p {
    @include rem(margin-top, $alert-p-margin-top);
    color: inherit;
  }

  h1,
  h2,
  h3,
  h4 {
    color: inherit;
  }

  /**
   * Alert with close button:
   * `.alert .alert__close-button.button svg.button__icon.icon`
   *
   * An alert with a close button. Make sure you add `aria-label="Close"`.
   *
   *     @example
   *     .alert
   *       button.alert__close-button.button(type='button' aria-label='Close' )
   *          svg.button__icon.icon(aria-hidden='true', focusable='false')
   *            use(xlink:href='../icons.svg#close' href='../icons.svg#close')
   *       p Alert with a close button
   */
  @include mixie('close-button') {
    @include seamless-button($icon-size: $alert-icon-size);
    @include rem(margin-top, -4px);
    @include rem(margin-right, -4px);
    float: right;

    + * {
      margin-top: 0;
    }
  }

  /**
   * Alert with icon:
   * `.alert .alert__icon`
   *
   * An alert with an icon.
   *
   * <div class="card card--standout top-margin">
   *   <div class="card__body">
   *     <p>
   *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title1" focusable="false">
   *         <title id="title1">Information</title>
   *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *       </svg>
   *       <strong>Accessibility</strong>
   *     </p>
   *     <p>Make sure you provide an accessible name to the <code>svg</code> using <code>aria-labelledby</code> referencing the <code>id</code> of the <code>title</code> element.</p>
   *     <p><code>role="img"</code>, on the other hand, ensures that the <code>svg</code> is identified as a graphic by assistive technologies.</p>
   *   </div>
   * </div>
   *
   *     @example
   *     .alert
   *       svg.alert__icon.icon(role='img' aria-labelledby='icon-title', focusable='false')
   *         title#icon-title Warning
   *         use(xlink:href='../icons.svg#warning' href='../icons.svg#warning')
   *       p Alert with an icon
   */
  @include mixie('icon') {
    @include rem(width, $alert-icon-size);
    @include rem(height, $alert-icon-size);
    @include rem(margin-right, $alert-icon-margin * 4);
    @include rem(margin-top, $alert-icon-margin);
    float: left;

    & + * {
      margin-top: 0;
    }
  }

  /**
   * Thinner Alert:
   * `.alert--thin`
   *
   * An alert with less padding
   *
   *     @example
   *     .alert.alert--thin
   *       p An alert with less padding
   */
  @include modifier('thin') {
    @include rem(padding, $thin-alert-padding);
  }

  /**
   * Hidden Alert:
   * `.alert--hidden`
   *
   * A hidden alert.
   *
   *     @example
   *     .alert.alert--hidden
   *       p Hidden alert
   */

  @include modifier('hidden') {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    opacity: 0;
    visibility: hidden;
    // ALERT FADE-OUT TRANSITION
    // Height doesn't need a delay because it is added with JS when opacity transition ends
    transition-delay: 0.4s, 0.3s, 0.3s, 0.3s, 0.3s, 0s, 0s; // visibility, padding-top, padding-bottom, border-top-width, border-bottom-width, height, opacity
  }

  /**
   * Colored alert:
   * `.alert--mycolor`
   *
   * A colored alert.
   *
   *     @example
   *     include ./code-samples/colored-alert.pug
   */
  @each $color in $alert-colors {
    @include modifier($color) {
      background-color: color($color, light);
      border-color: color($color, mid);

      @include mixie('icon') {
        color: color($color, dark);
      }
    }
  }

  @include modifier('white') {
    background-color: $white;
  }

  /**
   * Alerts with arrows:
   * `.alert--arrow-{y}-{x}-{direction}`
   * An alert with an arrow.
   *
   * The first two arguments, `{y}` and `{x}`, specify the position of the arrow. e.g. `.alert--arrow-top-left-…` will attach the arrow on the left side of the div, at the top.
   *
   * The last argument, `{direction}`, specifies the direction e.g. `.alert--arrow-bottom-left-vertical` will attach the arrow at the bottom side of the div, on the left and the arrow will be pointing downwards.
   *
   *     @example
   *     include ./code-samples/alerts-with-arrows.pug
   */
  @each $arrow in $arrows {
    $x-axis: nth(nth($arrow, 1), 1);
    $y-axis: nth(nth($arrow, 1), 2);
    $arrow-head-directions: nth($arrow, 2);

    @each $direction in $arrow-head-directions {

      @include modifier('arrow-' + $x-axis + '-' + $y-axis + '-' + $direction) {
        @include generate-alert-with-arrow($x-axis, $y-axis, $direction);
      }

      @include modifier('arrow-' + $y-axis + '-' + $x-axis + '-' + $direction) {
        @include generate-alert-with-arrow($x-axis, $y-axis, $direction);
      }
    }
  }

  /**
   * Inline Alert:
   * `.alert--inline-block`
   *
   * An alert that behaves as an inline block.
   *
   * An alert that can be placed, inline, next to an element, with its width set to `auto`, as opposed to being placed above or below it, as a float, with its width set to `100%`.
   *
   *     @example
   *     include ./code-samples/inline-alert.pug
   */
  @include modifier('inline-block') {
    float: none;
    display: inline-block;
    width: auto;
    margin-bottom: 0;
  }

  /**
   * Stacked Alerts:
   *
   * When several alerts are stacked, a small space between them is automatically applied.
   *
   * As a general rule, though, avoid stacking too many alerts on the same page to prevent an excess of visual noise.
   *
   *     @example
   *     include ./code-samples/stacked-alerts.pug
   */
  + .alert {
    margin-top: $ukg-spacing-xs;
  }
}

/**
* Floating alerts:
* `.alert-floating-container`
*
* The parent element `.alert-floating-container` allows you to position floating alert messages at the top of the screen. **It must be the first child of the `<main>` element**.
*
* <div class="card card--standout top-margin">
*   <div class="card__body">
*     <p>
*       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title2">
*         <title id="title2">Information</title>
*         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
*       </svg>
*       <strong>Accessibility</strong>
*     </p>
*     <p>Make sure to add <code>role="status" aria-live="polite"</code> to <code>.alert-floating-container</code>. The live region must be present when the initial markup is loaded so that the browser and assistive technologies are aware of it. Any subsequent changes in the content are then announced by screen readers.</p>
*   </div>
* </div>
*
*     @example
*     .alert-floating-container(role='status' aria-live='polite')
*     p See the page #[a(href='../templates/floating-alerts.html') floating alerts]&nbsp;for a full example.
*/
@include block('alert-floating-container') {
  position: fixed;
  z-index: index($z-index-scale, alert);
  left: 0;
  display: flex;
  overflow: visible;
  flex-direction: column;
  width: 100%;
  align-items: center;
  @supports ((position: sticky) or (position: -webkit-sticky)) {
    position: sticky;
    top: 0;
    height: 0; // Make sure the floating alert doesn't use space in the layout
  }

  @include block('alert') {
    width: 85%;
    box-shadow: $ukg-elevation-08;
    @include rem('max-width', 560px);

    &:first-child {
      margin-top: $ukg-spacing-m;
    }
  }
}

@media all and (max-width: $tablet) {
  @include block('layout') {
    &:not(.layout--semi-responsive) {
      .horizontal-menu + .main .alert-floating-container {
        position: absolute;
        top: auto;
      }

      .horizontal-menu--sticky + .main .alert-floating-container {
        position: fixed;
      }
    }
  }
}
