/*
 * Bourbon’s triangle() mixin doesn’t allow CSS Custom Properties as
 * color parameter (as of Bourbon v7.0.0). This replacement mixin
 * should be removed if a new version fix this.
 * Meanwhile this mixin is a copy of the original one without the
 * color parameter restriction.
 * (see https://github.com/thoughtbot/bourbon/issues/1103)
 */

@mixin triangle(
  $direction,
  $width,
  $height,
  $color
) {
  @if not index(
    'up' 'up-right' 'right' 'down-right' 'down' 'down-left' 'left' 'up-left',
    $direction
  ) {
    @error 'Direction must be `up`, `up-right`, `right`, `down-right`, ' +
      '`down`, `down-left`, `left` or `up-left`.';
  } @else {
    border-style: solid;
    height: 0;
    width: 0;

    @if $direction == 'up' {
      border-color: transparent transparent $color;
      border-width: 0 ($width / 2) $height;
    } @else if $direction == 'up-right' {
      border-color: transparent $color transparent transparent;
      border-width: 0 $width $width 0;
    } @else if $direction == 'right' {
      border-color: transparent transparent transparent $color;
      border-width: ($height / 2) 0 ($height / 2) $width;
    } @else if $direction == 'down-right' {
      border-color: transparent transparent $color;
      border-width: 0 0 $width $width;
    } @else if $direction == 'down' {
      border-color: $color transparent transparent;
      border-width: $height ($width / 2) 0;
    } @else if $direction == 'down-left' {
      border-color: transparent transparent transparent $color;
      border-width: $width 0 0 $width;
    } @else if $direction == 'left' {
      border-color: transparent $color transparent transparent;
      border-width: ($height / 2) $width ($height / 2) 0;
    } @else if $direction == 'up-left' {
      border-color: $color transparent transparent;
      border-width: $width $width 0 0;
    }
  }
}
