/***
 * <div class="alert alert--warning">
 *   <svg role="img" aria-labelledby="implementation-alert-icon" focusable="false" class="alert__icon icon">
 *     <title id="implementation-alert-icon">Warning</title>
 *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
 *   </svg>
 *   <p>The text editor component is provided for <strong>HTML and CSS reference only</strong>. To implement this component into your codebase, you will need to provide your own logic.</p>
 * </div>
 *
 * <div class="alert vertical-margin">
 *   <svg role="img" aria-labelledby="emberjs-alert-icon" focusable="false" class="alert__icon icon">
 *     <title id="emberjs-alert-icon">Information</title>
 *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
 *   </svg>
 *   <p>For a fully functional text editor, please check the EmberJS version based on <a href="https://prosemirror.net">Prosemirror</a>
 * and <a href="https://codemirror.net">Codemirror</a>:<p>
 *   <ul>
 *     <li><a href="https://internal-documentation.hrsd.us-east4.dev.gcp.int/tribe-js/ember-peopledoc-text-editor/master/">Documentation - Ember PeopleDoc text editor</a></li>
 *     <li><a href="https://github.com/UKGEPIC/hrsd-text-editor-app-front">Repository - PeopleDoc text editor embedded application</a></li>
 *   </ul>
 * </div>
 *
 * # Text editor
 *
 * A text editor is a component that allows user to format text - e.g. weight, size, style, alignment,
 * lists, and indentation. Users are also able to add images, videos, attach files and link to URLs.
 * The text editor contains two modes: WYSIWYG, which presents the user with a
 * "what-you-see-is-what-you-get" editing area, and advanced mode where user is able to edit the text
 * using HTML markup.
 *
 * ## When to use the text editor
 *
 * A text editor with all options available should only be used when the text that user is editing
 * will be the primary content of the page e.g. when creating and editing articles.
 *
 * If the text that the user is writing won’t be the primary content of the page, then the basic text
 * editor can be used. Examples:
 * - Description of a process or process template
 * - Description of a form, or form field
 *
 * ## When not to use the text editor
 *
 * A textarea without any editing options should be used when you do not want to provide the user with
 * full control of the content created.
 *
 * ## Position and style
 *
 * A text editor can be positioned in a modal, in a card, or directly against the page background.
 *
 * Please keep things simple and use only the formatting options that are necessary for the use case.
 *
 * If there is a visible text on the screen that acts as a label for the text editor, this text should
 * be referenced as the accessible name of the component for assistive technologies. Please check the
 * [accessibility section](#notes-aria-labelledby) for implementation details.
 *
 * ## Accessibility
 *
 * **WAI-ARIA roles, states, and properties**
 *
 * - The element `.text-editor__toolbar` has role `toolbar` and an accessible name provided by
 * the `aria-label` attribute.
 * - Toggle buttons like "Bold" and "Edit source code" use the attribute `aria-pressed` to indicate
 * if the button is active or inactive. The value `true` or `false` must be set programmatically.
 * - Buttons that belong to a group where only one at a time can be active, like text alignment buttons,
 * have the role `radio` and the attribute `aria-checked`. The value `true` or `false` must be set programmatically.
 * - All the buttons with role `radio` that belong to the same group must have a common parent element
 * with the role `radiogroup` and an accessible name provided by `aria-label`.
 * - The "Edit source code" button has `aria-describedby` referencing the content of the warning alert
 * `.text-editor__alert`.
 * - `aria-disabled` is used instead of the HTML attribute `disabled` so that disabled controls remain
 * focusable and screen reader users can be aware of their presence.
 * - _Optional:_ the element
 * `.text-editor` can have the role `group` and an accessible name provided by `aria-label` or
 * `aria-labelledby`. See [use case 1](#use-case-1) and [use case 2](#use-case-2) for tabbed
 * interface examples where the text in the tab provides an accessible name to the text editor.
 * `aria-labelledby` can reference any visible text element on the screen via the ID attribute.
 *
 * **Keyboard Interaction**
 *
 * Toolbar:
 * - Use the [roving tabindex method](https://w3c.github.io/aria-practices/#kbd_roving_tabindex) for
 * managing focus within the toolbar. The toolbar represents one single stop in the tab sequence, and arrow
 * keys left, and right are used to move focus among the different toolbar controls.
 *
 * Popovers and dropdowns:
 * - When a popover or dropdown in the text editor is opened as a result of a user action, the focus must be
 * sent to the first focusable element inside of the popover or dropdown.
 * - The tab key must be constrained within the popover or dropdown. Users must not be able to tab out of the
 * popover or dropdown.
 * - When the popover or dropdown closes, the focus must return to the original trigger button. The popover or
 * dropdown can close as a result of a user action, or by pressing the `Escape` key.
 *
 * Edit source code button:
 * - Click on the "edit source code" button toggles the class `.text-editor--advanced-mode` and displays the
 * [advanced mode view](#advanced-mode-text-editor).
 * - Focus must remain in the same position - the toggle button.
 *
 * Radiogroup:
 * - If `radiogroup`'s children are not `input[type=radio]`, there should be a programmaticaly added behaviour
 * for [arrow keys](https://www.w3.org/TR/wai-aria-practices-1.1/examples/toolbar/toolbar.html#kbd_label_3) to navigate through items.
 *
 * ## Deprecation warnings
 *
 * Color swatches:
 * - Color swatches with `input[type=radio]` are deprecated. Backwards compatibility will be removed in a future version of PDUI. We recommend to [replace `input[type=radio]` with `button`](#text-editor-color-swatches) as soon as possible.
 *
 */

$text-editor-button-padding:            10px;
$text-editor-button-width:              50px; // 40px width + 8px padding + 2px border - 1px of negative margin
$text-editor-button-icon-size:          20px;
$text-editor-label-height:              20px;
$text-editor-preview-height:            150px;

/**
 *  Full text editor:
 * `.text-editor`
 *
 * A text editor with a full set of controls.
 *
 * <div class="alert top-margin">
 *   <svg role="img" aria-labelledby="full-text-editor-alert-icon" focusable="false" class="alert__icon icon">
 *     <title id="full-text-editor-alert-icon">Information</title>
 *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
 *   </svg>
 *   <strong>Disabled controls</strong>
 *   <p>In this example, the "Bold" button is disabled and active, whereas the "Underline" button is disabled but inactive.</p>
 * </div>
 *
 * <div class="alert alert--warning top-margin">
 *   <svg role="img" aria-labelledby="full-text-editor-alert-icon-2" focusable="false" class="alert__icon icon">
 *     <title id="full-text-editor-alert-icon-2">Warning</title>
 *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
 *   </svg>
 *   <strong>Focus and keyboard</strong>
 *   <p>
 *     Implement the focus and keyboard interaction of this element as described in the section
 *     <a href="#accessibility">Accessibility / Keyboard Interaction / Toolbar</a>.
 *   </p>
 * </div>
 *
 *     @example
 *     include ./code-samples/full-text-editor.pug
 *
 */

/**
 *  Advanced mode text editor:
 * `.text-editor--advanced-mode`
 *
 * Advanced mode view of the text editor.
 *
 * <div class="alert alert--warning top-margin">
 *   <svg role="img" aria-labelledby="full-text-editor-alert-icon-2" focusable="false" class="alert__icon icon">
 *     <title id="full-text-editor-alert-icon-2">Warning</title>
 *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
 *   </svg>
 *   <strong>Focus and keyboard</strong>
 *   <p>
 *     Implement the focus and keyboard interaction of this element as described in the section
 *     <a href="#accessibility">Accessibility / Keyboard Interaction / Edit source code button</a>.
 *   </p>
 * </div>
 *
 *     @example
 *     include ./code-samples/advanced-mode-text-editor.pug
 *
 */

@include block('text-editor') {
  @include element('toolbar') {
    @include rem(margin-left, 1px);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  @include modifier('advanced-mode') {
    @include element('advanced-mode-element') {
      display: block;
    }

    @include element('default-mode-element') {
      display: none;
    }

    @include element('toolbar') {
      justify-content: flex-end;
    }
  }

  @include element('advanced-mode-element') {
    display: none;
  }

  @include element('toolbar') {
    margin-bottom: $ukg-spacing-xs;

    + .text-editor__label {
      // Align first label with source code button
      margin-top: rem(-$text-editor-label-height) - $ukg-spacing-xs;
      padding-right: rem($text-editor-button-width) + $ukg-spacing-xs;
    }
  }

  @include element('toolbar-main') {
    display: flex;
    flex-wrap: wrap;
    @include rem(flex-basis, calc(100% - #{$text-editor-button-width})); // Fix issue in IE11
  }

  @include element('toolbar-right') {
    @include rem(flex-basis, $text-editor-button-width); // Fix issue in IE11
    flex-shrink: 0;
  }

  @include element('toolbar-group') {
    display: flex;

    @include modifier('bordered') {
      @include rem(border, 1px solid color(grey, 5));
      @include rem(margin-left, -1px);
      padding: 0 $ukg-spacing-xxxs;
      margin-bottom: $ukg-spacing-xs;

      &:first-child {
        @include rem(border-top-left-radius, $border-radius-medium);
        @include rem(border-bottom-left-radius, $border-radius-medium);
      }

      &:last-child {
        @include rem(border-top-right-radius, $border-radius-medium);
        @include rem(border-bottom-right-radius, $border-radius-medium);
      }
    }

    @include modifier('break') {
      margin-right: $ukg-spacing-xs;
      @include rem(border-top-right-radius, $border-radius-medium);
      @include rem(border-bottom-right-radius, $border-radius-medium);

      + .text-editor__toolbar-group {
        @include rem(border-top-left-radius, $border-radius-medium);
        @include rem(border-bottom-left-radius, $border-radius-medium);
      }
    }
  }

  @include element('preview') {
    padding: $ukg-spacing-xs;
    @include rem(min-height, $text-editor-preview-height);
    @include rem(border-radius, $form-field-border-radius);
    @include rem(border, solid $form-field-border-width form-field-color(standard, border));
    margin-bottom: $ukg-spacing-s;
    display: none;
  }

  @include mixie('button') {
    @include rem(padding, $text-editor-button-padding);
    border: 0;
    position: relative;
    overflow: visible;
    border-radius: rem($border-radius-small);
    margin: $ukg-spacing-xxs $ukg-spacing-xxxs;

    &:focus {
      @include focus-outline-pseudo-element($gap: 1px);
      @include rem(box-shadow, 0 0 0 1px $white);
      z-index: index($z-index-scale, base); // Prevent focus indicator from visually overlap with next button
    }

    &:active {
      &[aria-disabled='true'] {
        &:not([aria-pressed='true']):not([aria-checked='true']) {
          background: $white;
        }
      }
    }

    .button__icon {
      width: rem($text-editor-button-icon-size);
      height: rem($text-editor-button-icon-size);
      margin: 0;
      vertical-align: middle; // Fix issue in IE11
    }
  }

  @include mixie('alert') {
    margin: 0 0 $ukg-spacing-xs;
  }

  @include mixie('textarea') {
    max-width: 100%;
    overflow-y: auto;
    margin-bottom: $ukg-spacing-s;
  }

  /**
  * Basic text editor:
  * `.text-editor`
  *
  * A text editor with a basic set of controls.
  *
  * <div class="alert top-margin">
  *   <svg role="img" aria-labelledby="basic-text-editor-alert-icon" focusable="false" class="alert__icon icon">
  *     <title id="basic-text-editor-alert-icon">Information</title>
  *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
  *   </svg>
  *   <strong>Controls selection</strong>
  *   <p>You can remove from the markup any control that it is not needed.</p>
  *   <p>Alternatively, you can use the helper class <code>.hide</code> to hide a control with CSS if necessary.</p>
  * </div>
  *
  *     @example
  *     include ./code-samples/basic-text-editor.pug
  *
  */

  /**
  * Text editor color swatches:
  * `.text-editor__swatch`
  *
  * A group of swatches with buttons. They are used on the [text color dropdown](#dropdown-color-text).
  *
  * We use [cells](blocks-cells.html) to align the group of swatches horizontally.
  *
  * Each group of swatches contains a maximum of 6 colors.
  *
  *     @example
  *     include ./code-samples/text-editor-color-swatches.pug
  *
  */

  /**
  * Text editor light swatch:
  * `.text-editor__swatch--light`
  *
  * A light color swatch. The checkmark color is `grey 3` instead of `white`.
  *
  * <div class="alert top-margin">
  *   <svg role="img" aria-labelledby="text-editor-light-swatch-alert-icon" focusable="false" class="alert__icon icon">
  *     <title id="text-editor-light-swatch-alert-icon">Information</title>
  *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
  *   </svg>
  *   <strong>Color contrast</strong>
  *   <p>Use the <code>--light</code> modifier when the color contrast between the default white checkmark and its background is insufficient as per the <a href="https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html">WCAG 2.1</a>. That is, the color contrast ratio is less than <code>4.5:1</code>.</p>
  *   <p>Some colors won't reach the minimum contrast ratio with any of the two options. In those cases, we recommend applying the modifier that provides a higher color contrast, even if insufficient.</p>
  * </div>
  *
  *     @example
  *     include ./code-samples/text-editor-light-swatch.pug
  *
  */

  // Remove the parent selector .text-editor so that it can be placed in a .dropdown
  @at-root .text-editor__swatch {
    color: $white;

    @include modifier('light') {
      color: color(grey, 3);
    }
  }

  // Remove the parent selector .text-editor so that it can be placed in a .dropdown

  /* DEPRECATED CODE
  Provide support to the old HTML structure:
  */

  @at-root .text-editor__swatch-input {
    @include sr-only;

    &:focus {
      + .text-editor__swatch-label {
        @include focus-outline-pseudo-element;
      }
    }

    &:checked {
      + .text-editor__swatch-label {
        .text-editor__swatch-icon {
          visibility: visible;
        }
      }
    }
  }

  /*
   ============================
   END OF DEPRECATED CODE
  */

  // Remove the parent selector .text-editor so that it can be placed in a .dropdown
  @at-root {
    .text-editor__swatch-label, // Deprecated
    .text-editor__swatch-button {
      display: inline-block;
      border: 0;
      border-radius: rem($border-radius-small);
      padding: 0;
      cursor: pointer;
    }

    .text-editor__swatch-button {
      &:focus {
        @include focus-outline-pseudo-element($border-radius: $border-radius-small + 3);
      }

      &[aria-checked] {
        .text-editor__swatch-icon {
          visibility: visible;
        }
      }
    }
  }

  // Remove the parent selector .text-editor so that it can be placed in a .dropdown
  @at-root .text-editor__swatch-icon {
    width: rem(24px);
    height: rem(24px);
    visibility: hidden;
  }

  /**
  * Text color codes:
  *
  * Group of fields to input a custom color code. They are used on the [text color dropdown (color picker view)](#dropdown---text-color-color-picker-view).
  *
  *     @example
  *     include ./code-samples/text-color-codes.pug
  */

  // Remove the parent selector .text-editor so that it can be placed in a .dropdown
  @at-root .text-editor__color-wrapper {
    width: rem(265px);
    max-width: 80vw;
    padding: $ukg-spacing-s;
  }

  // Remove the parent selector .text-editor so that it can be placed in a .dropdown
  @at-root .text-editor__color-codes {
    display: flex;
    justify-content: space-between;
    margin: 0 -$ukg-spacing-xxs;
  }

  // Remove the parent selector .text-editor so that it can be placed in a .dropdown
  @at-root .text-editor__rgbset {
    display: flex;
  }

  // Remove the parent selector .text-editor so that it can be placed in a .dropdown
  @at-root .text-editor__color-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: rem($small-font-size);
    margin: 0 $ukg-spacing-xxs;
  }

  // Remove the parent selector .text-editor so that it can be placed in a .dropdown
  @at-root .text-editor__color-hex {
    max-width: rem(81px);
  }

  // Remove the parent selector .text-editor so that it can be placed in a .dropdown
  @at-root .text-editor__color-rgb {
    max-width: rem(38px);
  }

  /**
  * Dropdown - Text color:
  *
  * See how to change the color of the drop icon in [text color icon](blocks-icon.html#text-color-icon).
  *
  * <div class="card card--standout top-margin">
  *   <div class="card__body">
  *     <p>
  *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="dropdown-insert-image-a11y-icon" focusable="false">
  *         <title id="dropdown-insert-image-a11y-icon">Information</title>
  *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
  *       </svg>
  *       <strong>Accessibility</strong>
  *     </p>
  *     <p>Use <code>aria-haspopup="dialog"</code> instead of <code>aria-haspopup="true"</code>.</p>
  *   </div>
  * </div>
  *
  * <div class="alert alert--warning top-margin">
  *   <svg role="img" aria-labelledby="dropdown-insert-image-alert-icon" focusable="false" class="alert__icon icon">
  *     <title id="dropdown-insert-image-alert-icon">Warning</title>
  *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
  *   </svg>
  *   <strong>Focus and keyboard</strong>
  *   <p>On click on the button "Add custom color" the view switches to the <a hre="#dropdown---text-color-color-picker-view">custom color picker</a>.</p>
  *   <p>
  *     Implement the focus and keyboard interaction of this element as described in the section
  *     <a href="#accessibility">Accessibility / Keyboard Interaction / Popovers and dropdowns</a>.
  *   </p>
  * </div>
  *
  *     @example
  *     include ./code-samples/dropdown-text-color.pug
  */

  /**
  * Dropdown - Text color (color picker view):
  *
  * See how to change the color of the drop icon in [text color icon](blocks-icon.html#text-color-icon).
  *
  * <div class="card card--standout top-margin">
  *   <div class="card__body">
  *     <p>
  *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="dropdown-insert-image-a11y-icon" focusable="false">
  *         <title id="dropdown-insert-image-a11y-icon">Information</title>
  *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
  *       </svg>
  *       <strong>Accessibility</strong>
  *     </p>
  *     <p>Use <code>aria-haspopup="dialog"</code> instead of <code>aria-haspopup="true"</code>.</p>
  *   </div>
  * </div>
  *
  * <div class="alert alert--warning top-margin">
  *   <svg role="img" aria-labelledby="dropdown-insert-image-alert-icon" focusable="false" class="alert__icon icon">
  *     <title id="dropdown-insert-image-alert-icon">Warning</title>
  *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
  *   </svg>
  *   <strong>Focus and keyboard</strong>
  *   <p>This view is shown when the user clicks on the button <a href="#dropdown---text-color">"Add custom color"</a>.</p>
  *   <p>
  *     Implement the focus and keyboard interaction of this element as described in the section
  *     <a href="#accessibility">Accessibility / Keyboard Interaction / Popovers and dropdowns</a>.
  *   </p>
  * </div>
  *
  *     @example
  *     include ./code-samples/dropdown-text-color-picker.pug
  */

  /**
  * Dropdown - Insert image:
  *
  * <div class="card card--standout top-margin">
  *   <div class="card__body">
  *     <p>
  *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="dropdown-insert-image-a11y-icon" focusable="false">
  *         <title id="dropdown-insert-image-a11y-icon">Information</title>
  *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
  *       </svg>
  *       <strong>Accessibility</strong>
  *     </p>
  *     <p>Use <code>aria-haspopup="dialog"</code> instead of <code>aria-haspopup="true"</code>.</p>
  *   </div>
  * </div>
  *
  * <div class="alert alert--warning top-margin">
  *   <svg role="img" aria-labelledby="dropdown-insert-image-alert-icon" focusable="false" class="alert__icon icon">
  *     <title id="dropdown-insert-image-alert-icon">Warning</title>
  *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
  *   </svg>
  *   <strong>Focus and keyboard</strong>
  *   <p>
  *     Implement the focus and keyboard interaction of this element as described in the section
  *     <a href="#accessibility">Accessibility / Keyboard Interaction / Popovers and dropdowns</a>.
  *   </p>
  * </div>
  *
  *     @example
  *     include ./code-samples/dropdown-insert-image.pug
  */

  /**
  * Dropdown - Text size:
  * `.text-editor__vertical-menu`
  *
  * The following modifier classes add normal text and heading styles to `.text-editor__menu-item` elements:
  * - `.text-editor__menu-item--body-text`
  * - `.text-editor__menu-item--heading-1`
  * - `.text-editor__menu-item--heading-2`
  * - `.text-editor__menu-item--heading-3`
  * - `.text-editor__menu-item--heading-4`
  * - `.text-editor__menu-item--heading-5`
  * - `.text-editor__menu-item--heading-6`
  *
  *     @example
  *     include ./code-samples/dropdown-text-size.pug
  */
  // Remove the parent selector .text-editor so that it can be placed in a .dropdown
  @at-root .text-editor__vertical-menu {
    @include element('menu-item') {
      @include modifier('body-text') {
        @include rem(font-size, $base-font-size);
        font-weight: $regular;
        text-transform: initial;
      }

      @include modifier('heading-1') {
        @include h1;
        text-transform: initial;
      }

      @include modifier('heading-2') {
        @include h2;
        text-transform: initial;
      }

      @include modifier('heading-3') {
        @include h3;
        text-transform: initial;
      }

      @include modifier('heading-4') {
        @include h4;
        text-transform: initial;
      }

      @include modifier('heading-5') {
        @include h5;
        text-transform: initial;
      }

      @include modifier('heading-6') {
        @include h6;
      }
    }
  }

  /**
  * Popover - Insert link:
  *
  * A [popover](blocks-popover.html) to insert a link in the visual editor. It can be use on plain text or images.
  *
  * <div class="alert alert--warning top-margin">
  *   <svg role="img" aria-labelledby="popover-insert-link-alert-icon" focusable="false" class="alert__icon icon">
  *     <title id="popover-insert-link-alert-icon">Warning</title>
  *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
  *   </svg>
  *   <strong>Focus and keyboard</strong>
  *   <p>
  *     Implement the focus and keyboard interaction of this element as described in the section
  *     <a href="#accessibility">Accessibility / Keyboard Interaction / Popovers and dropdowns</a>.
  *   </p>
  * </div>
  *
  *     @example
  *     include ./code-samples/popover-insert-link.pug
  *
  */

  /**
  * Popover - Edit link:
  *
  * A [popover](blocks-popover.html) to edit a link in the visual editor. It can be use on plain text or images.
  *
  * <div class="alert alert--warning top-margin">
  *   <svg role="img" aria-labelledby="popover-edit-link-alert-icon" focusable="false" class="alert__icon icon">
  *     <title id="popover-edit-link-alert-icon">Warning</title>
  *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
  *   </svg>
  *   <strong>Focus and keyboard</strong>
  *   <p>
  *     Implement the focus and keyboard interaction of this element as described in the section
  *     <a href="#accessibility">Accessibility / Keyboard Interaction / Popovers and dropdowns</a>.
  *   </p>
  * </div>
  *
  *     @example
  *     include ./code-samples/popover-edit-link.pug
  */

  /**
  * Popover - Text link actions:
  *
  * A [popover](blocks-popover.html) displaying a set of actions on existing text links: open link, edit link and unlink.
  *
  * <div class="alert alert--warning top-margin">
  *   <svg role="img" aria-labelledby="popover-text-link-actions-alert-icon" focusable="false" class="alert__icon icon">
  *     <title id="popover-text-link-actions-alert-icon">Warning</title>
  *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
  *   </svg>
  *   <strong>Focus and keyboard</strong>
  *   <p>
  *     Implement the focus and keyboard interaction of this element as described in the section
  *     <a href="#accessibility">Accessibility / Keyboard Interaction / Popovers and dropdowns</a>.
  *   </p>
  * </div>
  *
  *     @example
  *     include ./code-samples/popover-text-link-actions.pug
  */

  /**
  * Popover - Image link actions:
  *
  * A [popover](blocks-popover.html) displaying a set of actions on existing image links: open link, edit link, unlink and delete image.
  *
  * <div class="alert alert--warning top-margin">
  *   <svg role="img" aria-labelledby="popover-image-link-actions-alert-icon" focusable="false" class="alert__icon icon">
  *     <title id="popover-image-link-actions-alert-icon">Warning</title>
  *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
  *   </svg>
  *   <strong>Focus and keyboard</strong>
  *   <p>
  *     Implement the focus and keyboard interaction of this element as described in the section
  *     <a href="#accessibility">Accessibility / Keyboard Interaction / Popovers and dropdowns</a>.
  *   </p>
  * </div>
  *
  *     @example
  *     include ./code-samples/popover-image-link-actions.pug
  */

  /**
  * Popover - Delete image:
  *
  * A [popover](blocks-popover.html) to remove an existing image.
  *
  * <div class="alert alert--warning top-margin">
  *   <svg role="img" aria-labelledby="popover-delete-image-alert-icon" focusable="false" class="alert__icon icon">
  *     <title id="popover-delete-image-alert-icon">Warning</title>
  *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
  *   </svg>
  *   <strong>Focus and keyboard</strong>
  *   <p>
  *     Implement the focus and keyboard interaction of this element as described in the section
  *     <a href="#accessibility">Accessibility / Keyboard Interaction / Popovers and dropdowns</a>.
  *   </p>
  * </div>
  *
  *     @example
  *     include ./code-samples/popover-delete-image.pug
  */

  /**
  * Image loading:
  * `.is-loading`
  *
  * Add a subtle animation to the preview of an image that is loading in the visual editor.
  *
  *     @example
  *     .text-editor
  *       img.is-loading(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/img-h-1.png' width='352' height='198' alt='Loading image…')
  */

  .is-loading {
    animation: 'opacity-loop' 1.8s linear infinite alternate;
  }
}

/**
 * Use case 1:
 * A text editor inside of a card and a tabs component.
 *
 *     @example
 *     include ./code-samples/use-case-1.pug
 *
 */

/**
 * Use case 2:
 * A text editor inside of a modal and a tabs component.
 *
 *     @example
 *     include ./code-samples/use-case-2.pug
 *
 */

/**
 * ## Deprecations
*/

/**
 * Color swatches with radio inputs:
 *
 * Color swatches with `input[type=radio]` are deprecated. Backwards compatibility will be removed in a future version of PDUI.
 *
 * We recommend to [replace `input[type=radio]` with `button`](#text-editor-color-swatches) as soon as possible
 *
 *     @example
 *     include ./code-samples/shared/color-swatch.pug
 *     include ./code-samples/shared/color-swatch-input.pug
 *     p.bold.bottom-margin.bottom-margin--small ❌ Old code (deprecated):
 *     |
 *     |
 *     .text-editor
 *       .cells.cells--no-wrap.cells--half-spaced.cells--justify-start(role='radiogroup' aria-label='Color swatches')
 *         +swatch-input(2, '4A4A4A', 'Dark grey', true)
 *         +swatch-input(2, '1B7F66', 'Green')
 *         +swatch-input(2, '1374B4', 'Blue')
 *         +swatch-input(2, 'DC0C22', 'Red')
 *         +swatch-input(2, 'CF4B0E', 'Orange')
 *         +swatch-input(2, '6000FE', 'Violet')
 *     p.bold.top-margin.top-margin--large.bottom-margin.bottom-margin--small ✅ New code:
 *     |
 *     |
 *     .text-editor
 *       .cells.cells--no-wrap.cells--half-spaced.cells--justify-start(role='radiogroup' aria-label='Color swatches')
 *         +swatch(2, '4A4A4A', 'Dark grey', true)
 *         +swatch(2, '1B7F66', 'Green')
 *         +swatch(2, '1374B4', 'Blue')
 *         +swatch(2, 'DC0C22', 'Red')
 *         +swatch(2, 'CF4B0E', 'Orange')
 *         +swatch(2, '6000FE', 'Violet')
*/
