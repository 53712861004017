@mixin unstyled-list {
  margin: 0;
  padding-left: 0;
  list-style-type: none;

  li {
    margin-left: 0;
    position: relative;

    &::before {
      /*
        add zero-width space to fix unstyled-list not being announced with VoiceOver
        https://unfetteredthoughts.net/2017/09/26/voiceover-and-list-style-type-none/
      */
      content: '\200B';
      position: absolute;
    }
  }
}

@mixin inline-list {
  display: flex;
  flex-wrap: wrap;

  li {
    padding: $ukg-spacing-xxs $ukg-spacing-xs $ukg-spacing-xxs 0;
  }
}

@mixin unpadded-list {
  li {
    padding: 0;
  }
}
