/***
 * # Header
 *
 * The structural horizontal bar that is placed at the top of the page.
 *
 * See the design pattern pages for an example of use cases.
 *
 * ## Position and style
 *
 * - There can only be one `header` element per page
 * - The element `.header` must be the first direct child of `.layout__content-container`
 *
 * ## Accessibility
 *
 * - Hide the decorative element `.header__arrow` to assistive technologies with `aria-hidden="true"`
 * - Use a `nav` element when the header contains a group of [breadcrumb navigation links](#header-with-breadcrumbs-links)
 *
 * ```html
 * <nav class="header__breadcrumb" aria-label="Breadcrumb">
 *   <!-- One or more .header__surtitle links -->
 * </nav>
 * ```
 *
 * ## Deprecation warnings
 *
 * - The HTML markup of the `.header` component has been updated to improve the accessibility of the breadcrumb navigation. Please update to the new HTML code as soon as possible.
 *
 * **Old code:**
 *
 * ```html
 * <header class="header" role="banner">
 *   <div class="header__container container">
 *     <div class="header__cells cells cells--align-middle">
 *       <div class="cells__cell">
 *          <a class="header__surtitle" href="#">Link 1</a>
 *          <span class="header__arrow"></span>
 *          <a class="header__surtitle" href="#">Link 2</a>
 *          <span class="header__arrow"></span>
 *          <h1 class="header__title">Page title</h1>
 *       </div>
 *       …
 *     </div>
 *   </div>
 * </header>
 * ```
 *
 * **New code:**
 *
 * <div class="alert alert--warning top-margin">
 *   <strong>Important:</strong>&nbsp;
 *   Add the <code>nav</code> wrapper only if `.header__surtitle` elements are links.
 * </div>
 *
 * ```html
 * <header class="header" role="banner">
 *   <div class="header__container container">
 *     <div class="header__cells cells cells--align-middle">
 *       <div class="cells__cell">
 *         <nav class="header__breadcrumb" aria-label="Breadcrumb"> <!-- New <nav> wrapper -->
 *           <a class="header__surtitle" href="#">Link 1</a>
 *           <span class="header__arrow" aria-hidden="true"></span>
 *           <a class="header__surtitle" href="#">Link 2</a>
 *           <span class="header__arrow" aria-hidden="true"></span>
 *         </nav>
 *         <h1 class="header__title">Page title</h1>
 *       </div>
 *       …
 *     </div>
 *   </div>
 * </header>
 * ```
 *
 */

@mixin link-color-on-dark-header {
  color: var(--horizontal-menu-link-color);

  &:hover,
  &:focus,
  &:active {
    color: var(--horizontal-menu-link-color-hover);
    background-color: var(--horizontal-menu-link-color);
  }
}

$dark-header-bg-color:       $topbar-background;
$on-dark-header-color:       $on-topbar-background;
$header-arrow-size:          20px;
$sidebar-toggler-size:       24px;
$header-cell-margin:         10px;
$header-logo-max-width:      160px;

/**
 * Basic header:
 *
 * `.header`
 *
 * The page header
 *
 *   @example
 *   include ./code-samples/basic-header.pug
 */

@include block('header') {
  position: relative;
  background-color: $header-bg-color;
  color: $font-color-on-primary;
  flex-shrink: 1; // Works in combination with .layout__content-container {display: flex; flex-direction: column;}

  @include mixie('container') {
    display: flex;
    align-items: center;
    padding: rem(21px);
  }

  @include element('sidebar-toggler') {
    @include seamless-button($icon-size: $sidebar-toggler-size);
    display: inline-flex;
    flex: none;
    margin-right: rem($header-cell-margin);

    .icon--white {
      color: $font-color-on-primary;
    }

    &.shake {
      animation-name: 'shake-horizontal';
      animation-duration: 1.5s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
    }
  }

  @include element('sidebar-notifier') {
    position: absolute;
    bottom: rem(9px);
    right: rem(9px);
    height: rem(9px);
    width: rem(9px);
    border: rem(2px) solid $white;
    box-shadow: 0 0 0 rem(1px) color(primary, mid);
    border-radius: 50%;
    pointer-events: none;
    background-color: color(danger, dark);

    .layout--sidebar-expanded &,
    .layout--sidebar-revealed & {
      display: none;
    }
  }

  @include mixie('cells') {
    flex: auto;

    .cells__cell:not(:last-child) {
      margin-right: rem($header-cell-margin);
    }
  }

  &:not(.header--dark) {
    .button:not(.button--on-primary) {
      // always display the appropiate style on hover, active and focus
      // even if the modifier class `.button--on-primary` is not used
      @include on-color-button('primary');
    }

    .header__sidebar-toggler::before {
      border-color: currentColor;
    }
  }

  /**
   * Header with breadcrumbs (links):
   *
   * On smaller screens the breadcrumb and header title font size will be reduced.
   *
   * <div class="card card--standout top-margin">
   *   <div class="card__body">
   *     <p>
   *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title1" focusable="false">
   *         <title id="title1">Information</title>
   *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *       </svg>
   *       <strong>Accessibility</strong>
   *     </p>
   *     <p>
   *       Make sure to use the <code>nav</code> tag with the attribute <code>aria-label="Breadcrumb"</code> for the breadcrumb when it contains links.
   *       That way screen reader users will be informed that the element provides navigation links.
   *     </p>
   *   </div>
   * </div>
   *
   *     @example
   *     include ./code-samples/header-with-breadcrumbs.pug
   */
  @include element('breadcrumb') {
    display: inline;
  }

  @include element('title') {
    @include h2;
    display: inline;
    font-weight: $regular;
    color: inherit;
    margin: 0;
  }

  @include element('surtitle') {
    @include h2;
    opacity: 0.8;
    font-weight: $regular;
    color: $font-color-on-primary;
  }

  a {
    &.header__surtitle {
      @include on-color-link('primary');
      @include improved-link-underline($header-bg-color);

      &:hover,
      &:focus,
      &:active {
        opacity: 1;
      }
    }
  }

  @include element('arrow') {
    @include h2;
    margin-left: rem(2px);

    &::after {
      opacity: 0.8;
      content: '\203A\0000a0';
    }
  }

  .header__arrow ~ .header__surtitle {
    margin-left: rem(-6px);
  }

  /**
   * Header with breadcrumbs (no links):
   *
   * On smaller screens the breadcrumb and header title font size will be reduced.
   *
   * <div class="card card--standout top-margin">
   *   <div class="card__body">
   *     <p>
   *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title1" focusable="false">
   *         <title id="title1">Information</title>
   *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *       </svg>
   *       <strong>Accessibility</strong>
   *     </p>
   *     <p>
   *       Don't use a <code>nav</code> tag for the breadcrumb when there are no links inside.
   *     </p>
   *   </div>
   * </div>
   *
   *     @example
   *     include ./code-samples/header-with-breadcrumbs-no-links.pug
   */

  /**
   * Header with sidebar toggle button :
   *
   * If a sidebar is used, and the relevant sidebar toggler HTML has been added, a sidebar toggle button (burger icon) will display on the left-hand side of the header title.
   *
   * The toggle button will hide if the viewport width is equal or more than 1024px, and the modifier [`.layout--no-minified-sidebar`](../docs/blocks-layout-v2.html#no-minified-sidebar) is used.
   *
   * PREVIEW : [Responsive page with sidebar](../templates/responsive-with-sidebar.html)
   */

  /**
   * Sidebar toggle button notifications :
   *
   * If the sidebar contains notification [badges](../docs/blocks-badge.html) then the sidebar toggle button will display a colored dot.
   *
   * PREVIEW : [Responsive page with sidebar](../templates/responsive-with-sidebar.html)
   */

  /**
   * Dark header:
   *
   * `.header--dark`
   *
   * A dark header.
   *
   * <div class="alert top-margin">
   *   <svg role="img" aria-labelledby="dark-header-alert" focusable="false" class="icon alert__icon">
   *     <title id="dark-header-alert">Information</title>
   *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *   </svg>
   *   <strong>PDUI themes</strong>
   *   <p>Despites its name <code>--dark</code>, this modifier takes the same background color as the <a href="../docs/blocks-horizontal-menu.html">horizontal menu</a> component
   *   when used in a <a href="../docs/base-colors.html#themes">custom PDUI theme</a>. The horizontal menu color in a custom theme can be dark, or light.</p>
   * </div>
   *
   *     @example
   *     include ./code-samples/dark-header.pug
   */
  @include modifier('dark') {
    background-color: $dark-header-bg-color;
    color: $on-dark-header-color;

    @include element('surtitle') {
      color: $on-dark-header-color;
    }

    @include element('sidebar-notifier') {
      box-shadow: 0 0 0 rem(1px) $dark-header-bg-color;
    }

    a {
      &.header__surtitle {
        @include link-color-on-dark-header;
        @include improved-link-underline($dark-header-bg-color);
      }
    }

    .button:not(.button--on-dark-grey) {
      // always display the appropiate style on hover, active and focus
      // even if the modifier class `.button--on-dark-grey` is not used
      @include on-dark-grey-button;
    }
  }

  /**
   * Thin header:
   *
   * `.header--thin`
   *
   * A flexible height header with thin padding.
   *
   *   @example
   *   include ./code-samples/thin-header.pug
   */
  @include modifier('thin') {
    @include mixie('container') {
      padding-top: $ukg-spacing-s;
      padding-bottom: $ukg-spacing-s;
    }
  }

  /**
   * Header with a max-width:
   *
   * Using the [container](../docs/helpers-container.html) layout helper
   *
   * `.container.container--small`
   *
   * `.container.container--medium`
   *
   * `.container.container--large`
   *
   * `.container.container--extra-large`
   *
   *   @example
   *   include ./code-samples/header-max-width.pug
   */

  /**
   * Header logo:
   *
   * `.header__logo`
   *
   * A header with a logo.
   *
   * <div class="alert alert--warning top-margin">
   *   <svg role="img" aria-labelledby="xxx" focusable="false" class="icon alert__icon">
   *     <title id="xxx">Warning</title>
   *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
   *   </svg>
   *   <strong>Important</strong>
   *   <p>The header elements <code>title</code> and <code>surtitle</code> must not be direct children of <code>.header__container</code>.</p>
   *   <p>Always place them in the corresponding <code>.header__cells > .cells__cell</code> even if there are no other cells present.</p>
   * </div>
   *
   *     @example
   *     include ./code-samples/header-with-logo.pug
   */
  @include element('logo') {
    max-width: rem($header-logo-max-width);
  }
}

@media not all and (max-width: $tablet) {
  .layout--no-minified-sidebar {
    .header__sidebar-toggler {
      display: none;
    }
  }
}

@media all and (max-width: $tablet) {
  .layout--no-minified-sidebar.layout--semi-responsive {
    .header__sidebar-toggler {
      display: none;
    }
  }
}

// Reduce font size on responsive layouts
@media screen and (max-width: $breadcrumbs-threshold) {
  .layout:not(.layout--semi-responsive) {
    .header__title,
    .header__surtitle,
    .header__arrow {
      font-size: rem(18px);
    }
  }
}
