/***
 * # Inputs
 *
 * A form input, with an optional placeholder.
 *
 * ## When to use inputs
 *
 * Inputs should be used for collecting short-form information.
 * Inputs should always be paired with a label to show the user what content
 * to submit. [Placeholders should be avoided](https://www.nngroup.com/articles/form-design-placeholders/), unless in the search component, or if absolutely necessary.
 *
 * ## When not to use inputs
 *
 * Do not use inputs for long form information - use a textarea instead.
 * Do not use inputs for date information - use a datepicker instead.
 *
 * ## Position and style
 *
 * Inputs can optionally be styled with `dark`, `valid` and `invalid` modifiers.
 * The dark modifier should be applied when the input is placed directly against
 * the page background. The invalid and valid modifiers should be applied
 * to indicate data validity.
 */

/*
  Variables can be found in src/settings/_form.scss
  Mixins can be found in src/tools/_form.scss
*/

/**
 * Input field:
 *
 * `.input`
 *
 * Just a standard input.
 *
 *   @example
 *   input.input.right-margin.right-margin--tiny
 *   button.button Submit
 */
.input {
  @include form-field;
  @include rem(width, $form-field-width);
  @include rem(max-width, 100%);

  /**
   * Disabled input:
   *
   * `.input[disabled]`
   *
   * A disabled input.
   *
   *   @example
   *   input.input(disabled)
   *
   */
  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    @include disabled;
  }

  /**
   * Large input field:
   *
   * `.input--large`
   *
   * A large input.
   *
   *   @example
   *   input.input.input--large.right-margin.right-margin--tiny
   *   button.button.button--large Submit
   */
  @include modifier('large') {
    @include large-field;
  }

  /**
   * Short input field:
   *
   * `.input--short`
   *
   * A short input.
   *
   *   @example
   *   input.input.input--short
   */
  @include modifier('short') {
    @include rem(width, $short-form-field-width);
  }

  /**
   * Medium input field:
   *
   * `.input--medium`
   *
   * A medium input.
   *
   *   @example
   *   input.input.input--medium
   */
  @include modifier('medium') {
    @include rem(width, $medium-form-field-width);
  }

  /**
   * Long input field:
   *
   * `.input--long`
   *
   * A long input.
   *
   *   @example
   *   input.input.input--long
   */
  @include modifier('long') {
    @include rem(width, $long-form-field-width);
  }

  /**
   * Full width input field:
   *
   * `.input--full-width`
   * A full width (block) input.
   *
   *   @example
   *   input.input.input--full-width
   */
  @include modifier('full-width') {
    display: block;
    width: 100%;
  }

  /**
   * Input width helper:
   *
   * If you want the width of an item to be the same width as an input, use the [input-width](../docs/helpers-width.html#input-width) helper.
   */

  /**
   * Dark input field:
   *
   * `.input--dark`
   *
   * A dark input (for using on grey backgrounds).
   *
   *   @example
   *   input.input.input--dark
   */
  @include modifier('dark') {
    @include dark-form-field;
  }

  /**
   * Valid input field:
   *
   * `.input--valid`
   *
   * A valid input.
   *
   *   @example
   *   input.input.input--valid
   */
  @include modifier('valid') {
    @include valid-form-field;
  }

  /**
   * Invalid input field:
   *
   * `.input--invalid`
   *
   * An invalid input.
   *
   *   @example
   *   input.input.input--invalid
   */
  @include modifier('invalid') {
    @include invalid-form-field;
  }

  /**
   * Autosizing Input:
   *
   * `textarea.input.input--autosize`
   *
   * A `textarea` that initially looks like an `input` field. Its height will expand to fit its content.
   *
   * All of the other input modifiers (e.g. `valid`, `invalid`) can be combined with this modifier.
   *
   * <div class="alert alert--danger top-margin">The HTML tag must be a <strong>&lt;textarea/&gt;</strong> instead of an <strong>&lt;input/&gt;</strong>.<br><br>A <strong>[rows="1"]</strong> attribute must also be added to the <strong>&lt;textarea/&gt;</strong> element.<br><br>This modifier must be combined with either the <br><strong>$(textarea).('autosize')</strong> method or the <strong>[data-autosize="true"]</strong> attribute (see [here](../docs/blocks-textarea.html)).</div>
   *
   *   @example
   *   textarea#input-autosize-demo.input.input--autosize(rows='1' placeholder='Hit enter to see another row')
   *   script.
   *     $('#input-autosize-demo').textarea('autosize');
   *
   */
  @include modifier('autosize') {
    line-height: 1.4;
    @include rem(padding, 6px 5px);
    @include rem(min-height, 32px);
    resize: vertical;

    @include and-modifier('large') {
      @include rem(padding, 9px 5px 7px);
      @include rem(min-height, 40px);
    }
  }
}
