/***
 * # Badges
 *
 * Badges highlight important pieces of text.
 *
 * ## When to use badges
 *
 * [Basic badges](#basic-badge):
 *  - To draw attention to interactive components, such as [tabs](../docs/blocks-tabs.html) and
 *  [vertical menus](../docs/blocks-vertical-menu-v2.html). For example, a badge with `3` on the 'workplace'
 *  menu link could indicate there are three outstanding items to process.
 *
 * [Non-interactive badges](#non-interactive-badge):
 *  - To indicate categories or tags
 *
 * ## When not to use badges
 *
 *  - To display more than a few words or numbers
 *  - To add color to an interface
 *
 * ## Position and style
 *
 * - Basic badges should be used within interactive elements, or components
 * - Non-interactive badges can be positioned anywhere in the user interface
 * - Non-interactive badges can be combined with any of the icons available in the [iconography library](../docs/blocks-icon.html)
 * - Color variations can be used as a progressive enhancement that draws the eye, but it should not be used
 * in a way that is critical to the meaning of the badge
 *
 * ## Content
 *
 * - If a [basic badge](#basic-badge) should contain a number equal or greater than `100`, use `99+` instead.
 *
 * ## Accessibility
 *
 * **HTML structure**
 *
 * - A group of badges must always be appropriately structurated within an HTML `<ul>` element.
 * - Use the classes `.unstyled-list.inline-list` to remove the default styling of the list element and display the badges inline.
 * - See [“use case: list of badges”](#use-case-list-of-badges) for an example.
 *
 * **Text alternatives**
 *
 * - In some cases, such as numeric badges in [vertical menus](../docs/blocks-vertical-menu-v2.html#vertical-menu-with-badge) or [tabs](../docs/blocks-tabs.html#tabs-with-badges), it is recommended to use `<span class="sr-only"> { Placeholder text } </span>` to give context to the value that badges indicate.
 * Adding descriptive text allows for users with assistive technologies to understand what badges indicate.
 * - See [tabs with badges](../docs/blocks-tabs.html#tabs-with-badges) for an example.
 *
 * ## Deprecation warnings:
 * - [The class `.badge--small` has been deprecated](#small-badge)
 * - [The class `.badge--transparent` has been deprecated](#transparent-badge)
 * - [The classes `.badge--{mycolor}-light` have been deprecated](#light-colored-badge)
 */

/**
 * Basic badge:
 * `.badge`
 *
 * Use basic badges to draw attention to interactive components such as [vertical menus](../docs/blocks-vertical-menu-v2.html#vertical-menu-with-badge)
 * or [tabs](../docs/blocks-tabs.html#tabs-with-badges).
 *
 * In other uses cases, see [non-interactive badges](#non-interactive-badge).
 *
 *     @example
 *     include ./code-samples/basic-badge.pug
 */
@include block('badge') {
  @include rem(font-size, $small-font-size);
  display: inline-flex;
  vertical-align: middle;
  font-family: $metadata-font;

  &:not(.badge--non-interactive) {
    @include rem(border-radius, 9px);
    @include rem(padding, 1px 4px);
    @include rem(line-height, 14px);
    @include rem(min-width, 16px);
    justify-content: center;
    background-color: color(grey, 3);
    font-weight: $bold;
    color: $white;
    text-transform: uppercase; // Support old usages of badges with text
  }

  /**
   * Colored badge:
   * `.badge.badge--mycolor`
   *
   * <div class="card card--standout top-margin">
   *   <div class="card__body">
   *     <p>
   *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title1" focusable="false">
   *         <title id="title1">Information</title>
   *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *       </svg>
   *       <strong>Accessibility</strong>
   *     </p>
   *     <p>Do not use color in a way that is critical to the meaning of the badge.
   *     In this component, use color as a progressive enhancement that draws the eye.
   *     Color changes are not always perceived by people with color vision deficiencies.</p>
   *   </div>
   * </div>
   *
   *     @example
   *     include ./code-samples/colored-badge.pug
   */
  @each $color in $color-badges {
    @include modifier($color) {
      $background-color: color($color, deep);

      @if $color == 'primary' {
        $background-color: var(--theme-background-color);
        color: var(--font-color-on-primary);
      }
      background-color: $background-color;
    }
  }

  /**
   * Badge on dark background:
   * `.badge--on-dark`
   *
   * A badge with the colors inverted to be used on dark backgrounds.
   *
   *     @example
   *     include ./code-samples/badge-dark-background.pug
   */
  @include modifier('on-dark') {
    // Extended in dark vertical-menu and dark horizontal-menu
    color: color(grey, 3);
    background-color: $white;

    @each $color in $color-badges {
      &.badge--#{$color} {
        color: color($color, deep);
      }
    }
  }

  /**
   * Non-interactive badge:
   * `.badge--non-interactive > .badge__icon`
   *
   * Non-interactive badges always have [an icon](../docs/block-icon.html) and a textual label. Use color and shape together for different badge types.
   * This ensures badges are useful to people with color vision deficiencies.
   *
   * Use [colored icon modifier classes](../docs/blocks-icon.html#colored-icons) to add color to the icon.
   *
   * <div class="alert alert--info top-margin">
   *   <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title2" focusable="false">
   *     <title id="title2">Information</title>
   *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *   </svg>
   *   The use of icons and labels in these examples is for **demostrational purposes**.
   *   Non-interactive badges can be combined with any of the icons available in the [iconography library](../docs/blocks-icon.html) depending on the use case.
   * </div>
   *
   *     @example
   *     include ./code-samples/non-interactive-badge.pug
   *
   */
  @include modifier('non-interactive') {
    @include rem(line-height, 18px);

    @include mixie('icon') {
      @include rem(width, 18px);
      @include rem(height, 18px);
      @include rem(margin-right, 4px);
      flex: none;
    }

    @include mixie('label') {
      flex: 1 0 auto; // Needed in IE11 so that text wraps as expected
    }
  }

  /**
   * Non-interactive badge with a dot:
   * `.badge--non-interactive > .badge__icon`
   *
   * In cases where an icon doesn't make sense for a badge, use different colored `dot` icons.
   * Keep in mind, they will be harder to scan for people with color vision deficiencies.
   *
   * Use [colored icon modifier classes](../docs/blocks-icon.html#colored-icons) to add color to the dot icon.
   *
   *     @example
   *     include ./code-samples/non-interactive-dot-badge.pug
   *
   */

  /**
   * Muted badge:
   * `.badge--muted`
   *
   * Remove the color of the icon and reduce the opacity.
   * It is useful to indicate that a badge is inactive, and it helps to draw the attention to other active badges in the same group.
   *
   *     @example
   *     include ./code-samples/muted-badge.pug
   */
  @include modifier('muted') {
    @include muted;

    @include mixie('icon') {
      color: inherit;
    }
  }

  /**
   * Use case: list of badges:
   * `ul.unstyled-list.inline-list`
   *
   *     @example
   *     include ./code-samples/list-of-badges.pug
   *
   */

  /**
   * Use case: Vertical menu with badges:
   *
   * The colored dot `.vertical-menu__marker` is used as an indicator that the submenu contains badges.
   *
   *     @example
   *     include ./code-samples/vertical-menu-with-badges.pug
   */

  /**
   * Use case: Header with badge indicator:
   *
   * The colored dot `.header__sidebar-notifier` is used as an indicator that the sidebar navigation contains badges.
   *
   * PREVIEW : [Responsive page with sidebar](../templates/responsive-with-sidebar.html)
   */
}
