$upload-button-max-width: 450px;

@include block('upload-button') {
  font-size: rem($base-font-size);
  max-width: rem($upload-button-max-width);

  @include element('button') {
    display: inline-block;
    position: relative;
  }

  @include mixie('input') {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: index($z-index-scale, base);
    text-indent: -10em; // Moves the blinking caret out of the button in IE11
    cursor: pointer;

    &::-webkit-file-upload-button { // sass-lint:disable-line no-vendor-prefixes
      cursor: pointer;
    }

    &:focus,
    &:hover {
      + .upload-button__display {
        @include std-hover;
      }
    }

    &:focus,
    &:active {
      + .upload-button__display {
        @include focus-outline-pseudo-element($border-radius: $button-border-radius + 4px);
      }
    }

    &:active {
      + .upload-button__display {
        @include std-active;
      }
    }

    &:disabled {
      cursor: not-allowed;

      &::-webkit-file-upload-button { // sass-lint:disable-line no-vendor-prefixes
        cursor: not-allowed;
      }

      + .upload-button__display {
        @include std-disabled;
      }
    }
  }

  @include element('display') {
    @include std-button;
  }

  @include element('list') {
    @include unstyled-list;

    > * {
      margin-top: $ukg-spacing-xs;
    }
  }

  @include mixie('alert') {
    margin-top: $ukg-spacing-s;
  }
}
