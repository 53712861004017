/***
 * # Icons
 *
 * Icons are interface markers that draw attention and provide contextual help.
 *
 * - See all [feature level icons](#feature-level-icons)
 * - See all [ultility icons](#utility-icons)
 *
 * ## When to use icons
 *
 *  - To mark an important link or interface element
 *  - To provide additional, contextual information
 *
 * ## When not to use icons
 *
 * Overuse of inappropriate icons is a major UX problem.
 *
 * - If it is not extremely clear what the icon means, then don't use one. Using a misleading icon can confuse the user.
 * - If you are already using the icon for one interface item, then don't reuse it for something else. An individual icon should only have one meaning.
 *
 * Note: If you need an icon that is not provided here, we may need to create a new one.
 *
 * ## Position and style
 *
 * Icons can be positioned inside other components (see: alerts, buttons) or next to text.
 *
 * There are three 'neutral' colored icons to use against different backgrounds - standard (no modifier), light-grey and white.
 *
 * Outside of these three options, icons should only be colored where it adds meaning;
 * for example, a red (danger) icon next to an error message, or a green (success) icon next to a confirmation message.
 *
 * If you want to color an icon to make an interface 'pop'- think again - there may be a better solution.
 *
 * ## Accessibility
 *
 * We distinguish between two types of SVG icons:
 *
 * __1. Decorative/illustrative SVGs__
 *
 *  - An SVG icon is decorative/illustrative when content or information **is not lost** if the icon is not displayed.
 *  - In such cases, simply use `aria-hidden="true"` on the `<svg>` element.
 *
 * Examples: [Vertical menu with icons](blocks-vertical-menu-v2.html#vertical-menu-with-icons) and [button with icon](blocks-button.html#button-with-an-icon)–here the icon can be treated as decorative because the `button` has a descriptive `aria-label` attribute.
 *
 * __2. Informative SVGs__
 *
 *  - An SVG icon is informative when content or information **is lost** if the icon is not displayed.
 *  - In such cases, make use of the `<title>` element and `aria-labelledby`, `id` and `role="img"` attributes, as follows:
 *
 * ```html
 * <svg class="alert__icon icon" role="img" focusable="false" aria-labelledby="uniqueID">
 *   <title id="uniqueID">Warning</title>
 *   <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
 * </svg>
 * ```
 *
 * Example: [Alert with icon](blocks-alert.html#alert-with-icon).
 *
 * ## Cross-browser compatibility
 *
 * To make sure that the SVG icons work consistently across browsers, please consider the following:
 * - Add the attribute `focusable="false"` attribute to the `<svg>` element. This will prevent a bug on IE whereby `<svg>` elements are focusable by default.
 * - Use both attributes, `xlink:href` and `href`, as `href` is not yet supported in Safari. For the time being, we use both attributes to maximize cross-browser compatibility
 *
 */

/*
  List the icon colors you want to generate
  If you change this list, make sure you also update the example docs.
*/
$icon-colors: 'primary', 'warning', 'danger', 'success', 'info';

/**
 * Basic icon:
 *
 * `.icon`
 *
 *   @example
 *   svg.icon(role='img' aria-labelledby='icon1-label', focusable='false')
 *     title#icon1-label Document
 *     use(xlink:href='../icons.svg#document' href='../icons.svg#document')
 */
@include block('icon') {
  /*
    ICONS WITH DIFFERENT SIZES

    Some of our SVG icons have two alternative sizes: large and small.

    We use CSS variables to toggle the visibility between the large and the small version in the shadow DOM.

    Browsers that don't support CSS variables will display the small version in all sizes.

    See ../icons/duplicate.svg for an example of the SVG code.
  */
  #{--icon-small-fill}: transparent;
  #{--icon-large-fill}: currentColor;

  @include rem(height, 32px);
  @include rem(width, 32px);
  vertical-align: middle;

  /**
   * Tiny icon:
   *
   * `.icon--tiny`
   *
   *   @example
   *   svg.icon.icon--tiny(role='img' aria-labelledby='icon2-label', focusable='false')
   *     title#icon2-label User
   *     use(xlink:href='../icons.svg#person' href='../icons.svg#person')
   */
  @include modifier('tiny') {
    #{--icon-small-fill}: currentColor;
    #{--icon-large-fill}: transparent;
    @include rem(height, 8px);
    @include rem(width, 8px);
  }

  /**
   * Small icon:
   *
   * `.icon--small`
   *
   *   @example
   *   svg.icon.icon--small(role='img' aria-labelledby='icon3-label', focusable='false')
   *     title#icon3-label User
   *     use(xlink:href='../icons.svg#person' href='../icons.svg#person')
   */
  @include modifier('small') {
    #{--icon-small-fill}: currentColor;
    #{--icon-large-fill}: transparent;
    @include rem(height, 18px);
    @include rem(width, 18px);
  }

  /**
   * Large icon:
   *
   * `.icon--large`
   *
   *   @example
   *   svg.icon.icon--large(role='img' aria-labelledby='icon4-label', focusable='false')
   *     title#icon4-label Inbox
   *     use(xlink:href='../icons.svg#inbox' href='../icons.svg#inbox')
   */
  @include modifier('large') {
    @include rem(height, 64px);
    @include rem(width, 64px);
  }

  /**
   * Colored icons:
   *
   * `.icon--dark-grey`
   *
   * `.icon--primary-dark`
   * `.icon--warning-dark`
   * `.icon--danger-dark`
   * `.icon--success-dark`
   * `.icon--info-dark`
   *
   * `.icon--primary`
   * `.icon--warning`
   * `.icon--danger`
   * `.icon--success`
   * `.icon--info`
   * `.icon--grey`
   * `.icon--light-grey`
   * `.icon--white`
   *
   *   @example
   *   include ./code-samples/colored-icon.pug
   */
  @each $color in $icon-colors {
    @include modifier($color) {
      color: color($color, mid);
    }
    @include modifier(#{$color}-dark) {
      color: color($color, dark);
    }
  }

  @include modifier('white') {
    color: $white;
  }

  @include modifier('grey') {
    color: color(grey, 4);
  }

  @include modifier('light-grey') {
    color: color(grey, 5);
  }

  @include modifier('dark-grey') {
    color: $dark-font-color;
  }

  /**
   * On primary icon:
   *
   * `.icon--on-primary`
   *
   * Useful when the icon is placed on a primary color background.
   *
   * This modifier is relevant for [theming](base-colors.html#themes): if the primary
   * color is dark, the icon will be white. If it is light, the icon will be dark grey.
   * **It helps you to ensure an accessible color contrast**.
   *
   *   @example
   *   include ./code-samples/icon-on-primary-background.pug
   */

  @include modifier('on-primary') {
    color: $font-color-on-primary;
  }

  /**
   * Spinning icon:
   *
   * `.icon--spin`
   *
   *     @example
   *     svg.icon.icon--spin(role='img' aria-labelledby='icon5-label', focusable='false')
   *       title#icon5-label Spinner
   *       use(xlink:href='../icons.svg#spinner' href='../icons.svg#spinner')
   */
  @include modifier('spin') {
    animation-name: spin-clockwise;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  /**
   * Icon flipped vertically:
   *
   * `.icon--flipped-vertical`
   *
   *   @example
   *   include ./code-samples/icon-flipped-vertically.pug
   */
  @include modifier('flipped-vertical') {
    transform: scaleY(-1);
  }

  /**
   * Icon flipped horizontally:
   *
   * `.icon--flipped-horizontal`
   *
   *   @example
   *   include ./code-samples/icon-flipped-horizontally.pug
   */
  @include modifier('flipped-horizontal') {
    transform: scaleX(-1);
  }

  /**
   * Text color icon:
   *
   * `.icon-text-color`
   *
   * A wrapper that contains two `svg`:
   * 1. `text-color`
   * 2. `color-filled`
   *
   * <div class="alert top-margin">
   *   <svg role="img" aria-labelledby="icon-text-color-1" focusable="false" class="icon alert__icon">
   *     <title id="icon-text-color-1">Information</title>
   *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *   </svg>
   *   <p>The color of the drop can be changed using <a href="#colored-icons">color modifiers</a> or inline CSS with a specific color code.</p>
   *   <p>E.g.: <code>style="color:#E3023F"</code></p>
   * </div>
   *
   *   @example
   *   include ./code-samples/icon-text-color.pug
   */
  @at-root .icon-text-color {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    @include block('icon') {
      &:last-child {
        position: absolute;
        width: 62.5%;
        height: 62.5%;
        right: 0;
        bottom: 0;
      }
    }
  }
}

/**
 * Feature level icons:
 *
 *     @example
 *     include ./code-samples/feature-icons.pug
 */

/**
 * Utility icons:
 *
 * <div class="alert top-margin">
 *   <strong>Warning</strong>
 *   <p>The icons <code>view-list</code> and <code>view-grid</code> are meant to be used associated one with another one. They should not be used as standalone icons.</p>
 * </div>
 *
 *   @example
 *   include ./code-samples/utility-icons.pug
 */

/**
 * Icons with text:
 *
 *     @example
 *     include ./code-samples/icon-with-text.pug
 */
