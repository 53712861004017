/***
 *
 * <div class="alert alert--warning bottom-margin">
 *   <svg class="alert__icon icon" aria-hidden="true" focusable="false">
 *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning">
 *   </svg>
 *   <p class="h4">Deprecation warning</p>
 *   <p>The accordion component has been deprecated and it will be deleted in a future version of PDUI.</p>
 *   <p>Please, migrate to <a href="../docs/blocks-expansion-panel.html">expansion-panel</a>.</p>
 * </div>
 *
 * # Accordions
 *
 * An accordion is a block that presents information in a space-efficient format. It puts users in control of
 * revealing or hiding additional information when expanding or collapsing it.
 *
 * ## When to use accordions
 *
 * - When some extra information is available but not essential
 * - When you want to progressively disclose data
 * - When the space is limited, screen is cluttered or to simplify an interface
 * - When [modals](../docs/blocks-modal.html) or [popovers](../docs/blocks-popover.html) are either not possible or not relevant
 *
 * ## When not to use accordions
 *
 * - When the information is critical. Do not use it to hide error messages or any other information
 * that requires immediate action
 * - Avoid “nested” accordions, that is collapsible content within collapsible content
 *
 * ## Position and style
 *
 * - Accordions can be used within a [modal](../docs/blocks-modal.html), a [card](../docs/blocks-card.html) or a [container](../docs/blocks-container.html)
 * - Accordions can contain a single collapsible item, or multiple collapsible items
 * - In case of multiple accordions, we provide the option to display
 * [“Collapse all” and “Expand all”](#collapse-all--expand-all) buttons which appear
 * as two basic buttons. Use this option depending on:
 *   - The number of modules to expand/collapse
 *   - The relevance according to the use case
 *
 * ## Content
 *
 * - Keep accordion labels as brief as possible while still being clear and descriptive
 * - The title should clearly indicate what the user will see when the content is expanded
 *
 * ## Accessibility
 *
 * - We use the [details](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/details) and [summary](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/summary) HTML elements to provide a semantic disclosure widget that is accessible out of the box
 * - We use a [polyfill](https://github.com/javan/details-element-polyfill) to add the functionality and relevant ARIA roles and states to accordions on [older browsers (IE11)](https://caniuse.com/#feat=details)
 *
* ## Initialization
 *
 * <div class="alert alert--danger top-margin top-margin--small">
 *   <p>This component will auto-instantiate unless it has the <code>[data-no-auto]</code> attribute at page load (DOMContentLoaded) or on <code>init.pdui</code> event recept on an ancestor.<p>
 *   <p>If the component is added to the page dynamically, trigger the <code>init.pdui</code> event on a parent node to instantiate it.</p>
 *   <p>If the component is not instantiated, it will auto-instantiate as soon as a method of that component is called programmatically, regardless of the presence of <code>[data-no-auto]</code>.</p><p>If you don’t set the component to auto-instantiate and you wish to instantiate it manually (pass options to it etc.) do: <br><code>$(el).accordion();</code></p>
 * </div>
 */

/**
 * Accordion:
 *`.accordion`
 *
 * An accordion must consist of one or more `.accordion__item` rows.
 *
 *     @example
 *     include ./code-samples/basic-accordion.pug
 */

$accordion-h-space: 18px;
$accordion-v-space: 16px;
$accordion-toggle-size: 24px;

@include block('accordion') {
  @include element('item') {
    @include rem(border, 1px solid color(grey, 4));

    &:first-of-type {
      @include rem(border-radius, $border-radius-small $border-radius-small 0 0);
    }

    &:last-of-type {
      @include rem(border-radius, 0 0 $border-radius-small $border-radius-small);
    }

    + .accordion__item {
      border-top: 0;
    }

    &[open] {
      @include element('toggle') {
        transform: rotate(180deg);
      }
    }

    // Fix bug from Blink/Webkit
    // https://bugs.chromium.org/p/chromium/issues/detail?id=589475
    // Box-Sizing does not inherit correctly inside <details>
    // So, as workaround we force box-sizing to direct childs.
    > * {
      box-sizing: border-box;
    }
  }

  @include element('summary') {
    position: relative;
    @include rem(padding, $accordion-h-space $accordion-v-space);

    // Remove arrow in Webkit / Blink browsers
    &::-webkit-details-marker {
      display: none;
    }

    // Remove arrow in IE11
    &::before {
      display: none;
    }

    &:hover {
      background-color: color(grey, 5);
    }

    &:focus {
      @include focus-outline-pseudo-element($pseudo-element: after);
    }
  }

  @include element('title') {
    @include rem(font-size, $h4-size);
    @include rem(padding-right, $accordion-toggle-size);
    display: block;
  }

  @include element('toggle') {
    position: absolute;
    top: $accordion-h-space;
    right: $accordion-v-space;
    @include rem(width, $accordion-toggle-size);
    @include rem(height, $accordion-toggle-size);
  }

  @include element('content') {
    @include rem(padding, $accordion-h-space $accordion-v-space);

    > *:first-child {
      margin-top: 0;
    }
  }
}

/**
 * Expanded accordion:
 *`.accordion__item[open]`
 *
 * Add the attribute `open` to present the accodion item initially expanded.
 *
 *     @example
 *     include ./code-samples/expanded-accordion.pug
 */

/**
 * Collapse all / Expand all:
 *
 * Optional buttons to control the state of a multiple accordion as a bundle.
 *
 *     @example
 *     include ./code-samples/accordion-with-collapse-expand-buttons.pug
 */

/**
*
* ### Events
*
* | Event | Triggered by | Occurence |
* |-------|--------------|-----------|
* | change.pdui.accordion | change | after |
*/
