$small:        $wrapper-small;
$medium:       $wrapper-medium;
$large:        $wrapper-large;
$extra-large:  $wrapper-extra-large;

/***
 * # Container
 *
 * A container is, as the name suggests, a helper that contains other content - restricting its minimum and maximum width, adding padding to it, or even centering it.
 *
 * ## When to use a container
 *
 * Containers should be used to construct high level layouts.
 * They can also be used as wrappers for other elements in order to control their width or padding or center them inside their parent.
 * See the [responsive page with no sidebar](../templates/responsive-no-sidebar.html) for a good example.
 * <br><br>
 * The `.container` class and its modifiers could also be used directly on an element if needed, e.g.
 * `<div class="card container"><div class="card__body"></div></div>`
 * <br>
 * However, this is not recommended practice for good HTML semantics.
 *
 */

/**
 * Basic container:
 *
 * `.container`
 *
 * A container with its width set to 100%.
 * <br>
 * <br>
 * <div class="alert alert--warning">There is no point in using this class without any of the below modifiers.</div>
 *
 *     @example
 *     .container
 *       .card
 *         .card__body Container
 */
@include block('container') {
  display: block;
  position: relative;
  padding: $ukg-spacing-m;

  /**
   * A max-width container (small) :
   *
   * `.container--small`
   *
   * A container that expands up to a certain width.
   *
   *   @example
   *   .container.container--small
   *     .card
   *       .card__body A max-width container (small)
   */
  @include modifier('small') {
    max-width: rem($small);
  }

  /**
   * A max-width container (medium) :
   *
   * `.container--medium`
   *
   * A container that expands up to a certain width.
   *
   *   @example
   *   .container.container--medium
   *     .card
   *       .card__body A max-width container (medium)
   */
  @include modifier('medium') {
    max-width: rem($medium);
  }

  /**
   * A max-width container (large) :
   *
   * `.container--large`
   *
   * A container that expands up to a certain width.
   *
   *   @example
   *   .container.container--large
   *     .card
   *       .card__body A max-width container (large)
   */
  @include modifier('large') {
    max-width: rem($large);
  }

  /**
   * A max-width container (extra large) :
   *
   * `.container--extra-large`
   *
   * A container that expands up to a certain width.
   *
   *   @example
   *   .container.container--extra-large
   *     .card
   *       .card__body A max-width container (extra large)
   */
  @include modifier('extra-large') {
    max-width: rem($extra-large);
  }

  /**
   * Centered container:
   *
   * `.container--centered`
   *
   * A container that is centered in the middle of its parent.
   *
   *   @example
   *   .container.container--centered.container--small
   *     .card
   *       .card__body Centered container
   */
  @include modifier('centered') {
    float: none; // in case the element it's used upon is a float
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }

  /**
   * Unpadded container:
   *
   * `.container--unpadded`
   *
   * A container with no padding.
   *
   *   @example
   *   .container.container--unpadded
   *     .card
   *       .card__body unpadded container
   */
  @include modifier('unpadded') {
    padding: 0;
  }
}

@media all and (max-width: $mobile) {
  @include block('layout') {
    &:not(.layout--semi-responsive) {
      @include block('container') {
        padding: $ukg-spacing-s;

        @include modifier('unpadded') {
          padding: 0;
        }
      }
    }
  }
}
