/***
 * # Borders
 *
 * A set of helpers for adding borders, and border-radius, to an element.
 * The border is consistent with the style of other bordered PDUI components, such as [cards](blocks-card.html).
 *
 */

@mixin border($side: null, $remove: false) {
  $class-prefix: border;
  $value: rem(1px) solid $block-border-color;

  @if $remove {
    $class-prefix: no-border;
    $value: none !important;
  }

  @if $side {
    .#{$class-prefix}-#{$side} {
      border-#{$side}: $value;
    }
  } @else {
    .#{$class-prefix} {
      border: $value;
    }
  }
}

@mixin border-radius($side: null, $remove: false) {
  $class-prefix: border-radius;
  $value: rem($border-radius-large);

  @if $remove {
    $class-prefix: no-border-radius;
    $value: 0 !important;
  }

  @if $side {
    .#{$class-prefix}-#{$side} {
      @if $side == top or $side == bottom {
        border-#{$side}-left-radius: $value;
        border-#{$side}-right-radius: $value;
      }
      @if $side == left or $side == right {
        border-top-#{$side}-radius: $value;
        border-bottom-#{$side}-radius: $value;
      }
    }
  } @else {
    .#{$class-prefix} {
      border-radius: $value;
    }
  }
}

/**
 * Border:
 * `.border`
 *
 * A helper that adds a border to all sides of an element.
 *
 *     @example
 *     p.border A paragraph with border.
 */
@include border;

/**
 * Border top:
 * `.border-top`
 *
 * A helper that adds a top border to an element.
 *
 *     @example
 *     p.border-top A paragraph with a top border
 */
@include border(top);

/**
 * Border right:
 * `.border-right`
 *
 * A helper that adds a right border to an element.
 *
 *     @example
 *     p.border-right A paragraph with a right border.
 */
@include border(right);

/**
 * Border bottom:
 * `.border-bottom`
 *
 * A helper that adds a bottom border to an element.
 *
 *     @example
 *     p.border-bottom A paragraph with a bottom border.
 */
@include border(bottom);

/**
 * Border left:
 * `.border-left`
 *
 * A helper that adds a left border to an element.
 *
 *     @example
 *     p.border-left A paragraph with a left border.
 */
@include border(left);

/**
 * Border radius:
 * `.border-radius`
 *
 * A helper that adds a large border radius to all four corners of an element.
 *
 *     @example
 *     p.border.border-radius A paragraph with rounded corners.
 */
@include border-radius;

/**
 * Border radius top:
 * `.border-radius-top`
 *
 * A helper that adds a large border radius to the top corners of an element.
 *
 *     @example
 *     p.border.border-radius-top A paragraph with top rounded corners.
 */
@include border-radius(top);

/**
 * Border radius right:
 * `.border-radius-right`
 *
 * A helper that adds a large border radius to the right corners of an element.
 *
 *     @example
 *     p.border.border-radius-right A paragraph with right rounded corners.
 */
@include border-radius(right);

/**
 * Border radius bottom:
 * `.border-radius-bottom`
 *
 * A helper that adds a large border radius to the bottom corners of an element.
 *
 *     @example
 *     p.border.border-radius-bottom A paragraph with bottom rounded corners.
 */
@include border-radius(bottom);

/**
 * Border radius left:
 * `.border-radius-left`
 *
 * A helper that adds a large border radius to the left corners of an element.
 *
 *     @example
 *     p.border.border-radius-left A paragraph with left rounded corners.
 */
@include border-radius(left);

/**
 * Remove borders:
 * `.no-border`, `no-border-top`, `no-border-right`, `no-border-bottom`, `no-border-left`
 *
 * Helpers to manually remove the border of an element.
 *
 * <div class="alert top-margin">
 *   <svg role="img" aria-labelledby="border-alert-1" focusable="false" class="alert__icon icon">
 *     <title id="border-alert-1">Information</title>
 *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
 *   </svg>
 *   <p>We use CSS <code>!important</code> in remove border helper classes to make sure that they can always carry out
 *   their one and very specific purpose, despite having very low specificity.</p>
 * </div>
 *
 */
@include border($remove: true);
@include border(top, $remove: true);
@include border(right, $remove: true);
@include border(bottom, $remove: true);
@include border(left, $remove: true);

/**
 * Remove border radius:
 * `.no-border-radius`, `no-border-radius-top`, `no-border-radius-right`, `no-border-radius-bottom`, `no-border-radius-left`
 *
 * Helpers to manually remove the border radius of an element.
 *
 * <div class="alert top-margin">
 *   <svg role="img" aria-labelledby="border-alert-2" focusable="false" class="alert__icon icon">
 *     <title id="border-alert-2">Information</title>
 *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
 *   </svg>
 *   <p>We use CSS <code>!important</code> in remove border radius helper classes to make sure that they can always carry out
 *   their one and very specific purpose, despite having very low specificity.</p>
 * </div>
 *
 */
@include border-radius($remove: true);
@include border-radius(top, $remove: true);
@include border-radius(right, $remove: true);
@include border-radius(bottom, $remove: true);
@include border-radius(left, $remove: true);
