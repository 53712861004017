// TYPOGRAPHY

/*
  Generates a heading. Size and line height are mandatory.
  You can also optionally pass in the font weight and font family.
  Without these arguments, it will return a light title font.
*/
@mixin heading($size, $line-height, $weight: $regular) {
  font-size: rem($size);
  line-height: $line-height / $size;
  font-weight: $weight;
  color: inherit;
}

@mixin h1 {
  @include heading($h1-size, $h1-line-height);
}

@mixin h2 {
  @include heading($h2-size, $h2-line-height);
}

@mixin h3 {
  @include heading($h3-size, $h3-line-height);
}

@mixin h4 {
  @include heading($h4-size, $h4-line-height, $regular);
}

@mixin h5 {
  @include heading($h5-size, $h5-line-height, $regular);
}

@mixin h6 {
  @include heading($h6-size, $h6-line-height, $bold);
}

@mixin no-first-child-margin {
  > *:first-child {
    margin-top: 0;
  }
}

@mixin no-child-margin {
  > * {
    margin-top: 0;
  }
}

@mixin highlighted-text {
  background-color: color(warning, mid);
  color: color(grey, 1);
}

@mixin line-through {
  text-decoration: line-through;
}

@mixin hyphens {
  hyphens: auto;
}
