/***
 * # Dynamic list
 *
 * A list of items with meta information. Use for displaying an object.
 *
 * Each item can optionally contain a checkbox, to allow for multiple row selection,
 * or a radio button, for single row selection.
 *
 * ## Position and style
 *
 * Use directly against the page background to list objects - do not use
 * in any other context.
 *
 * ## Responsive design
 *
 * There are two main ways to make dynamic lists adapt to different viewport sizes:
 *
 * 1. Make the dynamic list [horizontally scrollable](#use-case-horizontal-scroll) by placing it inside of a wrapper with the helper classes
 * [`.scroll-x`](../docs/helpers-overflow.html)<br>
 *   - *Note: this option is not compatible with [sticky header](#dynamic-list-with-sticky-header)*
 *
 * 2. Set a specific width percentage to each column by combining the modifiers
 * [fixed layout](#fixed-layout-dynamic-list) and
 * [fixed width columns](#fixed-width-columns)<br>
 *   - *Note: with this option, cells respect the given width and don't adapt to their content. You need to make sure
 *   that the percentages chosen work with your content in all different viewport sizes*
 *
 * Regardless the options above, **in screens smaller than 851px the content of all dynamic lists gets stacked in one column**:
 *
 * - Column titles move to the left of each `td.dynamic-list__column`
 * - For this to happen you need to add the relevant `[data-th="{column title}"]` attribute to each `td.dynamic-list__column` element
 *
 * Resize your screen to see this in action in the examples below.
 *
 * ## Accessibility
 *
 * - The `<caption>` tag defines a table caption (or title) of a table and
 * is always the first child of a `<table>`. Make sure to adapt the example text,
 * in the following examples, to your needs.
 * - [Checkboxes](#dynamic-list-with-multiple-row-selection) and [radio buttons](#dynamic-list-with-single-row-selection)
 * must have an accessible name provided by `aria-label` or `aria-labelledby`
 *
 * ## Initialization
 *
 * <div class="alert alert--danger top-margin">
 *   Unless the <code>[data-no-auto]</code> attribute is used, this component will auto-instantiate:
 *   clicking to select/unselect rows will add/remove the relevant "selected" modifiers.
 * </div>
 *
 */

$list-item-padding:                          15px;
$list-item-padding-mobile:                   $list-item-padding - 7;
$list-item-vertical-spacing:                 $item-vertical-spacing;
$list-item-horizontal-spacing:               8px;
$list-column-title-padding:                  5px $list-item-padding;
$list-mobile-threshold:                      850px;
$list-item-border-mobile:                    1px solid $block-border-color;
$list-item-margin-mobile:                    10px;
$list-column-row-margin-bottom:              4px;

// If you update this variable, remember to update the docs too.
$list-column-widths: 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95;

$elements-without-specific-display: '*:not(.tooltip):not(.popover):not(.modal):not(.hide):not(.hidden):not(.invisible):not(.show):not(.sr-only):not(ul)';

@mixin last-row($element) {
  @at-root .dynamic-list .dynamic-list__item:last-child .dynamic-list__#{$element} {
    @content;
  }
}

@mixin first-row-after-thead($element) {
  @at-root .dynamic-list thead + tbody .dynamic-list__item:first-child .dynamic-list__#{$element} {
    @content;
  }
}

/**
 * Basic dynamic list:
 *
 * `.dynamic-list`
 *
 * A list of items, with an optional header.
 *
 *     @example
 *     include ./code-samples/basic-dynamic-list.pug
 */

/**
 * Dynamic list with multiple row selection:
 *
 * <div class="card card--standout top-margin">
 *   <div class="card__body">
 *     <p>
 *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title1" focusable="false">
 *         <title id="title1">Information</title>
 *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
 *       </svg>
 *       <strong>Accessibility</strong>
 *     </p>
 *     <p><code>input[type="checkbox"]</code> has the attribute <code>aria-labelledby="id"</code> refering to the element that contains the accessible name of the object.</p>
 *   </div>
 * </div>
 *
 *     @example
 *     include ./code-samples/dynamic-list-with-multiple-row-selection.pug
 */

/**
 * Dynamic list with single row selection:
 *
 * <div class="card card--standout top-margin">
 *   <div class="card__body">
 *     <p>
 *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title1" focusable="false">
 *         <title id="title1">Information</title>
 *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
 *       </svg>
 *       <strong>Accessibility</strong>
 *     </p>
 *     <p><code>input[type="radio"]</code> has the attribute <code>aria-labelledby="id"</code> refering to the element that contains the accessible name of the object.</p>
 *   </div>
 * </div>
 *
 *     @example
 *     include ./code-samples/dynamic-list-with-single-row-selection.pug
 */
@include block('dynamic-list') {
  width: 100%;
  font-size: rem($base-font-size);
  background-color: $block-background-color;
  border-collapse: separate;

  @include element('item') {
    /**
     * Selected list item:
     *
     * `.dynamic-list__item--selected`
     *
     * A selected list item.
     *
     *   @example
     *   include ./code-samples/selected-list-item.pug
     */
    @include modifier('selected') {
      background: $highlight-range;

      @include element('selector') {
        color: $font-color-on-primary;
        background-color: color(primary, mid);
        position: relative;

        &::before {
          content: '';
          display: table-cell;
          width: rem(2px);
          height: 100%;
          position: absolute;
          left: rem(-1px);
          top: 0;
        }

        @include block('checkbox') {
          @include element('icon') {
            color: color(primary, mid);
          }

          @include element('input') {
            &:focus,
            &:active {
              + {
                @include element('label') {
                  @include element('control') {
                    &::before {
                      border-color: currentColor;
                    }
                  }
                }
              }
            }
          }
        }

        @include block('radio') {
          @include element('input') {
            &:focus,
            &:active {
              + {
                @include element('label') {
                  @include element('control') {
                    &::before {
                      border-color: currentColor;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @include element('selector') {
    width: $ukg-spacing-xxl;
    text-align: center;
    vertical-align: middle;
    background-color: $block-darker-background-color;
    border: rem(1px) solid $block-border-color;
    border-bottom-width: 0;

    @include last-row('selector') {
      border-bottom-width: rem(1px);
    }

    @include first-row-after-thead('selector') {
      border-top-width: 0;
    }

    .radio__input {
      pointer-events: none;
    }
  }

  /**
   * Bulk actions checkbox:
   *
   * `.checkbox[data-target="#dynamic-list-id .dynamic-list__selector .checkbox__input"]`
   *
   * Use a [bulk actions checkbox](../docs/blocks-checkbox.html) to check/unheck all of the selectable rows of a dynamic list.
   *
   * <div class="alert alert--warning top-margin">
   *  A bulk actions checkbox can only be used on a <strong>multiple row selection</strong> dynamic list.
   *  It does not work with radio buttons.
   * </div>
   *
   *     @example
   *     include ./code-samples/bulk-actions-checkbox.pug
   */

  /**
   * Dynamic list with sticky header:
   *
   * `.dynamic-list--sticky-header`
   *
   * To archive a responsive behaviour while avoiding horizontal scrolling,
   * use with [.dynamic-list--fixed-layout](#fixed-layout-dynamic-list) and declare a [fix width percentage](#fixed-width-columns)
   * to each column.
   *
   * See [listing page](../templates/listing-page.html) for an example in context.
   *
   * <div class="alert top-margin">
   *   <svg role="img" aria-labelledby="sticky-header-alert-1" focusable="false" class="icon alert__icon">
   *     <title id="sticky-header-alert-1">Information</title>
   *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *   </svg>
   *   <strong>Important</strong>
   *   <p>Sticky header is not compatible with dynamic lists that have an horizontally scrollable parent element.
   *   It doesn't work with a parent <a href="../docs/helpers-overflow.html"><code>.scroll-x</code></a> or <a href="../templates/semi-responsive-with-sidebar.html"><code>.layout--semi-responsive</code></a>.</p>
   * </div>
   * <div class="alert alert--warning top-margin">
   *   <svg role="img" aria-labelledby="sticky-header-alert" focusable="false" class="icon alert__icon">
   *     <title id="sticky-header-alert">Warning</title>
   *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
   *   </svg>
   *   <strong>Support</strong>
   *   <p>Sticky header is only supported by modern browsers.</p>
   *   <p>The support is related to <a href="https://caniuse.com/#feat=css-sticky"><code>position: sticky</code></a>.</p>
   *   <p>The table will behave like a regular table if the browser do not support this property.</p>
   * </div>
   *
   *     @example
   *     include ./code-samples/dynamic-list-with-sticky-header.pug
   */
  @supports ((position: sticky) or (position: -webkit-sticky)) {
    @include modifier('sticky-header') {
      .dynamic-list__column-title,
      thead td:empty {
        position: sticky;
        top: 0;
        z-index: index($z-index-scale, base);
      }
    }
  }

  /**
   * Fixed layout dynamic list:
   *
   * `.dynamic-list--fixed-layout`
   *
   * It allows to set an explicit column width by declaring it to the cells in the first row,
   * normally `.dynamic-list__column-title` elements.
   *
   * <div class="alert top-margin">
   *   <svg role="img" aria-labelledby="fixed-layout-alert" focusable="false" class="icon alert__icon">
   *     <title id="fixed-layout-alert">Information</title>
   *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *   </svg>
   *   <strong>Responsive design</strong>
   *   <p>Fixed layout can be used in combination with <a href="#fixed-width-columns">fixed width columns</a>
   *   modifiers to create responsive dynamic lists.</p>
   * </div>
   *
   *     @example
   *     include ./code-samples/fixed-layout-dynamic-list.pug
   */
  @include modifier('fixed-layout') {
    table-layout: fixed;
  }

  /**
   * Column title:
   *
   * `.dynamic-list__column-title`
   *
   * A column title. If you want a title to go over several columns, you can use colspan, e.g. `<th class="dynamic-list__column-title" colspan="2">`
   *
   *   @example
   *   include ./code-samples/column-title.pug
   */
  .dynamic-list__column-title,
  thead td:empty {
    padding: rem($list-column-title-padding);
    font-weight: $bold;
    text-align: left;
    background-color: $block-darker-background-color;
    border: rem(1px) solid $block-border-color;
    border-left-width: 0;

    &:first-child {
      border-left-width: rem(1px);
    }
  }

  @include element('column-title') {
    /**
     * Right aligned column title:
     *
     * `.dynamic-list__column-title--right`
     *
     * A right aligned column title.
     *
     *   @example
     *   include ./code-samples/right-aligned-column-title.pug
     */
    @include modifier('right') {
      text-align: right;
    }
  }

  /**
   * Column:
   *
   * `.dynamic-list__column`
   *
   * A column.
   *
   * <div class="alert top-margin">
   *   <svg role="img" aria-labelledby="fixed-layout-alert" focusable="false" class="icon alert__icon">
   *     <title id="fixed-layout-alert">Information</title>
   *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *   </svg>
   *   <strong>Responsive design</strong>
   *   <p>Column titles will be moved to the left of each <code>td.dynamic-list__column</code> element on screens below 851px.</p>
   *   <p>For this to work you need to add the relevant <code>[data-th="{column title}"]</code> attribute to each <code>td.dynamic-list__column</code> element.</p>
   * </div>
   *
   *     @example
   *     include ./code-samples/column.pug
   */

  /**
   * Collapsible Content:
   *
   * `.dynamic-list__column--collapsible`
   *
   * A column with collapsible content, mainly use with Expansion Panel component.
   *
   *
   *     @example
   *     include ./code-samples/column-collapsible.pug
   */
  @include element('column') {
    padding: rem(($list-item-padding - $list-item-vertical-spacing) $list-item-padding $list-item-padding);
    border-color: $block-border-color;
    border-style: solid;
    border-width: rem(1px) 0 0 0;

    &:first-child {
      border-left-width: rem(1px);
    }

    &:last-child {
      border-right-width: rem(1px);
    }

    @include last-row('column') {
      border-bottom-width: rem(1px);
    }

    @include first-row-after-thead('column') {
      border-top-width: 0;
    }

    @include modifier('collapsible') {
      padding: 0;
    }
  }

  /**
   * Fixed width columns:
   *
   * `.dynamic-list__column--<width>pc`
   *
   * A column with a fixed width - 5% intervals from 5% to 95%.
   *
   * <div class="alert top-margin">
   *   <svg role="img" aria-labelledby="fixed-layout-alert" focusable="false" class="icon alert__icon">
   *     <title id="fixed-layout-alert">Information</title>
   *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *   </svg>
   *   <strong>Responsive design</strong>
   *   <p>Use in combination with <a href="#fixed-layout-dynamic-list">fixed layout</a> to ensure that the column always
   *   has the declared width regardless of its content.</p>
   * </div>
   *
   *     @example
   *     include ./code-samples/fixed-width-columns.pug
   */
  @each $width in $list-column-widths {
    @include element(column--#{$width}pc) {
      width: $width * 1%;
    }
  }

  /**
   * Column row:
   *
   * `.dynamic-list__column-row`
   *
   * A row that is nested inside a column. Can contain headings, text, icons
   * badges, etc.
   *
   *   @example
   *   include ./code-samples/column-row.pug
   */
  @include element('column-row') {
    display: block;
    text-align: left;
    @include clearfix;

    > * {
      margin: rem($list-item-vertical-spacing $list-item-horizontal-spacing 0 0);
      float: left;
    }

    > #{$elements-without-specific-display} {
      display: inline-block;
      vertical-align: middle;
      line-height: 1.1;
      float: none;
    }

    ul {
      float: none;
    }

    // Adjust padding on inset elements to ensure perfect alignment :)
    > h5 {
      padding: rem(1px) 0;
    }

    > p {
      padding: rem(2px) 0;
    }

    /**
     * Right aligned row:
     *
     * `.dynamic-list__column-row--right`
     *
     * A row whose content is aligned to the right.
     *
     *   @example
     *   include ./code-samples/right-aligned-row.pug
     */
    @include modifier('right') {
      text-align: right;

      > * {
        float: right;
        margin: rem($list-item-vertical-spacing 0 0 $list-item-horizontal-spacing);
      }

      > #{$elements-without-specific-display} {
        float: none;
      }
    }
  }

  @include element('title') {
    font-weight: $regular;
    font-size: rem($h5-size);
  }

  /**
   * Dynamic list without outer border:
   *
   * `.dynamic-list--no-border`
   *
   * Remove the outer border of the dynamic list.
   *
   * <div class="alert top-margin">
   *   <svg role="img" aria-labelledby="no-border-alert" focusable="false" class="icon alert__icon">
   *     <title id="no-border-alert">Information</title>
   *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *   </svg>
   *   <strong>Use case</strong>
   *   <p>This modifier is helpful when placing the list inside of an already bordered container,
   *   for example a <a href="#use-case-horizontal-scroll">dynamic list with horizontal scroll</a>.</p>
   * </div>
   *
   *   @example
   *   include ./code-samples/no-border.pug
   */
  @include modifier('no-border') {
    .dynamic-list__column-title,
    thead td:empty {
      border-top-width: 0;

      &:first-child {
        border-left-width: 0;
      }

      &:last-child {
        border-right-width: 0;
      }
    }

    @include element('selector') {
      border-left-width: 0;
    }

    > tbody,
    > caption tbody {
      @include element('item') {
        &:first-child {
          .dynamic-list__column,
          .dynamic-list__selector {
            border-top-width: 0;
          }
        }
      }
    }

    @include element('item') {
      &:last-child {
        .dynamic-list__column,
        .dynamic-list__selector {
          border-bottom-width: 0;
        }
      }
    }

    @include element('column') {
      &:first-child {
        border-left-width: 0;
      }

      &:last-child {
        border-right-width: 0;
      }
    }
  }
}

@media all and (max-width: $list-mobile-threshold) {
  @include block('dynamic-list') {
    thead {
      display: none;
    }

    tbody {
      background-color: transparent;
      border: 0;
      box-shadow: none;
    }

    @include element('item') {
      float: left;
      width: 100%;
      display: block;
      border: rem($list-item-border-mobile);
      margin-bottom: rem($list-item-margin-mobile);

      td {
        padding: rem($list-item-padding-mobile);

        &:nth-child(odd) {
          background-color: $block-darker-background-color;
        }

        &:nth-child(even) {
          background-color: white;
        }
      }

      &:last-of-type {
        border: rem($list-item-border-mobile);
      }
    }

    @include element('selector') {
      display: block;
      width: 100%;
      text-align: left;
      border-width: 0 0 rem(1px) 0;
    }

    @include element('column') {
      padding: 0;
      border: none;
      display: block;
      width: 100%;
      float: left;

      &[data-th] {
        &::before {
          display: inline-block;
          content: attr(data-th) ': ';
          font-weight: $bold;
          width: auto;
          margin-right: rem(5px);
          margin-bottom: rem(3px);
          vertical-align: top;
        }
      }

      @include modifier('collapsible') {
        display: inline-block;
      }

      @each $width in $list-column-widths {
        @include modifier(#{$width}pc) {
          width: 100%;
        }
      }
    }

    @include element('column-row') {
      display: inline-block;
      text-align: left;
      vertical-align: top;
      position: relative;
      top: rem(1px);

      &::after {
        display: none;
      }

      > * {
        margin: 0;
        margin-right: rem(3px);
        margin-bottom: rem($list-column-row-margin-bottom);
      }

      @include modifier('right') {
        > * {
          margin: 0;
          text-align: left;
          float: none;
        }
      }
    }

    @include element('title') {
      font-size: rem($base-font-size);
    }

    @include modifier('no-border') {
      @include element('item') {
        border-right-width: 0;
        border-left-width: 0;

        &:first-child {
          border-top-width: 0;
        }

        &:last-child {
          border-bottom-width: 0;
        }
      }
    }
  }
}

/**
 * Use case: horizontal scroll:
 *
 * 1. Add [.border](../docs/helpers-border.html#border) and
 * [.scroll-x](../docs/helpers-overflow.html#horizontally-scrollable-container)
 * to the parent `div` element
 * 2. Add [.dynamic-list--no-border](#dynamic-list-without-outer-border) to the dynamic list
 *
 * The result is a bordered list that scrolls horizontally if the content overflows the available space.
 *
 *   @example
 *   include ./code-samples/horizontal-scroll.pug
 */
