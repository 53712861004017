/***
 * # Cells
 *
 * The cell layout is an alternative to the grid, where the content defines
 * the width of each column.
 *
 * ## When to use cells
 *
 * Cells are perfect for layouts where you are unsure of what the dimensions of
 * the content will be, but want to stack the content horizontally.
 *
 * They are also ideal for aligning content vertically, when used with the
 * `--align-middle` modifier.
 *
 * Spacing between cells can be added via the `--spaced` and `--half-spaced`
 * modifiers.
 *
 */

/**
 * Basic cells:
 * `.cells > .cells__cell`
 *
 * Cells will adjust their width based on their content.
 *
 *   @example
 *   include ./code-samples/basic-cells.pug
 */
@include block('cells') {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;

  @include element('cell') {
    flex-grow: 1;

    /**
     * Content only cell:
     * `.cell--fit-content`
     *
     * A cell that is only as wide as its content.
     *
     *   @example
     *   include ./code-samples/fit-content-cell.pug
     */
    @include modifier('fit-content') {
      flex-grow: 0;
    }
  }

  /**
   * Space between cells:
   *
   * `.cells--spaced`
   *
   * Adds a space/gutter between each cell..
   *
   *   @example
   *   include ./code-samples/spaced-cells.pug
   */
  @include modifier('spaced') {
    @include element('cell') {
      margin-right: $ukg-spacing-m;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  /**
   * Smaller space between cells:
   *
   * `.cells--half-spaced`
   *
   * Adds a half space/gutter between each cell.
   *
   *   @example
   *   include ./code-samples/half-spaced-cells.pug
   */
  @include modifier('half-spaced') {
    @include element('cell') {
      margin-right: $ukg-spacing-s;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  /**
   * Align in the middle (vertically):
   * `.cells--align-middle`
   *
   * Vertically aligns cells in the middle.
   *
   *   @example
   *   include ./code-samples/align-middle-cells.pug
   */
  @include modifier('align-middle') {
    align-items: center;
  }

  /**
   * Align to the bottom (vertically):
   * `.cells--align-bottom`
   *
   * Vertically aligns cells to the bottom.
   *
   *   @example
   *   include ./code-samples/align-bottom-cells.pug
   */
  @include modifier('align-bottom') {
    align-items: flex-end;
  }

  /**
   * Justify to the start:
   * `.cells--justify-start`
   *
   * Packs the cells towards the start of the container.
   *
   *   @example
   *   include ./code-samples/justify-start.pug
   */
  @include modifier('justify-start') {
    justify-content: flex-start;
  }

  /**
   * Justify to the end:
   * `.cells--justify-end`
   *
   * Packs the cells towards the end of the container.
   *
   *   @example
   *   include ./code-samples/justify-end.pug
   */
  @include modifier('justify-end') {
    justify-content: flex-end;
  }

  /**
   * Center cells:
   * `.cells--justify-center`
   *
   * Packs the cells around the center.
   *
   *   @example
   *   include ./code-samples/justify-center.pug
   */
  @include modifier('justify-center') {
    justify-content: center;
  }

  /**
   * Cells without new lines:
   * `.cells--no-wrap`
   *
   * Prevents the cells from wrapping into new lines.
   *
   *   @example
   *   include ./code-samples/cells-no-wrap.pug
   */
  @include modifier('no-wrap') {
    flex-wrap: nowrap;
  }

  /**
   * Use case: card header:
   *
   * Use cells to align the button to the right of the card header.
   *
   *   @example
   *   include ./code-samples/use-case-card-header.pug
   */

  /**
   * Use case: button alignment:
   *
   * Use cells to vertically align in the middle a link and a button.
   *
   *   @example
   *   include ./code-samples/use-case-button-alignment.pug
   */

  /**
   * Use case: label with action button:
   *
   * Use cells to position a label and an icon-only button side by side.
   *
   * Improve the position of the icon-only button with the help of a negative margin.
   *
   *   @example
   *   include ./code-samples/use-case-label-with-action-button.pug
   */

  /**
   * Use case: input with action button:
   *
   * Use cells to position an input and an icon-only button side by side.
   *
   *   @example
   *   include ./code-samples/use-case-input-with-action-button.pug
   */
}
