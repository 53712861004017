/***
 * # Tiles
 *
 * A responsive grid of tiles where each tile can be a [thumbnail](../docs/blocks-thumbnail.html) of an image, a document etc.
 *
 * You can choose how many tiles per row to show (1 to 12).
 *
 * The number of tiles per row will be adjusted on smaller screens regardless of your choice:
 *
 * - **Tablet (681px to 1024px):** all variations have 3 tiles per row, except `.tiles--1` and `.tiles--2` that keep the same number of tiles per row as on desktop
 * - **Mobile (680px or less):** all variations have 2 tiles per row, except `.tiles--1` that keeps the same number of tiles per row as on desktop
 *
 * `.tiles` is the container. It must be an HTML `ul` element.
 *
 * `.tiles__tile` is a `li` element for each tile inside of which you can place your content.
 *
 * ```html
 * <ul class="tiles tiles--5">
 *   <li clas="tiles__tile"></li>
 *   <li clas="tiles__tile"></li>
 *   <li clas="tiles__tile"></li>
 *   <li clas="tiles__tile"></li>
 *   …
 * </ul>
 * ```
 */

$tiles-mobile-threshold:  680px;
$tiles-per-row-tablet:    3;
$tiles-per-row-mobile:    2;
$tiles-min-per-row:       1;
$tiles-max-per-row:       12;
$tiles-default-per-row:   4;
$tiles-gap:               $ukg-spacing-s;

/**
 * Basic tiles:
 * `.tiles .tiles__tile`
 *
 * A responsive grid of tiles showing 4 tiles per row by default.
 *
 *   @example
 *   include ./code-samples/tiles.pug
 */
@include block('tiles') {
  @include unstyled-list;
  @include unpadded-list;
  margin: - $tiles-gap / 2;
  display: flex;
  flex-wrap: wrap;

  @include element('tile') {
    padding: $tiles-gap / 2;
    width: 100% / $tiles-default-per-row;
  }

  /**
   * Centered tiles:
   * `.tiles.tiles--centered`
   *
   * Use this modifier if you want your tiles to be horizontally centered
   *
   *   @example
   *   include ./code-samples/tiles-centered.pug
   */
  @include modifier('centered') {
    justify-content: center;
  }

  /**
   * n tiles per row:
   * `.tiles.tiles--{n}`
   *
   * Use this modifier to _specify the number of tiles per row_, instead of the _default_ number which is <strong>4</strong>.
   *
   * `{n}` can be any integer between 1 and 12
   *
   * <div class="alert top-margin">
   *   <svg role="img" aria-labelledby="alert-title-n-per-row" focusable="false" class="alert__icon icon">
   *     <title id="alert-title-n-per-row">Information</title>
   *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *   </svg>
   *   <p>This modifier can be omitted (as per the previous example), and if so, the tiles per row will be 4.</p>
   * </div>
   *
   *   @example
   *   - for (var i = 1; i <= 12; i++) {
   *     - if (i != 4) {
   *       - if ( i === 1) {
   *         h4.sg.bottom-margin(id=`${i}-tiles-per-row`)
   *           | #{i} tile per row
   *       - }
   *       - else {
   *         h4.sg.vertical-margin(id=`${i}-tiles-per-row`)
   *           | #{i} tiles per row
   *           - if ( i === 3) {
   *             | &nbsp;(on tablet and desktop)
   *           - }
   *           - if ( i > 3) {
   *             | &nbsp;(on desktop)
   *           - }
   *       - }
   *       ul.tiles(class=`tiles--${i}`)
   *         - for (var n = 1; n <= i; n++) {
   *           li.tiles__tile
   *             .thumbnail
   *               .thumbnail__image
   *                 button.thumbnail__button.button(type='button' aria-label='Download document title')
   *                   svg.button__icon.icon--small(aria-hidden='true', focusable='false')
   *                     use(xlink:href='../icons.svg#download' href='../icons.svg#download')
   *                 img(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/img-v-1.png' alt='Window')
   *               .thumbnail__content
   *                 h4.thumbnail__title
   *                   a(href='#') #{i} per row
   *                 p.thumbnail__meta Meta 1
   *                 p.thumbnail__meta Meta 2
   *           -}
   *     -}
   *   -}
   */
  @for $tiles-per-row from $tiles-min-per-row through $tiles-max-per-row {
    @if $tiles-per-row != $tiles-default-per-row { // Skip default number of tiles per row, as that is covered by default without a modifier class
      @include modifier($tiles-per-row) {
        @include element('tile') {
          width: 100% / $tiles-per-row;
        }
      }
    }
  }

  /**
   * Tiles with outer spacing:
   * `.tiles-wrapper.margin > .tiles`
   *
   * To add outer spacing to a group of tiles include a parent `.tiles-wrapper` element with any necessary [margin helper classes](../docs/helpers-margin.html).
   *
   * <div class="alert alert--warning top-margin">
   *   <svg role="img" aria-labelledby="alert-title-tiles-with-outer-spacing" focusable="false" class="alert__icon icon">
   *     <title id="alert-title-tiles-with-outer-spacing">Warning</title>
   *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
   *   </svg>
   *   <p>Do not apply margin helper classes directly to the element <code>.tiles</code>.</p>
   * </div>
   *
   *   @example
   *   p The following group of tiles has a vertical margin:
   *   .tiles-wrapper.vertical-margin
   *     ul.tiles
   *      - for (var i = 1; i <= 7; i++) {
   *        li.tiles__tile
   *          .thumbnail
   *            .thumbnail__image
   *              button.thumbnail__button.button(type='button' aria-label='Download document title')
   *                svg.button__icon.icon--small(aria-hidden='true', focusable='false')
   *                  use(xlink:href='../icons.svg#download' href='../icons.svg#download')
   *              img(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/img-v-1.png' alt='Window')
   *            .thumbnail__content
   *              h4.thumbnail__title
   *                a(href='#') 4 per row
   *              p.thumbnail__meta Meta 1
   *              p.thumbnail__meta Meta 2
   *      -}
   */
}

// Helper class for the tiles component
.tiles-wrapper {
  overflow: hidden;
}

@media all and (min-width: $tiles-mobile-threshold + 1) and (max-width: $tablet) {
  @include block('tiles') {
    &:not(.tiles--1):not(.tiles--2):not(.tiles--3) {
      .tiles__tile { // Cannot use `@include element('tiles')` after the `:not()` pseudo-class because it returns an error
        width: 100% / $tiles-per-row-tablet;
      }
    }
  }
}

@media all and (max-width: $tiles-mobile-threshold) {
  @include block('tiles') {
    &:not(.tiles--1):not(.tiles--2) {
      .tiles__tile { // Cannot use `@include element('tiles')` after the `:not()` pseudo-class because it returns an error
        width: 100% / $tiles-per-row-mobile;
      }
    }
  }
}
