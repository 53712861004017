/***
 * # Cards
 *
 * A card is a top level decorative container. Cards can optionally contain a
 * header that displays a title or actions and a footer,
 * which would normally contain actions.
 *
 * ## When to use cards
 *
 *  - To contain other elements against the main page background
 *
 * ## When not to use cards
 *
 * Cards should not be used inside other components, including inside other cards.
 * Cards can be used inside layout helpers, such as grids.
 *
 * ## Position and style
 *
 * Cards can be positioned anywhere in the UI, as long as they are against the main background of the page.
 */

$card-padding:                          $block-padding;
$card-border-color:                     $block-border-color;

$card-element-spacing:                  $card-padding / 1.5;

$card-footer-background-color:          color(grey, 6);

$card-standout-border-color:            color(primary, mid);

/**
  * Basic card:
  *
  * `.card`
  *
  * A card with a header, a body and a footer.
  *
  * <div class="card card--standout top-margin">
  *   <div class="card__body">
  *     <p>
  *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title1" focusable="false">
  *         <title id="title1">Information</title>
  *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
  *       </svg>
  *       <strong>Accessibility</strong>
  *     </p>
  *     <p>You can use any heading level <code>h1-h6</code> inside of the card header.</p>
  *     <p>Choose a heading level that is logical in context and that helps to structure the document properly.</p>
  *   </div>
  * </div>
  *
  *     @example
  *     include ./code-samples/basic-card.pug
  */
@include block('card') {
  @include card;
  @include rem(font-size, $base-font-size);

  p {
    @include rem(margin-top, $card-element-spacing);
  }

  @include element('header') {
    @include no-first-child-margin;
    @include rem(padding, $card-padding);
    @include rem(border-bottom, solid 1px hsla(var(--grey-level-5-h), var(--grey-level-5-s), var(--grey-level-5-l), 0.4));

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @include h3;
      margin: 0;
      padding: 0;

      &:not(:last-child) {
        @include rem(margin-bottom, $card-element-spacing / 2);
      }
    }

    p {
      margin: 0;
    }
  }

  @include element('body') {
    @include no-first-child-margin;
    @include rem(padding, $card-padding);
    @include rem(font-size, $base-font-size);

    @include modifier('unpadded') {
      padding: 0;
    }
  }

  @include element('footer') {
    @include no-first-child-margin;
    @include rem(padding, $card-padding);
    @include rem(font-size, $base-font-size);
    background-color: $card-footer-background-color;
    @include rem(border-top, 1px solid $card-border-color);
    position: relative;
    border-radius: 0 0 rem($border-radius-large $border-radius-large);

    @include block('button') {
      @include rem(margin-top, 2px);
      @include rem(margin-bottom, 2px);
    }
  }

  /**
  * Card with unpadded body:
  *
  * `.card__body--unpadded`
  *
  * A card with an unpadded `.card__body`. This modifier is typically
  * used in cards that contain a tabs component.
  *
  *   @example
  *   include ./code-samples/card-unpadded-body.pug
  */

  /**
  * Card with subtitle and badges:
  *
  * `.card__subtitle`
  *
  * The card header can contain a subtitle
  * and a list of badges.
  *
  *   @example
  *   include ./code-samples/card-with-subtitle-and-badges.pug
  */
  @include element('subtitle') {
    color: hsla(var(--grey-level-3-h), var(--grey-level-3-s), var(--grey-level-3-l), 0.76);

    &:not(:last-child) {
      @include rem(margin-bottom, $card-element-spacing / 2);
    }
  }

  /**
  * Card with inner title:
  *
  * `.card__inner-title`
  *
  * Card body and card footer can contain one or more inner titles.
  *
  *   @example
  *   include ./code-samples/card-with-inner-title.pug
  */
  @include element('inner-title') {
    @include h5;
    @include rem(margin, $card-padding 0 $card-element-spacing);
  }

  /**
  * Card with content divider:
  *
  * `.card__body hr`
  * `.card__footer hr`
  *
  * Use HTML `hr` elements to separate blocks
  * of content inside of a card body or card footer.
  *
  *   @example
  *   include ./code-samples/card-content-divider.pug
  */

  hr {
    @include rem(margin, $card-padding 0);
  }

  /**
  * Standout card:
  *
  * `.card--standout`
  *
  * A card with a standout style applied. Use for content you want to emphasise on the page.
  *
  *   @example
  *   include ./code-samples/standout-card.pug
  */
  @include modifier('standout') {
    border-color: $card-standout-border-color;

    @include element('header') {
      border-color: $card-standout-border-color;
    }

    @include element('footer') {
      border-color: $card-standout-border-color;
      background-color: $highlight-range;
    }
  }

  /**
  * Use case 1:
  *
  * A card with a title, a subtitle and a list of badges. The card body contains an inner section with a title.
  *
  *   @example
  *   include ./code-samples/use-case-1.pug
  */

  /**
  * Use case 2:
  *
  * A card with a title and a long subtitle. The footer contains an inner title and a set of input elements and buttons.
  *
  *   @example
  *   include ./code-samples/use-case-2.pug
  */

  /**
  * Use case 3:
  *
  * A card with a title and a long subtitle. The footer contains multiple sections separated by dividers and an alert component with a warning message.
  *
  *   @example
  *   include ./code-samples/use-case-3.pug
  */

  /**
  * Stacked cards:
  *
  * Use border helpers to prevent double borders between stacked cards.
  *
  *   @example
  *   include ./../expansion-panel/code-samples/accordion-expansion-panel.pug
  */

  &.no-border-bottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    + .card {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
