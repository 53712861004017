/***
 * # Tabs
 *
 * A group of interactive controls that handle the visibility of the corresponding content panels. Clicking or focusing on a tab displays its associated panel.
 *
 * ## When to use tabs
 *
 * Tabs should be used when content should be logically chunked into groups.
 * Tabs are especially useful when dealing with large sets of content.
 *
 * ## When not to use tabs
 *
 * Avoid using tabs when there is the possibility that there will be very
 * little content in each content section. In this situation, it might be
 * better to combine the content into one section, or use a button group
 * as a filtering mechanism.
 *
 * ## Position and style
 *
 * Tabs are a high level component and should be positioned directly against
 * the page background. With the appropriate modifiers, tabs can be used inside
 * of [cards](blocks-card.html#card-with-unpadded-body) and
 * [modals](blocks-modal.html#modal-with-fixed-tabs).
 *
 * ## Accessibility
 *
 * - A tabs component (except [tabs as links](#tabs-as-links)) uses the relevant ARIA roles `tablist`, `tab` and `tabspanel` with recommended [keyboard interaction](#keyboard-interaction).
 * - The tab list element represents one stop in the tab order
 * - When focus is on the tab list, the next focusable element should be the tab panel itself.
 * - The [tabs as links](#tabs-as-links) variation has different semantics: It is a navigational group of links, and it uses the semantic HTML element `nav`
 *
 * ## Keyboard interaction
 *
 * With focus inside of the `.tabs__list`:
 * - <kbd>→</kbd>: moves focus and activates the next tab.
 * - <kbd>←</kbd>:  moves focus and activates the previous tab.
 * - <kbd>Home</kbd>: moves focus and activates the first tab.
 * - <kbd>End</kbd>: moves focus and activates the last tab.
 *
 * The [tabs as links](#tabs-as-links) variation has different semantics, and does not have specific keyboard interaction.
 *
 * ## Initialization
 *
 * <div class="alert alert--danger top-margin top-margin--small">
 *   <p>This component will auto-instantiate unless it has the <code>[data-no-auto]</code> attribute at page load (DOMContentLoaded) or on <code>init.pdui</code> event recept on an ancestor.<p>
 *   <p>If the component is added to the page dynamically, trigger the <code>init.pdui</code> event on a parent node to instantiate it.</p>
 *   <p>If the component is not instantiated, it will auto-instantiate as soon as a method of that component is called programmatically, regardless of the presence of <code>[data-no-auto]</code>.</p>
 *   <p>If you don't set the component to auto-instantiate and you wish to instantiate it manually (pass options to it etc.) do:<br><code>$(el).tabs();</code></p>
 * </div>
 */

$tab-block-border-color:      color(grey, 5);
$tab-block-border-width:      1px;

$tab-padding:                 19px;
$tab-offset:                  3px; // size of space to remove between tabs

$tab-color:                   $base-font-color;
$tab-background-color:        color(grey, 6);
$tab-hover-background-color:  $white;
$tab-selected-border-width:   2px;
$tab-selected-border-color:   color(primary, mid);
$tab-selected-color:          $base-font-color;

$tab-nav-padding:             15px;
$tab-nav-border-color:        $tab-block-border-color;
$tab-shadow-color:            hsla(var(--grey-level-4-h), var(--grey-level-4-s), var(--grey-level-4-l), 0.1);
$tab-shadow-length:           10px;
$tab-control-margin:          10px;

/**
 * Basic tabs:
 * `.tabs`
 *
 * A series of tabs.
 *
 * <div class="alert alert--warning top-margin">
 *   <p>If you include the <br> <code>[data-open]</code> <br> attribute on a tab, the component will open the relevant tab, upon initialization.</p>
 *   <p>Take a look at the bottom of the page on how to open a tab programmatically.</p>
 * </div>
 *
 *     @example
 *     include ./code-samples/basic-tabs.pug
 */
@include block('tabs') {
  font-size: rem($base-font-size);

  @include element('header') {
    display: flex;
    border-bottom: rem($tab-block-border-width) solid $tab-block-border-color;
    background-color: $tab-background-color;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    position: relative;
  }

  @include element('list') {
    max-width: 100%;
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
  }

  @include element('tab') {
    padding: rem($tab-padding);
    padding-bottom: rem($tab-padding + 1px);
    display: inline-block;
    color: $tab-color;
    font-size: rem($base-font-size);
    text-decoration: none;
    background-color: transparent;
    border-radius: rem($border-radius-medium $border-radius-medium 0 0);
    @include inset-focus-indicator(transparent);

    &:hover {
      text-decoration: none;
      color: $tab-selected-color;
      background-color: $tab-hover-background-color;
      cursor: pointer;
    }

    &:focus,
    &:active {
      @include inset-focus-indicator;
    }

    &[aria-current], // aria-current="page" in tab as links
    &[aria-selected='true'] {
      color: $tab-selected-color;
      background-color: $tab-hover-background-color;
      font-weight: $bold;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: $tab-selected-border-width;
        background-color: $tab-selected-border-color;
      }
    }

    .badge {
      position: relative;
      top: rem(-2px);
      left: rem(2px);
    }
  }

  @include element('content') {
    margin-top: rem($type-margin);

    &[hidden] {
      display: none;
    }
  }

  /**
   * Bordered tabs:
   * `.tabs--bordered`
   *
   * A set of tabs with borders on all sides of the header.
   *
   *     @example
   *     include ./code-samples/bordered-tabs.pug
   */
  @include modifier('bordered') {

    @include element('header') {
      border: rem($tab-block-border-width) solid $tab-block-border-color;
    }
  }

  /**
   * Equalized tabs height:
   * `.tabs--equalize-height`
   *
   * Equalize the height of `.tab__body` according to the highest `.tabs__content`.
   * It prevents a jumping effect of the content below the tabs component.
   *
   * This modifier is typically used in tabs inside of modals.
   *
   *     @example
   *     include ./code-samples/equalized-tabs.pug
   */
  @include modifier('equalize-height') {
    max-height: 100%;

    @include element('header') {
      flex: 0 0 auto;
    }

    @include element('body') {
      // Use grid-template to provide IE 11 support via Autoprefixer
      // Read about this: https://css-tricks.com/css-grid-in-ie-css-grid-and-the-new-autoprefixer/
      display: grid;
      grid-template: 'content' 100% / 1fr;
      flex: 0 1 auto;
      max-height: 100%;
      overflow: hidden;
    }

    @include element('content') {
      grid-area: content;
      max-height: 100%;
      overflow: auto;

      &[hidden] {
        display: block;
        visibility: hidden;
        overflow-y: hidden;
      }
    }
  }
}

/**
 * Tabs with badges:
 *
 * You may include a [badge](../docs/blocks-badge.html) inside each tab.
 *
 *     @example
 *     include ./code-samples/tabs-with-badges.pug
 */

/**
 * Tabs as links:
 * `<a href='#' class="tabs__tab"/>`
 *
 * A series of tabs that are actual links to other pages.
 * Clicking on any of the tabs will not switch any content below them.
 * It will instead load a new page.
 *
 * <div class="alert alert--warning top-margin">
 *   Beware of using the tabs component as a list of links, because even if it looks identical, it does not behave the same way regarding keyboard navigation. This can be confusing for keyboard users.
 * </div>
 *
 *     @example
 *     include ./code-samples/tabs-as-links.pug
 */
