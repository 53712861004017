$sidebar-logo-width:              90px;
$sidebar-logo-height:             50px;
$sidebar-logo-margin:             16px;
$sidebar-transition-duration:     0.4s;
$sidebar-transition-ease:         cubic-bezier(0, 0, 0.2, 1);

$sidebar-background-color:        color(grey, 3);
$sidebar-focus-indicator-color:   rgba($white, 0.65);

$content-with-sidebar-left-border: $sidebar-width solid transparent;
$content-with-sidebar-minified-left-border: $sidebar-minified-width solid transparent;

$sidebar-logo-background:         $sidebar-background-color;

@mixin menu-item-expanded($sub-link: false, $drop: false) {
  width: 100%;
  height: auto;
  max-width: rem($sidebar-width - 16px); // Make space for the scrollbar
  max-height: none;
  margin-left: 0;
  padding-right: rem(8px);
  border-radius: 0 rem(22px 22px) 0;

  @if $sub-link == true {
    padding-left: rem(44px);
  } @else if $drop == true {
    padding-left: rem(24px);
  } @else {
    padding-left: rem(16px);

    @include modifier('sub-link-selected') {
      background-color: transparent;
      color: currentColor;

      &:hover {
        background-color: $block-darker-background-color;
      }
    }
  }

  &:active,
  &:focus {
    &::before {
      border-radius: 0 rem(22px 22px) 0;
      border-left-width: 0;
    }
  }
}

@mixin menu-expanded {
  @include element('link') {
    @include menu-item-expanded;
  }

  @include element('sub-link') {
    @include menu-item-expanded($sub-link: true);
  }

  .vertical-menu__link-title,
  .vertical-menu__dropdown-icon,
  .badge {
    opacity: 1;
  }

  @include element('marker') {
    position: static;
    margin-left: rem(8px);
  }

  @include element('title') {
    @include sr-only-reset;
    display: block; // Restores display as set in _vertical-menu.scss
    padding: rem(18px 16px 8px 16px); // Restores padding as set in _vertical-menu.scss
  }

  li:not(:first-child) > .vertical-menu__title {
    position: relative; // Restores position as set in _vertical-menu.scss
    margin-top: rem(16px); // Restores margin as set in _vertical-menu.scss
  }

  @include element('sub-menu') {
    @include modifier('opened') {
      visibility: visible;
      height: auto;
    }
  }
}

@mixin menu-minified {
  @include element('link') {
    width: rem(44px);
    max-height: rem(44px);
    margin-left: rem(4px);
    padding-right: rem(12px);
    padding-left: rem(12px);
    border-radius: rem(22px);
    overflow: hidden;

    &:active,
    &:focus {
      &::before {
        border-radius: 50%;
        border-left-width: rem(3px);
      }
    }

    @include modifier('sub-link-selected') {
      background-color: color(grey, 5);
    }
  }

  @include element('marker') {
    position: absolute;
    bottom: rem(10px);
    left: rem(25px);
    margin-left: 0;
  }

  .vertical-menu__link-title,
  .vertical-menu__dropdown-icon,
  .badge {
    opacity: 0;
  }

  @include element('title') {
    @include sr-only;
  }

  li:not(:first-child) > .vertical-menu__title {
    margin-top: 0;
  }

  @include element('sub-menu') {
    @include modifier('opened') {
      visibility: hidden;
      height: 0;
    }
  }
}

@mixin sidebar-expanded {
  @include element('sidebar') {
    width: rem($sidebar-width);
    transform: translateX(0);

    + .layout__content {
      border-left: rem($content-with-sidebar-left-border);
    }
  }

  @include element('sidebar-logo') {
    opacity: 1;
    pointer-events: auto;
  }

  @include element('close-button') {
    display: none;
  }

  @include element('sidebar-content') {
    @include block('vertical-menu') {
      @include menu-expanded;

      @include modifier ('dark') {
        @include element('link') {
          @include modifier('sub-link-selected') {
            &:hover {
              background-color: rgba($white, 0.15);
            }
          }
        }
      }
    }
  }

  ~ .layout__sidebar-drop,
  .layout__sidebar-drop {
    display: none;
  }
}

@mixin sidebar-minified {
  @include element('sidebar') {
    width: rem($sidebar-minified-width);
    transform: translateX(0);

    + .layout__content {
      border-left: rem($content-with-sidebar-minified-left-border);
    }
  }

  @include element('sidebar-logo') {
    opacity: 0;
    pointer-events: none;
  }

  @include element('close-button') {
    display: none;
  }

  @include element('sidebar-content') {
    @include block('vertical-menu') {
      @include menu-minified;

      @include modifier('dark') {
        @include element('link') {
          @include modifier('sub-link-selected') {
            background-color: color(grey, 2);
          }
        }
      }
    }
  }

  ~ .layout__sidebar-drop,
  .layout__sidebar-drop {
    display: block;
  }
}

/**
 * <div class="alert alert--info bottom-margin">
 *   <svg class="alert__icon icon" aria-hidden="true" focusable="false">
 *     <use xlink:href="../icons.svg#info" href="../icons.svg#info">
 *   </svg>
 *   <p class="h4">Important information</p>
 *   <p>This is <strong>version 2</strong> of the layout component. <a href="#how-to-migrate-from-layout-v1">Information to migrate from v1</a></p>
 *   <p>In order to use version 2, you need to add the <code>data-version="2"</code> attribute to the <code>.layout</code> element.</p>
 * </div>
 *
 * # Layout
 *
 * A structural layout for PDUI interfaces.
 *
 * The class `.layout` must be applied to the `body` element: it is the very top-level parent container of all other elements.
 *
 * The `.layout` body element only has the three following children (in order):
 * 1. [layout__skip](#skip-to-content)
 * 2. [layout__sidebar](#layout-sidebar) (optional)
 * 3. [layout__content](#layout-content)
 *
 * ```html
 * <body class="layout" data-icons-path="…" data-version="2">
 *   <a class="layout__skip" href="#content">Skip to Content</a>
 *   <aside class="layout__sidebar">…</aside> <!-- Optional -->
 *   <div id="content" class="layout__content">
 *     <div class="layout__content-container">
 *       <!-- Page content -->
 *     </div>
 *   </div>
 * </body>
 * ```
 * **Alternative HTML structure**
 *
 * If the framework that you use doesn't allow the above structure (e.g. all of your HTML needs to be wrapped in one single top-level `<div>`), you can use the following structure instead:
 *
 * ```html
 * <body class="layout" data-icons-path="…" data-version="2">
 *   <div>
 *     <a class="layout__skip" href="#content">Skip to Content</a>
 *     <aside class="layout__sidebar">…</aside> <!-- Optional -->
 *     <div id="content" class="layout__content">
 *       <div class="layout__content-container">
 *         <!-- Page content -->
 *       </div>
 *     </div>
 *   </div>
 * </body>
 * ```
 *
 * ## How to migrate from layout v1
 *
 * - Add the attribute `data-version="2"` to the `.layout` element.
 * - Add the new `button.layout__close-button` right after the element `.layout__sidebar-logo` - see [code snippet](#layout-sidebar).
 * - Migrate the vertical menus used in the sidebar navigation to [version 2](blocks-vertical-menu-v2.html#how-to-migrate-from-vertical-menu-v1).
 * - Remove the class `.vertical-menu--lined` from the vertical menu used in the sidebar navigation.
 *
 */
@include block('layout', 2) {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  overflow-x: hidden; // Prevents horizontal scrolling

  @include modifier('animatable') {
    @include element('content') {
      transition: margin-left $sidebar-transition-duration $sidebar-transition-ease, border $sidebar-transition-duration $sidebar-transition-ease;
    }

    @include element('sidebar') {
      transition: width $sidebar-transition-duration $sidebar-transition-ease, transform $sidebar-transition-duration $sidebar-transition-ease;
    }

    @include element('sidebar-logo') {
      transition: height $sidebar-transition-duration linear, opacity $sidebar-transition-duration linear;
    }

    @include element('sidebar-content') {
      .vertical-menu__link,
      .vertical-menu__sub-link {
        white-space: nowrap;
        transition: width $sidebar-transition-duration $sidebar-transition-ease, border-radius $sidebar-transition-duration linear;
      }
    }

    @include and-modifier('sidebar-expanded') {
      .layout__sidebar-content {
        .vertical-menu__link-title,
        .vertical-menu__dropdown-icon,
        .badge {
          transition: opacity $sidebar-transition-duration linear;
        }
      }
    }
  }

  /**
   * ## Skip to content
   * `a.layout__skip`
   *
   * The first child of `.layout`.
   *
   * It is only visible on focus and it provides a keyboard mechanism to bypass the navigation and skip directly to the main content of page.
   *
   * ```html
   * <body class="layout" data-version="2">
   *   <a class="layout__skip" href="#content">Skip to Content</a>
   *   …
   * </body>
   * ```
   */
  @include element('skip') {
    @include sr-only;

    &:focus {
      @include sr-only-reset;
      @include focus-outline;
      position: absolute;
      left: rem(10px);
      top: rem(10px);
      padding: rem(10px);
      font-size: rem($base-font-size);
      z-index: index($z-index-scale, 'skip-link');
      background-color: $dark-header-bg-color;
      color: $white;
    }
  }

  /**
   * ## Layout content
   * `.layout__content`
   *
   * A child of `.layout` that contains the [header](../docs/blocks-header.html), [main](../docs/blocks-main.html) and [footer](../docs/blocks-footer.html) components.
   *
   * See it in action: [responsive without sidebar](../templates/responsive-no-sidebar.html).
   *
   * ```html
   * <body class="layout" data-version="2">
   *   <a class="layout__skip" href="#content">Skip to Content</a>
   *   <div id="content" class="layout__content">
   *     <div class="layout__content-container">
   *       <header role="banner" class="header">…</header>
   *       <main role="main" class="main">…</main>
   *       <footer role="contentinfo" class="footer">…</footer>
   *     </div>
   *   </div>
   * </body>
   * ```
   */
  @include element('content') {
    position: relative;
    width: 100%;
  }

  @include element('content-container') {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-color: $body-background-color;
    display: flex;
    flex-direction: column;
  }

  /**
   * ## Layout sidebar
   * `aside.layout__sidebar`
   *
   * An optional child of `.layout` that sits to the left of `.layout__content`.
   *
   * If the viewport width is less than 1024px:
   * - The sidebar is hidden by default
   * - The sidebar toggler button reveals or hides the sidebar
   *
   * If the viewport width is equal or more than 1024px:
   * - The sidebar is minified by default - it is shown as a bar with icons
   * - The sidebar toggler button expands or minifies the sidebar
   *
   * See it in action: [reponsive with sidebar](../templates/responsive-with-sidebar.html).
   *
   * ```html
   * <body class="layout" data-version="2">
   *   <!--
   *     OPTIONAL:
   *     Place the following script right after the opening body tag
   *     if you wish to keep track of the expanded/minified state of the sidebar
   *   -->
   *   <script>
   *     if (localStorage && localStorage.getItem('sidebarExpanded') === "true") {
   *       document.body.className += ' layout--sidebar-expanded';
   *     }
   *   </script>
   *   <a class="layout__skip" href="#content">Skip to Content</a>
   *   <aside class="layout__sidebar">
   *     <div class="layout__sidebar-content">
   *       <div class="layout__sidebar-logo">
   *         <img src="logo.svg" alt="Company name"/>
   *       </div>
   *       <button class="layout__close-button button button--icon" type="button">
   *         <svg class="icon icon--small icon--white" role="img" focusable="false" aria-labelledby="uniqueID-icon-close-sidebar">
   *           <title id="uniqueID-icon-close-sidebar">Close main menu</title>
   *           <use xlink:href="../icons.svg#close" href="../icons.svg#close"></use>
   *         </svg>
   *       </button>
   *       <nav class="vertical-menu vertical-menu--dark" aria-label="Main menu">…</nav>
   *     </div>
   *   </aside>
   *   <div id="content" class="layout__content">…</div>
   * </body>
   * ```
   *
   * <div class="alert top-margin">
   *   <svg role="img" aria-labelledby="alert-sidebar" focusable="false" class="alert__icon icon">
   *     <title id="alert-sidebar">Information</title>
   *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *   </svg>
   *   <p>You can modify the default behaviour of the sidebar with the modifiers <a href="#semi-responsive-layout">layout--semi-responsive</a> and <a href="#no-minified-sidebar">layout--no-minified-sidebar</a>.</p>
   * </div>
   */
  @include element('sidebar') {
    height: 100%; // 100vh not working on Windows Safari 5.1
    width: rem($sidebar-width);
    border-top: 0;
    border-bottom: 0;
    color: $white;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: content-box;
    z-index: index($z-index-scale, sidebar);
    transform: translateX(-100%);

    * {
      box-sizing: border-box;
    }
  }

  @include element('sidebar-content') {
    background-color: $sidebar-background-color;
    height: 100%;
    overflow: hidden;
    padding-bottom: rem(16px);
    position: relative;

    &:hover {
      overflow-y: auto;
    }

    @include block('vertical-menu') {
      @include menu-expanded;
    }
  }

  &:not(.layout--light-sidebar) {
    @include element('sidebar-content') {
      /*
        TEMPORARY USE OF @EXTEND
        Ignite DLS plans to deliver scrollbar mixins in the future.
        Once they are available in @ignite/tokens/scss/ignite-tokens.mixins.css
        we can replace the @extend by a @include
      */
      @extend .scrollbar-dark; // From @ignite/tokens/css/ignite-tokens.css
    }
  }

  @include element('sidebar-logo') {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem($sidebar-logo-width);
    height: rem($sidebar-logo-height);
    margin: rem($sidebar-logo-margin);
    background-color: $sidebar-logo-background;

    &:focus,
    &:active {
      @include focus-outline-pseudo-element($outline-color: $sidebar-focus-indicator-color, $gap: 4px);
    }

    img {
      max-height: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  @include element('close-button') {
    position: absolute;
    top: rem(21px);
    left: rem($sidebar-width - 60px); // Align to the left to avoid a jump when the scrollbar appears

    &:focus,
    &:active {
      position: absolute;

      &::before {
        border-color: $sidebar-focus-indicator-color;
      }
    }
  }

  @include modifier('sidebar-revealed') {
    @include element('sidebar') {
      transform: translateX(0);
      box-shadow: $ukg-elevation-09;
    }

    @include element('content') {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: hsla(var(--grey-level-1-h), var(--grey-level-1-s), var(--grey-level-1-l), 0.32);
        z-index: index($z-index-scale, sidebar-backdrop);
      }
    }
  }

  /**
   * ## Semi responsive layout
   *
   * `.layout--semi-responsive`
   *
   * This modifier sets a minimum width of 965px to the main content of the page.
   *
   * If the viewport width is less than 1024px:
   * - The main content area has an horizontal scrollbar
   * - The sidebar behaves like in larger viewports - it minifies, but it doesn't hide
   *
   * See it in action: [semi responsive with sidebar](../templates/semi-responsive-with-sidebar.html).
   *
   * ```html
   * <body class="layout layout--semi-responsive" data-version="2">…</body>
   * ```
   */
  @include modifier('semi-responsive') {
    @include sidebar-minified;

    @include element('content') {
      overflow: auto;
    }

    @include element('content-container') {
      min-width: rem($layout-content-min-width);
    }

    @include and-modifier('sidebar-expanded') {
      @include sidebar-expanded;
    }

    @include and-modifier('no-minified-sidebar') {
      @include sidebar-expanded;
    }
  }

  /**
   * ## No-minified sidebar
   * `.layout--no-minified-sidebar`
   *
   * If the viewport width is equal or more than 1024px:
   * - The sidebar toggler button is hidden
   * - The sidebar is always expanded, and can not be minified
   *
   * We use this modifier in the PDUI documentation, so you can see it in action in this page :)
   *
   * ```html
   * <body class="layout layout--no-minified-sidebar" data-version="2">…</body>
   * ```
   *
   * <div class="alert top-margin">
   *   <svg role="img" aria-labelledby="alert-no-minified-sidebar" focusable="false" class="alert__icon icon">
   *     <title id="alert-no-minified-sidebar">Information</title>
   *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *   </svg>
   *   <p>If you combine the modifiers <code>.layout--no-minified-sidebar</code> and <code>.layout--semi-responsive</code>, the sidebar will always be expanded regardless of the screen size.</p>
   * </div>
   */
  @include modifier('no-minified-sidebar') {
    @media not all and (max-width: $tablet) {
      @include sidebar-expanded;
    }
  }

  &:not(.layout--no-minified-sidebar) {
    @media not all and (max-width: $tablet) {
      @include sidebar-minified; // If the modifier is not used, the sidebar is minified > $tablet
    }

    @include and-modifier('sidebar-expanded') {
      @media not all and (max-width: $tablet) {
        @include sidebar-expanded;
      }
    }
  }

  /**
   * ## Light sidebar
   * `.layout--light-sidebar`
   *
   * Display the sidebar navigation with a white background, in line with UKG brand.
   * Besides adding the modifier class `.layout--light-sidebar`, you also should:
   * - Remove all sidebar elements modifiers for dark background, such as `.vertical-menu--dark`, `.avatar--on-dark` and `icon--white`
   * - Use the [teal UKG logo](https://github.com/UKGEPIC/hrsd-peopledoc-ui/blob/master/packages/peopledoc-ui/contributing-docs/ASSETS.md) for white backgrounds
   *
   * ```html
   * <body class="layout layout--light-sidebar" data-version="2">…</body>
   * ```
   */

  @include modifier('light-sidebar') {
    @include element('sidebar') {
      color: $base-font-color;
    }

    @include element('sidebar-content') {
      background-color: $white;
      border-right: rem(1px) solid $block-border-color;
    }

    @include element('sidebar-logo') {
      background-color: $white;

      &:focus,
      &:active {
        @include focus-outline-pseudo-element($gap: 4px);
      }
    }

    @include element('close-button') {
      &:focus,
      &:active {
        &::before {
          border-color: color(primary, mid);
        }
      }
    }
  }
}

/*
  When the sidebar is minified and the user hovers over a sidebar link
  that pops out a submenu to the right of the sidebar,
  the following CSS will make sure the ":hover" background color of the link
  will persist when the user moves the cursor from the link onto
  the popped-out submenu.
*/
@include block('vertical-menu', 2) {
  @include element('link') {
    &.layout__sidebar-drop-enabled {
      background-color: $block-darker-background-color;
    }
  }

  @include modifier('dark') {
    @include element('link') {
      &.layout__sidebar-drop-enabled {
        background-color: rgba($white, 0.15);
      }
    }
  }
}

/*
  In some implementations the element `.layout` is a child of <body>,
  which means that sidebar-drops are sibling elements, and not direct
  children of `.layout`. For this reason, we use BEM naming convention,
  but cannot use our bemify mixin because it would include a `.layout`
  parent selector.
*/

.layout__sidebar-drop {
  display: none;
  max-height: 100vh;
  padding-left: rem(8px);
  z-index: index($z-index-scale, sidebar-drop);
}

.layout__sidebar-drop-content {
  box-shadow: $ukg-elevation-08;
  border-radius: rem($border-radius-medium);
  position: relative;

  .layout__sidebar-drop-target-attached-bottom &::before {
    bottom: rem(20px);
  }

  .layout__sidebar-drop-target-attached-top &::before {
    top: rem(20px);
  }

  @include block('vertical-menu', 2) {
    border-radius: rem($border-radius-medium $border-radius-medium);

    > .vertical-menu__title {
      padding-top: rem(12px);
      max-width: rem(278px);
    }

    @include element('sub-menu') {
      visibility: visible;
      opacity: 1;
      height: auto;
      overflow: visible;
      transition: none;
      width: rem(278px);
    }

    @include element('sub-link') {
      @include menu-item-expanded($drop: true);
    }

    @include element('sub-menu') {
      .vertical-menu__title {
        padding-left: rem(24px);
      }
    }
  }
}

/*
  In some implementations the element `.layout` is a child of <body>
  without a default scrolling mechanism like <body> has, so floating
  alerts can't be sticky without adding this scrolling mechanism
*/

body:not(.layout) .layout {
  overflow-x: visible;
}
