/***
 * # Horizontal section
 *
 * A background container for breaking a page into horizontal rows.
 * Combine with containers to create horizontal layouts.
 */

/**
 * Basic horizontal section:
 * `.horizontal-section`
 *
 * A horizontal background element.
 *
 *     @example
 *     section.horizontal-section
 *       p.no-top-margin Content with #[a(href='#') a link].
 */
@include block('horizontal-section') {
  overflow: auto;

  /**
   * White horizontal section:
   *
   * `.horizontal-section--white`
   *
   * A horizontal section with a white background.
   *
   *   @example
   *   section.horizontal-section.horizontal-section--white
   *     p.no-top-margin Content with #[a(href='#') a link].
   */
  @include modifier('white') {
    background-color: $white;
    @include rem(border-top, 1px solid color(grey, 5));
    @include rem(border-bottom, 1px solid color(grey, 5));
  }

  /**
   * Primary horizontal section:
   *
   * `.horizontal-section--primary`
   *
   * A horizontal section with a primary color background.
   *
   *   @example
   *   section.horizontal-section.horizontal-section--primary
   *     p.no-top-margin Content with #[a(href='#') a link].
   */
  @include modifier('primary') {
    background-color: var(--theme-background-color);
    color: $font-color-on-primary;

    .white {
      color: $font-color-on-primary; // overwrite white text modifiers inside the component
    }

    a:not(.button) {
      @include on-color-link('primary');
    }
  }

  /**
   * Dark horizontal section:
   *
   * `.horizontal-section--dark`
   *
   * A horizontal section with a dark color background.
   *
   *   @example
   *   section.horizontal-section.horizontal-section--dark
   *     p.no-top-margin Content with #[a(href='#') a link].
   */
  @include modifier('dark') {
    background-color: color(grey, 3);
    color: $white;
    @include rem(border-top, 1px solid color(grey, 1));
    @include rem(border-bottom, 1px solid color(grey, 1));

    a:not(.button) {
      @include on-dark-link;
    }
  }

  /**
   * Light horizontal section:
   *
   * `.horizontal-section--light`
   *
   * A horizontal section with a light color background.
   *
   *   @example
   *   section.horizontal-section.horizontal-section--light
   *     p.no-top-margin Content with #[a(href='#') a link].
   */
  @include modifier('light') {
    background-color: color(grey, 6);
  }

  /**
   * Horizontal section with bottom border:
   *
   * `.horizontal-section--bottom-border`
   *
   * A horizontal section with a bottom border.
   *
   *   @example
   *   section.horizontal-section.horizontal-section--bottom-border
   *     p.no-top-margin Content with #[a(href='#') a link].
   */
  @include modifier('bottom-border') {
    @include rem(border-bottom, 5px solid color(primary, mid));
  }
}
