/***
 * # Fieldsets
 *
 * A container for breaking form controls into semantic sections.
 *
 * ## When to use fieldset
 *
 * - To group related form controls inside of an HTML `form` element
 *
 * ## When not to use fieldset
 *
 * - To group content other than form controls. If you need to create a section for regular text content, check the [card](blocks-card.html) component instead
 *
 * ## Accessibility
 *
 * - Always include a `<legend>` at the top of each fieldset to act as a title for that for section
 *
 */

$legend-border-color: hsla(var(--grey-level-5-h), var(--grey-level-5-s), var(--grey-level-5-l), 0.4);

/**
 * Basic fieldset:
 *
 * `.fieldset`
 *
 * A fieldset with a legend.
 *
 *   @example
 *   include ./code-samples/basic-fieldset.pug
 */
@include block('fieldset') {
  position: relative;
  border: 0;
  padding: 0;
  margin: $ukg-spacing-m 0;
  min-width: 0; /* Webkit fix to remove the native fieldset min-width */
  width: 100%;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: $ukg-spacing-xxl;
  }

  > legend {
    display: block;
    width: 100%;
    @include h3;
    padding-bottom: $ukg-spacing-s;
    border-bottom: solid rem(1px) $legend-border-color;
    margin-bottom: $ukg-spacing-l;
  }

  /**
   * Small fieldset:
   *
   * `.fieldset--small`
   *
   * A small fieldset with a legend.
   *
   *   @example
   *   include ./code-samples/small-fieldset.pug
   */
  @include modifier('small') {
    margin: $ukg-spacing-m 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: $ukg-spacing-s;
    }

    > legend {
      @include h6;
      padding-bottom: $ukg-spacing-xs;
      margin-bottom: $ukg-spacing-s;
      top: 0;
    }
  }

  /**
   * Required fieldset:
   *
   * `.fieldset--required`
   *
   * A required fieldset. All the input elements inside the fieldset **must** be required.
   *
   * A typical use case for this is a required group of radio buttons.
   *
   *   @example
   *   include ./code-samples/required-fieldset.pug
   */
  @include modifier('required') {
    > legend {
      &::after {
        @include required-field-marker;
      }
    }
  }
}

@media all and (max-width: $mobile) {
  @include block('fieldset') {
    &:last-of-type {
      margin: $ukg-spacing-s 0;
    }

    > legend {
      margin-bottom: 0;
    }
  }
}

/**
 * Disabled fieldset:
 *
 * A disabled fieldset will disable every input elements inside.
 *
 * <div class="alert alert--warning top-margin">
 *   <p>Note that the fieldset's <code>disabled</code> attribute only applies on native input elements.</p>
 *   <p>For the <code>select</code> case, you should manually disable it as described on its <a href="blocks-select.html#disabled-select">dedicated page</a>.</p>
 * </div>
 *
 *   @example
 *   include ./code-samples/disabled-fieldset.pug
 */
