/***
 * # Dropdowns
 *
 * A dropdown is a menu (hidden by default) that appears under an element when
 * this element is clicked. It appears on top of the other interface elements.
 *
 * Buttons that trigger dropdowns should be labelled with a [menu-caret-down](#use-case-button-group) or a [menu-overflow](#use-case-dropdown-with-a-tooltip) icon.
 *
 * ## When to use dropdowns
 *
 * Dropdowns are great for including several menu options in crowded interfaces.
 *
 * When using a dropdown, it is important that the links on the dropdown menu
 * are linked thematically and that the trigger
 * is an accurate label for the menu content.
 *
 * ## When not to use dropdowns
 *
 * Avoid using dropdowns for one item - use a button or a link instead.
 * For a small number of items, consider using a button group if you have the space.
 *
 * If there are many links in a dropdown, it can become difficult for the user
 * to find what they're looking for; try to limit the list to no more than 7 links.
 *
 * Do not use a dropdown for otherthings than a vertical menu as content.
 *
 * ## Position and style
 *
 * Dropdowns can be positioned anywhere in the UI. If using a button for a
 * trigger, adhere to the rules for styling and positioning buttons.
 *
 * ## Accessibility
 *
 * - The dropdown trigger has the attributes `aria-haspopup="true" aria-controls="dropdown-list-ID"`
 * - The list element `.vertical-menu > ul` has the attributes `role="menu" aria-labelledby="toggle-id"`
 * - The `li` elements have the attribute `role="presentation"`
 * - The elements `.vertical-menu__link` have the attributes `role="menuitem" tabindex="-1"`
 * - The dropdown trigger and the dropdown menu must always have an accessible name. There are different ways to archive this. See details in the examples below
 *
 * ## Keyboard interaction
 *
 * With focus on the dropdown button:
 * - <kbd>Enter</kbd> or <kbd>Space</kbd>: opens the dropdown menu, and moves focus to the first item.
 *
 * With focus inside of the dropdown menu:
 * - <kbd>↓</kbd>: moves focus to the next item. If focus is on the last item, moves focus to the first.
 * - <kbd>↑</kbd>: moves focus to the previous item. If focus is on the first item, moves focus to the last.
 * - <kbd>Home</kbd> or <kbd>PageUp</kbd>: moves focus to the first item.
 * - <kbd>End</kbd> or <kbd>PageDown</kbd>: moves focus to the last item.
 * - <kbd>Esc</kbd>: closes the dropdown menu, and moves focus to the dropdown button.
 *
 * ## Initialization
 *
 * <div class="alert alert--danger top-margin top-margin--small">
 *   <p>This component will auto-instantiate unless it has the <code>[data-no-auto]</code> attribute at page load (DOMContentLoaded) or on <code>init.pdui</code> event recept on an ancestor.<p>
 *   <p>If the component is added to the page dynamically, trigger the <code>init.pdui</code> event on a parent node to instantiate it.</p>
 *   <p>If the component is not instantiated, it will auto-instantiate as soon as a method of that component is called programmatically, regardless of the presence of <code>[data-no-auto]</code>.</p>
 *   <p>If you don't set the component to auto-instantiate and you wish to instantiate it manually (pass options to it etc.) do:<br><code>$(el).dropdown();</code></p>
 * </div>
 *
 */

/**
 * Basic dropdown:
 *
 * `.dropdown`
 *
 * A dropdown menu.
 *
 *   @example
 *   include ./code-samples/basic-dropdown.pug
 */

/**
 * Right aligned dropdown:
 *
 * `.dropdown--align-right`
 *
 * A dropdown menu where the menu is right aligned.
 *
 *   @example
 *   include ./code-samples/right-aligned-dropdown.pug
 */
@include block('dropdown') {
  display: none;
  z-index: index($z-index-scale, dropdown);
  border-radius: rem($border-radius-medium);
  background-color: $block-background-color;
  box-shadow: $ukg-elevation-08;
  @include rem(min-width, 200px);
  @include rem(max-width, 350px);
  @include rem(margin-top, 2px);

  /*
    If the menu is lined, remove the top and bottom lines
    (which otherwise create a double border with the card border)
  */
  @include block('vertical-menu') {
    border-radius: rem($border-radius-medium);

    li:first-child > .vertical-menu__link {
      border-radius: rem($border-radius-medium) 0 0;
    }

    li:last-child > .vertical-menu__link {
      border-radius: 0 0 rem($border-radius-medium);
    }

    @include modifier('lined') {
      border-top: 0;

      li:last-child > .vertical-menu__link {
        border-bottom: 0;
      }
    }
  }

  @include modifier('visible') {
    display: block;
    visibility: visible;
  }
}

/**
 * Use case: button group:
 *
 * `.dropdown`
 *
 * A dropdown menu of a button group.
 *
 *   @example
 *   include ./code-samples/button-group-dropdown.pug
 */

/**
 * Use case: dropdown with a tooltip:
 *
 * A dropdown with a tooltip. Use it when the toggle button contains an icon only.
 *
 * <div class="card card--standout top-margin">
 *   <div class="card__body">
 *     <p>
 *       <svg class="icon icon--small right-margin right-margin--tiny" focusable="false">
 *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
 *       </svg>
 *       <strong>Accessibility</strong>
 *     </p>
 *     <p>Both, the dropdown trigger and the <code>ul[role="menu"]</code> are labelled by the content of the tooltip.</p>
 *     <p>Use <code>aria-labelledby="tooltip-ID"</code> in the dropdown trigger <code>button</code> and in the <code>.dropdown > ul[role="menu"]</code> to properly provide accessible names in this use case.</p>
 *   </div>
 * </div>
 *
 *     @example
 *     include ./code-samples/dropdown-with-tooltip.pug
 */
