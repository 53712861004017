/***
 * # Input groups
 *
 * A form layout component that allows to align a group of fields next to each other in one row.
 *
 * ## When to use input groups
 *
 * Input groups should be used to position form fields side by side.
 *
 * ## When not to use input groups
 *
 * Input groups should not be used to align elements other than form fields. In that case, you might use [cells](./docs/blocks-cells.html) instead.
 *
 * ## Position and style
 *
 * Fields will wrap in multiple lines when there is not enough room.
 *
 */

/**
* Basic input group:
* `.input-group`<br>
* `> .input-group__item`
*
*   @example
*   include ./code-samples/basic-input-group.pug
*/
@include block('input-group') {
  display: flex;
  flex-wrap: wrap;
  margin-top: rem(-$input-group-gap);
  margin-right: rem(-$input-group-gap / 2);
  margin-left: rem(-$input-group-gap / 2);

  @include element('item') {
    display: flex;
    flex-direction: column;
    padding-top: rem($input-group-gap);
    padding-right: rem($input-group-gap / 2);
    padding-left: rem($input-group-gap / 2);
  }
}
