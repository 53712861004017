/***
 *
 * <div class="alert alert--warning bottom-margin">
 *   <svg class="alert__icon icon" aria-hidden="true" focusable="false">
 *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning">
 *   </svg>
 *   <p class="h4">Deprecation warning</p>
 *   <p>This version of the file upload component has been deprecated and it will be deleted in a future version of PDUI.</p>
 *   <p>Please, migrate to [upload-button](./blocks-upload-button.html) or [upload-area](./blocks-upload-area.html).</p>
 * </div>
 *
 * # File upload
 *
 * An interactive component for uploading files to a system.
 * Using this component users can upload multiple documents, either by clicking
 * on the upload link, or dropping documents directly onto the upload zone.
 *
 * ## Position and style
 * File uploads should used within forms, inside high level containers (e.g. cards).
 * They should generally not be positioned directly against the application
 * background.
 *
 * ## Behaviour in applications
 * This File upload will upload files to a specified URL as soon as they are added.
 * If you intend to use an inline file upload that will submit the file to the server
 * when the form is submitted, you must use a `<input type="file">` instead of this
 * file uploader.
 *
 * ## Initialization
 *
 * <div class="alert alert--danger top-margin top-margin--small">
 *   <p>This component will auto-instantiate unless it has the <code>[data-no-auto]</code> attribute at page load (DOMContentLoaded) or on <code>init.pdui</code> event recept on an ancestor.<p>
 *   <p>If the component is added to the page dynamically, trigger the <code>init.pdui</code> event on a parent node to instantiate it.</p>
 *   <p>If the component is not instantiated, it will auto-instantiate as soon as a method of that component is called programmatically, regardless of the presence of <code>[data-no-auto]</code>.</p>
 *   <p>If you don't set the component to auto-instantiate and you wish to instantiate it manually (pass options to it etc.) do:<br><code>$(el).uploader();</code></p>
 * </div>
 *
 * ## Options
 *
 * The file upload component needs to know the path to the SVG icons. You have two ways to declare the icons path on the component level:
 * - Using the `iconsPath` option at initialization
 * - Setting the `data-icons-path` attribute on the element `.file-upload`
 * If none of the above is found, the file upload component will use the path from the `data-icons-path` attribute on the `<body>` element.
 *
 */

$file-upload-max-width:                   600px;
$file-upload-border-width:                $target-border-width;
$file-upload-border-color:                $target-border-color;

$file-upload-instuctions-padding:         $target-padding;
$file-upload-instructions-border-width:   $target-inner-border-width;
$file-upload-instructions-border-color:   $target-inner-border-color;
$file-upload-instructions-hover-color:    $target-inner-hover-color;
$file-upload-instuctions-margin:          $target-inner-margin;
$file-upload-feedback-top-margin:         15px; // Spacing between content and alert

$file-upload-error-color:                 color(danger, dark);
$file-upload-error-padding:               20px;

@include block('file-upload', 1) {
  max-width: rem($file-upload-max-width);
  font-size: rem($base-font-size);

  @include element('content') {
    @include form-field-color-variation(standard);
    border: rem($file-upload-border-width) solid $file-upload-border-color;
    border-radius: rem($form-field-border-radius);

    > form {
      overflow: auto;
    }
  }

  @include mixie('table') {
    table-layout: fixed;
    word-break: break-all;
    width: 100%;
    border: 0;

    .table__column {
      padding-right: rem(10px);
    }

    // respect specificity from table
    .table__body .table__row:last-child .table__column {
      border-bottom-width: rem(1px);
    }
  }

  @include mixie('filename') {
    width: 65%;
  }

  @include mixie('progress') {
    width: 20%;
  }

  @include mixie('actions') {
    width: 15%;

    a {
      cursor: pointer;
    }
  }

  @include element('error-info') {
    display: none;
  }

  @include element('file') {
    @include modifier('error') {
      .table__column {
        border-bottom: 0;
      }

      + .file-upload__error-info {
        display: table-row;
      }

      @include element('remove-action') {
        display: none;
      }

      @include element('size') {
        display: none;
      }

      @include element('filename') {
        width: auto;
      }

      @include element('error') {
        display: table-cell;
      }
    }
  }

  @include element('instructions') {
    display: block;
    text-align: center;
    width: calc(100% - #{rem($file-upload-instuctions-margin * 2)});
    height: 100%;
    padding: rem($file-upload-instuctions-padding);
    cursor: pointer;
    border: rem($file-upload-instructions-border-width) dotted $file-upload-instructions-border-color;
    margin: $file-upload-instuctions-margin;
    font-weight: $bold;

    &:hover {
      background-color: $file-upload-instructions-hover-color;
      color: $font-color-primary-on-white;
    }

    span {
      display: block;
      font-size: rem($small-font-size);
      font-weight: $regular;
    }
  }

  /*
    The class below (`.dz-drag-hover`) is added by Dropzone when a user drags
    a file over the form target. We want to apply a 'hover' style to deal
    with this event:
  */
  .dz-drag-hover {
    @include element('instructions') {
      text-decoration: none;
      background-color: $file-upload-instructions-hover-color;
    }
  }

  @include mixie('feedback') {
    margin-top: rem($file-upload-feedback-top-margin);
  }

  @include mixie('error') {
    color: $file-upload-error-color;
    padding-right: rem($file-upload-error-padding);
  }

  @include modifier('with-button') {
    @include element('select') {
      position: relative;
      display: inline-block;
    }

    @include mixie('button') {
      position: relative;
    }

    @include mixie('input') {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    p {
      text-transform: uppercase;
      font-weight: $bold;
    }

    @include element('state') {
      ul {
        padding-left: rem(20px);
        margin-top: rem(10px);
      }

      @include modifier('empty') {
        p {
          color: form-field-color(invalid, text);
          text-transform: capitalize;
        }

        ul {
          display: none;
        }
      }
    }
  }

  @include modifier('disabled') {
    @include disabled;

    * {
      pointer-events: none;
    }
  }
}
