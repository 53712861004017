/***
 * # Avatars
 *
 * An avatar is an image that represents a user.
 * When an image of a user is available, it will be displayed.
 * If the image is not available, the initials of the user will be displayed instead.
 *
 * ## When to use avatars
 *
 *  - To highlight a user's account area
 *  - To identify an employee
 *  - In comment threads
 *
 * ## When not to use avatars
 *
 * Avoid using large numbers of small avatars (for example, in an employee list), as this can create a busy interface.
 *
 * ## Position and style
 *
 * Avatars can be positioned anywhere in the interface against either a light or dark background.
 *
 * ## Deprecation warnings
 *
 * - [Avatars with a primary color icon are deprecated](#avatar-with-primary-color-icon)
 * - [Avatars on a primary background color must have the modifier `.avatar--on-primary`](#avatar-without-on-primary-modifier)
 * - [Avatars on a dark background color must have the modifier `.avatar--on-dark`](#avatar-without-on-dark-modifier)
 */

$avatar-large-size:          50px;
$avatar-medium-size:         30px;
$avatar-small-size:          20px;
$avatar-border-radius:       50%;
$avatar-fallback-text-large: 21px;
$avatar-fallback-text-mid:   14px;
$avatar-fallback-text-small: 9px;
$avatar-color:               color(grey, 3);

$avatar-icon-size:           39px;
$avatar-icon-padding:        4px;
$avatar-icon-medium-size:    20px;
$avatar-icon-small-size:     18px;

// Generate a fallback avatar
@mixin avatar-fallback($size: 'large') {
  font-family: $metadata-font;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  overflow: hidden;
  border: 0;
  vertical-align: middle;
  color: $white;
  background-color: $avatar-color;

  @if $size == 'large' {
    font-size: rem($avatar-fallback-text-large);
    line-height: rem($avatar-large-size);
  } @else if $size == 'medium' {
    font-size: rem($avatar-fallback-text-mid);
    line-height: rem($avatar-medium-size);
  } @else { // small
    font-size: rem($avatar-fallback-text-small);
    line-height: rem($avatar-small-size);
  }
}

// Medium Avatar
@mixin medium-avatar {
  width: rem($avatar-medium-size);
  height: rem($avatar-medium-size);
  max-width: rem($avatar-medium-size);
  max-height: rem($avatar-medium-size);
  border-width: rem(1px);
}

/**
 * Basic avatar:
 * `.avatar`
 *
 * A standard (large) avatar.
 *
 *     @example
 *     img.avatar(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/avatar-female-1.png' alt='User Name')
 */
@include block('avatar') {
  width: rem($avatar-large-size);
  height: rem($avatar-large-size);
  max-width: rem($avatar-large-size);
  max-height: rem($avatar-large-size);
  border: rem(2px) solid $avatar-color;
  border-radius: rem($avatar-border-radius);

  /**
   * Avatar with icon:
   * `.avatar__icon`
   *
   * An avatar containing an icon instead.
   *
   *     @example
   *     include ./code-samples/avatar-with-icon.pug
   */
  @include element('icon') {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    vertical-align: top;
  }

  /**
   * Fallback avatar:
   * `.avatar--fallback`
   *
   * A fallback avatar.
   *
   *     @example
   *     span.avatar.avatar--fallback ww
   */
  @include modifier('fallback') {
    @include avatar-fallback;
  }

  /**
   * Medium avatar:
   * `.avatar--medium`
   *
   * A medium avatar.
   *
   *     @example
   *     include ./code-samples/medium-avatar.pug
   */
  @include modifier('medium') {
    @include medium-avatar;

    @include and-modifier('fallback') {
      @include avatar-fallback($size: 'medium');
    }

    @include element('icon') {
      width: rem($avatar-icon-medium-size);
      height: rem($avatar-icon-medium-size);
    }
  }

  /**
   * Small avatar:
   * `.avatar--small`
   *
   * A small avatar.
   *
   *     @example
   *     include ./code-samples/small-avatar.pug
   */
  @include modifier('small') {
    width: rem($avatar-small-size);
    height: rem($avatar-small-size);
    max-width: rem($avatar-small-size);
    max-height: rem($avatar-small-size);
    border: 0;

    @include and-modifier('fallback') {
      border: rem(2px) solid $avatar-color;
      @include avatar-fallback($size: 'small');
    }

    @include element('icon') {
      width: rem($avatar-icon-small-size);
      height: rem($avatar-icon-small-size);
    }
  }

  /**
   * Avatar on primary background:
   * `.avatar--on-primary`
   *
   * An avatar for using on primary color background.
   *
   * <div class="alert top-margin">
   *   <p>On <a href="base-colors.html#themes">custom themes</a>, the color of the avatar is <code>white</code> if the theme
   *   primary color is dark, and <code>dark grey</code> if the theme primary color is light.
   * </div>
   *
   *     @example
   *     .horizontal-section.horizontal-section--primary(style='padding: 1rem;')
   *       img.avatar.avatar--on-primary(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/avatar-female-3.png' alt='User Name')
   *       .avatar.avatar--on-primary.vertical-margin.vertical-margin--small
   *         svg.avatar__icon.icon(role='img' aria-labelledby='avatar-icon-4', focusable='false')
   *           title#avatar-icon-4 User name
   *           use(xlink:href='../icons.svg#peoplebot' href='../icons.svg#peoplebot')
   *       span.avatar.avatar--on-primary.avatar--fallback ww
   */
  @include modifier('on-primary') {
    border-color: $font-color-on-primary;

    @include element('icon') {
      &.icon--primary {
        color: $font-color-on-primary;
      }
    }

    @include modifier('fallback') {
      background-color: $font-color-on-primary;
      color: var(--avatar-color);
    }
  }

  /**
   * Avatar on dark background:
   * `.avatar--on-dark`
   *
   * An avatar for using on dark color background.
   *
   *     @example
   *     .horizontal-section.horizontal-section--dark(style='padding: 1rem;')
   *       img.avatar.avatar--on-dark(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/avatar-male-2.png' alt='User Name')
   *       .avatar.avatar--on-dark.vertical-margin.vertical-margin--small
   *         svg.avatar__icon.icon(role='img' aria-labelledby='avatar-icon-4', focusable='false')
   *           title#avatar-icon-4 User name
   *           use(xlink:href='../icons.svg#peoplebot' href='../icons.svg#peoplebot')
   *       span.avatar.avatar--on-dark.avatar--fallback ww
   */
  @include modifier('on-dark') {
    border-color: $white;

    @include element('icon') {
      &.icon--primary {
        color: $white;
      }
    }

    @include modifier('fallback') {
      background-color: $white;
      color: $avatar-color;
    }
  }

  /**
  * ## Deprecations
  */

  /**
   * Avatar with primary color icon:
   * `.avatar__icon.icon--primary`
   *
   * <div class="alert alert--warning top-margin">
   *   <p>The class <code>.icon--primary</code> must be removed from the element <code>.avatar__icon</code>.</p>
   * </div>
   *
   *     @example
   *     .avatar
   *       svg.avatar__icon.icon.icon--primary(role='img' aria-labelledby='avatar-icon-6', focusable='false')
   *         title#avatar-icon-6 User name
   *         use(xlink:href='../icons.svg#peoplebot' href='../icons.svg#peoplebot')
   */
  @include element('icon') {
    &.icon--primary {
      color: $avatar-color;
    }
  }

  /**
   * Avatar without on-primary modifier:
   * `.avatar`
   *
   * <div class="alert alert--warning top-margin">
   *   <p>If an avatar is displayed on primary background color, it must have the modifier class <code>.avatar--on-primary</code>.</p>
   * </div>
   *
   *     @example
   *     .horizontal-section.horizontal-section--primary
   *       .avatar
   *         svg.avatar__icon.icon.icon--primary(role='img' aria-labelledby='avatar-icon-7', focusable='false')
   *           title#avatar-icon-7 User name
   *           use(xlink:href='../icons.svg#peoplebot' href='../icons.svg#peoplebot')
   *     .header.top-margin
   *       img.avatar(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/avatar-female-2.png' alt='User Name')
   *     .horizontal-section.horizontal-section--primary.top-margin
   *       span.avatar.avatar--fallback ww
   *
   */
  .horizontal-section--primary &,
  .header & {
    border-color: $font-color-on-primary;

    @include element('icon') {
      &.icon--primary {
        color: $font-color-on-primary;
      }
    }

    @include modifier('fallback') {
      background-color: $font-color-on-primary;
      color: var(--avatar-color);
    }
  }

  /**
   * Avatar without on-dark modifier:
   * `.avatar`
   *
   * <div class="alert alert--warning top-margin">
   *   <p>If an avatar is displayed on dark background color, it must have the modifier class <code>.avatar--on-dark</code>.</p>
   * </div>
   *
   *     @example
   *     nav.vertical-menu.vertical-menu--dark(data-version='2')
   *       ul
   *         li
   *           button.vertical-menu__link.vertical-menu__link--with-avatar(type='button')
   *             img.avatar.avatar--medium(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/avatar-female-1.png' alt='')
   *             span.vertical-menu__link-title My account
   *     .horizontal-section.horizontal-section--dark.top-margin
   *       .avatar
   *         svg.avatar__icon.icon.icon--primary(role='img' aria-labelledby='avatar-icon-8', focusable='false')
   *           title#avatar-icon-8 User name
   *           use(xlink:href='../icons.svg#peoplebot' href='../icons.svg#peoplebot')
   *     .header.header--dark.top-margin
   *       img.avatar(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/avatar-male-3.png' alt='User Name')
   *     .horizontal-section.horizontal-section--dark.top-margin
   *       span.avatar.avatar--fallback ww
   *
   */
  .header--dark &,
  .horizontal-section--dark &,
  .vertical-menu--dark & {
    border-color: $white;

    @include element('icon') {
      &.icon--primary {
        color: $white;
      }
    }

    @include modifier('fallback') {
      background-color: $white;
      color: $avatar-color;
    }
  }
}
