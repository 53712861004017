/***
 * # Popovers
 *
 * - A popover is a small block that appears on top of other page elements
 * - Popovers are positioned next to and point towards their trigger
 * - Popovers are activated on click
 * - Re-click on the trigger, or click outside, hides the the popover
 *
 * ## When to use popovers
 *
 * - Popovers are ideal for providing supplementary or additional information
 * to the page without crowing the UI. For example, popovers can be used
 * to reveal help text
 * - The trigger for a popover **must** be an HTML `button` element that exists
 * to reveal the popover and serves no other purpose
 *
 * ## When not to use popovers
 *
 * - Popovers, like [tooltips](blocks-tooltip.html), should not be used for
 * critical information, as they are less discoverable than on-page content
 * - Popovers are ideal for short content, but should not be used to display a lot of
 * information. For any content over a paragraph long, reconsider the UI. If you are looking for
 * a way to disclose longer content, check out [accordions](blocks-accordion.html) and [modals](blocks-modal.html)
 * - Popovers should not be used with a trigger that has a purpose other than revealing the
 * popover, as for example a submit button or a link. For such cases, you might want to use
 * a [tooltip](blocks-tooltip.html) instead, or add an additional popover trigger button [next to the interactive
 * element](#use-case-a-link-with-a-popover)
 *
 * ## Position and style
 *
 * - Popovers can be positioned relative to their trigger by using the `top`,
 * `right`, `bottom` and `left` modifiers. The position of the popover will
 * update dynamically if the viewport changes and the popover is no longer
 * visible
 * - Popovers might contain a header, multiple lines of text and interactive
 * elements such as links
 * - The trigger of the popover can be styled as a regular [button](blocks-button.html#basic-button), a [text button](blocks-button.html#text-button)
 * or an [icon-only button](blocks-button.html#icon-only-button), depending on the use case
 *
 * ## Accessibility
 *
 * - The trigger must be an HTML `button` element that serves no other purpose than revealing the popover
 * - The element `.popover` must have a unique ID attribute
 * - The attribute `aria-expanded="false"` is added to the trigger programmatically and updated to `aria-expanded="true"` when the popover is visible
 * - The attribute `aria-controls="uniqueID"` that refers to the element `.popover#uniqueID` is added to the trigger programmatically
 * - Popovers are positioned next to the trigger in the DOM to ensure a logical tab order
 *
 * ## Initialization
 *
 * <div class="alert alert--danger top-margin top-margin--small">
 *   <p>This component will auto-instantiate unless it has the <code>[data-no-auto]</code> attribute at page load (DOMContentLoaded) or on <code>init.pdui</code> event recept on an ancestor.<p>
 *   <p>If the component is added to the page dynamically, trigger the <code>init.pdui</code> event on a parent node to instantiate it.</p>
 *   <p>If the component is not instantiated, it will auto-instantiate as soon as a method of that component is called programmatically, regardless of the presence of <code>[data-no-auto]</code>.</p>
 *   <p>If you don't set the component to auto-instantiate and you wish to instantiate it manually (pass options to it etc.) do:<br><code>$(el).popover();</code></p>
 * </div>
 *
 * ## Deprecation warnings
 *
 * - Popovers revealed by anything else than an HTML `button` are deprecated. Backwards compatibility will be removed in a future version of PDUI.
 * We recommend to do the following updates as soon as possible:
 *   - [Replace all non-interactive popover triggers by a button](#non-interactive-popover-triggers) - e.g. `svg`, `span`, or `p` elements
 *   - If the trigger carries a function other than revealing the popover, e.g. a link or a submit button, [add a dedicated popover trigger next to it](#a-link-with-a-popover) or use a [tooltip](blocks-tooltip.html#tooltip-as-auxiliary-description) instead
 *   - If the popover has a parent that does not allow interactive content, refactor your code. E.g.: [a button cannot be the child of a label element](#a-popover-inside-of-a-label)
 *
 */

$popover-min-width:           150px;
$popover-max-width:           250px;
$popover-vertical-padding:    10px;
$popover-horizontal-padding:  8px;
$popover-padding:             $popover-vertical-padding $popover-horizontal-padding;
$popover-title-padding:       7px 8px;

$popover-bg:                  $white;
$popover-border-radius:       $border-radius-medium;
$popover-border-color:        $block-darker-border-color;

// Darken the border around the arrow to create emphasis & make it more visually consistent
$popover-arrow-border-color:  $block-darker-border-color;

$popover-arrow-size:          13px; // The actual arrow head size will be half of this value
$popover-arrow-offset:        $popover-arrow-size / 2;

$popover-hover-border-color:  $block-darker-border-color;

// Help popover
$help-text-color:             $font-color-on-primary;
$help-icon-size:              14px;
$help-icon-margin:            2px; // Space between help icon and text
$help-border-radius:          50%;

// If you change me, make sure you also change the docs!
$popover-colors: 'primary', 'warning', 'danger', 'success', 'info';

/**
 * Basic popover:
 * `.popover`
 *
 * A standard popover.
 *
 *     @example
 *     include ./code-samples/standard.pug
 */

/**
 * Bottom popover:
 * `.popover--bottom`
 *
 * A popover that appears below the trigger.
 *
 *     @example
 *     include ./code-samples/standard-bottom.pug
 */

/**
 * Left popover:
 * `.popover--left`
 *
 * A popover that appears left of the trigger.
 *
 *     @example
 *     include ./code-samples/standard-left.pug
 */

/**
 * Right popover:
 * `.popover--right`
 *
 * A popover that appears right of the trigger.
 *
 *     @example
 *     include ./code-samples/standard-right.pug
 */
@include bubble('popover', $ukg-spacing-xs);

span[data-toggle='popover'] {
  position: relative;

  &::after {
    border-bottom: rem(1px) solid $popover-hover-border-color;
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
  }
}

@include block('popover') {
  z-index: index($z-index-scale, popover);

  &:hover {
    cursor: default;
  }

  @include element('body') {
    min-width: rem($popover-min-width);
    max-width: rem($popover-max-width);
    border-radius: rem($popover-border-radius);
    font-size: rem($base-font-size);
    background-color: $popover-bg;
    box-shadow: $ukg-elevation-08;
  }

  @include element('title') {
    padding: rem($popover-padding);
    @include h5;
    margin-bottom: 0;
    line-height: 1;
    border-bottom: rem(1px) solid hsla(var(--grey-level-4-h), var(--grey-level-4-s), var(--grey-level-4-l), 0.4);
  }

  @include element('content') {
    @include no-first-child-margin;
    padding: rem($popover-padding);

    // Reduce subsequent typography margins inside the popover
    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6,
    > p {
      margin-top: $type-margin / 2;
    }
  }

  /**
   * Popover with a small padding:
   * `.popover--padding-small`
   *
   * It uses half of the padding in popover title and content.
   *
   *     @example
   *     include ./code-samples/small-padding.pug
   */
  @include modifier('padding-small') {
    @include element('title') {
      padding: rem($popover-vertical-padding) / 2 rem($popover-horizontal-padding) / 2;
    }

    @include element('content') {
      padding: rem($popover-vertical-padding) / 2 rem($popover-horizontal-padding) / 2;
    }
  }

  /**
   * Popover with no minimum width:
   * `.popover--no-min-width`
   *
   * It removes the default `150px` minumum width. The width of the popover adapts to the content.
   *
   *     @example
   *     include ./code-samples/no-minimum-width.pug
   */
  @include modifier('no-min-width') {
    @include element('body') {
      min-width: 0;
    }
  }

  /**
   * Popover with no maximum width:
   * `.popover--no-max-width`
   *
   * It removes the default `250px` maximum width. The width of the popover adapts to the content.
   *
   *     @example
   *     include ./code-samples/no-maximum-width.pug
   */
  @include modifier('no-max-width') {
    @include element('body') {
      max-width: none;
    }
  }
}

/**
* Use case: a link with a popover:
*
* A link with an information popover button next to it.
*
* <div class="alert alert--warning top-margin">
*   <svg class="alert__icon icon" role="img" aria-labelledby="title2" focusable="false">
*     <title id="title2">Warning</title>
*     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
*   </svg>
*   <p>Popovers should not be used with a trigger that has a purpose other than revealing the popover, as for example a link.</p>
*   <p>Place a popover next to the related interactive element instead.</p>
* </div>
*
*     @example
*     include ./code-samples/link-with-popover.pug
*/
