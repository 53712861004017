/***
 * # Form help texts
 *
 * An assistive text that offers a side note or intructions within a form.
 *
 * ## When to use form help texts
 *
 * Form help texts should be used to provide instructions or assistance within forms.
 *
 * ## Position and style
 *
 * Form help texts can be placed at the begining of a form to provide overall form instructions that apply to the entire form.
 *
 * Form help texts can be placed [underneath the label](#form-help-below-the-label), or [underneath the form field](#form-help-below-the-field) to provide inline instructions to specific fields.
 *
 * If an inline [form error](./blocks-form-error.html) is also present, place the form error directly below their associated input field and [before the help text](#form-help-and-form-errors).
 *
 * ## Accessibility
 *
 * Form help texts should be programmatically associated with its related form field with the attribute `aria-describedby`.
 *
 * It is recommended to place overall form help texts before the `<form>` element to ensure that it is read aloud by screen readers before they switch to "Forms Mode".
 *
 * <small>Source: https://www.w3.org/WAI/tutorials/forms/instructions/</small>
 */

/**
* Basic form help:
* `.form-help`
*
*   @example
*   .form-help Assistive text.
*/
@include block('form-help') {
  @include ukg-metadata-small-low-emphasis();

  /**
  * Form help with multiple paragraphs:
  * `.form-help > p`
  *
  *   @example
  *   .form-help
  *     p Assistive text line 1.
  *     p Assistive text line 2.
  */
  p {
    @include ukg-metadata-small-low-emphasis();
    margin: 0;

    + p {
      margin-top: $ukg-spacing-xxs;
    }
  }
}

/**
* Form help below the label:
*
* Use [margin helpers](./helpers-margin.html) to add space between elements.
*
*   @example
*   label.label(for='input1') Label
*   .form-help.bottom-margin.bottom-margin--tiny(id='input1-help') Assistive text.
*   input.input(id='input1' aria-describedby='input1-help')
*/

/**
* Form help below the field:
*
* Use [margin helpers](./helpers-margin.html) to add space between elements.
*
*   @example
*   label.label(for='input2') Label
*   input.input(id='input2' aria-describedby='input2-help')
*   .form-help.top-margin.top-margin--tiny(id='input2-help') Assistive text.
*/

/**
* Form help and form errors:
*
* A field with both an error message and a form help text.
*
*   @example
*   label.label(for='input3') Label
*   input.input(id='input3' aria-describedby='input3-error input3-help')
*   .form-error(id='input3-error') Error message.
*   .form-help.top-margin.top-margin--tiny(id='input3-help') Assistive text.
*/
