/***
 * # Trees
 *
 * A tree is a hierarchical list of items. Each item on a tree is a node.
 * Trees may optionally include triggers to expand and collapse child nodes,
 * or be used as a form field by including checkboxes or radios.
 *
 * ## When to use trees
 *
 * Trees should be used for displaying hierarchical information.
 *
 * ## Position and style
 *
 * Trees can be positioned inside other containers (e.g. cards or modals), or
 * directly against the main page background.
 */

$tree-line-color:                           color(grey, 3);
$tree-hover-color:                          color(grey, 1);
$tree-icon-color:                           color(grey, 3);

$tree-item-spacing:                         3px; // Vertical padding on an indidual tree item
$tree-nesting-depth:                        20px; // Left hand margin that indicates nested levels

$tree-horizontal-line-width:                10px; // Length of horizontal tree line
$tree-horizontal-line-position:             13px; // How far down the vertical line is the horizontal line
$tree-icon-margin:                          4px; // Space between icon and text
$tree-icon-size:                            16px; // Width and Height of the icons

$tree-toggle-size:                          12px; // Size of the toggle button
$tree-toggle-icon-size:                     10px; // Size of the icon inside the toggle button
$tree-toggle-background-color:              $white;
$tree-toggle-focus-color:                   color(grey, 6);
$tree-toggle-position-left:                 -$tree-nesting-depth; // Horizontal position of the toggle button

$selectable-tree-item-spacing:              0; // Decrease padding for select elements
$selectable-tree-horizontal-line-position:  14px; // Reposition the line accordingly
$selectable-tree-icon-offset:               8px; // Adjust icons and toggle to account for new spacing

/**
 * Basic tree:
 * `.tree`
 *
 * A data tree with nested elements
 *
 *     @example
 *     .tree
 *       ul
 *         li
 *           svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree1-icon-label1')
 *             title#tree1-icon-label1 Folder
 *             use(xlink:href='../icons.svg#folder' href='../icons.svg#folder')
 *           | Compliance
 *           ul
 *             li
 *               svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree1-icon-label2')
 *                 title#tree1-icon-label2 Document
 *                 use(xlink:href='../icons.svg#document' href='../icons.svg#document')
 *               | Acknowledgement of Receipt (FTZ and C-PTAT document)
 *             li
 *               svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree1-icon-label3')
 *                 title#tree1-icon-label3 Document
 *                 use(xlink:href='../icons.svg#document' href='../icons.svg#document')
 *               | I-9
 *             li
 *               svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree1-icon-label4')
 *                 title#tree1-icon-label4 Document
 *                 use(xlink:href='../icons.svg#document' href='../icons.svg#document')
 *               | Notice of Pay Rate and Payday
 *         li
 *           svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree1-icon-label5')
 *             title#tree1-icon-label5 Folder
 *             use(xlink:href='../icons.svg#folder' href='../icons.svg#folder')
 *           | Employment Related Information
 *           ul
 *             li
 *               svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree1-icon-label6')
 *                 title#tree1-icon-label6 Folder
 *                 use(xlink:href='../icons.svg#folder' href='../icons.svg#folder')
 *               | Benefits
 *               ul
 *                 li
 *                   svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree1-icon-label7')
 *                     title#tree1-icon-label7 Document
 *                     use(xlink:href='../icons.svg#document' href='../icons.svg#document')
 *                   | Benefits Enrollment Confirmation - AQ
 *                 li
 *                   svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree1-icon-label8')
 *                     title#tree1-icon-label8 Document
 *                     use(xlink:href='../icons.svg#document' href='../icons.svg#document')
 *                   |  Dental Benefit Selection
 *                 li
 *                   svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree1-icon-label9')
 *                     title#tree1-icon-label9 Document
 *                     use(xlink:href='../icons.svg#document' href='../icons.svg#document')
 *                   |  Health Benefits
 *             li
 *               svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree1-icon-label10')
 *                 title#tree1-icon-label10 Folder
 *                 use(xlink:href='../icons.svg#folder' href='../icons.svg#folder')
 *               | Contract
 *               ul
 *                 li
 *                   svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree1-icon-label11')
 *                     title#tree1-icon-label11 Document
 *                     use(xlink:href='../icons.svg#document' href='../icons.svg#document')
 *                   | Contact
 *                 li
 *                   svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree1-icon-label12')
 *                     title#tree1-icon-label12 Document
 *                     use(xlink:href='../icons.svg#document' href='../icons.svg#document')
 *                   |  Profit Sharing
 */
@include block('tree') {
  position: relative;
  @include rem(font-size, $base-font-size);

  ul,
  li {
    position: relative;
  }

  ul {
    @include rem(padding-left, $tree-nesting-depth);
    list-style: none;
    margin: 0;
  }

  li {
    @include rem(padding, $tree-item-spacing 0);
    margin: 0;

    &::before,
    &::after {
      content: '';
      position: absolute;
      @include rem(left, -$tree-horizontal-line-width - $tree-icon-margin);
    }

    &::before {
      @include rem(border-top, 1px solid $tree-line-color);
      @include rem(width, $tree-horizontal-line-width);
      @include rem(top, $tree-horizontal-line-position);
      height: 0;
    }

    &::after {
      @include rem(border-left, 1px solid $tree-line-color);
      @include rem(top, $tree-item-spacing * 2);
      height: 100%;
      width: 0;
    }
  }

  ul > li:last-child::after {
    @include rem(height, 7px);
  }

  @include mixie('icon') {
    @include rem(width, $tree-icon-size);
    @include rem(height, $tree-icon-size);
    @include rem(margin-right, $tree-icon-margin);
    color: $tree-icon-color;
  }

  /**
   * Collapsible tree:
   * `.tree--collapsible`
   *
   * A tree where nodes can be opened and closed.
   *
   * <div class="card card--standout top-margin">
   *   <div class="card__body">
   *     <p>
   *       <svg class="icon icon--small right-margin right-margin--tiny" focusable="false">
   *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
   *       </svg>
   *       <strong>Accessibility</strong>
   *     </p>
   *     <p>Make sure to add the <code>aria-expanded</code> attribute to all the toggle buttons with the value set to <code>true</code> or <code>false</code>, depending on their initial state.</p>
   *     <p>The <code>aria-labelledby</code> attribute provides a descriptive label to the toggle button while the <code>aria-controls</code> attribute identifies the <code>&lt;ul&gt;</code> element that the toggle button controls.</p>
   *   </div>
   * </div>
   *
   *     @example
   *     .tree.tree--collapsible
   *       ul
   *         li
   *           button.tree__toggle(type='button' aria-expanded='true' aria-controls='list01' aria-labelledby='label01')
   *             svg.tree__toggle-icon.tree__toggle-icon--opened.icon(aria-hidden='true' focusable='false')
   *               use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
   *           svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree2-icon-label1')
   *             title#tree2-icon-label1 Folder
   *             use(xlink:href='../icons.svg#folder' href='../icons.svg#folder')
   *           span#label01 Compliance
   *           ul#list01
   *             li
   *               svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree2-icon-label2')
   *                 title#tree3-icon-label2 Document
   *                 use(xlink:href='../icons.svg#document' href='../icons.svg#document')
   *               | Acknowledgement of Receipt (FTZ and C-PTAT document)
   *             li
   *               button.tree__toggle.tree__toggle--closed(type='button' aria-expanded='false' aria-controls='list02' aria-labelledby='label02')
   *                 svg.tree__toggle-icon.icon(aria-hidden='true' focusable='false')
   *                   use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
   *               svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree2-icon-label3')
   *                 title#tree2-icon-label3 Folder
   *                 use(xlink:href='../icons.svg#folder' href='../icons.svg#folder')
   *               span#label02 Notice of Pay Rate and Payday
   *               ul#list02
   *                 li
   *                   svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree2-icon-label4')
   *                     title#tree3-icon-label4 Document
   *                     use(xlink:href='../icons.svg#document' href='../icons.svg#document')
   *                   | US Pay Rates
   *                 li
   *                   button.tree__toggle(type='button' aria-expanded='true' aria-controls='list03' aria-labelledby='label03')
   *                     svg.tree__toggle-icon.tree__toggle-icon--opened.icon(aria-hidden='true' focusable='false')
   *                       use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
   *                   svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree2-icon-label5')
   *                     title#tree2-icon-label5 Folder
   *                     use(xlink:href='../icons.svg#folder' href='../icons.svg#folder')
   *                   span#label03 European Pay Rate
   *                   ul#list03
   *                     li
   *                       svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree2-icon-label6')
   *                         title#tree3-icon-label6 Document
   *                         use(xlink:href='../icons.svg#document' href='../icons.svg#document')
   *                       | Pay Rate - FR
   *                     li
   *                       svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree2-icon-label6')
   *                         title#tree3-icon-label6 Document
   *                         use(xlink:href='../icons.svg#document' href='../icons.svg#document')
   *                       | Pay Rate - UK
   */
  @include element('toggle') {
    position: absolute;
    @include rem(left, $tree-toggle-position-left);
    z-index: index($z-index-scale, tree-toggle);
    @include rem(height, $tree-toggle-size);
    @include rem(width, $tree-toggle-size);
    @include rem(margin-top, $tree-item-spacing);
    @include rem(border, 1px solid $tree-line-color);
    background-color: $tree-toggle-background-color;
    color: $tree-icon-color;
    cursor: pointer;
    padding: 0;

    &:hover,
    &:focus {
      color: $tree-hover-color;
      border-color: $tree-hover-color;
    }

    &:focus,
    &:active {
      @include focus-outline-pseudo-element($position: absolute, $border-radius: $border-radius-small);
      background-color: $tree-toggle-focus-color;
    }

    @include modifier('closed') {
      ~ ul {
        display: none;
      }
    }
  }

  @include mixie('toggle-icon') {
    @include rem(width, $tree-toggle-icon-size);
    @include rem(height, $tree-toggle-icon-size);
    position: absolute;
    left: 0;
    top: 0;
    @include rotate-counterclockwise;
    @include modifier('opened') {
      @include rotate-reset;
    }
  }

  /**
   * Single select tree:
   * `.tree--selectable`
   *
   * A tree where you can select one option
   *
   *     @example
   *     .tree.tree--selectable
   *       ul
   *         li
   *           .tree__input.radio
   *             input(type='radio' name='radio' value='radio1' id='radio1').radio__input
   *             label.radio__label(for='radio1')
   *               span.radio__control
   *               span.radio__display Radio 1
   *           ul
   *             li
   *               .tree__input.radio
   *                 input(type='radio' name='radio' value='radio1a' id='radio1a').radio__input
   *                 label.radio__label(for='radio1a')
   *                   span.radio__control
   *                   span.radio__display Radio 1a
   *             li
   *               .tree__input.radio
   *                 input(type='radio' name='radio' value='radio1b' id='radio1b').radio__input
   *                 label.radio__label(for='radio1b')
   *                   span.radio__control
   *                   span.radio__display Radio 1b
   *               ul
   *                 li
   *                   .tree__input.radio
   *                     input(type='radio' name='radio' value='radio1b1' id='radio1b1').radio__input
   *                     label.radio__label(for='radio1b1')
   *                       span.radio__control
   *                       span.radio__display Radio 1b1
   *                 li
   *                   .tree__input.radio
   *                     input(type='radio' name='radio' value='radio1b2' id='radio1b2').radio__input
   *                     label.radio__label(for='radio1b2')
   *                       span.radio__control
   *                       span.radio__display Radio 1b2
   *                   ul
   *                     li
   *                       .tree__input.radio
   *                         input(type='radio' name='radio' value='radio1b2a' id='radio1b2a').radio__input
   *                         label.radio__label(for='radio1b2a')
   *                           span.radio__control
   *                           span.radio__display Radio 1b2a
   *                     li
   *                       .tree__input.radio
   *                         input(type='radio' name='radio' value='radio1b2b' id='radio1b2b').radio__input
   *                         label.radio__label(for='radio1b2b')
   *                           span.radio__control
   *                           span.radio__display Radio 1b2b
   */

  /**
   * Multi select tree:
   * `.tree--selectable`
   *
   * A tree where you can select multiple options
   *
   *     @example
   *     .tree.tree--selectable
   *       ul
   *         li
   *           .tree__input.checkbox
   *             input(type='checkbox' name='checkbox' id='checkbox1').checkbox__input
   *             label.checkbox__label(for='checkbox1')
   *               span.checkbox__control
   *                 svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
   *                   use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *               span.checkbox__display checkbox 1
   *           ul
   *             li
   *               .tree__input.checkbox
   *                 input(type='checkbox' name='checkbox' id='checkbox1a').checkbox__input
   *                 label.checkbox__label(for='checkbox1a')
   *                   span.checkbox__control
   *                     svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
   *                       use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *                   span.checkbox__display checkbox 1a
   *             li
   *               .tree__input.checkbox
   *                 input(type='checkbox' name='checkbox' id='checkbox1b').checkbox__input
   *                 label.checkbox__label(for='checkbox1b')
   *                   span.checkbox__control
   *                     svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
   *                       use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *                   span.checkbox__display checkbox 1b
   *               ul
   *                 li
   *                   .tree__input.checkbox
   *                     input(type='checkbox' name='checkbox' id='checkbox1b1').checkbox__input
   *                     label.checkbox__label(for='checkbox1b1')
   *                       span.checkbox__control
   *                         svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
   *                           use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *                       span.checkbox__display checkbox 1b1
   *                 li
   *                   .tree__input.checkbox
   *                     input(type='checkbox' name='checkbox' id='checkbox1b2').checkbox__input
   *                     label.checkbox__label(for='checkbox1b2')
   *                       span.checkbox__control
   *                         svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
   *                           use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *                       span.checkbox__display checkbox 1b2
   *                   ul
   *                     li
   *                       .tree__input.checkbox
   *                         input(type='checkbox' name='checkbox' id='checkbox1b2a').checkbox__input
   *                         label.checkbox__label(for='checkbox1b2a')
   *                           span.checkbox__control
   *                             svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
   *                               use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *                           span.checkbox__display checkbox 1b2a
   *                     li
   *                       .tree__input.checkbox
   *                         input(type='checkbox' name='checkbox' id='checkbox1b2b').checkbox__input
   *                         label.checkbox__label(for='checkbox1b2b')
   *                           span.checkbox__control
   *                             svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
   *                               use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *                           span.checkbox__display checkbox 1b2b
   */
  @include modifier('selectable') {
    /*
      Modify the standard tree to allow a little more padding to fit the
      form elements
    */

    li {
      @include rem(padding, $selectable-tree-item-spacing 0);

      &::before {
        @include rem(top, $selectable-tree-horizontal-line-position);
      }
    }

    ul > li:last-child::after {
      @include rem(height, 9px);
    }

    @include mixie('icon') {
      position: relative;
      @include rem(top, $selectable-tree-icon-offset);
      float: left;
    }

    @include mixie('input') {
      margin-top: 0;
    }

    @include element('toggle') {
      @include rem(margin-top, $selectable-tree-icon-offset);
    }
  }

  /**
   * Use case: collapsible multi select tree:
   * `.tree--selectable.tree--collapsible`
   *
   * A collapsible tree where you can select multiple options
   *
   *     @example
   *     .tree.tree--selectable.tree--collapsible
   *       ul
   *         li
   *           button.tree__toggle(type='button' aria-expanded='true' aria-controls='list04' aria-labelledby='label04')
   *             svg.tree__toggle-icon.tree__toggle-icon--opened.icon(aria-hidden='true' focusable='false')
   *               use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
   *           .tree__input.checkbox
   *             input(type='checkbox' name='checkbox2' id='checkbox2-1').checkbox__input
   *             label.checkbox__label(for='checkbox2-1' id="label04")
   *               span.checkbox__control
   *                 svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
   *                   use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *               span.checkbox__display checkbox 1
   *           ul#list04
   *             li
   *               .tree__input.checkbox
   *                 input(type='checkbox' name='checkbox2' id='checkbox2-1a').checkbox__input
   *                 label.checkbox__label(for='checkbox2-1a')
   *                   span.checkbox__control
   *                     svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
   *                       use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *                   span.checkbox__display checkbox 1a
   *             li
   *               button.tree__toggle(type='button' aria-expanded='true' aria-controls='list05' aria-labelledby='label05')
   *                 svg.tree__toggle-icon.tree__toggle-icon--opened.icon(aria-hidden='true' focusable='false')
   *                   use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
   *               .tree__input.checkbox
   *                 input(type='checkbox' name='checkbox2' id='checkbox2-1b').checkbox__input
   *                 label.checkbox__label(for='checkbox2-1b' id='label05')
   *                   span.checkbox__control
   *                     svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
   *                       use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *                   span.checkbox__display checkbox 1b
   *               ul#list05
   *                 li
   *                   .tree__input.checkbox
   *                     input(type='checkbox' name='checkbox2' id='checkbox2-1b1').checkbox__input
   *                     label.checkbox__label(for='checkbox2-1b1')
   *                       span.checkbox__control
   *                         svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
   *                           use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *                       span.checkbox__display checkbox 1b1
   *                 li
   *                   button.tree__toggle.tree__toggle--closed(type='button' aria-expanded='false' aria-controls='list06' aria-labelledby='label06')
   *                     svg.tree__toggle-icon.icon(aria-hidden='true' focusable='false')
   *                       use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
   *                   .tree__input.checkbox
   *                     input(type='checkbox' name='checkbox2' id='checkbox2-1b2').checkbox__input
   *                     label.checkbox__label(for='checkbox2-1b2' id='label06')
   *                       span.checkbox__control
   *                         svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
   *                           use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *                       span.checkbox__display checkbox 1b2
   *                   ul#list06
   *                     li
   *                       .tree__input.checkbox
   *                         input(type='checkbox' name='checkbox2' id='checkbox2-1b2a').checkbox__input
   *                         label.checkbox__label(for='checkbox2-1b2a')
   *                           span.checkbox__control
   *                             svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
   *                               use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *                           span.checkbox__display checkbox 1b2a
   *                     li
   *                       .tree__input.checkbox
   *                         input(type='checkbox' name='checkbox2' id='checkbox2-1b2b').checkbox__input
   *                         label.checkbox__label(for='checkbox2-1b2b')
   *                           span.checkbox__control
   *                             svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
   *                               use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *                           span.checkbox__display checkbox 1b2b
   */

  /**
    * Use case: collapsible multi select tree with icons:
    * `.tree--selectable.tree--collapsible`
    *
    * A collapsible tree with icons where you can select multiple options
    *
    *     @example
    *     .tree.tree--selectable.tree--collapsible
    *       ul
    *         li
    *           button.tree__toggle(type='button' aria-expanded='true' aria-controls='list07' aria-labelledby='label07')
    *             svg.tree__toggle-icon.tree__toggle-icon--opened.icon(aria-hidden='true' focusable='false')
    *               use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
    *           svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree4-icon-label1')
    *             title#tree4-icon-label1 Document
    *             use(xlink:href='../icons.svg#document' href='../icons.svg#document')
    *           .tree__input.checkbox
    *             input(type='checkbox' name='checkbox3' id='checkbox3-1').checkbox__input
    *             label.checkbox__label(for='checkbox3-1' id='label07')
    *               span.checkbox__control
    *                 svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
    *                   use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
    *               span.checkbox__display checkbox 1
    *           ul#list07
    *             li
    *               svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree4-icon-label2')
    *                 title#tree4-icon-label2 Document
    *                 use(xlink:href='../icons.svg#document' href='../icons.svg#document')
    *               .tree__input.checkbox
    *                 input(type='checkbox' name='checkbox3' id='checkbox3-1a').checkbox__input
    *                 label.checkbox__label(for='checkbox3-1a')
    *                   span.checkbox__control
    *                     svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
    *                       use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
    *                   span.checkbox__display checkbox 1a
    *             li
    *               button.tree__toggle(type='button' aria-expanded='true' aria-controls='list08' aria-labelledby='label08')
    *                 svg.tree__toggle-icon.tree__toggle-icon--opened.icon(aria-hidden='true' focusable='false')
    *                   use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
    *               svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree4-icon-label3')
    *                 title#tree4-icon-label3 Document
    *                 use(xlink:href='../icons.svg#document' href='../icons.svg#document')
    *               .tree__input.checkbox
    *                 input(type='checkbox' name='checkbox3' id='checkbox3-1b').checkbox__input
    *                 label.checkbox__label(for='checkbox3-1b' id='label08')
    *                   span.checkbox__control
    *                     svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
    *                       use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
    *                   span.checkbox__display checkbox 1b
    *               ul#list08
    *                 li
    *                   svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree4-icon-label4')
    *                     title#tree4-icon-label4 Document
    *                     use(xlink:href='../icons.svg#document' href='../icons.svg#document')
    *                   .tree__input.checkbox
    *                     input(type='checkbox' name='checkbox3' id='checkbox3-1b1').checkbox__input
    *                     label.checkbox__label(for='checkbox3-1b1')
    *                       span.checkbox__control
    *                         svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
    *                           use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
    *                       span.checkbox__display checkbox 1b1
    *                 li
    *                   button.tree__toggle.tree__toggle--closed(type='button' aria-expanded='false' aria-controls='list09' aria-labelledby='label09')
    *                     svg.tree__toggle-icon.icon(aria-hidden='true' focusable='false')
    *                       use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
    *                   svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree4-icon-label5')
    *                     title#tree4-icon-label5 Document
    *                     use(xlink:href='../icons.svg#document' href='../icons.svg#document')
    *                   .tree__input.checkbox
    *                     input(type='checkbox' name='checkbox3' id='checkbox3-1b2').checkbox__input
    *                     label.checkbox__label(for='checkbox3-1b2' id='label09')
    *                       span.checkbox__control
    *                         svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
    *                           use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
    *                       span.checkbox__display checkbox 1b2
    *                   ul#label09
    *                     li
    *                       svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree4-icon-label6')
    *                         title#tree4-icon-label6 Document
    *                         use(xlink:href='../icons.svg#document' href='../icons.svg#document')
    *                       .tree__input.checkbox
    *                         input(type='checkbox' name='checkbox3' id='checkbox3-1b2a').checkbox__input
    *                         label.checkbox__label(for='checkbox3-1b2a')
    *                           span.checkbox__control
    *                             svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
    *                               use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
    *                           span.checkbox__display checkbox 1b2a
    *                     li
    *                       svg.tree__icon.icon(role='img' focusable='false' aria-labelledby='tree4-icon-label7')
    *                         title#tree4-icon-label7 Document
    *                         use(xlink:href='../icons.svg#document' href='../icons.svg#document')
    *                       .tree__input.checkbox
    *                         input(type='checkbox' name='checkbox3' id='checkbox3-1b2b').checkbox__input
    *                         label.checkbox__label(for='checkbox3-1b2b')
    *                           span.checkbox__control
    *                             svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
    *                               use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
    *                           span.checkbox__display checkbox 1b2b
    */
}
