/***
 *
 * <div class="alert bottom-margin">
 *   <svg class="alert__icon icon" aria-hidden="true" focusable="false">
 *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
 *   </svg>
 *   <p class="h4">Deprecation warning</p>
 *   <p>The horizontal form layout is <strong>no longer recommended</strong>. Moving forward, please avoid using this layout in favor of a vertical form layout.</p>
 *   <p>See the templates <a href="./../templates/complex-form.html">complex form</a> and <a href="./../templates/small-forms.html">small forms</a> for examples of the current recommendation.</p>
 * </div>
 *
 * # Horizontal form
 *
 * A form where inputs and labels are presented in rows, with the main label
 * appearing to the left of the input(s).
 *
 * This layout should be used for large, full page forms.
 *
 * See the
 * [long form layout](../templates/complex-horizontal-form.html)
 * for an example use case.
 */

$small-horizontal-form-width:   500px;
$form-mobile-threshold:         720px;

/**
 * Basic horizontal form:
 *
 * `.horizontal-form`
 *
 * A horizontal form.
 *
 *   @example
 *   include ./code-samples/basic-horizontal-form.pug
 */
@include block('horizontal-form') {
  @include element('row') {
    display: inline-block;
    width: 100%;
    margin-bottom: $ukg-spacing-m;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @include element('title-column') {
    width: 25%;
    border-left: 0 solid transparent;
    border-right: 0 solid transparent;
    border-left-width: $ukg-spacing-xxl;
    border-right-width: $ukg-spacing-m;
    float: left;
    text-align: right;

    @media only screen and (max-width: $desktop) {
      border-left-width: $ukg-spacing-m;
    }

    @media only screen and (max-width: $tablet) {
      width: 40%; /* making the left-hand side column wider on tablet */
      border-left-width: 0;
    }
  }

  @include element('label') {
    font-weight: $bold;
    color: $base-font-color;
  }

  @include element('help') {
    @include ukg-metadata-small-low-emphasis();

    p {
      @include ukg-metadata-small-low-emphasis();
      margin-top: $ukg-spacing-xs;
    }
  }

  @include element('field-column') {
    width: 75%;
    float: right;

    @media only screen and (max-width: $tablet) {
      width: 60%; /* making the right-hand side column narrower on tablet */
    }

    > .horizontal-form__field:first-child {
      > .radio:first-child,
      > .checkbox:first-child {
        margin-top: $ukg-spacing-xxs;
      }
    }
  }

  @include element('field') {
    margin-right: $ukg-spacing-s;
    float: left;
    max-width: 100%;
  }

  @include element('field-help') {
    @include ukg-metadata-small-low-emphasis();
    margin-top: $ukg-spacing-xxs;
  }

  @include element('row-help') {
    @include ukg-metadata-small-low-emphasis();
    clear: both;
    float: left;
    margin-top: $ukg-spacing-xxs;
  }

  /**
   * Small horizontal form:
   *
   * `.horizontal-form--small`
   *
   * A small horizontal form with a fixed maximum width. Use inside modals, etc.
   *
   *   @example
   *   include ./code-samples/small-horizontal-form.pug
   */
  @include modifier('small') {
    @include rem(max-width, $small-horizontal-form-width);

    @include element('row') {
      margin-bottom: $ukg-spacing-s;
    }

    @include element('title-column') {
      width: 35%;
    }

    @include element('field-column') {
      width: 65%;
    }
  }
}

@media all and (max-width: $form-mobile-threshold) {
  @include block('horizontal-form') {
    @include element('row') {
      margin-bottom: $ukg-spacing-s;
    }

    @include element('title-column') {
      width: 100%;
      text-align: left;
    }

    @include element('label') {
      display: inline-block;
    }

    @include element('help') {
      margin-bottom: $ukg-spacing-xs;

      p:first-child {
        margin-top: 0;
      }
    }

    @include element('field-column') {
      width: 100%;
    }

    @include modifier('small') {
      @include element('title-column') {
        width: 100%;
      }

      @include element('field-column') {
        width: 100%;
      }
    }
  }
}
