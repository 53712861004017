/***
 * # Selectable blocks
 *
 * A selectable block is an 'option' block that contains either a checkbox or a radio button, with additional information.
 *
 * ## When to use selectable blocks
 *
 * - To display additional help text/description under each option
 * - To nest other (conditional) form elements
 *
 * ## Position and style
 *
 * Selectable blocks should be positioned inside forms and/or formsets.
 * Valid and invalid styles should only be used as state indicators - do not use them to add color to the interface.
 */

// If you change this list, don't forget to update the docs.
$block-state: 'valid', 'invalid';

/**
 * Basic selectable block:
 * `.selectable-block`
 *
 * A block that could contain a radio or checkbox, with additional information.
 *
 *     @example
 *     .selectable-block
 *       .selectable-block__input.checkbox
 *         input(type='checkbox' name='checkbox' id='checkbox' aria-describedby="checkbox-description").checkbox__input
 *         label.checkbox__label(for='checkbox')
 *           span.checkbox__control
 *             svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
 *               use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
 *           span.checkbox__display A checkbox
 *       .selectable-block__content(id="checkbox-description")
 *         p A description for the checkbox
 *         p You can have more than one line. In fact, you can put almost anything here, as it is just a standard div.
 *     .selectable-block
 *       .selectable-block__input.radio
 *         input(type='radio' name='radio' id='radio' aria-describedby="radio-description").radio__input
 *         label.radio__label(for='radio')
 *           span.radio__control
 *           span.radio__display A radio
 *       .selectable-block__content(id="radio-description")
 *         p A description for the radio
 *         p You can have more than one line. In fact, you can put almost anything here, as it is just a standard div.
 */
@include block('selectable-block') {
  @include rem(font-size, $base-font-size);

  @include mixie('input') {
    font-weight: $bold;
  }

  @include element('content') {
    @include rem(margin-left, 25px);
    @include rem(margin-bottom, 30px);
    @include rem(margin-top, -5px);

    > * {
      @include rem(margin-top, 10px);
    }
  }

  /**
   * Colored block:
   * `.selectable-block--color`
   *
   *     @example
   *     each state in ['valid', 'invalid']
   *       .selectable-block(class=`selectable-block--${state}`)
   *         .selectable-block__input.checkbox
   *           input(type='checkbox' name=`checkbox-${state}` id=`checkbox-${state}` aria-describedby=`checkbox-${state}-description`).checkbox__input
   *           label.checkbox__label(for=`checkbox-${state}`)
   *             span.checkbox__control
   *               svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
   *                 use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *             span.checkbox__display This checkbox is #{state}
   *         .selectable-block__content(id=`checkbox-${state}-description`)
   *           p A description for the checkbox
   *       .selectable-block(class=`selectable-block--${state}`)
   *         .selectable-block__input.radio
   *           input(type='radio' name=`radio-${state}` id=`radio-${state}` aria-describedby=`radio-${state}-description`).radio__input
   *           label.radio__label(for=`radio-${state}`)
   *             span.radio__control
   *             span.radio__display This radio is #{state}
   *         .selectable-block__content(id=`radio-${state}-description`)
   *           p A description for the radio
   */
  @each $state in $block-state {
    @include modifier($state) {

      @include element('input') {
        border-color: form-field-color($state, border);
        color: form-field-color($state, text);
        fill: form-field-color($state, border);
      }

      .checkbox__input,
      .radio__input {
        &:focus + [class$='label'] [class$='control'] { // Target both, checkboxes and radios
          &::before {
            border-color: form-field-color($state, focus-border);
          }
        }
      }

      .checkbox__control,
      .radio__control {
        color: form-field-color($state, border);
      }

      @include element('content') {
        color: form-field-color($state, text);
      }
    }
  }

  /**
   * Disabled block:
   * `input[disabled]`
   *
   *     @example
   *     .selectable-block
   *       .selectable-block__input.checkbox
   *         input(type='checkbox' name='checkbox-disabled' id='checkbox-disabled' disabled aria-describedby="checkbox-disabled-description").checkbox__input
   *         label.checkbox__label(for='checkbox-disabled')
   *           span.checkbox__control
   *             svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
   *               use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *           span.checkbox__display This checkbox is disabled
   *       .selectable-block__content(id="checkbox-disabled-description")
   *         p A description for the checkbox
   *     .selectable-block
   *       .selectable-block__input.radio
   *         input(type='radio' name='radio-disabled' id='radio-disabled' disabled aria-describedby="radio-disabled-description").radio__input
   *         label.radio__label(for='radio-disabled')
   *           span.radio__control
   *           span.radio__display This radio is disabled
   *       .selectable-block__content(id="radio-disabled-description")
   *         p A description for the radio
   */
}
