/*
  FOCUS OUTLINE
  Focus outline generated using box-shadow.

  USAGE:
  - The background of the element must be known.
  - It can be used in elements that can not contain other elements nor
  pseudo elements, such as `<input>`.

  DEFAULT PROPERTIES:
  - $background-color: $white
  - $outline-color: color(primary, mid)

*/

@mixin focus-outline($background-color: $white, $outline-color: color(primary, mid)) {
  box-shadow:
    0 0 0 $button-focus-outline-offset $background-color,
    0 0 0 ($button-focus-outline-offset + $button-focus-outline-width) $outline-color;
  outline: none;
}

/*
  FOCUS OUTLINE USING A PSEUDO ELEMENT
  Alternative focus outline using a pseudo element.

  USAGE:
  - It cannot be used in elements that can not contain other elements nor
  pseudo elements, such as `<input>`.
  - It leaves the real background of the element visible.
  - It doesn't inherit the border-radius of the parent element.

  DEFAULT PROPERTIES:
  - $outline-color: color(primary, mid)
  - $position: relative // If the parent element is absolute positioned, change to `absolute`
  - $gap: 2px // Space between the element and the focus outline
  - $border-radius: $border-radius-medium // Can be also defined in % for round buttons
  - $pseudo-element: before // If the parent element already has a ::before pseudo-element, change to `after`
*/
@mixin focus-outline-pseudo-element($outline-color: color(primary, mid), $position: relative, $gap: 2px, $border-radius: $border-radius-medium, $pseudo-element: before) {
  position: $position;
  outline: none;
  box-shadow: none;
  overflow: visible;
  box-sizing: border-box;

  &::#{$pseudo-element} {
    content: '';
    position: absolute;
    border: rem($button-focus-outline-width) solid $outline-color;
    top: rem(- ($button-focus-outline-width + $gap));
    left: rem(- ($button-focus-outline-width + $gap));
    border-radius: rem($border-radius);
    width: calc(100% + #{rem($button-focus-outline-width + $gap) * 2});
    height: calc(100% + #{rem($button-focus-outline-width + $gap) * 2});
  }
}

/*
  INSET FOCUS INDICATOR
  Inset focus indicator generated using border and box-shadow.

  USAGE:
  - Use in special cases where an outline focus indicator doesn't work,
  e.g.: horizontal-menu, list-item and tabs.

  DEFAULT PROPERTIES:
  - $indicator-color: color(primary, mid)
*/
@mixin inset-focus-indicator($indicator-color: color(primary, mid)) {
  border: 1px solid $indicator-color;
  box-shadow: inset 0 0 0 $button-focus-outline-width - 1 $indicator-color;
  outline: none;
}

/*
  SUBTLE FOCUS INDICATOR
  A subtle focus indicator generated using a border on the left or the right of an element

  USAGE:
  - Use in lists of items - for example selects.

  DEFAULT PROPERTIES:
  - $outline-color: color(primary, mid)
  - $text-color: ''
  - $outline-position: left
*/
@mixin subtle-focus-indicator($indicator-color: color(primary, mid), $text-color: '') {
  border-left: rem($button-focus-outline-width) solid $indicator-color;
  outline: none;
  @if $text-color != '' {
    color: $text-color;
  }
}

/*
  LINK FOCUS INDICATOR
  A focus indicator for inline links.

  USAGE:
  - Use in hyperlinks and inline text focusable elements.

  DEFAULT PROPERTIES:
  - $indicator-color: color(primary, dark)
  - $text-color: $font-color-on-primary
*/
@mixin link-focus-indicator($indicator-color: color(primary, dark), $text-color: $font-color-on-primary) {
  background-color: $indicator-color;
  color: $text-color;
  text-decoration: none;
  outline: none;
}

/*
  HIDE FOCUS INDICATOR
  This mixin can be useful in some circumstance, as for example,
  hidding the focus ring on active if the element is disabled.
*/

@mixin hide-focus-indicator {
  box-shadow: none;

  &::before {
    display: none;
  }
}

/*
  BUTTON PRESSED INDICATOR
  An indicator for pressed buttons.
  It shows that the buttons is selected.
  It uses background linear-gradient, compatible with border and box-shadow properties.
*/
@mixin button-active-indicator($indicator, $background) {
  background: linear-gradient(to top, $indicator 0, $indicator rem(3px), $background rem(3px), $background 100%);
}
