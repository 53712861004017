// SETTINGS FONTS

// Roboto font path in production. Do not remove.
$roboto-font-path: './fonts';
// Noto font path in production. Do not remove.
$noto-font-path: './fonts';

// FONT FAMILY

$base-font:     $ukg-font-family;
$base-font-sc:  'PingFang SC', '微软雅黑', 'Microsoft YaHei', sans-serif; // Simplified Chinese
$base-font-tc:  'PingFang TC', '微軟正黑體', 'Microsoft JhengHei', sans-serif; // Traditional Chinese
$base-font-arabic: 'Noto Sans', 'Roboto', 'Helvetica', sans-serif;
$base-font-hebrew: 'Noto Sans', 'Roboto', 'Helvetica', sans-serif;
$base-font-japanese: 'Noto Sans JP', 'Roboto', 'Helvetica', sans-serif;
$base-font-korean: 'Noto Sans KR', 'Roboto', 'Helvetica', sans-serif;
$base-font-thai: 'Noto Serif Thai', 'Roboto', 'Helvetica', sans-serif;

// A narrower version of the base font best used in high-density contexts
$metadata-font: $ukg-font-family-display;

// SIZES

/*
  Set $rem-baseline to the browser default of 16px.  This will ensure our
  rem functions scale appropriately.

  See // See https://github.com/pierreburel/sass-rem for more information.
*/
$rem-baseline:    16px;

$h1-size:   30px;
$h2-size:   26px;
$h3-size:   22px;
$h4-size:   20px;
$h5-size:   18px;
$h6-size:   14px;

$base-font-size:  15px;
$small-font-size: 12px;
$menu-font-size:  13px;

// WEIGHTS

$regular:      400;
$bold:        700;

// VERTICAL RHYTHM (thanks to https://drewish.com/tools/vertical-rhythm/)

$base-line-height:  20px;
$h1-line-height:    40px;
$h2-line-height:    32px;
$h3-line-height:    26px;
$h4-line-height:    26px;
$h5-line-height:    24px;
$h6-line-height:    18px;

$type-margin:           $base-line-height;
