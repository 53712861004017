$file-upload-max-width:                         450px;
$file-upload-border-color:                      color(grey, 5);
$file-upload-background-color:                  color(grey, 6);

$file-upload-dropzone-max-width:                $wrapper-medium;
$file-upload-dropzone-padding:                  $target-padding;
$file-upload-dropzone-border-width:             $target-border-width;
$file-upload-dropzone-border-color:             color(grey, 4);
$file-upload-dropzone-background-color:         $white;
$file-upload-dropzone-height:                   215px;

$file-upload-success-color:                     color(success, dark);
$file-upload-error-color:                       color(danger, dark);
$file-upload-error-padding:                     20px;

$file-upload-file-input-max-width:              $form-field-width;
$file-upload-file-input-min-width:              45px;

$file-upload-progress-bar-width:                100px;

@include block('file-upload', 2) {
  @include rem(font-size, $base-font-size);
  @include rem(max-width, $file-upload-max-width);

  @include modifier('dropzone') {
    @include rem(max-width, $file-upload-dropzone-max-width);
  }

  @include element('button') {
    display: inline-block;
    position: relative;
  }

  @include mixie('input') {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: index($z-index-scale, base);
    text-indent: -10em; // Moves the blinking caret out of the button in IE11
    cursor: pointer;

    &::-webkit-file-upload-button { // sass-lint:disable-line no-vendor-prefixes
      cursor: pointer;
    }

    &:focus,
    &:hover {
      + .file-upload__display {
        @include std-hover;
      }
    }

    &:focus,
    &:active {
      + .file-upload__display {
        @include focus-outline-pseudo-element($border-radius: $button-border-radius + 4px);
      }
    }

    &:active {
      + .file-upload__display {
        @include std-active;
      }
    }

    &:disabled {
      cursor: not-allowed;

      &::-webkit-file-upload-button { // sass-lint:disable-line no-vendor-prefixes
        cursor: not-allowed;
      }

      + .file-upload__display {
        @include std-disabled;
      }
    }
  }

  @include element('display') {
    @include std-button;
  }

  @include element('list') {
    @include unstyled-list;
  }

  @include element('list-name') {
    outline: 0;
  }

  @include element('file') {
    @include rem(border, 1px solid $file-upload-border-color);
    margin: $ukg-spacing-xs 0 0;
    padding: $ukg-spacing-xs;
    background-color: $file-upload-background-color;
    border-radius: $border-radius-small;

    // STATE: RENAMING
    @include modifier('renaming') {
      @include element('file-content') {

        @include modifier('display') {
          display: none;
        }

        @include modifier('rename-mode') {
          display: inline-flex; // Using `display: flex` would prevent Voice Over on Safari from properly announcing the `ul` element as a semantic list
        }

        @include element('file-action') {
          display: block;
        }
      }
    }

    // STATE: READY
    @include modifier('ready') {
      @include element('file-action') {
        @include modifier('rename') {
          display: block;
        }

        @include modifier('remove') {
          display: block;
        }
      }
    }

    // STATE: QUEUED
    @include modifier('queued') {
      @include element('file-action') {
        @include modifier('remove') {
          display: block;
        }
      }

      @include element('file-status') {
        @include modifier('queued') {
          display: block;
          @include rem(min-width, $file-upload-progress-bar-width);
        }
      }
    }

    // STATE: PROCESSING
    @include modifier('processing') {
      @include mixie('progress-bar') {
        display: block;
      }
    }

    // STATE: UPLOADED
    @include modifier('uploaded') {
      @include mixie('icon') {
        @include modifier('success') {
          display: block;
        }
      }

      @include element('file-main') {
        color: $file-upload-success-color;
      }
    }

    // STATE: ERROR
    @include modifier('error') {
      @include mixie('icon') {
        @include modifier('error') {
          display: block;
        }
      }

      @include element('file-action') {
        @include modifier('remove') {
          display: block;
        }

        @include modifier('retry') {
          display: block;
        }
      }

      @include element('file-main') {
        color: $file-upload-error-color;
      }
    }

    // STATE: ERROR - UPLOAD LIMIT REACHED
    @include modifier('error-excess') {

      @include element('file-action') {
        @include modifier('retry') {
          display: none;
        }
      }
    }
  }

  @include element('file-content') {
    display: inline-flex; // Using `display: flex` would prevent Voice Over on Safari from properly announcing the `ul` element as a semantic list
    width: 100%;
    align-items: center;

    // RENAME MODE
    @include modifier('rename-mode') {
      display: none;
      align-items: flex-start; // because `start` is not supported by IE11

      @include element('file-main') {
        flex: auto;
        align-items: flex-start; // because `start` is not supported by IE11
      }
    }
  }

  @include element('file-main') {
    flex: initial;
    display: inline-flex; // Using `display: flex` would prevent Voice Over on Safari from properly announcing the `ul` element as a semantic list
    align-items: center;
  }

  @include element('file-actions') {
    flex: auto;
    display: inline-flex; // Using `display: flex` would prevent Voice Over on Safari from properly announcing the `ul` element as a semantic list
    justify-content: flex-end;
    align-items: center;
  }

  @include mixie('icon') {
    display: none;
    flex-shrink: 0;
  }

  @include mixie('progress-bar') {
    display: none;
    @include rem(width, $file-upload-progress-bar-width);
  }

  @include element('file-action') {
    display: none;
    margin-left: $ukg-spacing-xs;
  }

  @include element('file-input-wrapper') {
    width: 100%;
    @include rem(max-width, $file-upload-file-input-max-width);
    @include rem(min-width, $file-upload-file-input-min-width);
  }

  @include element('file-input') {
    width: 100%;
    min-width: 0;
  }

  @include element('file-status') {
    display: none;
  }

  @include element('file-info') {
    margin: $ukg-spacing-xxs $ukg-spacing-xs $ukg-spacing-xxs $ukg-spacing-xxs;

    @include modifier('name') {
      word-break: break-all;
      flex: initial;
    }

    @include modifier('size') {
      flex-shrink: 0;
    }
  }

  @include element('dropzone') {
    position: relative;
    @include rem(min-height, $file-upload-dropzone-height);
    @include rem(padding, $file-upload-dropzone-padding);
    @include rem(border, $file-upload-dropzone-border-width dashed $file-upload-dropzone-border-color);
    background-color: $file-upload-dropzone-background-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &.dz-drag-hover { // Added by Dropzone when a user drags a file over the form target
      background-color: $file-upload-background-color;

      @include element('dropzone-instructions') {
        display: none;

        @include modifier('on-hover') {
          display: block;
          pointer-events: none;
        }
      }

      @include element('dropzone-button') {
        display: none;
      }

      @include element('dropzone-icon') {
        pointer-events: none;
      }
    }
  }

  @include modifier('disabled') {

    @include element('dropzone') {
      pointer-events: none;
      border-color: hsla(var(--grey-level-4-h), var(--grey-level-4-s), var(--grey-level-4-l), 0.4);
    }

    @include element('dropzone-icon') {
      opacity: 0.4;
    }

    @include element('dropzone-instructions') {
      opacity: 0.4;
    }
  }

  @include element('dropzone-icon') {
    margin-bottom: $ukg-spacing-s;
  }

  @include element('dropzone-instructions') {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;

    @include modifier('on-hover') {
      display: none;
    }
  }

  @include element('dropzone-button') {
    margin-top: $ukg-spacing-xxs;
  }

  @include mixie('alert') {
    margin-top: $ukg-spacing-s;
  }
}
