/***
 * # Tables
 *
 * A standard table that contains immutable data.
 *
 * A table should always contain
 * - Headings (column or row) that can be hidden with `.sr-only` class if needed
 * - [`scope`](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/th#attr-scope) attribute on headings
 * - `caption` content that can be hidden with `.sr-only` class if needed
 *
 * A table can optionally contain
 * - Row titles
 * - A sort mechanism (via each column heading)
 *
 * ## When to use tables
 *
 * Tables should be used to display simple tabular data.
 *
 * ## When not to use tables
 *
 * Do not use tables to display dynamic or complex data.
 *
 * ## Position and style
 *
 * Tables should not be nested inside other tables. They are best
 * positioned inside large components, such as cards.
 *
 * Tables should not contain any other elements, including badges
 * or buttons.
 *
 * ## Accessibility
 *
 * The `<caption>` tag defines a table caption (or title) of a table and
 * is always the first child of a `<table>`.
 *
 * The `scope` attribute on `th` headings can have a value `col`
 * for columns or `row` for rows.
 *
 * <div class="alert alert--danger top-margin">
 *   Please make sure to adapt the example text, in the following examples, to your needs.
 * </div>
 *
 */

$table-min-width:                100%;
$table-mobile-threshold:         850px;

$table-padding:                  10px;
$table-font-size:                $base-font-size;
$table-border-color:             $block-border-color;
$table-dark-border-color:        color(grey, 4);
$table-stripe-color:             color(grey, 6);

$table-background-color:         $white;

$table-thead-text-color:         color(grey, 3);
$table-tfoot-text-color:         color(grey, 4);

$table-row-padding-mobile:       5px;
$table-row-margin-mobile:        15px;

/**
 * Basic table:
 * `.table`
 *
 * A classic table.
 * <br><br>
 * IMPORTANT:
 * <br><br>
 * Column headers will be moved to the left of each `td.table__column` element on smaller screens.
 * <br><br>
 * In order for this to happen you need to add the relevant `[data-th="{column header}"]` attribute to each `td.table__column` element.
 *
 *     @example
 *     table.table
 *       caption.sr-only caption for table
 *       thead.table__header
 *         tr.table__row
 *           th.table__column.table__column--label(scope="col") Header
 *           th.table__column.table__column--label(scope="col")
 *             a(href='#')
 *               | Header sort
 *               svg.icon.icon--small(role='img' aria-labelledby='table-caret-label1', focusable='false')
 *                 title#table-caret-label1 Sort
 *                 use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
 *       tbody.table__body
 *         - for (var i = 0; i < 4; i++)
 *           tr.table__row
 *             td.table__column.table__column--label(data-th='Header') Label
 *             td.table__column(data-th='Header Sort') Content
 *       tfoot.table__footer
 *         tr.table__row
 *           td.table__column.table__column--label(data-th='Header') Label
 *           td.table__column(data-th='Header Sort') Content
 */
@include block('table') {
  font-size: rem($table-font-size);
  min-width: rem($table-min-width);
  border-collapse: separate;
  border: rem(1px) solid $table-border-color;
  border-width: rem(1px) 0;
  background-color: $table-background-color;

  @include element('column') {
    border-bottom: rem(1px) solid $table-border-color;
    padding: rem($table-padding);

    @include modifier('label') {
      font-weight: $bold;
    }
  }

  @include element('body', 'footer') {
    @include element('row') {
      &:last-child {
        @include element('column') {
          border-bottom-width: 0;
        }
      }
    }
  }

  // manage implicit tbody (`.table__body` is optional)
  tbody > .table__row:last-child {
    @include element('column') {
      border-bottom-width: 0;
    }
  }

  @include element('header') {
    font-weight: $bold;

    @include element('column') {
      border-bottom: rem(1px) solid $table-dark-border-color;
      text-align: left;
      color: $table-thead-text-color;
      background-color: $table-background-color; // opaque for sticky

      a {
        &:not(:focus):not(:active) {
          color: $table-thead-text-color;
          font-weight: $bold;
        }

        svg {
          width: rem(10px);
          height: rem(10px);
        }
      }
    }
  }

  @include element('footer') {
    color: $table-tfoot-text-color;

    @include element('column') {
      border-top: rem(1px) double $table-dark-border-color;

      @include modifier('label') {
        font-weight: $regular;
      }
    }
  }

  /**
   * Compact table:
   * `.table--compact`
   *
   * A compact table.
   *
   *     @example
   *     table.table.table--compact
   *       caption.sr-only caption for table
   *       thead.table__header
   *         tr.table__row
   *           th.table__column.table__column--label(scope="col") Header
   *           th.table__column.table__column--label(scope="col")
   *             a(href='#')
   *               | Header sort
   *               svg.icon.icon--small(role='img' aria-labelledby='table-caret-label1', focusable='false')
   *                 title#table-caret-label1 Sort
   *                 use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
   *       tbody.table__body
   *         - for (var i = 0; i < 4; i++)
   *           tr.table__row
   *             td.table__column.table__column--label(data-th='Header') Label
   *             td.table__column(data-th='Header Sort') Content
   *       tfoot.table__footer
   *         tr.table__row
   *           td.table__column.table__column--label(data-th='Header') Label
   *           td.table__column(data-th='Header Sort') Content
   */
  @include modifier('compact') {
    @include element('column') {
      padding: rem($table-padding / 2);
    }
  }

  /**
   * Striped table:
   * `.table--striped`
   *
   * A striped table.
   *
   *     @example
   *     table.table.table--striped
   *       caption.sr-only caption for table
   *       thead.table__header
   *         tr.table__row
   *           th.table__column.table__column--label(scope="col") Header
   *           th.table__column.table__column--label(scope="col")
   *             a(href='#')
   *               | Header sort
   *               svg.icon.icon--small(role='img' aria-labelledby='table-caret-label2', focusable='false')
   *                 title#table-caret-label2 Sort
   *                 use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
   *       tbody.table__body
   *         - for (var i = 0; i < 4; i++)
   *           tr.table__row
   *             td.table__column.table__column--label(data-th='Header') Label
   *             td.table__column(data-th='Header Sort') Content
   */
  @include modifier('striped') {
    @include element('body') {
      @include element('row') {
        &:nth-child(odd) {
          background-color: $table-stripe-color;
        }
      }
    }
  }

  /**
   * Table with border:
   * `.table--bordered`
   *
   * A table with a border around it.
   *
   *     @example
   *     table.table.table--bordered
   *       caption.sr-only caption for table
   *       thead.table__header
   *         tr.table__row
   *           th.table__column.table__column--label(scope="col") Header
   *           th.table__column.table__column--label(scope="col")
   *             a(href='#')
   *               | Header sort
   *               svg.icon.icon--small(role='img' aria-labelledby='table-caret-label3', focusable='false')
   *                 title#table-caret-label3 Sort
   *                 use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
   *       tbody.table__body
   *         - for (var i = 0; i < 4; i++)
   *           tr.table__row
   *             td.table__column.table__column--label(data-th='Header') Label
   *             td.table__column(data-th='Header Sort') Content
   *       tfoot.table__footer
   *         tr.table__row
   *           td.table__column.table__column--label(data-th='Header') Label
   *           td.table__column(data-th='Header Sort') Content
   */
  @include modifier('bordered') {
    border-width: rem(1px);
  }

  /**
   * Table without top or bottom border:
   * `.table--no-top-border` , `.table--no-bottom-border`
   *
   * `.table--no-top-border` will remove the top border from the first `.table__row` inside `.table__body`.
   *
   * `.table--no-bottom-border` will remove the bottom border from the last `.table__row` inside `.table__body`.
   *
   *     @example
   *     table.table.table--no-top-border.table--no-bottom-border
   *       caption.sr-only caption for table
   *       thead.table__header.sr-only
   *         tr.table__row
   *           th.table__column.table__column--label(scope="col") Header
   *           th.table__column.table__column--label(scope="col")
   *             a(href='#')
   *               | Header sort
   *               svg.icon.icon--small(role='img' aria-labelledby='table-caret-label1', focusable='false')
   *                 title#table-caret-label1 Sort
   *                 use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
   *       tbody.table__body
   *         - for (var i = 0; i < 4; i++)
   *           tr.table__row
   *             td.table__column.table__column--label(data-th='Header') Label
   *             td.table__column(data-th='Header Sort') Content
   *
   */
  @include modifier('no-top-border') {
    border-top: 0;
  }

  @include modifier('no-bottom-border') {
    border-bottom: 0;
  }

  /**
   * Use case:
   * Bordered table with stripes
   *
   *     @example
   *     table.table.table--bordered.table--striped
   *       caption.sr-only caption for table
   *       thead.table__header
   *         tr.table__row
   *           th.table__column.table__column--label(scope="col") Header
   *           th.table__column.table__column--label(scope="col")
   *             a(href='#')
   *               | Header sort
   *               svg.icon.icon--small(role='img' aria-labelledby='table-caret-label5', focusable='false')
   *                 title#table-caret-label5 Sort
   *                 use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
   *       tbody.table__body
   *         - for (var i = 0; i < 4; i++)
   *           tr.table__row
   *             td.table__column.table__column--label(data-th='Header') Label
   *             td.table__column(data-th='Header Sort') Content
   *       tfoot.table__footer
   *         tr.table__row
   *           td.table__column.table__column--label(data-th='Header') Label
   *           td.table__column(data-th='Header Sort') Content
   *
   */

  /**
   * Checkbox table:
   * `.table--checkbox`
   *
   * A table consisting of rows of checkboxes.
   *
   * The user can check/uncheck individual checkboxes, whole columns, whole rows or the whole table.
   *
   * Checkboxes can be omitted and `[disabled]` ones may also be used (see example #2).
   *
   * _Note:_ *If at any time no enabled checkboxes are found in a column/row, then the `.table__change-button` of that column/row will be disabled (see example #2).*
   *
   * All of the table styling modifiers may be applied to a Checkbox Table as well.
   *
   * `[data-check-column]`, `[data-uncheck-column]`, `[data-check-row]`, `[data-uncheck-row]`, `[data-check-all]` and `[data-uncheck-all]` need to be defined on the `.table` element in order to indicate the text to be used.
   *
   * <div class="alert alert--danger top-margin top-margin--small">Whenever checkboxes are added, removed, checked or unchecked by non-user actions,<br><code style="background-color: white; color: black;">$(table).table('update');</code><br>must be performed afterwards in order to update the state of the table.</div>
   *
   *     @example
   *     strong Example #1 :
   *     br
   *     br
   *     - var q = 4;
   *     div.scroll-x
   *       table.table.table--checkbox(id='checkbox-table-demo-1' data-check-column='Select column' data-uncheck-column='Deselect column' data-check-all='Select all' data-uncheck-all='Deselect all' data-check-row='Select row' data-uncheck-row='Deselect row')
   *         caption.sr-only caption for table
   *         thead.table__header
   *           tr.table__row
   *             th.table__column.table__column--label(scope="col") Header title 1
   *             - for (var i=1; i <= q; i++) {
   *               th.table__column.table__column--label
   *                 - if (i < q) {
   *                 div Header title #{i+1}
   *                 button.table__change-button(type='button') Select column
   *                 - } else {
   *                 button.table__change-button(type='button') Select all
   *                 - }
   *             - }
   *         tbody.table__body
   *           - for (var i = 1; i < q; i++) {
   *           tr.table__row
   *             td.table__column.table__column--label(data-th='Header title 1') Label
   *             - for (var ii = 1; ii <= q; ii++) {
   *             td.table__column(data-th=`Header title ${ii+1}`)
   *               - if (ii == q) {
   *               button.table__change-button(type='button') Select row
   *               - } else {
   *               .checkbox.checkbox--standalone
   *                 input.checkbox__input(type='checkbox' name=`checkobox-table-demo-1-row-${i}-checkbox-${ii}` id=`checkobox-table-demo-1-row-${i}-checkbox-${ii}`)
   *                 label.checkbox__label(for=`checkobox-table-demo-1-row-${i}-checkbox-${ii}`)
   *                   span.checkbox__control
   *                     svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
   *                       use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *               - }
   *             - }
   *           - }
   *         tfoot.table__footer
   *           tr.table__row
   *             td.table__column.table__column--label(data-th='Header title 1') Label
   *             - for (var i = 1; i <= q; i++) {
   *               - if (i == q) {
   *                 td.table__column Text
   *               - } else {
   *                 td.table__column(data-th=`Header title ${i+1}`) Text
   *               - }
   *             - }
   *     script.
   *       $('#checkbox-table-demo-1').table();
   *
   *     br
   *     br
   *     strong Example #2 :
   *     br
   *     br
   *
   *     - var q = 4;
   *     div.scroll-x.border-left.border-right
   *       table.table.table--checkbox.table--striped(id='checkbox-table-demo-2' data-check-column='Select column' data-uncheck-column='Deselect column' data-check-all='Select all' data-uncheck-all='Deselect all' data-check-row='Select row' data-uncheck-row='Deselect row')
   *         caption.sr-only caption for table
   *         thead.table__header
   *           tr.table__row
   *             th.table__column.table__column--label(scope="col") Header title 1
   *             - for (var i=1; i <= q; i++) {
   *               td.table__column.table__column--label
   *                 - if (i < q) {
   *                 div Header title #{i+1}
   *                 button.table__change-button(type='button') Select column
   *                 - } else {
   *                 button.table__change-button(type='button') Select all
   *                 - }
   *             - }
   *         tbody.table__body
   *           - for (var i = 1; i < q; i++) {
   *           tr.table__row
   *             td.table__column.table__column--label(data-th='Header title 1') Label
   *             - for (var ii = 1; ii <= q; ii++) {
   *             td.table__column(data-th=`Header title ${ii+1}`)
   *               - if (ii == q) {
   *               button.table__change-button(type='button') Select row
   *               - } else if (i !== 1 || ii !== 2) {
   *               .checkbox.checkbox--standalone
   *                 input.checkbox__input(type='checkbox' name=`checkobox-table-demo-2-row-${i}-checkbox-${ii}` id=`checkobox-table-demo-2-row-${i}-checkbox-${ii}` disabled=((i==3 && ii==3) || (i==1 && ii==1) || (i==2 && ii==2) || (i==3 && ii==2)), checked=((i==3 && ii==3) || (i==2 && ii==2) || (i==1 && ii==3)))
   *                 label.checkbox__label(for=`checkobox-table-demo-2-row-${i}-checkbox-${ii}`)
   *                   span.checkbox__control
   *                     svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
   *                       use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *               - }
   *             - }
   *           - }
   *         tfoot.table__footer
   *           tr.table__row
   *             td.table__column.table__column--label(data-th='Header title 1') Label
   *             - for (var i = 1; i <= q; i++) {
   *             - if (i == q) {
   *             td.table__column Text
   *             - } else {
   *             td.table__column(data-th=`Header title ${i+1}`) Text
   *             - }
   *             - }
   *     script.
   *       $('#checkbox-table-demo-2').table();
   *
   *     br
   *     br
   *     strong Example #3 :
   *     br
   *     br
   *
   *     - var q = 5;
   *     div.scroll-x.border-left.border-right
   *       table.table.table--checkbox.table--striped(id='checkbox-table-demo-3' data-check-column='Select column' data-uncheck-column='Deselect column' data-check-all='Select all' data-uncheck-all='Deselect all' data-check-row='Select row' data-uncheck-row='Deselect row')
   *         caption.sr-only caption for table
   *         thead.table__header
   *           tr.table__row
   *             th.table__column.table__column--label(scope="col") Header title 1
   *             - for (var i=1; i <= q; i++) {
   *               td.table__column.table__column--label
   *                 - if (i < q) {
   *                 div Header title #{i+1}
   *                 - if (i > 1) {
   *                 button.table__change-button(type='button') Select column
   *                 - }
   *                 - } else {
   *                 button.table__change-button(type='button') Select all
   *                 - }
   *             - }
   *         tbody.table__body
   *           - for (var i = 1; i < q; i++) {
   *           tr.table__row
   *             td.table__column.table__column--label(data-th='Header title 1') Label
   *             - for (var ii = 1; ii <= q; ii++) {
   *             td.table__column(data-th=`Header title ${ii+1}`)
   *               - if (ii == q && i !== 2 && i !== 3) {
   *               button.table__change-button(type='button') Select row
   *               - } else if (i !== 2 && i !== 3 && ii !== 1) {
   *               .checkbox.checkbox--standalone
   *                 input.checkbox__input(type='checkbox' name=`checkobox-table-demo-3-row-${i}-checkbox-${ii}` id=`checkobox-table-demo-3-row-${i}-checkbox-${ii}` disabled=((i==3 && ii==3) || (i==1 && ii==1) || (i==2 && ii==2) || (i==3 && ii==2)), checked=((i==3 && ii==3) || (i==2 && ii==2) || (i==1 && ii==3)))
   *                 label.checkbox__label(for=`checkobox-table-demo-3-row-${i}-checkbox-${ii}`)
   *                   span.checkbox__control
   *                     svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
   *                       use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *               - }
   *             - }
   *           - }
   *         tfoot.table__footer
   *           tr.table__row
   *             td.table__column.table__column--label(data-th='Header title 1') Label
   *             - for (var i = 1; i <= q; i++) {
   *             - if (i == q) {
   *             td.table__column Text
   *             - } else {
   *             td.table__column(data-th=`Header title ${i+1}`) Text
   *             - }
   *             - }
   *     script.
   *       $('#checkbox-table-demo-3').table();
   */
  @include modifier('checkbox') {
    @include element('header') {
      @include element('column') {
        text-align: center;

        &:nth-child(1) {
          text-align: left;
        }
      }
    }

    @include element('body') {
      @include element('column') {
        text-align: center;

        &:nth-child(1) {
          text-align: left;
        }
      }
    }

    @include element('footer') {
      @include element('column') {
        text-align: center;

        &:nth-child(1) {
          text-align: left;
        }
      }
    }

    @include element('change-button') {
      appearance: none;
      border: 0;
      background-color: transparent;
      color: $font-color-primary-on-white;
      text-decoration: underline;
      font-weight: $regular;

      &:focus,
      &:active {
        @include link-focus-indicator;
      }

      @include modifier('uncheck') {
        color: color('danger', deep);

        &:focus,
        &:active {
          @include link-focus-indicator($indicator-color: color('danger', deep), $text-color: $white);
        }
      }

      &.table__change-button--disabled,
      &:disabled {
        @include disabled;
      }
    }
  }

  /**
   * Table with sticky header:
   * `.table--sticky-header`
   *
   * <div class="alert alert--warning top-margin">
   *   <svg role="img" aria-labelledby="sticky-header-alert" focusable="false" class="icon alert__icon">
   *     <title id="sticky-header-alert">Warning</title>
   *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
   *   </svg>
   *   <strong>Support</strong>
   *   <p>Sticky header is only supported by modern browsers.</p>
   *   <p>The support is related to <a href="https://caniuse.com/#feat=css-sticky"><code>position: sticky</code></a>.</p>
   *   <p>The table will behave like a regular table if the browser do not support this property.</p>
   * </div>
   *
   *   @example
   *   table.table.table--sticky-header
   *     caption.sr-only caption for table
   *     thead.table__header
   *       tr.table__row
   *         th.table__column.table__column--label(scope="col") Header
   *         th.table__column.table__column--label(scope="col")
   *           a(href='#')
   *             | Header sort
   *             svg.icon.icon--small(role='img' aria-labelledby='table-caret-label1', focusable='false')
   *               title#table-caret-label1 Sort
   *               use(xlink:href='../icons.svg#expand-more' href='../icons.svg#expand-more')
   *     tbody.table__body
   *       - for (var i = 0; i < 4; i++)
   *         tr.table__row
   *           td.table__column.table__column--label(data-th='Header') Label
   *           td.table__column(data-th='Header Sort') Content
   *     tfoot.table__footer
   *       tr.table__row
   *         td.table__column.table__column--label(data-th='Header') Label
   *         td.table__column(data-th='Header Sort') Content
   */
  @supports ((position: sticky) or (position: -webkit-sticky)) {
    @include modifier('sticky-header') {
      @include element('header') {
        @include element('column') {
          position: sticky;
          top: 0;
          z-index: index($z-index-scale, base);
        }
      }
    }
  }
}

@media all and (max-width: $table-mobile-threshold) {
  @include block('table') {
    @include element('header') {
      display: none;
    }

    @include element('row') {
      display: inline-block;
      width: 100%;
      padding: rem($table-padding);
      border-bottom: rem(1px) solid $table-border-color;

      @include element('column') {
        padding: 0;
        border: 0;
      }
    }

    @include element('column') {
      display: inline-block;
      width: 100%;
      text-align: left;

      &[data-th] {
        &::before {
          content: attr(data-th) ': ';
          font-weight: $bold;
        }
      }
    }

    @include element('body') {
      display: block;

      @include element('row') {
        &:last-child {
          border-bottom: 0;
        }
      }
    }

    @include element('footer') {
      display: block;

      @include element('row') {
        &:first-child {
          border-top: rem(1px) solid $table-dark-border-color;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    @include modifier('checkbox') {
      .table__body,
      .table__footer {
        @include element('column') {
          text-align: left;

          &:last-child {
            display: none;
          }
        }
      }
    }
  }
}

/**
 * ### Events
 *
 * | Event | Triggered by | Occurence |
 * |-------|--------------|-----------|
 * | checkboxClicked.pdui.table | click on a checkbox of a Checkbox Table | after |
 * | columnChecked.pdui.table | click on a "Select column" button of a Checkbox Table | after |
 * | columnUnchecked.pdui.table | click on a "Deselect column" button of a Checkbox Table | after |
 * | rowChecked.pdui.table | checking a checkbox that selects a whole row | after |
 * | rowUnchecked.pdui.table | unchecking a checkbox that selects a whole row | after |
 * | allChecked.pdui.table | checking the whole table by clicking on the last "Select column" | after |
 * | allUnchecked.pdui.table | unchecking the whole table by clicking on the last "Deselect column" | after |
*/
