/***
 * # Links
 *
 * A link is a clickable element that allows the user to navigate to other resources, as for example web pages,
 * files, locations within the same page, email addresses, or any other URL.
 *
 * ## When to use links
 *
 * - Use links as navigational elements within the application
 * - Use links to guide the user to external resources or URLs
 * - Use links to guide the user to non-HTML content as for example downloadable files
 *
 * ## When not to use links
 *
 * - Do not use links to trigger an action within the application. In this case, use a [button](../docs/block-button.html#text-button) instead.
 *
 * ## Position and style
 *
 * - A link may stand alone after a sentence or an option. To highlight the relationship, place the link next to the sentence or option. Use a 12.5px separation ([`.margin--small`](../docs/helpers-margin.html)).
 * - A link may be part of a text, as an inline element inside of a sentence.
 * - Font size and weight are the same as the body text.
 *
 *
 * ## Accessibility
 *
 * - All links must have a declared `href` attribute with a destination in order to be considered hyperlinks and to be accessible to assistive technologies and keyboard users.
 * - The text of the link must be descriptive, clear and concise, providing enough information about its destination. Avoid terms like "click here".
 * - If the content of the link is not text, but an icon or another graphic element, use the aria-label attribute to provide an accessible text. In the case of `img` elements, you can also use the `alt` attribute for this purpose as in the example [non-text link](#non-text-link)
 * - Always inform the user about the type and the weight of a downloadable file. See [download link](#download-link) for a full example.
 */

/**
 * Inline link:
 *
 *
 *   @example
 *   p An inline link that goes to a #[a(href="#") different page] on the site.
 *
 */
a,
.link {
  @include std-link;
}

/**
 * A button that looks like a link:
 * `.link`
 *
 * Prefer using a [text button](./blocks-button.html#text-button) if possible.
 *
 *   @example
 *   p A #[button.link button that looks like a link] to be used inline.
 *
 */
.link {
  appearance: none;
  display: inline;
  background: none;
  border: 0;
  padding: 0;
  font-size: inherit;

  &:disabled,
  &[aria-disabled='true'] {
    @include disabled;

    &,
    &:hover { // override :disabled:hover default buttons
      color: color(primary, dark);
      background: none;
      border-color: transparent;
    }
  }
}

/**
 * Download link:
 *
 * Make sure to add the `download` attribute and to mention the type and the size of the file.
 *
 * <div class="alert top-margin">
 *   <p>The <code>download</code> attribute has an <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-download">optional value</a> but if set, it should be the document filename and extension.</p>
 * </div>
 *
 *   @example
 *   p
 *    a(href="#" download="Document.pdf") Title of the document (#[abbr(title='Adobe Portable Document Format') PDF], 24.5 #[abbr(title='Megabyte') MB])
 */

/**
 * Link with icon:
 *
 * Support of icons inside links.
 *
 *   @example
 *   p
 *    a(href="document.pdf")
 *      svg.button__icon.icon.icon--small.right-margin.right-margin--tiny(aria-hidden="true" focusable="false")
 *        use(xlink:href="../icons.svg#document" href="../icons.svg#document")
 *      | Link with an icon
 */

/**
 * Disabled link:
 * `a.disabled`
 *
 * A link can be disabled with the [`.disabled` helper](./helpers-typography.html#disabled-link).
 * For buttons, use the attribute `aria-disabled="true"` instead.
 *
 * <div class="alert alert--warning top-margin">
 *    <svg role="img" aria-labelledby="alert-title-1" focusable="false" class="alert__icon icon">
 *      <title id="alert-title-1">Warning</title>
 *      <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
 *    </svg>
 *    <p>This helper alone won't disable the link. <strong>Make sure to remove the <code>href</code> attribute</strong> to cancel the hyperlinking behavior.</p>
 * </div>
 *
 *     @example
 *     p
 *      a.disabled Disabled Link
 */

/**
 * Non-text link:
 *
 * `a.non-text-link`
 *
 * Provides an alternative focus indicator to links that don't contain text,
 * but graphics elements, for example, a logo.
 *
 * <div class="card card--standout top-margin">
 *   <div class="card__body">
 *     <p>
 *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="card-title-1" focusable="false">
 *         <title id="card-title-1">Information</title>
 *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
 *       </svg>
 *       <strong>Accessibility</strong>
 *     </p>
 *     <p>Make sure to provide a descriptive <code>alt</code> attribute to the image inside of the link.</p>
 *   </div>
 * </div>
 *
 *   @example
 *   p
 *     a.non-text-link(href='#')
 *       img(src='../images/UKG-teal.svg' width='90' height='30' alt='UKG')
 *
 */
.non-text-link {
  display: inline-block;
  background-color: transparent;
  border: 0;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: initial;
  }

  &:hover {
    @include focus-outline-pseudo-element($gap: 4px);

    &::before {
      @include rem(border-width, 1px);
    }
  }

  &:focus {
    @include focus-outline-pseudo-element($gap: 4px);
  }
}

/**
 * Link that opens in a new browser tab:
 * `target="_blank"`
 *
 * For security reasons, add the attribute `rel="noopener noreferrer"` to outgoing links with `target="_blank"`.
 * This prevents the possibility of external attacks from the linked page to the original one.
 *
 *     @example
 *     p An inline link that opens an #[a(href='#', target='_blank', rel='noopener noreferrer') external page] in a new browser tab.
 *
 */
