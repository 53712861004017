/***
 * # Loader
 *
 * `$(element).loader();`
 *
 * A loader that can be overlayed on top of any element whose content hasn't fully loaded yet.
 *
 * The developer can define attributes such as `data-loader-text` and `data-loader-min-height` to the said element.
 *
 * ## Initialization
 *
 * <div class="alert alert--danger top-margin top-margin--small">
 *   <p>This component will auto-instantiate unless it has the <code>[data-no-auto]</code> attribute at page load (DOMContentLoaded) or on <code>init.pdui</code> event recept on an ancestor.<p>
 *   <p>If the component is added to the page dynamically, trigger the <code>init.pdui</code> event on a parent node to instantiate it.</p>
 *   <p>If the component is not instantiated, it will auto-instantiate as soon as a method of that component is called programmatically, regardless of the presence of <code>[data-no-auto]</code>.</p>
 *   <p>If you don't set the component to auto-instantiate and you wish to instantiate it manually (pass options to it etc.) do:<br><code>$(el).loader();</code></p>
 * </div>
 */

/**
 * Example #1:
 *
 * A use case where the content that is loaded eventually happens to be longer than the height of the loader
 *
 *     @example
 *
 *     #some-loading-element(data-loader, data-loader-text='Loading list…')
 *     <!-- ignore all of the below - for demo purposes only -->
 *     br
 *     .button#toggle-loading Finish loading
 *     script.
 *       $('#toggle-loading').click(function () {if (/finish/i.test($(this).text())) {$(this).text('Start loading'); $('#some-loading-element').append($('#ajax-response').html()); $('#some-loading-element').loader('remove');} else {$(this).text('Finish loading'); $('#some-loading-element').html('').loader();}});
 *     #ajax-response(style='display:none;')
 *       table.dynamic-list
 *         thead
 *           tr
 *             th
 *             th.dynamic-list__column-title Column title 1
 *             th.dynamic-list__column-title Column title 2
 *             th.dynamic-list__column-title.dynamic-list__column-title--right Column title 3
 *         tbody
 *           for i in [0,1,2,3,4,5,6,7]
 *             - var idx = i>0 ? i : '';
 *             tr.dynamic-list__item
 *               td.dynamic-list__selector
 *                 .checkbox.checkbox--dark.checkbox--standalone
 *                   input(type='checkbox' name='checkbox' id=`checkbox${idx}`).checkbox__input
 *                   label.checkbox__label(for=`checkbox${idx}`)
 *                     span.checkbox__control
 *                       svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
 *                         use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
 *               td.dynamic-list__column(data-th='Column title 1')
 *                 .dynamic-list__column-row
 *                   p.dynamic-list__title
 *                     a Document Name
 *               td.dynamic-list__column(data-th='Column title 2')
 *                 .dynamic-list__column-row
 *                   p Meta
 *                 .dynamic-list__column-row
 *                   p Meta
 *               td.dynamic-list__column(data-th='Column title 3')
 *                 .dynamic-list__column-row.dynamic-list__column-row--right
 *                   p Right aligned
 */

/**
 * Example #2:
 *
 * A use case where the content that is loaded eventually happens to be shorter than the height of the loader
 *
 *     @example
 *
 *     #some-loading-element-2(data-loader, data-loader-text='Loading list…', data-loader-min-height='300')
 *     <!-- ignore all of the below - for demo purposes only -->
 *     br
 *     .button#toggle-loading-2 Finish loading
 *     script.
 *       $('#toggle-loading-2').click(function () {if (/finish/i.test($(this).text())) {$(this).text('Start loading'); $('#some-loading-element-2').append($('#ajax-response-2').html()); $('#some-loading-element-2').loader('remove');} else {$(this).text('Finish loading'); $('#some-loading-element-2').html('').loader();}});
 *     #ajax-response-2(style='display:none;')
 *       table.dynamic-list
 *         thead
 *           tr
 *             th
 *             th.dynamic-list__column-title Column title 1
 *             th.dynamic-list__column-title Column Ttitle 2
 *             th.dynamic-list__column-title.dynamic-list__column-title--right Column title 3
 *         tbody
 *           for i in [0,1]
 *             - var idx = i>0 ? i : '';
 *             tr.dynamic-list__item
 *               td.dynamic-list__selector
 *                 .checkbox.checkbox--dark.checkbox--standalone
 *                   input(type='checkbox' name='checkbox' id=`checkbox${idx}`).checkbox__input
 *                   label.checkbox__label(for=`checkbox${idx}`)
 *                     span.checkbox__control
 *                       svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
 *                         use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
 *               td.dynamic-list__column(data-th='Column title 1')
 *                 .dynamic-list__column-row
 *                   p.dynamic-list__title
 *                     a Document Name
 *               td.dynamic-list__column(data-th='Column title 2')
 *                 .dynamic-list__column-row
 *                   p Meta
 *                 .dynamic-list__column-row
 *                   p Meta
 *               td.dynamic-list__column(data-th='Column title 3')
 *                 .dynamic-list__column-row.dynamic-list__column-row--right
 *                   p Right aligned
 */

$loader-height-transition-duration: 0.8s;

@keyframes barberpole {
  from { background-position: 0%; }
  to { background-position: 100%; }
}

@include block('loader') {
  transition: height $loader-height-transition-duration;
  pointer-events: none;
  background-color: color(grey, 6);

  @include element('stripes') {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(-45deg, transparent 25%, white 25%, white 50%, transparent 50%, transparent 75%, white 75%);
    @include rem(background-size, 27px 27px);
    animation: barberpole 9s linear infinite;
  }

  @include element('text-container') {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: table;

    @include element('text') {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      font-weight: $bold;
    }
  }
}

.hidden-loading-element {
  opacity: 0;
}
