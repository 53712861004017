/***
 * # Upload items
 *
 * Upload items display information and actions related to a single file within an upload process.
 *
 * ## When to use upload items
 *
 * PDUI uses upload items to dynamically display files added by the user with the [upload button](./blocks-upload-button.html) and the [upload area](./blocks-upload-area.html) components.
 *
 * Upload items should be used to display files in the context of an upload process.
 *
 * ## When not to use upload items
 *
 * Upload items should not be used to display content other than upload files. To present different content types in a list format, consider [list items](./blocks-list-item.html), [trees](./blocks-tree.html), and [dynamic lists](./blocks-dynamic-list.html).
 *
 * ## Position and style
 *
 * Upload items are dynamically added below [upload buttons](./blocks-upload-button.html) and [upload areas](./blocks-upload-area.html) when users interact with those components.
 *
 * ## Keyboard interaction
 *
 * When focus is on the rename button:
 * - <kbd>Enter</kbd> or <kbd>Space</kbd> move focus to the rename input field.
 *
 * When focus is on the confirm rename button:
 * - <kbd>Enter</kbd> or <kbd>Space</kbd> move focus to the rename button
 *
 * When focus is on the cancel rename button:
 * - <kbd>Enter</kbd> or <kbd>Space</kbd> move focus to the rename button
 */

/**
 * ## Upload button items (static)
 *
 * When file items are used to display files uploaded using the [upload button](./blocks-upload-button.html), the **delete and retry actions are not available**. This is because the upload button is built on top of the native HTML `input[type=”file”]` element, and it does not provide this functionality.
 *
 * ### Basic upload button item
 *
 *     @example
 *     - var uniqueFileId = 'static'
 *     include ./code-samples/upload-item.pug
 */

/**
 * ### Renamable upload button item
 *
 * <div class="alert alert--warning top-margin">
 *   <svg class="alert__icon icon" role="img" aria-labelledby="renamable-upload-button-item-alert" focusable="false">
 *     <title id="renamable-upload-button-item-alert">Warning</title>
 *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning">
 *   </svg>
 *   <p>Files cannot be renamed in the upload button out of the box, but the component provides a solution to allow this functionality. See <a href="./blocks-upload-button.html#renaming-selected-files-in-the-upload-button">renaming selected files in the native file upload</a>.</p>
 * </div>
 *
 *     @example
 *     include ./code-samples/renamable.pug
 */

/**
 * ## Upload area items (dynamic)
 *
 * When file items are used to display files uploaded using the [upload area](./blocks-upload-area.html), then **delete and retry actions, and other dynamic states are available**.
 *
 *
 * ### Basic upload area item
 *
 *     @example
 *     - var uniqueFileId = 'dynamic'
 *     - var isDynamic = true
 *     - var modifier = 'upload-item--ready'
 *     include ./code-samples/upload-item.pug
 */

/**
 * ### Renamable upload area item
 *
 *     @example
 *     include ./code-samples/renamable-dynamic.pug
 */

$upload-item-border-color: color(grey, 5);
$upload-item-background-color: color(grey, 6);

$upload-item-success-color: color(success, deep);
$upload-item-error-color: color(danger, deep);

$upload-item-file-input-max-width: $form-field-width;
$upload-item-file-input-min-width: 45px;

$upload-item-progress-bar-width: 100px;

@include block('upload-item') {

  // STATE: RENAMING
  @include modifier('renaming') {
    @include element('content') {

      @include modifier('display') {
        display: none;
      }

      @include modifier('rename-mode') {
        display: inline-flex; // Using `display: flex` would prevent Voice Over on Safari from properly announcing the `ul` element as a semantic list
      }

      @include element('action') {
        display: block;
      }
    }
  }

  // STATE: READY
  @include modifier('ready') {
    @include element('action') {
      @include modifier('rename') {
        display: block;
      }

      @include modifier('remove') {
        display: block;
      }
    }
  }

  /**
   * ### Upload area item - queued state
   * `.upload-item--queued`
   *
   *     @example
   *     - var uniqueFileId = 'dynamic-queued'
   *     - var renamable = true
   *     - var isDynamic = true
   *     - var modifier = 'upload-item--queued'
   *     include ./code-samples/upload-item.pug
   */
  @include modifier('queued') {
    @include element('action') {
      @include modifier('remove') {
        display: block;
      }
    }

    @include element('status') {
      @include modifier('queued') {
        display: block;
        min-width: rem($upload-item-progress-bar-width);
      }
    }
  }

  /**
   * ### Upload area item - processing state
   * `.upload-item--processing`
   *
   *     @example
   *     - var uniqueFileId = 'dynamic-loading'
   *     - var renamable = true
   *     - var isDynamic = true
   *     - var modifier = 'upload-item--processing'
   *     include ./code-samples/upload-item.pug
   */
  @include modifier('processing') {
    @include mixie('progress-bar') {
      display: block;
    }
  }

  /**
   * ### Upload area item - uploaded state
   * `.upload-item--uploaded`
   *
   *     @example
   *     - var uniqueFileId = 'dynamic-success'
   *     - var renamable = true
   *     - var isDynamic = true
   *     - var modifier = 'upload-item--uploaded'
   *     include ./code-samples/upload-item.pug
   */
  @include modifier('uploaded') {
    @include mixie('icon') {
      @include modifier('success') {
        display: block;
        color: color(success, dark);
      }
    }

    @include element('main') {
      color: color(success, deep);
    }
  }

  /**
   * ### Upload area item - error state
   * `.upload-item--error`
   *
   *     @example
   *     - var uniqueFileId = 'dynamic-error'
   *     - var renamable = true
   *     - var isDynamic = true
   *     - var modifier = 'upload-item--error'
   *     - var errors = ['Please attach file in JPG or PDF format']
   *     include ./code-samples/upload-item.pug
   */
  @include modifier('error') {
    @include element('content') {
      border-color: form-field-color('invalid', border);
    }

    @include mixie('icon') {
      @include modifier('error') {
        display: block;
        color: color(danger, dark);
      }
    }

    @include element('action') {
      @include modifier('remove') {
        display: block;
      }

      @include modifier('retry') {
        display: block;
      }
    }

    &:not(.upload-item--renaming) {
      @include element('main') {
        color: color(danger, deep);
      }
    }
  }

  /**
   * ### Upload area item - no retry
   * `.upload-item--no-retry`
   *
   * Use if there is an error, but the retry action is not available, e.g., the maximum number of files has been reached.
   *
   *     @example
   *     - var uniqueFileId = 'dynamic-error'
   *     - var renamable = true
   *     - var isDynamic = true
   *     - var modifier = 'upload-item--error upload-item--no-retry'
   *     include ./code-samples/upload-item.pug
   */
  @include modifier('no-retry') {
    @include element('action') {
      @include modifier('retry') {
        display: none;
      }
    }
  }

  @include element('content') {
    display: inline-flex; // Using `display: flex` would prevent Voice Over on Safari from properly announcing the `ul` element as a semantic list
    width: 100%;
    min-height: rem(50px);
    align-items: center;
    border: rem(1px) solid $upload-item-border-color;
    margin: 0;
    padding: $ukg-spacing-xs / 2 $ukg-spacing-xs;
    background-color: $upload-item-background-color;
    border-radius: rem($border-radius-medium);

    // RENAME MODE
    @include modifier('rename-mode') {
      display: none;
      align-items: flex-start; // because `start` is not supported by IE11
      padding: $ukg-spacing-xs;

      @include element('main') {
        flex: auto;
        align-items: flex-start; // because `start` is not supported by IE11
      }
    }
  }

  @include element('main') {
    flex: initial;
    display: inline-flex; // Using `display: flex` would prevent Voice Over on Safari from properly announcing the `ul` element as a semantic list
    align-items: center;
  }

  @include element('actions') {
    flex: auto;
    display: inline-flex; // Using `display: flex` would prevent Voice Over on Safari from properly announcing the `ul` element as a semantic list
    justify-content: flex-end;
    align-items: center;
  }

  @include mixie('icon') {
    display: none;
    flex-shrink: 0;
  }

  @include mixie('progress-bar') {
    display: none;
    width: rem($upload-item-progress-bar-width);
  }

  @include element('action') {
    display: none;
    margin-left: $ukg-spacing-xs;
  }

  @include element('input-wrapper') {
    width: 100%;
    max-width: rem($upload-item-file-input-max-width);
    min-width: rem($upload-item-file-input-min-width);
  }

  @include element('input') {
    width: 100%;
    min-width: 0;
    border-radius: rem($border-radius-small);
  }

  @include element('status') {
    display: none;
  }

  @include element('info') {
    margin: $ukg-spacing-xxs $ukg-spacing-xs $ukg-spacing-xxs $ukg-spacing-xxs;

    @include modifier('name') {
      word-break: break-all;
      flex: initial;
    }

    @include modifier('size') {
      flex-shrink: 0;
    }
  }
}
