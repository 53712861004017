/***
 * # Tooltips
 *
 * A tooltip is a small information panel that pops over the item from where it
 * is triggered. Tooltips are hidden by default and display when the user
 * hovers over the trigger, or focuses on it using the keyboard.
 *
 * ## When to use tooltips
 *
 * Tooltips should be used to provide a brief, informative message related to an
 * interactive element or control, as for example a button, a link or an input field.
 *
 * ## When not to use tooltips
 *
 * Tooltips, like [popovers](../docs/blocks-popover.html), should not be used for critical information, as they are
 * less discoverable than on-page content. Avoid using tooltips on mobile
 * interfaces where the user is unable to hover with a mouse.
 *
 * Tooltips should not contain rich information, images or interactive elements.
 *
 * Do not use tooltips with non-interactive elements such as plain text or images, as they won't
 * be accessible for all users.
 *
 * ## Position and style
 *
 * Tooltips are positioned either at the top, right, bottom or left of their
 * triggers. The position of the tooltip will update dynamically if the viewport
 * changes and the tooltip is no longer visible.
 *
 * ## Accessibility
 *
 * We distinguish between two use cases:
 *
 * - **Tooltip as primary label:** the tooltip is the primary text associated with the
 * element, e.g. a button with an icon only. The ARIA attribute used is `aria-labelledby`.
 * See [tooltip as primary label](#tooltip-as-primary-label).
 *
 * - **Tooltip as auxiliary description:** the tooltip provides additional, auxiliary information to
 * an element that already contains a primary label, e.g. a button with text. The ARIA attribute used
 * is `aria-describedby`. See [tooltip as auxiliary description](#tooltip-as-auxiliary-description).
 *
 */

$tooltip-max-width:           250px;
$tooltip-padding:             5px 8px;
$tooltip-border-radius:       $border-radius-small;

$tooltip-bg:                  color(grey, 3);
$tooltip-color:               $white;

$tooltip-line-height:         1.2;

@include bubble('tooltip', $ukg-spacing-xxxs) {
  @include no-first-child-margin;
  @include rem(font-size, $small-font-size);
  @include rem(border-radius, $tooltip-border-radius);
  @include rem(padding, $tooltip-padding);
  @include rem(max-width, $tooltip-max-width);
  line-height: $tooltip-line-height;
  background-color: $tooltip-bg;
  box-shadow: $ukg-elevation-08;
  color: $tooltip-color;
  text-align: center;
  visibility: visible;

  &:hover {
    cursor: default;
  }

  > * {
    @include rem(font-size, $small-font-size);
    line-height: $tooltip-line-height;
  }

  /**
  * Tooltip as primary label:
  * `.tooltip`
  *
  * A tooltip providing the primary label to the element.
  *
  * <div class="card card--standout top-margin">
  *   <div class="card__body">
  *     <p>
  *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title1" focusable="false">
  *         <title id="title1">Information</title>
  *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
  *       </svg>
  *       <strong>Accessibility</strong>
  *     </p>
  *     <p>As no other text is present, the appropiate ARIA attribute to establish the relationship between the trigger and the tooltip is in this case `aria-labelledby`.</p>
  *   </div>
  * </div>
  *
  *     @example
  *     button.button.button--icon(type='button' data-toggle='tooltip' aria-labelledby='tooltip-primary-label')
  *       svg.button__icon.icon(aria-hidden='true', focusable='false')
  *          use(xlink:href='../icons.svg#home' href='../icons.svg#home')
  *     .tooltip#tooltip-primary-label(role='tooltip') Primary label
  */

  /**
  * Tooltip as auxiliary description:
  * `.tooltip`
  *
  * A tooltip providing an auxiliary description to the element.
  *
  * <div class="card card--standout top-margin">
  *   <div class="card__body">
  *     <p>
  *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title2" focusable="false">
  *         <title id="title2">Information</title>
  *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
  *       </svg>
  *       <strong>Accessibility</strong>
  *     </p>
  *     <p>As the button already has a text label, the appropiate ARIA attribute to establish the relationship between the trigger and the tooltip is in this case `aria-describedby`.</p>
  *   </div>
  * </div>
  *
  *     @example
  *     button.button.button--primary(type='button' data-toggle='tooltip' aria-describedby='tooltip-auxiliary-description') Submit
  *     .tooltip#tooltip-auxiliary-description(role='tooltip') Additional information
  */

  /**
  * Bottom tooltip:
  * `.tooltip--bottom`
  *
  * A tooltip that appears below the trigger.
  *
  *     @example
  *     a(href='#' data-toggle='tooltip' aria-describedby='tooltip-bottom') Clear
  *     .tooltip.tooltip--bottom#tooltip-bottom(role='tooltip') Additional information
  */

  /**
  *  Left tooltip:
  * `.tooltip--left`
  *
  * A tooltip that appears left of the trigger.
  *
  *     @example
  *     label.label(for='tooltip-input') First name
  *     input.input#tooltip-input(data-toggle='tooltip' aria-describedby='tooltip-left' type='text')
  *     .tooltip.tooltip--left#tooltip-left(role='tooltip') Additional information
  */

  /**
  * Right tooltip:
  * `.tooltip--right`
  *
  * A tooltip that appears right of the trigger.
  *
  *     @example
  *     button.button(data-toggle='tooltip' aria-describedby='tooltip-right' type='button') Print
  *     .tooltip.tooltip--right#tooltip-right(role='tooltip') Additional information
  */

  /**
  * Tooltip on a disabled element:
  * `.tooltip__disabled-wrapper[data-toggle="tooltip"]`
  *
  * Disabled element are not interactive, therefore they don't respond to user actions such as `hover` or `focus`.
  *
  * To display a tooltip on a disabled element, create a wrapper and apply to it the attribute
  * `data-toggle="tooltip"` so that it triggers the tooltip on `hover`.
  *
  * The disabled element must have the attribute `aria-describedby` or `aria-labelledby` just as in default tooltips.
  *
  *     @example
  *     span.tooltip__disabled-wrapper(data-toggle='tooltip')
  *       button.button(aria-describedby='tooltip-disabled' type='button' disabled) Delete
  *     .tooltip#tooltip-disabled(role='tooltip') This PDF templare cannot be deleted because it is being used in an ongoing process or a process template.
  */

  /**
  * Tooltip in popover:
  * `.tooltip--in-popover`
  *
  * A tooltip inside of a [popover](blocks-popover.html).
  *
  *     @example
  *     button.button.button--text(aria-expanded='false' aria-controls='uniqueID_tooltip-in-popover' data-toggle='popover') Tooltip in popover
  *     .popover#uniqueID_tooltip-in-popover.popover--bottom.popover--no-min-width.popover--padding-small
  *       .popover__body
  *         .popover__content.centered
  *           button.button.button--icon(type='button' data-toggle='tooltip' aria-labelledby='open-link-tooltip-unique-ID-0')
  *             svg.button__icon.icon(aria-hidden='true', focusable='false')
  *               use(xlink:href='../icons.svg#new-window' href='../icons.svg#new-window')
  *           .tooltip.tooltip--in-popover#open-link-tooltip-unique-ID-0(role='tooltip') Open link
  *           button.button.button--icon(type='button' data-toggle='tooltip' aria-labelledby='open-edit-tooltip-unique-ID-0')
  *             svg.button__icon.icon(aria-hidden='true', focusable='false')
  *               use(xlink:href='../icons.svg#edit' href='../icons.svg#edit')
  *           .tooltip.tooltip--in-popover#open-edit-tooltip-unique-ID-0(role='tooltip') Edit link
  *           button.button.button--icon(type='button' data-toggle='tooltip' aria-labelledby='unlink-tooltip-unique-ID-0')
  *             svg.button__icon.icon(aria-hidden='true', focusable='false')
  *               use(xlink:href='../icons.svg#link-unlink' href='../icons.svg#link-unlink')
  *           .tooltip.tooltip--in-popover#unlink-tooltip-unique-ID-0(role='tooltip') Unlink
  */

  @at-root .tooltip {
    @include modifier('in-popover') {
      z-index: index($z-index-scale, tooltip-in-popover);
    }
  }
}

.tooltip__disabled-wrapper { // Cannot use @mixin element() because it is not a child of .tooltip
  display: inline-block;

  :disabled {
    pointer-events: none;
  }
}
