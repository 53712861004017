/***
 * # Radios
 *
 * A radio is a form input that the user can click on to select an option.
 * Radios are presented in groups. The user can only select one option from the
 * group.
 *
 * ## When to use radios
 *
 * Radios should be used when the user can select between a small number of
 * different options.
 *
 * ## When not to use radios
 *
 * Radios should not be used when there is a long list of options to select
 * from - instead use a select.
 *
 * Radios should not be used when it is ok for the user to select more
 * than one option. Use either a checkbox or multi-select instead.
 *
 * If you need to add a lot of text to the radio description, use a checkable
 * block (which can include a radio) instead.
 *
 * ## Position and style
 *
 * Standard radios should be placed inside cards or other containers.
 * If you need to use a radio directly against the page background, use the
 * `dark` modifier.
 *
 * Like other inputs, `valid` and `invalid` modifiers can be added to the radio
 * to indicate state.
 *
 * See [Fieldset](blocks-fieldset.html#required-fieldset) for an example of a required radio group.
 *
 */

$radio-control-border-radius: 50%;

@include checkable-input('radio', $radio-control-border-radius);

/**
 * Basic radio:
 * `.radio`
 *
 * A radio input.
 *
 *     @example
 *     .radio
 *       input(type='radio' name='radio' id='radio1' value='value-1').radio__input
 *       label.radio__label(for='radio1')
 *         span.radio__control
 *         span.radio__display This is a radio input
 *     .radio
 *       input(type='radio' name='radio' id='radio2' value='value-2' checked).radio__input
 *       label.radio__label(for='radio2')
 *         span.radio__control
 *         span.radio__display This is a checked radio input
 *     .radio
 *       input(type='radio' name='radio' id='radio3' value='value-3' disabled).radio__input
 *       label.radio__label(for='radio3')
 *         span.radio__control
 *         span.radio__display This is a disabled radio input
 */

/**
 * Valid radio:
 * `.radio.radio--valid`
 *
 * A valid radio input.
 *
 *     @example
 *     .radio.radio--valid
 *       input(type='radio' name='valid-radio' id='valid-radio1' value='value-1').radio__input
 *       label.radio__label(for='valid-radio1')
 *         span.radio__control
 *         span.radio__display This is a radio input
 *     .radio.radio--valid
 *       input(type='radio' name='valid-radio' id='valid-radio2' value='value-2' checked).radio__input
 *       label.radio__label(for='valid-radio2')
 *         span.radio__control
 *         span.radio__display This is a checked radio input
 *     .radio.radio--valid
 *       input(type='radio' name='valid-radio' id='valid-radio3' value='value-3' disabled).radio__input
 *       label.radio__label(for='valid-radio3')
 *         span.radio__control
 *         span.radio__display This is a disabled radio input
 */

/**
 * Invalid radio:
 * `.radio.radio--invalid`
 *
 * An invalid radio input.
 *
 *     @example
 *     .radio.radio--invalid
 *       input(type='radio' name='invalid-radio' id='invalid-radio1' value='value-1').radio__input
 *       label.radio__label(for='invalid-radio1')
 *         span.radio__control
 *         span.radio__display This is a radio input
 *     .radio.radio--invalid
 *       input(type='radio' name='invalid-radio' id='invalid-radio2' value='value-2' checked).radio__input
 *       label.radio__label(for='invalid-radio2')
 *         span.radio__control
 *         span.radio__display This is a checked radio input
 *     .radio.radio--invalid
 *       input(type='radio' name='invalid-radio' id='invalid-radio3' value='value-3' disabled).radio__input
 *       label.radio__label(for='invalid-radio3')
 *         span.radio__control
 *         span.radio__display This is a disabled radio input
 */

/**
 * Bold radio:
 * `.radio__label.bold`
 *
 * A radio input with a bold label. It uses the [helper class `.bold`](../docs/helpers-typography.html#bold-text)
 *
 *     @example
 *     .radio
 *       input(type='radio' name='bold-radio' id='bold-radio1' value='value-1').radio__input
 *       label.radio__label.bold(for='bold-radio1')
 *         span.radio__control
 *         span.radio__display This is a radio input with a bold label
 */
