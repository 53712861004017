/***
 * # Thumbnail
 *
 * A thumbnail that optionally contains a checkbox to allow for bulk actions.
 *
 * ## When to use thumbnails
 *
 * Use in blocks with other thumbnails, directly against the main background.
 * Do not use in any other context.
 *
 *
 * ## Initialization
 *
 * <div class="alert alert--danger top-margin top-margin--small">
 *   This component will auto-instantiate, unless it has the <code>[data-no-auto]</code> attribute.
 * </div>
 */

$thumbnail-padding:                               $block-padding / 2;
$thumbnail-checkbox-width:                        28px;

$thumbnail-image-aspect-ratio:                    10 / 16;

$thumbnail-content-border-color:                  $block-border-color;

$thumbnail-item-vertical-spacing:                 $item-vertical-spacing;
$thumbnail-border-width:                          1px;
$thumbnail-selected-border-width:                 2px;

$thumbnail-button-background:                     color(grey, 3);
$thumbnail-button-group-margin:                   6px;
$thumbnail-button-margin:                         1px;

$thumbnail-selected-background:                   $highlight-range;
$thumbnail-selected-links:                        $font-color-on-primary;
$thumbnail-selected-meta:                         var(--thumbnail-selected-meta);

/**
 * Basic thumbnail:
 * `.thumbnail`
 *
 * A thumnail that displays an image and selected data.
 *
 *     @example
 *     ul.tiles
 *       li.tiles__tile
 *         .thumbnail
 *           .thumbnail__image
 *             img(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/doc-contract-page-1.jpg' alt='Contract Page 1')
 *           .thumbnail__content
 *             h4.thumbnail__title
 *               a Document title
 *             p.thumbnail__meta Meta 1
 *             p.thumbnail__meta Meta 2
 *       li.tiles__tile
 *         .thumbnail
 *           .thumbnail__image
 *             img(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/img-v-2.png' alt='Window')
 *           .thumbnail__content
 *             .cells
 *               .thumbnail__checkbox.cells__cell.cells__cell--fit-content
 *                 .checkbox.checkbox--standalone.checkbox--dark
 *                   input(type='checkbox' name='checkbox' id='checkbox').checkbox__input
 *                   label.checkbox__label(for='checkbox')
 *                     span.checkbox__control
 *                       svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
 *                         use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
 *               .cells__cell
 *                 h4.thumbnail__title#thumbnail-title1
 *                   a Document with checkbox
 *                 p.thumbnail__meta Meta 1
 *                 p.thumbnail__meta Meta 2
 *
 */
@include block('thumbnail') {
  @include card;
  position: relative;
  overflow: hidden;
  color: color(grey, 3);

  .tiles & {
    height: 100%; // Equalise height if the thumbnail is part of a .tiles component.
  }

  @include element('image') {
    background-color: $white;
    padding-top: $thumbnail-image-aspect-ratio * 100%;
    height: 0;
    overflow: hidden;
    position: relative;

    > img {
      display: block;
      position: absolute;
      margin: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;

      @supports (object-fit: cover) {
        height: 100%;
        object-fit: cover;
        object-position: center top;
      }
    }
  }

  @include element('content') {
    @include rem(padding, $thumbnail-padding);
    @include rem(border-top, $thumbnail-border-width solid $thumbnail-content-border-color);
    width: 100%;
  }

  @include element('checkbox') {
    @include rem(width, $thumbnail-checkbox-width);

    // Adjust checkbox position…
    position: relative;
    @include rem(top, -2px);
  }

  @include element('title') {
    @include h5;
    line-height: 1.2;
    margin-top: 0;
  }

  @include element('meta') {
    @include rem(margin-top, $thumbnail-item-vertical-spacing);
    vertical-align: middle;
  }

  /**
   * Selected thumbnail:
   * `.thumbnail--selected`
   *
   * A selected-thumbnail.
   *
   *     @example
   *     ul.tiles
   *       li.tiles__tile
   *         .thumbnail.thumbnail--selected
   *           .thumbnail__image
   *             img(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/doc-payslip-page-1.jpg' alt='Payslip')
   *           .thumbnail__content
   *             .cells
   *               .thumbnail__checkbox.cells__cell.cells__cell--fit-content
   *                 .checkbox.checkbox--standalone.checkbox--dark
   *                   input(type='checkbox' name='checkbox' id='checkbox2' checked).checkbox__input
   *                   label.checkbox__label(for='checkbox2')
   *                     span.checkbox__control
   *                       svg.checkbox__icon.icon(aria-hidden='true', focusable='false')
   *                         use(xlink:href='../icons.svg#checkmark' href='../icons.svg#checkmark')
   *               .cells__cell
   *                 h4.thumbnail__title#thumbnail-title2
   *                   a Selected document
   *                 p.thumbnail__meta Meta 1
   *                 p.thumbnail__meta Meta 2
   */
  @include modifier('selected') {
    background-color: $thumbnail-selected-background;
  }

  /**
   * Thumbnail with icon button:
   * `.thumbnail`
   *
   * A thumbnail that displays an image with an action button.
   *
   *     @example
   *     ul.tiles
   *       li.tiles__tile
   *         .thumbnail
   *           .thumbnail__image
   *            button.thumbnail__button.button(type='button' aria-label='Download document title')
   *              svg.button__icon.icon.icon--small(aria-hidden='true', focusable='false')
   *                use(xlink:href='../icons.svg#download' href='../icons.svg#download')
   *            img(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/img-v-1.png' alt='Window')
   *           .thumbnail__content
   *             h4.thumbnail__title
   *               a Document title
   *             p.thumbnail__meta Meta 1
   *             p.thumbnail__meta Meta 2
   *
   */
  @include mixie('button') {
    position: absolute;
    opacity: 0;
    transition: opacity 0.1s ease-out;
    border: 0;
    color: $white;
    @include rem(right, $thumbnail-button-group-margin);
    @include rem(top, $thumbnail-button-group-margin);
    z-index: index($z-index-scale, base);

    &.button--primary {
      color: $font-color-on-primary;
    }

    /* nesting necessary otherwise colors overwritten with $thumbnail-button-background */
    &:not(.button--primary) {
      &:not(.button--danger) {
        background-color: $thumbnail-button-background;
      }
    }
  }

  &:hover,
  &.thumbnail--has-focus {
    .thumbnail__button {
      opacity: 0.5;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }

  /**
   * Thumbnail with multiple icons buttons:
   * `.thumbnail`
   *
   * A thumbnail that displays an image with an action button group.
   *
   *     @example
   *     ul.tiles
   *       li.tiles__tile
   *         .thumbnail
   *           .thumbnail__image
   *            .thumbnail__button-group.button-group
   *              a.button-group__button.thumbnail__button.button(href='#' aria-label='Download document title')
   *               svg.button__icon.icon.icon--small(aria-hidden='true', focusable='false')
   *                 use(xlink:href='../icons.svg#download' href='../icons.svg#download')
   *              a.button-group__button.thumbnail__button.button(href='#' aria-label='Search document title')
   *               svg.button__icon.icon.icon--small(aria-hidden='true', focusable='false')
   *                 use(xlink:href='../icons.svg#search' href='../icons.svg#search')
   *              a.button-group__button.thumbnail__button.button(href='#' aria-label='Delete document title')
   *               svg.button__icon.icon.icon--small(aria-hidden='true', focusable='false')
   *                 use(xlink:href='../icons.svg#delete' href='../icons.svg#delete')
   *            img(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/doc-fw9-page-2.jpg' alt='Form Page 2')
   *           .thumbnail__content
   *             h4.thumbnail__title
   *               a Document title
   *             p.thumbnail__meta Meta 1
   *             p.thumbnail__meta Meta 2
   *
   */
  @include mixie('button-group') {
    position: absolute;
    @include rem(border, $thumbnail-button-group-margin solid transparent);
    width: 100%;
    text-align: right;
    font-size: 0;
    z-index: index($z-index-scale, base);
    top: 0;
    right: 0;

    @include mixie('button') {
      position: relative;
      border: 0;
      display: inline-block;
      top: auto;
      right: auto;
      float: none;
      @include rem(margin-left, $thumbnail-button-margin);
      @include rem(margin-bottom, $thumbnail-button-margin);
      @include rem(border-radius, $button-border-radius); // overriding .button-group CSS for .button
    }
  }

  @media all and (max-width: $tablet) {
    @include mixie('button') {
      opacity: 1;
    }
  }

  /**
   * Thumbnail with colored icons buttons:
   *
   *     @example
   *     ul.tiles
   *       li.tiles__tile
   *         .thumbnail
   *           .thumbnail__image
   *             .thumbnail__button-group.button-group
   *               a.button-group__button.thumbnail__button.button.button--primary(href='#' aria-label='Download document title')
   *                 svg.button__icon.icon.icon--small(aria-hidden='true', focusable='false')
   *                   use(xlink:href='../icons.svg#download' href='../icons.svg#download')
   *               a.button-group__button.thumbnail__button.button.button--danger(href='#' aria-label='Delete document title')
   *                 svg.button__icon.icon.icon--small(aria-hidden='true', focusable='false')
   *                   use(xlink:href='../icons.svg#delete' href='../icons.svg#delete')
   *             img(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/doc-fw9-page-2.jpg' alt='Form Page 2')
   *           .thumbnail__content
   *             h4.thumbnail__title
   *               a Document title
   *             p.thumbnail__meta Meta 1
   *             p.thumbnail__meta Meta 2
   *       li.tiles__tile
   *         .thumbnail
   *           .thumbnail__image
   *            button.thumbnail__button.button.button--danger(type='button' aria-label='Download document title')
   *              svg.button__icon.icon.icon--small(aria-hidden='true', focusable='false')
   *                use(xlink:href='../icons.svg#download' href='../icons.svg#download')
   *            img(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/img-v-1.png' alt='Window')
   *           .thumbnail__content
   *             h4.thumbnail__title
   *               a Document title
   *             p.thumbnail__meta Meta 1
   *             p.thumbnail__meta Meta 2
   *
   */

  /**
   * Thumbnail image with its original aspect ratio:
   * `.thumbnail--original-aspect-ratio`
   *
   * A thumbnail that respects the original aspect ratio of the image, instead of forcing the default one.
   *
   * It prevents images from being cropped.
   *
   * <div class="alert alert--warning top-margin">
   *   <svg role="img" aria-labelledby="icon-warning" focusable="false" class="alert__icon icon">
   *     <title id="icon-warning">Warning</title>
   *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
   *   </svg>
   *   <p>Use this modifier only if you can ensure that <strong>all</strong> the thumbnail images in a grid have the same aspect ratio.</p>
   * </div>
   *
   *     @example
   *     ul.tiles
   *       li.tiles__tile
   *         .thumbnail.thumbnail--original-aspect-ratio
   *           .thumbnail__image
   *             img(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/doc-contract-page-1.jpg' alt='Contract Page 1')
   *           .thumbnail__content
   *             h4.thumbnail__title
   *               a Document title
   *             p.thumbnail__meta Meta 1
   *             p.thumbnail__meta Meta 2
   *       li.tiles__tile
   *         .thumbnail.thumbnail--original-aspect-ratio
   *           .thumbnail__image
   *             img(src='https://s3.eu-west-2.amazonaws.com/peopledoc-ui/doc-contract-page-1.jpg' alt='Contract Page 1')
   *           .thumbnail__content
   *             h4.thumbnail__title
   *               a Document title
   *             p.thumbnail__meta Meta 1
   *             p.thumbnail__meta Meta 2
   *
   */
  @include modifier('original-aspect-ratio') {
    @include element('image') {
      padding-top: 0;
      height: auto;

      > img {
        position: static;

        @supports (object-fit: cover) {
          height: auto;
          object-fit: unset;
          object-position: unset;
        }
      }
    }
  }
}
