/*
* PDUI BUTTON TOOLS
*
* 1. Standard button
*   1.1. Hover state
*   1.2. Active state
*   1.3. Focus indicator
*   1.4. Disabled state
*   1.5. Pressed state
* 2. Color button
*   2.1. Color button base
*   2.2. Hover state
*   2.3. Active state
*   2.4. Focus indicator
*   2.5. Pressed state
* 3. On color button
*   3.1. On color button base
*   3.2. Hover state
*   3.3. Active state
*   3.4. Focus indicator
*   3.5. Pressed state
* 4. On dark grey button
*   4.1. Hover state
*   4.2. Active state
*   4.3. Focus indicator
*   4.4. Pressed state
* 5. Button generators
+   5.1. Standard button
*   5.2. Color button
*   5.3. On a color background button
*   5.4. On a dark grey background button
*/

// 1. STANDARD BUTTON

// 1.1. HOVER STATE
// Generates a hover state for a standard button.
@mixin std-hover {
  background-color: $button-hover-background-color;
  border-color: $button-hover-border-color;
  color: $button-hover-color;
}

// 1.2. ACTIVE STATE
// Generates an active state for a standard button.
@mixin std-active {
  background-color: $button-hover-background-color;
  border-color: $button-active-border-color;
  color: $button-hover-color;
}

// 1.3. FOCUS INDICATOR
// Generates a focus indicator for a standard button.
// This mixin should be used in combination with '1.1 HOVER STATE' to style the focus state.
@mixin std-focus-indicator {
  @include focus-outline;
}

// 1.4. DISABLED STATE
// Generates a 'disabled' state for a standard button.
@mixin std-disabled {
  @include disabled;
  color: $button-text-color;
  background-color: $button-background-color;
  border-color: $button-border-color;
}

// 1.5. PRESSED STATE
// Generates a pressed state for a standard button.
@mixin std-pressed {
  @include button-active-indicator($button-active-border-color, $button-hover-background-color);
  border-color: $button-active-border-color;
  color: $button-hover-color;
}

// 2. COLOR BUTTON

// 2.1. COLOR BUTTON BASE
// Generates the default state for a color button.
@mixin color-base($color) {
  $border-color: color($color, deep);
  $background-color: color($color, dark);
  $font-color: $white;

  @if $color == 'primary' {
    $border-color: var(--button-primary-base-border-color);
    $background-color: var(--button-primary-base-background-color);
    $font-color: var(--button-primary-base-font-color);
  }
  border: rem(1px) solid $border-color;
  background-color: $background-color;
  color: $font-color;
}

// 2.2. HOVER STATE
// Generates a hover state for a color button.
@mixin color-hover($color) {
  $background-color: color($color, deep);

  @if $color == 'primary' {
    $background-color: var(--button-primary-hover-background-color);
  }
  background-color: $background-color;
}

// 2.3. ACTIVE STATE
// Generates an active state for a color button.
@mixin color-active($color) {
  @include color-hover($color);
  border-color: $button-active-border-color;
}

// 2.4. FOCUS INDICATOR
// Generates a focus indicator for a color button.
// This mixin should be used in combination with '2.2 HOVER STATE' to style the focus state.
@mixin color-focus-indicator {
  @include focus-outline($outline-color: $button-colored-focus-outline-color);
}

// 2.5. PRESSED STATE
// Generates a pressed state for a color button.
@mixin color-pressed($color) {
  border-color: $button-active-border-color;
  @if $color == 'primary' {
    @include button-active-indicator($font-color-on-primary, color($color, deep));
    color: $font-color-on-primary;
  } @else {
    @include button-active-indicator($white, color($color, deep));
    color: $white;
  }
}

// 3. ON COLOR BUTTON

// 3.1. ON COLOR BUTTON BASE
// Generates the default state for a button on a colored background.
@mixin on-color-base($color) {
  border-color: color($color, deep);

  @if $color == 'primary' {
    color: var(--button-primary-on-color-base-font-color);
  } @else {
    color: color($color, dark);
  }
}

// 3.2. HOVER STATE
// Generates a hover state for a button on a colored background.
@mixin on-color-hover($color) {
  @if $color == 'primary' {
    color: var(--button-primary-on-color-hover-font-color);
  } @else {
    color: color($color, deep);
  }
}

// 3.3. ACTIVE STATE
// Generates an active state for a button on a colored background.
@mixin on-color-active($color) {
  @include on-color-hover($color);
  border-color: color(grey, 3);
}

// 3.4. FOCUS INDICATOR
// Generates a focus indicator for a button on a colored background.
// This mixin should be used in combination with '3.1 HOVER STATE' to style the focus state.
@mixin on-color-focus-indicator($color) {
  @include focus-outline($background-color: color($color, dark), $outline-color: $white);
}

// 3.5. PRESSED STATE
// Generates a pressed state for a button on a colored background.
@mixin on-color-pressed($color) {
  @include on-color-hover($color);
  @include button-active-indicator(color(grey, 3), color(grey, 5));
  border-color: color(grey, 3);
}

// 4. ON DARK GREY BUTTON

// 4.1. HOVER STATE
// Generates a hover state for a button on a dark grey background.
@mixin on-dark-grey-hover {
  background-color: color(grey, 5);
}

// 4.2. ACTIVE STATE
// Generates an active state for a button on a dark grey background.
@mixin on-dark-grey-active {
  @include on-dark-grey-hover;
  border-color: color(primary, deep);
}

// 4.3. FOCUS INDICATOR
// Generates a focus indicator for a button on a dark grey background.
// This mixin should be used in combination with '4.1 HOVER STATE' to style the focus state.
@mixin on-dark-grey-focus-indicator {
  @include focus-outline($background-color: color(grey, 3));
}

// 4.4. PRESSED STATE
// Generates a pressed state for a button on a dark grey background.
@mixin on-dark-grey-pressed {
  @include button-active-indicator(color(primary, deep), color(grey, 5));
  border-color: color(primary, deep);
}

// 5. BUTTON GENERATORS

// 5.1. STANDARD BUTTON
// Applies the stardard button styles to any element
@mixin std-button {
  font-weight: bold;
  font-size: rem($h6-size);
  line-height: rem($button-line-height);
  padding: rem($button-padding);
  border: rem(1px) solid $button-border-color;
  border-radius: rem($button-border-radius);
  display: inline-block;
  background-color: $button-background-color;
  color: $button-text-color;
  cursor: pointer;
  vertical-align: top;
  text-align: center;
  overflow: hidden;
  max-width: 100%;
  white-space: normal;
  text-decoration: none;
}

// 5.2. COLOR BUTTON
// Takes a color and returns a corresponding solid color button. Use dark colors.
@mixin color-button($color) {
  @include color-base($color);

  &:hover,
  &:focus {
    @include color-hover($color);
  }

  &:focus,
  &:active {
    @include color-focus-indicator;
  }

  &:active {
    @include color-active($color);
  }

  &:disabled,
  &[aria-disabled='true'] {
    &,
    &:hover {
      @include color-base($color);
    }

    &:active {
      @include hide-focus-indicator;
    }
  }
}

// 5.3. BUTTON ON A COLORED BACKGROUND
// Returns a button for placing on top of dark backgrounds.
@mixin on-color-button($color) {
  @include on-color-base($color);

  &:hover,
  &:focus {
    @include on-color-hover($color);
  }

  &:focus,
  &:active {
    @include on-color-focus-indicator($color);
  }

  &:active {
    @include on-color-active($color);
  }

  &:disabled,
  &[aria-disabled='true'] {
    &,
    &:hover {
      @include on-color-base($color);
    }

    &:active {
      @include hide-focus-indicator;
    }
  }
}

// 5.4. BUTTON ON A DARK GREY BACKGROUND
// Returns a button for placing on top of dark backgrounds.
@mixin on-dark-grey-button {
  &:hover,
  &:focus {
    @include on-dark-grey-hover;
  }

  &:focus,
  &:active {
    @include on-dark-grey-focus-indicator;
  }

  &:active {
    @include on-dark-grey-active;
  }

  &:disabled,
  &[aria-disabled='true'] {
    &:active {
      @include hide-focus-indicator;
    }
  }
}
