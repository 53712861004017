// FORMS

@import '@ukgepic/hrsd-pdui-theme/src/settings/color', 'radius', 'typography';

// INPUT GROUP
$input-group-gap:                     ($ukg-spacing-s / 1rem) * $rem-baseline; // Convert $ukg-spacing-s to px

// FORM FIELDS

$form-field-width:                    225px;
$medium-form-field-width:             120px;
$long-form-field-width:               $form-field-width * 2 + $input-group-gap;
$short-form-field-width:              $form-field-width / 3;
$form-field-responsive-width:         100%;
$form-field-height:                   32px;
$large-form-field-height:             40px;

// IE says: do not use vertical padding on form fields :/
$form-field-padding:                  0 5px;
$large-form-field-padding:            0 7px;

$form-field-border-width:             1px;
$form-field-border-radius:            $border-radius-medium;

// FORM FIELD COLORS

$form-field-colors: (
  standard: (
    border:         color(grey, 4),
    text:           $base-font-color,
    focus-text:     color(grey, 4),
    focus-border:   color(primary, mid),
    placeholder:    hsla(var(--grey-level-3-h), var(--grey-level-3-s), var(--grey-level-3-l), 0.71)
  ),
  dark: (
    border:         $dark-font-color,
    text:           $dark-font-color,
    focus-text:     $dark-font-color,
    focus-border:   color(primary, mid),
    placeholder:    hsla(var(--grey-level-1-h), var(--grey-level-1-s), var(--grey-level-1-l), 0.71)
  ),
  invalid: (
    border:         color(danger, dark),
    text:           color(danger, deep),
    focus-text:     mix(white, color(danger, light), 40),
    focus-border:   color(grey, 3),
    placeholder:    rgba(color(danger, deep), 0.71)
  ),
  valid: (
    border:         color(success, dark),
    text:           color(success, deep),
    focus-text:     mix(white, color(success, light), 40),
    focus-border:   color(grey, 3),
    placeholder:    rgba(color(success, deep), 0.71)
  )
);
