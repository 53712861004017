/***
 * # Sticky tab
 *
 * A tab that sticks to the right hand side of the main container.
 * Use to link to important external resources, e.g. support portals.
 *
 * Note: Only one sticky tab should be added per page.
 */

/**
 * Basic sticky tab:
 * `.sticky-tab`
 *
 * A tab that sticks to the right hand side of the browser window.
 *
 *     @example
 *     a(href='#' target='_blank').sticky-tab Support
 */
@include block('sticky-tab') {
  // `:link` to overide a:link selector from @ignite/tokens CSS
  &,
  &:link,
  &:visited {
    @include std-button;
    @include color-button('primary');
    border-radius: rem($border-radius-medium $border-radius-medium 0 0);
    position: fixed;
    right: 0;
    top: 30%;
    transform: rotate(-90deg);
    transform-origin: bottom right;
    z-index: index($z-index-scale, sticky-tab);
  }
}
