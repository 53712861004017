/***
* # List item
*
* A list item is a link that displays information about an object; suitable
* objects include an employee, a request, a task, an article or a file.
*
* List items can contain an image or icon (on the left) and data on the right.
* The component includes the option to separate the data by importance, by
* using the `primary-content` and `secondary-content` modifiers.
*
* ## When to use list items
*
* List items should be used when you wish to present information about an object.
* When using list items, it is best to use items of the same type, so that the
* design is visually consistent.
*
* ## When not to use list items
*
* List items should not be used to list items where more detail about the
* object is required. Nor should they be used for listing items where actions
* can take place. Use the [dynamic list](../docs/blocks-dynamic-list.html) component for this instead.
*
* ## Position and style
*
* List items can be positioned directly on the page background, or within cards.
*
*/

$list-item-padding:                10px 15px;
$list-item-vertical-padding:       10px;
$large-list-item-padding:          20px;
$compact-list-item-padding:        5px;
$list-item-content-left-padding:   10px;
$list-item-right-left-padding:     6px;
$list-item-left-border-color:      color(primary, mid);
$list-item-left-border-width:      5px;
$list-item-unread-dot-size:        8px;

/**
* Basic list item:
*
* `.list-item`
*
* A clickable item that can be stacked on top of each other.
* List items may contain text, images (including avatars) and icons.
* Similar to a 'media object' in OSCSS.
*
* Where images are used, their width and height must be specified.
*
*   @example
*   include ./code-samples/basic-list-item.pug
*/

@include block('list-item') {
  // Use grid-template to provide IE 11 support via Autoprefixer
  // Read about this: https://css-tricks.com/css-grid-in-ie-css-grid-and-the-new-autoprefixer/
  display: block; // Fallback for older browsers
  display: grid;
  grid-template:
    'media    primary-content      right' auto
    'media    secondary-content    right' auto /
    auto      1fr                  auto;
  @include rem(padding, $list-item-padding);
  cursor: pointer;
  text-decoration: none;
  @include inset-focus-indicator(transparent); // Prevent visual "jump" when element receives focus

  > * {
    align-self: center; // Needed in IE11, because align-items is not supported
    display: block; // Needed in IE 11 so that grid items inline elements –e.g. span- behave as expected
  }

  + .list-item {
    @include rem(border-top, 1px solid color(grey, 5));
  }

  + .list-item--highlighted {
    border-top-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.35);
  }

  &:visited,
  &:link {
    text-decoration: none; // Override Ignite CSS
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none; // Override Ignite CSS
    color: $font-color-primary-on-white; // Overwrite default link style
  }

  &:focus,
  &:active {
    color: $font-color-primary-on-white; // Overwrite default link style
    background: $white; // Overwrite default link style
    @include inset-focus-indicator;
  }

  &:hover,
  &:hover:focus,
  &:hover:active {
    background-color: color(grey, 6);
  }

  @include element('media') {
    padding-right: $ukg-spacing-s;
    grid-area: media;

    img {
      display: block;
      max-width: initial;
    }
  }

  @include element('content') {
    grid-area: primary-content;
    grid-row-end: span 2;
  }

  @include element('right') {
    padding-left: $ukg-spacing-s;
    grid-area: right;
    color: $base-font-color;
  }

  /**
  * List item with primary and secondary content:
  *
  * `.list-item__primary-content` `list-item__secondary-content`
  *
  * An `as-title` modifer can be added to the primary content to increase the font size.
  *
  *   @example
  *   include ./code-samples/list-with-primary-secondary-content.pug
  */
  @include element('primary-content') {
    display: block;
    grid-area: primary-content;

    @include modifier('as-title') {
      @include h5;
    }
  }

  @include element('secondary-content') {
    display: block;
    grid-area: secondary-content;
    color: $base-font-color;
  }

  /**
  * Large list item:
  *
  * `.list-item--large`
  *
  * A list item with increased padding.
  *
  *   @example
  *   include ./code-samples/large-list-item.pug
  */
  @include modifier('large') {
    @include rem(padding, $large-list-item-padding);
  }

  /**
  * Compact list item:
  *
  * `.list-item--compact`
  *
  * A list item with reduced padding.
  *
  *   @example
  *   a.list-item.list-item--compact(href='#') Item / link one
  *   a.list-item.list-item--compact(href='#') Item / link two
  */
  @include modifier('compact') {
    @include rem(padding, $compact-list-item-padding);
  }

  /**
  * Top aligned list item:
  *
  * `.list-item--align-top`
  *
  * A list item where the image and text are top aligned, rather than centered vertically.
  *
  *   @example
  *   include ./code-samples/top-aligned-list-item.pug
  */
  @include modifier('align-top') {
    > * {
      align-self: start; // Needed in IE11, because align-items is not supported
    }
  }

  /**
  * Baseline aligned list item:
  *
  * `.list-item--align-baseline`
  *
  * A list item where the image and text are baseline aligned, rather than centered vertically.
  *
  *   @example
  *   include ./code-samples/baseline-aligned-list-item.pug
  */
  @include modifier('align-baseline') {
    > * {
      /* autoprefixer: off */
      align-self: baseline; // Manually provide alternative version for IE11, because baseline is not a valid value of `-ms-grid-row-align`
      -ms-grid-row-align: start;
    }
  }

  /**
  * Highlighted list item:
  *
  * `.list-item--highlighted`
  *
  * A list item that is highlighted to denote importance.
  *
  *   @example
  *   include ./code-samples/highlighted-list-item.pug
  */
  @include modifier('highlighted') {
    background-color: $highlight-range;

    + .list-item {
      border-top-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.35);
    }

    &:hover,
    &:hover:focus,
    &:hover:active {
      background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.15);
    }
  }

  /**
  * With left border:
  *
  * `.list-item--with-left-border`
  *
  * A list item with a blue border on the left.
  *
  *   @example
  *   include ./code-samples/list-item-with-blue-left-border.pug
  */
  @include modifier('with-left-border') {
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      @include rem(width, $list-item-left-border-width);
      background-color: $list-item-left-border-color;
    }
  }

  /**
  * 'In card' list item:
  *
  * `.list-item--in-card`
  *
  * A list item with extra horizontal padding that aligns with the header
  * and footer of a card.
  *
  *   @example
  *   include ./code-samples/list-item-in-card.pug
  */
  @include modifier('in-card') {
    @include rem(padding, $list-item-vertical-padding $card-padding);
  }

  /**
  * 'Unread' list item:
  *
  * `.list-item--unread`
  *
  * A list item marked as 'unread'.
  *
  * <div class="card card--standout top-margin">
  *   <div class="card__body">
  *     <p>
  *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title1" focusable="false">
  *         <title id="title1">Information</title>
  *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
  *       </svg>
  *       <strong>Accessibility</strong>
  *     </p>
  *     <p>Make sure to add a <code>span.sr-only</code> with the text 'Unread' inside of each <code>.list-item--unread</code>, so that this information is conveyed to assistive technologies.</p>
  *   </div>
  * </div>
  *
  *   @example
  *   include ./code-samples/unread-list-item.pug
  */
  @include modifier('read') {
    background-color: hsla(var(--grey-level-6-h), var(--grey-level-6-s), var(--grey-level-6-l), 0.5);
    color: $base-font-color;

    &:hover,
    &:hover:focus,
    &:hover:active {
      background-color: color(grey, 6);
    }
  }

  /**
  * 'Read' list item:
  *
  * `.list-item--read`
  *
  * A list item marked as 'read'.
  *
  * See [use case 4](#use-case-4) for an example of read and unread list items combined.
  *
  *   @example
  *   include ./code-samples/read-list-item.pug
  */

  @include modifier('unread') {
    color: $base-font-color;
    padding-left: $card-padding;

    @include element('primary-content') {
      font-weight: $bold;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        background-color: color(primary, dark);
        @include rem(width, $list-item-unread-dot-size);
        @include rem(height, $list-item-unread-dot-size);
        border-radius: 50%;
        left: rem(-$list-item-unread-dot-size / 2);
        top: 0.3em;
        transform: translateX(-10px);
      }
    }
  }
}

/**
* Use case 1:
*
* List items work well with [unpadded cards](../docs/blocks-card.html#unpadded-card-body).
*
* The `highlighted` modifer can be combined with a colored icon for additional
* effect.
*
*   @example
*   include ./code-samples/list-item-use-case-1.pug
*/

/**
* Use case 2:
*
* List items that include long links should have the
* [break-word helper](../docs/helpers-typography.html#break-word)
* applied, so that they wrap on mobile resolutions.
*
* <div class="card card--standout top-margin">
*   <div class="card__body">
*     <p>
*       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title1" focusable="false">
*         <title id="title1">Information</title>
*         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
*       </svg>
*       <strong>Accessibility</strong>
*     </p>
*     <p>Whenever possible, avoid long and unintelligible file names in links. The text must describe the destination of the link clearly and concisely.</p>
*   </div>
* </div>
*
*   @example
*   include ./code-samples/list-item-use-case-2.pug
*/

/**
* Use case 3:
*
* [Badges](../docs/blocks-badge.html) can be used too. Place them in the `.list-item__secondary-content` or `.list-item__right` containers.
*
*   @example
*   include ./code-samples/list-item-use-case-3.pug
*/

/**
* Use case 4:
*
* Read and unread list items combined in a card component.
*
*   @example
*   include ./code-samples/list-item-use-case-4.pug
*/

@media all and (max-width: $mobile) {
  @include block('list-item') {
    grid-template:
      'media    primary-content' auto
      'media    secondary-content' minmax(0, auto)
      'media    right' minmax(0, auto) /
      auto      1fr;

    @include element('right') {
      padding-top: $ukg-spacing-xs;
      padding-left: 0;

      &:first-child {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
      }
    }
  }
}
