/***
 * # Typography
 *
 * We use [Roboto](https://fonts.google.com/specimen/Roboto) for headings and body text,
 * and the following font families for languages not supported by Roboto:
 *
 * - PingFang TC and JhengHei for Traditional Chinese
 * - PingFang SC and YaHei for Simplified Chinese
 * - [Noto Sans Arabic](https://www.google.com/get/noto/#sans-arab) for Arabic
 * - [Noto Sans Hebrew](https://www.google.com/get/noto/#sans-hebr) for Hebrew
 * - [Noto Sans JP](https://www.google.com/get/noto/#sans-jpan) for Japanese
 * - [Noto Sans KR](https://www.google.com/get/noto/#sans-kore) for Korean
 * - [Noto Serif Thai](https://www.google.com/get/noto/#serif-thai) for Thai
 *
 * <div class="alert top-margin">
 *   <svg role="img" aria-labelledby="typography-alert-icon" focusable="false" class="icon alert__icon">
 *     <title id="typography-alert-icon">Warning</title>
 *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning"></use>
 *   </svg>
 *   <strong>The <code>lang</code> attribute</strong>
 *   <p>PDUI relies on the HTML <code>lang</code> attribute to apply the appropriate font family to each language.</p>
 *   <p>Correctly identifying the language of the page, and the language of the parts of the page is essential for accessibility and internationalization.</p>
 *   <p>For more information, see the <a href="../docs/accessibility-guidelines.html#the-lang-attribute">guidelines on using the language attribute</a>.</p>
 * </div>
 *
 * ## How to use typography
 *
 * - Always choose the right heading according to the HTML structure. If neccessary, you can use a helper class (e.g. `.h1`) to apply a different style.
 * - All caps should not be used, unless specified in a block
 * - Do not apply bold or italic styles to whole headings
 * - Use bold and italic styles sparingly - reserve them for when you **really** want to place *emphasis*
 *
 * See the [article](#article) section below for specific article typography styles.
 *
 * More typography styles can be found on the [typography helpers page](../docs/helpers-typography.html).
 */

/**
 * Body typography:
 * `p`
 *
 *   @example
 *   p This is a paragraph.
 *   p This is a paragraph with a #[a(href="#") link].
 *   p This is a paragraph with #[strong bold] and #[em italic] styles.
 */

body {
  @include rem-baseline;
  line-height: rem($base-line-height);
  font-family: $base-font;
  color: $base-font-color;
  vertical-align: center;
}

:lang(zh-Hans) {
  font-family: $base-font-sc;
}

:lang(zh-Hant) {
  font-family: $base-font-tc;
}

:lang(ar-001) {
  font-family: $base-font-arabic;
}

:lang(he-il) {
  font-family: $base-font-hebrew;
}

:lang(ja-jp) {
  font-family: $base-font-japanese;
}

:lang(ko-kr) {
  font-family: $base-font-korean;
}

:lang(th-th) {
  font-family: $base-font-thai;
}

/**
 * Heading 1:
 * `h1` `.h1`
 *
 *   @example
 *   h1 Weekend workouts can benefit health as much as a week of exercise, say researchers
 */
h1,
.h1 {
  @include h1;
}

/**
 * Heading 2:
 * `h2` `.h2`
 *
 *    @example
 *   h2 Weekend workouts can benefit health as much as a week of exercise, say researchers
 */
h2,
.h2 {
  @include h2;
}

/**
 * Heading 3:
 * `h3` `.h3`
 *
 *   @example
 *   h3 Weekend workouts can benefit health as much as a week of exercise, say researchers
 */
h3,
.h3 {
  @include h3;
}

/**
 * Heading 4:
 * `h4` `.h4`
 *
 *   @example
 *   h4 Weekend workouts can benefit health as much as a week of exercise, say researchers
 */
h4,
.h4 {
  @include h4;
}

/**
 * Heading 5:
 * `h5` `.h5`
 *
 *   @example
 *   h5 Weekend workouts can benefit health as much as a week of exercise, say researchers
 */
h5,
.h5 {
  @include h5;
}

/**
 * Heading 6:
 * `h6` `.h6`
 *
 *   @example
 *   h6 Weekend workouts can benefit health as much as a week of exercise, say researchers
 */
h6,
.h6 {
  @include h6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: rem($type-margin * 2);
}

p,
q,
cite {
  max-width: none;
  color: inherit;
  margin-top: rem($type-margin);
}

p,
q,
ul,
ol,
cite {
  font-size: rem($base-font-size);
}

small {
  font-size: rem($small-font-size);
  color: inherit;
}

strong {
  font-weight: $bold;
}

h1 strong,
h2 strong,
h3 strong,
h4 strong,
h5 strong,
h6 strong {
  font-weight: $bold;
}

/**
 * Unordered list:
 * `ul`
 *
 *   @example
 *   ul
 *     li List Item
 *     li List Item
 */

/**
 * Ordered list:
 * `ol`
 *
 *   @example
 *   ol
 *     li List Item
 *     li List Item
 */
ul,
ol {
  display: block;
  clear: both;
  margin-top: $ukg-spacing-m;
  margin-left: $ukg-spacing-m;
  color: inherit;

  li {
    margin-left: $ukg-spacing-s;
    padding-top: rem($type-margin / 6);
    padding-bottom: rem($type-margin / 6);
    color: inherit;
  }
}

/**
 * Blockquote:
 * `blockquote`
 *
 * A semantic element that represents an extended quotation.
 * The source of the quotation may be given using the `cite` element.
 *
 *   @example
 *   include ./code-samples/typography.blockquote.pug
 */
blockquote {
  padding-left: rem($type-margin);
  margin-top: rem($type-margin);
  border-left: rem(3px) solid $block-border-color;

  p,
  cite {
    display: block;
    margin-top: rem(10px);
  }

  p:first-of-type {
    margin-top: 0;
  }
}

/**
 * Small:
 * `small`
 *
 * A semantic inline element that represents side-comments or small print.
 *
 *   @example
 *   p This is a paragraph. #[small This is a small].
 */

/**
 * Mark:
 * `mark`
 *
 * A semantic element that represents a piece of the content that is specially
 * relevant in the enclosing context.
 *
 *   @example
 *   p This text is #[mark marked].
 */
mark {
  @include highlighted-text;
}

/**
 * Time:
 * `time`
 *
 * A semantic element that represents a specific period of time, e.g.: a time, a date or a time duration.
 *
 *   @example
 *   p Process due date: #[time(datetime="2019-11-30") November 30th]
 */

/**
 * Horizontal rule:
 * `hr`
 *
 * A semantic element that represents a thematic break between paragraph-level elements.
 *
 *   @example
 *   hr
 */
hr {
  margin: $ukg-spacing-m 0;
  border: 0;
  border-bottom: solid rem(1px) $block-border-color;
}

/**
 * ## Article
 *
 * Specific typography styles for rendering articles.
 *
 * <div class="alert top-margin">
 *   <svg role="img" aria-labelledby="article-alert-icon" focusable="false" class="icon alert__icon">
 *     <title id="article-alert-icon">Information</title>
 *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
 *   </svg>
 *   The parent element should have the class <code>.article</code> for these styles to apply.
 * </div>
 *
 * Visit [view article](../templates/view-article.html) to see them in context.
 */

.article {
  /**
   * Heading 1:
   * `.article h1`
   *
   *   @example
   *   article.article
   *     h1 Heading level 1
   */
  h1 {
    font-size: rem(40px);
    line-height: rem(40px);
  }

  /**
   * Heading 2:
   * `.article h2`
   *
   *   @example
   *   article.article
   *     h2 Heading level 2
   */
  h2 {
    font-size: rem(30px);
  }

  /**
   * Heading 3:
   * `.article h3`
   *
   *   @example
   *   article.article
   *     h3 Heading level 3
   */

  h3 {
    font-size: rem(25px);
  }

  /**
   * Heading 4:
   * `.article h4`
   *
   *   @example
   *   article.article
   *     h4 Heading level 4
   */
  h4 {
    font-size: rem(18px);
  }

  /**
   * Heading 5:
   * `.article h5`
   *
   *   @example
   *   article.article
   *     h5 Heading level 5
   */

  /**
   * Heading 6:
   * `.article h6`
   *
   *   @example
   *   article.article
   *     h6 Heading level 6
   */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: $ukg-spacing-m 0;
    font-weight: $bold;

    &:not(:first-child) {
      margin-top: rem(60px);
    }
  }

  /**
   * Paragraph:
   * `.article p`
   *
   *   @example
   *   article.article
   *     p This is a paragraph.
   */

  /**
   * Unordered list:
   * `.article ul`
   *
   *   @example
   *   article.article
   *     ul
   *       li List item
   *       li List item
   *       li List item
   */

  /**
   * Ordered list:
   * `.article ol`
   *
   *   @example
   *   article.article
   *     ol
   *       li List item
   *       li List item
   *       li List item
   */
  // Allow ".article p" to come after "blockquote p:first-of-type" for documentation purposes
  /* stylelint-disable-next-line no-descending-specificity */
  p,
  ul,
  ol,
  table {
    font-size: rem(16px);
    line-height: rem(30px);
  }

  ul,
  ol {
    &:not(.unstyled-list) {
      padding-left: rem(15px);
    }

    p {
      margin-top: 0;
    }
  }

  /**
   * Blockquote:
   * `.article blockquote`
   *
   *   @example
   *   article.article
   *     blockquote
   *       p Blockquote
   *       cite Source
   */
  blockquote {
    margin-left: rem(15px);
  }

  /**
   * Underlined text:
   * `.article u`
   *
   *   @example
   *   article.article
   *     u Underlined text
   *
   */
  u {
    text-decoration: underline;
  }

  /**
   * Non-typographic article elements:
   * `.article img`
   *
   * `.article iframe`
   *
   * `.article table`
   *
   * Some non-typographic HTML elements receive targeted modifications when they are placed inside of an `.article`.
   *
   *   @example
   *   include ./code-samples/typography.non-typographic-article-elements.pug
   */
  img {
    margin-top: rem(20px);
  }

  iframe {
    margin-top: rem(20px);
    position: relative;
    display: block;
  }

  table {
    margin-top: $ukg-spacing-m;
    border-top: rem(1px) solid $block-border-color;
    border-left: rem(1px) solid $block-border-color;

    p {
      margin-top: 0;
    }

    thead,
    th {
      font-weight: $bold;
    }

    td,
    th {
      text-align: left;
      border-bottom: rem(1px) solid $block-border-color;
      border-right: rem(1px) solid $block-border-color;
      padding: rem(7px);
    }

    // We have to cancel paragraph default styles when inside a <th>
    // because the text-editor have to wrap texts with a <p> element
    th p {
      font-weight: inherit;
    }
  }
}
