/***
 * # A 12-unit grid system of 2-column rows
 *
 * This layout helper is meant to be used inside the `<main role="main" class="main"></main>` areaa of your page (see [here](../docs/blocks-main.html)) in order to define the size and position of the elements your main content will consist of.
 *
 * <div class="alert alert--danger top-margin top-margin--small">This structure must <strong>not</strong> be used in order to create a <strong>tiled grid of thumbnails</strong> (documents, images etc.)</strong>. In that case, the <strong>[tiles](/docs/blocks-tiles.html)</strong> component must be used instead.</div>
 *
 */

$grid-columns: 12; // if this value is changed, all examples and CSS where this value (instead of the variable name) has been hardcoded must be updated as well
$change-order-threshold: 5;
$column-gutter: $ukg-spacing-m;

@mixin span-columns($span, $columns) {
  width: 100% / ($columns / $span);
}

@mixin column-modifiers($columns) {
  $column: 1;
  @while $column <= $grid-columns and $column <= $columns {
    @include modifier($column + '-'+ $grid-columns) {
      @content;
    }
    $column: $column + 1;
  }
}

@mixin column-modifiers-with-content($columns) {
  $column: 1;
  @while $column <= $grid-columns and $column <= $columns {
    @include modifier($column + '-'+ $grid-columns) {
      @include span-columns($column, $grid-columns);
    }
    $column: $column + 1;
  }
}

@mixin full-width-row {
  display: flex;
  flex-direction: column;

  @include element('column') {
    @include column-modifiers($grid-columns) {
      width: 100%;
      border-width: 0;
    }

    &:nth-of-type(2) {
      margin-top: $column-gutter / 2;
    }

    &:nth-of-type(1):not(:only-of-type) {
      border-width: 0;
    }
  }

  @include and-modifier('float-right') {
    @include element('column') {
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(1):not(:only-of-type) {
        border-width: 0;
      }
    }
  }
}

@mixin swap-order {
  .row__column:nth-of-type(1) {
    order: 2;
    margin-top: $column-gutter / 2;
  }

  .row__column:nth-of-type(2) {
    order: 1;
    margin-top: 0;
  }

  @include and-modifier('float-right') {
    .row__column:nth-of-type(1) {
      order: 1;
      margin-top: 0;
    }

    .row__column:nth-of-type(2) {
      order: 2;
      margin-top: $column-gutter / 2;
    }
  }
}

/**
 * Row with gutter:
 *
 * `.row .row__column.row__column--{unit}-12`
 *
 * <div class="alert alert--danger top-margin top-margin--small"><strong>Each row</strong> may contain either <strong>one</strong> or <strong>two columns</strong>.</div>
 *
 * A gutter will exist between the two columns whenever two columns are used instead of one.
 *
 * <div class="alert alert--danger top-margin top-margin--small"><strong>Each column</strong> inside a row may occupy <strong>1 to 12 units</strong>, but the <strong>total</strong> number of units occupied inside a row <strong>cannot be greater than 12</strong>.</div>
 *
 * You can use `.bottom-margin` from the [margin](../docs/helpers-margin.html) component to add a bottom margin to a `.row`.
 *
 *   @example
 *   include ./code-samples/grid-with-row-gutter.pug
 */
@include block('row') {
  display: inline-block; // or float: left;
  width: 100%;

  @include element('column') {
    float: left;
    height: auto;
    @include column-modifiers-with-content($grid-columns);

    &:nth-of-type(2) {
      border-left: $column-gutter / 2 solid transparent;
    }

    &:nth-of-type(1):not(:only-of-type) {
      border-right: $column-gutter / 2 solid transparent;
    }
  }

  /**
   * Row with no gutter:
   *
   * `.row.row--no-gutter`
   *
   * No gutter will exist between the two columns whenever two columns are used inside a row instead of one.
   *
   *   @example
   *   include ./code-samples/grid-no-row-gutter.pug
   */
  @include modifier('no-gutter') {
    @include element('column') {
      &:nth-of-type(1),
      &:nth-of-type(2) {
        border-width: 0;
      }
    }
  }

  /**
   * Row with columns floated to the right:
   *
   * `.row.row--float-right`
   *
   * <div class="alert alert--danger top-margin top-margin--small">The float will only be visible if the total number of units occupied inside a row is less than 12.</div>
   *
   *   @example
   *   include ./code-samples/grid-row-float-right.pug
   */
  @include modifier('float-right') {
    @include element('column') {
      float: right;

      &:nth-of-type(2) {
        border-left-width: 0;
        border-right: $column-gutter / 2 solid transparent;
      }

      &:nth-of-type(1):not(:only-of-type) {
        border-right-width: 0;
        border-left: $column-gutter / 2 solid transparent;
      }
    }

    @include and-modifier('no-gutter') {
      @include element('column') {
        &:nth-of-type(1),
        &:nth-of-type(2) {
          border-width: 0;
        }
      }
    }
  }
}

/**
 * Full width on screens <= 1024px :
 *
 * `.row--responsive-tablet`
 *
 * <strong>All columns are set to expand to 100% and stack on screens <= 600px by default.</strong>
 * <br>
 * <br>
 * When this modifier is used, all columns will expand to 100% and stack sooner, on screens <= 1024px.
 *
 *   @example
 *   include ./code-samples/responsive-tablet-grid.pug
 */

/**
 * Swap order when full-width :
 *
 * `.row--responsive-swap`
 *
 * This modifier will only take effect on screens <= 600px, as well as on screens <= 1024px if `row--responsive-tablet` is being used.
 *
 *   @example
 *   include ./code-samples/responsive-swap-grid.pug
 */
@media all and (max-width: $tablet) {
  @include block('row') {
    @include element('column') {
      @include modifier('1-12') {
        @include span-columns(3, $grid-columns);
      }
      @include modifier('11-12') {
        @include span-columns(9, $grid-columns);
      }
      @include modifier('2-12') {
        @include span-columns(3, $grid-columns);
      }
      @include modifier('10-12') {
        @include span-columns(9, $grid-columns);
      }
      @include modifier('3-12') {
        @include span-columns(5, $grid-columns);
      }
      @include modifier('9-12') {
        @include span-columns(7, $grid-columns);
      }
      @include modifier('4-12') {
        @include span-columns(5, $grid-columns);
      }
      @include modifier('8-12') {
        @include span-columns(7, $grid-columns);
      }
    }
    @include modifier('responsive-tablet') {
      @include full-width-row;
      @include and-modifier('responsive-swap') {
        @include swap-order;
      }
    }
  }
}

@media all and (max-width: $mobile) {
  @include block('row') {
    @include full-width-row;
    @include modifier('responsive-swap') {
      @include swap-order;
    }
  }
}
