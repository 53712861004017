/***
 * # Form errors
 *
 * A warning element that indicates there is an error with an individual form
 * field.
 *
 * ## When to use form errors
 *
 * Form errors should be used to indicate that the data entered into a
 * form field is either missing or incorrect.
 *
 * ## When not to use form errors
 *
 * Form errors should not be used to indicate errors *between* fields.
 * For this, use an alert at the top of the form instead.
 *
 * ## Position and style
 *
 * Form errors should be positioned directly underneath an invalid inputs or
 * textareas.
 *
 * The content of the error should describe the problem with the data entered
 * into the field.
 *
 * Ideally, form errors should be combined with `--invalid` modifiers on the
 * corresponding input and field label.
 *
 */

$form-error-border-radius:        $border-radius-small;
$form-error-icon-size:            16px;
$form-error-icon-vertical-margin: $ukg-spacing-xxxs / 2;

// Variables not specified above be found in src/settings/_form.scss

/**
 * Basic form error:
 *
 * `.form-error`
 *
 * A form error placed directly underneith the form field.
 *
 * <div class="card card--standout top-margin">
 *   <div class="card__body">
 *     <p>
 *       <svg class="icon icon--small right-margin right-margin--tiny" role="img" aria-labelledby="title1" focusable="false">
 *         <title id="title1">Information</title>
 *         <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
 *       </svg>
 *       <strong>Accessibility</strong>
 *     </p>
 *     <p>Always make sure to add to the input the `aria-describedby` attribute with the `id` of its related error element as a value.</p>
 *   </div>
 * </div>
 *
 *   @example
 *   include ./code-samples/basic-form-error.pug
 */
@include block('form-error') {
  @include ukg-metadata-small-important-text();
  opacity: 1; // Override opactity 0.87 from ukg-metadata-small-important-text
  padding: $ukg-spacing-xxs $ukg-spacing-xs;
  position: relative;
  margin-top: $ukg-spacing-xs;
  display: table; // behaves like a 'block', but not 100% wide.
  color: color(danger, deep);
  border-radius: rem($border-radius-small);
  max-width: 100%;

  @include mixie('icon') {
    height: rem($form-error-icon-size);
    width: rem($form-error-icon-size);
    margin: $form-error-icon-vertical-margin $ukg-spacing-xxxs $form-error-icon-vertical-margin 0;
    vertical-align: bottom;
    color: color(danger, dark);
  }

  /**
   * Stacked error messages:
   *
   * Multiple errors on one field.
   *
   *   @example
   *   include ./code-samples/stacked-error-messages.pug
   */
  + .form-error {
    margin-top: $ukg-spacing-xxxxs;

    &::before {
      display: none;
    }
  }
}
