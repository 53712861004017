$layout-content-background-color: $white;

$sidebar-v1-minified-width:       58px;
$sidebar-logo-width:              251px;
$sidebar-logo-height:             82px;
$sidebar-logo-height-small:       62px;
$sidebar-logo-border:             12px;
$transition-duration:             0.4s;

$sidebar-background-color:        color(grey, 3);

$dark-menu-hover-color:           color(grey, 6);
$dark-menu-hover-bg-color:        color(grey, 2);
$dark-menu-border-color:          color(grey, 1);

$content-with-sidebar-left-border: $sidebar-width solid transparent;
$content-with-sidebar-minified-left-border: $sidebar-v1-minified-width solid transparent;

$sidebar-logo-background:         color(grey, 2);

@mixin sidebar-expanded {
  .layout__sidebar {
    width: rem($sidebar-width);
  }

  .layout__sidebar-content {
    .vertical-menu {
      .vertical-menu__link {
        max-height: none;
        position: static;
      }

      .vertical-menu__link-title,
      .vertical-menu__dropdown-icon,
      .badge {
        opacity: 1;
      }

      .vertical-menu__title {
        display: block;
      }

      .vertical-menu__marker {
        position: static;
        margin-left: rem(8px);
      }
    }
  }

  .layout__sidebar-logo {
    opacity: 1;
    pointer-events: auto;
  }
}

/**
 * <div class=alert alert--warning bottom-margin'>
 *   <svg class="alert__icon icon" aria-hidden="true" focusable="false">
 *     <use xlink:href="../icons.svg#warning" href="../icons.svg#warning">
 *   </svg>
 *   <p class="h4">Deprecation warning</p>
 *   <p>This version of the layout component has been deprecated and it will be deleted in a future version of PDUI.</p>
 *   <p>Please, migrate to <a href="../docs/blocks-layout-v2.html">layout v2</a>.</p>
 * </div>
 *
 * # Layout
 *
 * This is the `<body>` tag with a `layout` class applied to it. It is the very top-level parent container of all other elements. It contains only three direct children. `.layout__skip` , `.layout__sidebar` (optional) and `.layout__content`.
 *
 * ```html
 * <body class="layout" data-icons-path="…">
 *   <a class="layout__skip" href="#content">Skip to Content</a>
 *   <aside class="layout__sidebar"></aside> <!-- Otional -->
 *   <div id="content" class="layout__content"></div>
 * </body>
 * ```
 *
 * <br>
 * <div class="alert">If the framework you are using doesn't allow the above structure (e.g. all of your HTML needs to be wrapped in one single top-level <strong>&lt;div/&gt;</strong>) then you can use the following structure instead:</div>
 *
 * ```html
 * <body class="layout" data-icons-path="…">
 *   <div>
 *     <a class="layout__skip" href="#content">Skip to Content</a>
 *     <aside class="layout__sidebar"></aside> <!-- Optional -->
 *     <div id="content" class="layout__content"></div>
 *   </div>
 * </body>
 * ```
 *
 */
@include block('layout', 1) {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  overflow-x: hidden; // Prevent horizontal scroll

  /**
   * ## Skip to content
   *
   * `.layout__skip`
   *
   * The first child of `.layout`.
   * It is not visible and will only be used (via the TAB key) by keyboard-only users to directly jump to the main content of your page.
   *
   * ```html
   * <body class="layout">
   *   <a class="layout__skip" href="#content">Skip to Content</a>
   *   ....
   *   ......
   * </body>
   * ```
   */
  @include element('skip') {
    position: absolute;
    top: auto;
    left: rem(-10000px);
    width: 1px;
    height: 1px;
    overflow: hidden;
    background-color: $dark-header-bg-color;
    color: $white;

    &:focus {
      left: rem(10px);
      top: rem(10px);
      width: auto;
      height: auto;
      padding: rem(10px);
      font-size: rem($base-font-size);
      z-index: index($z-index-scale, 'skip-link');
      @include focus-outline;
    }
  }

  /**
   * ## Content
   *
   * `.layout__content`
   *
   * A child of `.layout` that contains the [header](../docs/blocks-header.html), [main](../docs/blocks-main.html) and [footer](../docs/blocks-footer.html) components. Click [here](../templates/responsive-with-sidebar-deprecated.html) to see an example.
   *
   * ```html
   * <body class="layout">
   *   <a class="layout__skip" href="#content">Skip to Content</a>
   *   <div id="content" class="layout__content">
   *     <div class="layout__content-container">
   *       <header role="banner" class="header"></header>
   *       <main role="main" class="main"></main>
   *       <footer role="contentinfo" class="footer"></footer>
   *     </div>
   *   </div>
   * </body>
   * ```
   */
  @include element('content') {
    position: relative;
    width: 100%;
  }

  @include element('content-container') {
    position: relative;
    width: 100%;
    background-color: $body-background-color;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  /**
   * ## Sidebar
   *
   * `.layout__sidebar`
   *
   * An optional child of `.layout` that sits to the left of `.layout__content`. Click [here](../templates/responsive-with-sidebar-deprecated.html) to see an example.
   *
   * ```html
   * <body class="layout">
   *   <!--
   *     Place the following script, right after the opening body tag,
   *     ONLY IF you wish to keep track of the expanded/minified state of the sidebar
   *   -->
   *   <script>
   *     if (localStorage && localStorage.getItem('sidebarExpanded')) {
   *       document.body.className += ' layout--sidebar-expanded';
   *     }
   *   </script>
   *   <a class="layout__skip" href="#content">Skip to Content</a>
   *   <aside class="layout__sidebar">
   *     <div class="layout__sidebar-content">
   *       <div class="layout__sidebar-logo">
   *         <img src="somewhere.svg" alt="logo"/>
   *       </div>
   *       <nav class="vertical-menu"></nav>
   *     </div>
   *   </aside>
   *   <div id="content" class="layout__content"></div>
   * </body>
   * ```
   */
  @include element('sidebar') {
    height: 100%; // 100vh not working on Windows Safari 5.1
    width: rem($sidebar-width);
    border-top: 0;
    border-bottom: 0;
    color: $white;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: content-box;
    z-index: index($z-index-scale, sidebar);

    * {
      box-sizing: border-box;
    }
  }

  @include element('sidebar-logo') {
    position: relative;
    width: 100%;
    height: rem($sidebar-logo-height);
    transition: height $transition-duration linear, opacity $transition-duration linear;
    background-color: $sidebar-logo-background;
    text-align: center;
    border-width: rem($sidebar-logo-border);
    border-style: solid;
    border-color: transparent;
    display: block;

    img {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      max-height: rem(($sidebar-logo-height) - ($sidebar-logo-border * 2));
      max-width: 100%;
      height: auto;
    }
  }

  @include element('sidebar-content') {
    background-color: $sidebar-background-color;
    height: 100%;
    overflow: hidden;

    &:hover {
      overflow: auto;
    }

    .vertical-menu {
      .vertical-menu__link {
        height: auto;
      }
    }
  }

  /**
   * ## Semi responsive layout
   *
   * `.layout--semi-responsive`
   *
   * A `.layout` modifier that forces a minimum width on tha main area of your app, making it horizontally scrollable on smaller screens. Click [here](../templates/semi-responsive-with-sidebar-deprecated.html) to see an example. The sidebar will never hide on semi-responsive layouts, it will only collapse.
   *
   * ```html
   * <body class="layout layout--semi-responsive"></body>
   * ```
   */
  @include modifier('semi-responsive') {
    @include element('content') {
      overflow: auto;
    }
    @include element('content-container') {
      min-width: rem($tablet - $sidebar-v1-minified-width);
    }
  }

  @include modifier('animatable') {
    @include element('content') {
      transition: margin-left $transition-duration linear, border $transition-duration linear;
    }
    @include element('sidebar') {
      transition: width $transition-duration linear;
    }
  }

  /**
   * ## Non-collapsible sidebar
   *
   * `.layout--no-minified-sidebar`
   *
   * The sidebar will minify on tablet and will completely hide on mobile (except on semi-responsive layouts). A burger menu icon will appear in those cases, in order to toggle its visibility. If you don't want the sidebar to minify on tablet, simply add this modifier.
   *
   * <br>
   * <div class="alert">If the <strong>.layout--semi-responsive</strong> modifier (sidebar will never hide) is used along with the <strong>.layout--no-minified-sidebar</strong> modifier (sidebar will never minify) then the sidebar will neither minify nor hide regardless of the window width.</div>
   *
   * ```html
   * <body class="layout layout--no-minified-sidebar"></body>
   * ```
   *
   */

  /* .layout__content with a .layout__sidebar to its left */
  .layout__sidebar + .layout__content {
    @include rem(border-left, $content-with-sidebar-left-border);
  }
}

@media all and (max-width: $layout-tablet) {
  .layout:not([data-version]):not(.layout--no-minified-sidebar) {
    .layout__sidebar {
      @include rem(width, $sidebar-v1-minified-width);
    }

    .layout__sidebar-content {
      .vertical-menu {
        .vertical-menu__link {
          @include rem(max-height, 61px);
          position: relative;
          overflow: hidden;
        }

        .vertical-menu__link-title,
        .vertical-menu__dropdown-icon,
        .badge {
          opacity: 0;
          transition: opacity $transition-duration linear;
        }

        .vertical-menu__marker {
          position: absolute;
          @include rem(bottom, 18px);
          @include rem(left, 30px);
          margin-left: 0;
        }
      }
    }

    .layout__sidebar-logo {
      opacity: 0;
      pointer-events: none;
    }

    .layout__sidebar + .layout__content {
      @include rem(border-left, $content-with-sidebar-minified-left-border);
    }

    &.layout--sidebar-expanded {
      @include sidebar-expanded;

      .layout__content {
        border-left: rem($content-with-sidebar-left-border);
      }
    }

    ~ .sidebar-drop,
    .sidebar-drop {
      display: block;
    }

    &.layout--sidebar-expanded,
    &.layout--sidebar-revealed {
      ~ .sidebar-drop,
      .sidebar-drop {
        display: none;
      }
    }

    &:not(.layout--no-minified-sidebar):not(.layout--sidebar-expanded):not(.layout--sidebar-revealed) {
      .layout__sidebar .layout__sidebar-content > .vertical-menu {
        > ul > li > .vertical-menu__title {
          @include sr-only;
        }

        .vertical-menu__link--opened {
          background-color: transparent;
          color: currentColor;

          &:hover,
          &.sidebar-drop-enabled {
            background-color: $dark-menu-hover-bg-color;
            color: $dark-menu-hover-color;
          }
        }

        .vertical-menu__sub-menu--opened {
          display: none;
        }
      }
    }
  }
}

@media all and (max-width: $layout-mobile) {
  .layout:not([data-version]),
  .layout:not([data-version]):not(.layout--no-minified-sidebar) {
    &:not(.layout--semi-responsive) {
      .layout__sidebar {
        width: rem($sidebar-width);
        z-index: 0;
      }

      .layout__sidebar::after {
        display: none;
      }

      .layout__sidebar + .layout__content {
        border-left-width: 0;
        margin-left: 0;
      }

      &.layout--sidebar-revealed {
        @include sidebar-expanded;

        .layout__sidebar + .layout__content {
          border-left-width: 0;
          margin-left: rem($sidebar-width);
        }
      }
    }
  }
}

/* collapsed sidebar popouts CSS */
.sidebar-drop:not([data-version]) {
  display: none;
  @include rem(width, 200px);
  height: auto;
  max-height: 100vh;
  overflow: auto;
  z-index: index($z-index-scale, sidebar-drop);

  .sidebar-drop-content {
    float: left;
    width: 100%;
    height: auto;

    @include block('vertical-menu') {
      float: left;
      width: 100%;
      height: auto;
      position: relative;

      @include element('sub-menu') {
        visibility: visible;
        opacity: 1;
        height: auto;
        padding: 0;
        @include rem(margin-top, -1px);
        @include rem(border, 1px solid $dark-menu-border-color);
        border-left: 0;
      }

      @include element('sub-link') {
        @include rem(padding, 15px);
        @include rem(border-bottom, 1px solid $dark-menu-border-color);
        @include rem(line-height, 19px);
        position: relative;

        &:last-of-type {
          border-bottom: 0;
        }
      }
    }
  }
}

/*
  When the sidebar is minified and the user hovers over a sidebar link
  that pops out a submenu to the right of the sidebar,
  the following CSS will make sure the ":hover" background color of the link
  will persist when the user moves the cursor from the link onto
  the popped-out submenu.
*/
@include block('vertical-menu', 1) {
  @include modifier('dark') {
    @include element('link') {
      &.sidebar-drop-enabled {
        background-color: $dark-menu-hover-bg-color;
        color: $dark-menu-hover-color;
      }
    }
  }
}

// CONTROL HEADER BEHAVIOUR INSIDE A LAYOUT V1

/*
  Above 'layout-mobile', if the sidebar is not minifiable (i.e. the modifier '.layout--no-minified-sidebar' is used) hide the sidebar toggler button
*/
@media not all and (max-width: $layout-mobile) {
  @include block('layout', 1) {
    @include modifier('no-minified-sidebar') {
      @include block('header') {
        @include element('sidebar-toggler') {
          display: none;
        }
      }
    }
  }
}

/*
  Above 'layout-tablet', hide the sidebar toggler button
*/
@media not all and (max-width: $layout-tablet) {
  @include block('layout', 1) {
    @include block('header') {
      @include element('sidebar-toggler') {
        display: none;
      }
    }
  }
}
