/***
 * # Margins
 *
 * A set of utilities for adding margins to elements.
 *
 * <div class="alert top-margin">
 *   <svg role="img" aria-labelledby="margin-helpers-alert" focusable="false" class="alert__icon icon">
 *     <title id="margin-helpers-alert">Information</title>
 *     <use xlink:href="../icons.svg#info" href="../icons.svg#info"></use>
 *   </svg>
 *   <p>We use CSS <code>!important</code> in margin helper classes to make sure that they can always carry out
 *   their one and very specific purpose, despite having very low specificity.</p>
 * </div>
 *
 * ## When to use margins
 *
 *  - To create spaces between two high level blocks, for example, a card and an alert.
 *
 * ## When not to use margins
 *
 * - Do not use bottom margins on typography. Typographical elements already have their own bottom spacing.
 * - Do not use for creating 'grid like' layouts. Use a grid or cell layout instead.
 *
 *
 * ## Correspondence to Ignite tokens
 *
 * The values of PDUI margin helpers correspond to that of [Ignite DLS spacing tokens](https://dls-docs.dls-doc-site.dlas1.ucloud.int/components/spacing) as follows:
 *
 * | PDUI margin helper | Ignite token | Value |
 * |-|-|-|
 * | Default | Spacing, M | `1.5rem` / `24px`
 * | Extra tiny | Spacing, XXS | `0.25rem` / `4px`
 * | Tiny | Spacing, XS | `0.5rem` / `8px`
 * | Small | Spacing, S | `1rem` / `16px`
 * | Medium | Spacing, L | `2rem` / `32px`
 * | Extra medium | Spacing, XL | `2.5rem` / `40px`
 * | Large | Spacing, XXL | `3rem` / `48px`
 * | Extra large | Spacing, XXXL | `3.5rem` / `56px`
 */

@mixin generate-margin($name, $directions) {
  .#{$name} {

    @each $direction in $directions {
      margin-#{$direction}: $ukg-spacing-m !important;
    }

    @include modifier('extra-large') {
      @each $direction in $directions {
        margin-#{$direction}: $ukg-spacing-xxxl !important;
      }
    }

    @include modifier('large') {
      @each $direction in $directions {
        margin-#{$direction}: $ukg-spacing-xxl !important;
      }
    }

    @include modifier('extra-medium') {
      @each $direction in $directions {
        margin-#{$direction}: $ukg-spacing-xl !important;
      }
    }

    @include modifier('medium') {
      @each $direction in $directions {
        margin-#{$direction}: $ukg-spacing-l !important;
      }
    }

    @include modifier('small') {
      @each $direction in $directions {
        margin-#{$direction}: $ukg-spacing-s !important;
      }
    }

    @include modifier('tiny') {
      @each $direction in $directions {
        margin-#{$direction}: $ukg-spacing-xs !important;
      }
    }

    @include modifier('extra-tiny') {
      @each $direction in $directions {
        margin-#{$direction}: $ukg-spacing-xxs !important;
      }
    }
  }
}

@mixin generate-negative-margin($name, $directions) {
  .negative-#{$name} {

    @each $direction in $directions {
      margin-#{$direction}: - $ukg-spacing-m !important;
    }

    @include modifier('extra-large') {
      @each $direction in $directions {
        margin-#{$direction}: - $ukg-spacing-xxxl !important;
      }
    }

    @include modifier('large') {
      @each $direction in $directions {
        margin-#{$direction}: - $ukg-spacing-xxl !important;
      }
    }

    @include modifier('extra-medium') {
      @each $direction in $directions {
        margin-#{$direction}: - $ukg-spacing-xl !important;
      }
    }

    @include modifier('medium') {
      @each $direction in $directions {
        margin-#{$direction}: - $ukg-spacing-l !important;
      }
    }

    @include modifier('small') {
      @each $direction in $directions {
        margin-#{$direction}: - $ukg-spacing-s !important;
      }
    }

    @include modifier('tiny') {
      @each $direction in $directions {
        margin-#{$direction}: - $ukg-spacing-xs !important;
      }
    }

    @include modifier('extra-tiny') {
      @each $direction in $directions {
        margin-#{$direction}: - $ukg-spacing-xxs !important;
      }
    }
  }
}

/**
 * Margin:
 * `.margin`,<br>
 * `.margin--extra-tiny`,<br>
 * `.margin--tiny`,<br>
 * `.margin--small`,<br>
 * `.margin--medium`,<br>
 * `.margin--extra-medium`,<br>
 * `.margin--large`,<br>
 * `.margin--extra-large`
 *
 * A utility that adds a margin to all sides of an element.
 *
 *     @example
 *     .card.bottom-margin
 *       .button.button--primary.margin Button with Standard margin on all sides
 *     each size in ['extra-tiny', 'tiny', 'small', 'medium', 'extra-medium', 'large', 'extra-large']
 *       br
 *       .card.bottom-margin
 *         .button.button--primary.margin(class=`margin--${size}`) Button with #{size} margin on all sides
 */
@include generate-margin(margin, (top, right, bottom, left));

/**
 * Horizontal margin:
 * `.horizontal-margin`,<br>
 * `.horizontal-margin--extra-tiny`,<br>
 * `.horizontal-margin--tiny`,<br>
 * `.horizontal-margin--small`,<br>
 * `.horizontal-margin--medium`,<br>
 * `.horizontal-margin--extra-medium`,<br>
 * `.horizontal-margin--large`,<br>
 * `.horizontal-margin--extra-large`
 *
 * A utility that adds a horizontal margin to an element.
 *
 *     @example
 *     .card.bottom-margin
 *       .button.button--primary.horizontal-margin Button with standard horizontal margin
 *     each size in ['extra-tiny', 'tiny', 'small', 'medium', 'extra-medium', 'large', 'extra-large']
 *       br
 *       .card.bottom-margin
 *         .button.button--primary.horizontal-margin(class=`horizontal-margin--${size}`) Button with #{size} horizontal margin
 *
 */
@include generate-margin(horizontal-margin, (right, left));

/**
 * Vertical margin:
 * `.vertical-margin`,<br>
 * `.vertical-margin--extra-tiny`,<br>
 * `.vertical-margin--tiny`,<br>
 * `.vertical-margin--small`,<br>
 * `.vertical-margin--medium`,<br>
 * `.vertical-margin--extra-medium`,<br>
 * `.vertical-margin--large`,<br>
 * `.vertical-margin--extra-large`
 *
 * A utility that adds a vertical margin to an element.
 *
 *     @example
 *     .card.bottom-margin
 *       .button.button--primary.vertical-margin Button with standard vertical margin
 *     each size in ['extra-tiny', 'tiny', 'small', 'medium', 'extra-medium', 'large', 'extra-large']
 *       br
 *       .card.bottom-margin
 *         .button.button--primary.vertical-margin(class=`vertical-margin--${size}`) Button with #{size} vertical margin
 */
@include generate-margin(vertical-margin, (top, bottom));

/**
 * Top margin:
 * `.top-margin`,<br>
 * `.top-margin--extra-tiny`,<br>
 * `.top-margin--tiny`,<br>
 * `.top-margin--small`,<br>
 * `.top-margin--medium`,<br>
 * `.top-margin--extra-medium`,<br>
 * `.top-margin--large`,<br>
 * `.top-margin--extra-large`
 *
 * A utility that adds a top margin to an element.
 *
 * Adding 'negative' to the class name creates a negative margin in the same direction:
 *
 * `.negative-top-margin`,<br>
 * `.negative-top-margin--extra-tiny`,<br>
 * `.negative-top-margin--tiny`,<br>
 * `.negative-top-margin--small`,<br>
 * `.negative-top-margin--medium`,<br>
 * `.negative-top-margin--extra-medium`,<br>
 * `.negative-top-margin--large`,<br>
 * `.negative-top-margin--extra-large`
 *
 *     @example
 *     .card.bottom-margin
 *       .button.button--primary.top-margin Button with standard top margin
 *     each size in ['extra-tiny', 'tiny', 'small', 'medium', 'extra-medium', 'large', 'extra-large']
 *       br
 *       .card.bottom-margin
 *         .button.button--primary.top-margin(class=`top-margin--${size}`) Button with #{size} top margin
 */
@include generate-margin(top-margin, top);
@include generate-negative-margin(top-margin, top);

/**
 * Bottom margin:
 * `.bottom-margin`,<br>
 * `.bottom-margin--extra-tiny`,<br>
 * `.bottom-margin--tiny`,<br>
 * `.bottom-margin--small`,<br>
 * `.bottom-margin--medium`,<br>
 * `.bottom-margin--extra-medium`,<br>
 * `.bottom-margin--large`,<br>
 * `.bottom-margin--extra-large`
 *
 * A utility that adds a bottom margin to an element.
 *
 * Adding 'negative' to the class name creates a negative margin in the same direction:
 *
 * `.negative-bottom-margin`,<br>
 * `.negative-bottom-margin--extra-tiny`,<br>
 * `.negative-bottom-margin--tiny`,<br>
 * `.negative-bottom-margin--small`,<br>
 * `.negative-bottom-margin--medium`,<br>
 * `.negative-bottom-margin--extra-medium`,<br>
 * `.negative-bottom-margin--large`,<br>
 * `.negative-bottom-margin--extra-large`
 *
 *     @example
 *     .card.bottom-margin
 *       .button.button--primary.bottom-margin Button with standard bottom margin
 *     each size in ['extra-tiny', 'tiny', 'small', 'medium', 'extra-medium', 'large', 'extra-large']
 *       br
 *       .card.bottom-margin
 *         .button.button--primary.bottom-margin(class=`bottom-margin--${size}`) Button with #{size} bottom margin
 */
@include generate-margin(bottom-margin, bottom);
@include generate-negative-margin(bottom-margin, bottom);

/**
 * Left Margin:
 * `.left-margin`,<br>
 * `.left-margin--extra-tiny`,<br>
 * `.left-margin--tiny`,<br>
 * `.left-margin--small`,<br>
 * `.left-margin--medium`,<br>
 * `.left-margin--extra-medium`,<br>
 * `.left-margin--large`,<br>
 * `.left-margin--extra-large`
 *
 * A utility that adds a left margin to an element.
 *
 * Adding 'negative' to the class name creates a negative margin in the same direction:
 *
 * `.negative-left-margin`,<br>
 * `.negative-left-margin--extra-tiny`,<br>
 * `.negative-left-margin--tiny`,<br>
 * `.negative-left-margin--small`,<br>
 * `.negative-left-margin--medium`,<br>
 * `.negative-left-margin--extra-medium`,<br>
 * `.negative-left-margin--large`,<br>
 * `.negative-left-margin--extra-large`
 *
 *     @example
 *     .card.bottom-margin
 *       .button.button--primary.left-margin Button with standard left margin
 *     each size in ['extra-tiny', 'tiny', 'small', 'medium', 'extra-medium', 'large', 'extra-large']
 *       br
 *       .card.bottom-margin
 *         .button.button--primary.left-margin(class=`left-margin--${size}`) Button with #{size} left margin
 */
@include generate-margin(left-margin, left);
@include generate-negative-margin(left-margin, left);

/**
 * Right Margin:
 * `.right-margin`,<br>
 * `.right-margin--extra-tiny`,<br>
 * `.right-margin--tiny`,<br>
 * `.right-margin--small`,<br>
 * `.right-margin--medium`,<br>
 * `.right-margin--extra-medium`,<br>
 * `.right-margin--large`,<br>
 * `.right-margin--extra-large`
 *
 * A utility that adds a right margin to an element.
 *
 * Adding 'negative' to the class name creates a negative margin in the same direction:
 *
 * `.negative-right-margin`,<br>
 * `.negative-right-margin--extra-tiny`,<br>
 * `.negative-right-margin--tiny`,<br>
 * `.negative-right-margin--small`,<br>
 * `.negative-right-margin--medium`,<br>
 * `.negative-right-margin--extra-medium`,<br>
 * `.negative-right-margin--large`,<br>
 * `.negative-right-margin--extra-large`
 *
 *     @example
 *     .card.bottom-margin
 *       .button.button--primary.right-margin.float-right Button with standard right margin
 *     each size in ['extra-tiny', 'tiny', 'small', 'medium', 'extra-medium', 'large', 'extra-large']
 *       br
 *       .card.bottom-margin
 *         .button.button--primary.right-margin(class=`right-margin--${size}`).float-right Button with {size} right margin
 */
@include generate-margin(right-margin, right);
@include generate-negative-margin(right-margin, right);

/**
 * Remove margins:
 * `.no-top-margin`, `.no-right-margin`, `.no-bottom-margin`, `.no-left-margin`, `.no-horizontal-margin`, `.no-vertical-margin`
 *
 * Helpers to manually remove default margin from html elements, for example headings and other typographical elements.
 *
 */
.no-top-margin {
  margin-top: 0 !important;
}

.no-right-margin {
  margin-right: 0 !important;
}

.no-bottom-margin {
  margin-bottom: 0 !important;
}

.no-left-margin {
  margin-left: 0 !important;
}

.no-horizontal-margin {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.no-vertical-margin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
