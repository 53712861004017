/***
 * # Main content area
 *
 * The element placed between the header and the footer of the page, containing the main content of the page.
 *
 * See the [responsive page layout](../templates/responsive-with-sidebar.html) for an example use case.
 *
 * ATTENTION :
 * 1. There can only be one `main` element per page
 * 2. The `<main role="main" class="main"></main>` sits between the `<header>` and the `<footer>` of the page.
 * 2. The following HTML template must be used :
 *
 * ```html
 * <body class="layout" data-icons-path="…">
 *   <a class="layout__skip" href="#content">Skip to Content</a>
 *   <div id="content" class="layout__content">
 *     <div class="layout__content-container">
 *       <header role="banner" class="header"></header>
 *       <main role="main" class="main">
 *          <!-- Main Content goes here -->
 *       </main>
 *       <footer role="contentinfo" class="footer"></footer>
 *     </div>
 *   </div>
 * </body>
 * ```
 */

/**
 * Basic main content area:
 * `.main`
 *
 *     @example
 *     //ignore this .only-for-demo element
 *     div.only-for-demo(style='display:table; width: 100%')
 *       header.header(role='banner')
 *         .header__container.container
 *           button.header__sidebar-toggler
 *             svg(class='icon icon--white' role='img' aria-labelledby='label-burger-icon')
 *               title#label-burger-icon Toggle sidebar
 *               use(xlink:href='../icons.svg#menu-main' href='../icons.svg#menu-main')
 *             .header__sidebar-notifier
 *               span.sr-only Has notifications
 *           .header__cells.cells-cells--align-middle
 *             .cells__cell
 *               span.header__surtitle All Documents
 *               =" "
 *               span(aria-hidden="true").header__arrow
 *               =" "
 *               span.header__surtitle Some Documents
 *               =" "
 *               span(aria-hidden="true").header__arrow
 *               =" "
 *               h1.header__title.title
 *                 |  Company Documents
 *             .cells__cell.cells__cell--fit-content
 *                .button-group
 *                  button#uniqueID-cta.button-group__button.button(type='button') Call to action
 *                  button.button-group__button.button.button--thin#dropdown-demo(type='button' data-toggle='dropdown' aria-haspopup='true' aria-controls='uniqueID-cta-menu' aria-labelledby='uniqueID-cta')
 *                    svg.button__icon.icon(aria-hidden='true', focusable='false')
 *                      use(xlink:href='../icons.svg#menu-caret-down' href='../icons.svg#menu-caret-down')
 *                  .button-group__dropdown.dropdown.dropdown--align-right
 *                    .vertical-menu.vertical-menu--lined(data-version='2')
 *                      ul#uniqueID-cta-dropdown(role='menu' aria-labelledby='uniqueID-cta')
 *                        li(role='presentation')
 *                          a(href='#' role='menuitem' tabindex='-1').vertical-menu__link
 *                            span.vertical-menu__link-title Link 1
 *                        li(role='presentation')
 *                          a(href='#' role='menuitem' tabindex='-1').vertical-menu__link
 *                            span.vertical-menu__link-title Link 2
 *                        li(role='presentation')
 *                          a(href='#' role='menuitem' tabindex='-1').vertical-menu__link
 *                            span.vertical-menu__link-title Link 3
 *       main.main(role='main')
 *         .container
 *           h2.bottom-margin.bottom-margin--small This is the main content area
 *           .search.search--full-width.bottom-margin
 *             input.search__input.input.input--large(placeholder='Search Page')
 *             button.search__submit(type='button' aria-label='Search')
 *               svg.search__icon.icon(aria-hidden='true', focusable='false')
 *                 use(xlink:href='../icons.svg#search' href='../icons.svg#search')
 *           .card
 *             .card__header
 *               h3 Card Header
 *             .card__body Card body
 *       footer.footer(role='contentinfo')
 *         .container
 *           .footer__content
 *             .footer__column
 *               .footer__element
 *                 .footer__text
 *                   a(href='https://www.ukg.com/') Powered by UKG
 *                   br
 *                   Copyright &copy; 1997-2020. UKG Inc. All rights reserved.
 *             .footer__column.footer__column--align-right
 *               .footer__element
 *                 ul.footer__menu.footer__menu--align-right
 *                   li.footer__menu-item
 *                     a.footer__menu-link(href='#') Support
 *                   =" "
 *                   li.footer__menu-item
 *                     a.footer__menu-link(href='#') Legal
 *     script.
 *         $('#dropdown-demo').dropdown();
 */
@include block('main') {
  position: relative;
  width: 100%;
  flex: 1 0 auto; // Works in combination with .layout__content-container {display: flex; flex-direction: column;}. It pushes the footer at the bottom of the screen if the content is too short.
}
