/***
 * # Filter
 */

$filter-background-color:       $white;
$filter-border-color:           color(grey, 5);
$filter-padding:                10px;
$filter-border-radius:          $border-radius-large;
$add-filter-background-color:   color(grey, 6);
$add-filter-border-color:       color(grey, 5);

/**
 * Basic filter:
 *
 * `.filter`
 *
 * An individual filter with a label, form element and remove button.
 *
 *   @example
 *   include ./code-samples/basic-filter.pug
 */
@include block('filter') {
  display: table;
  width: 100%;
  margin-bottom: $ukg-spacing-s;
  @include rem(padding, $filter-padding);
  @include rem(border, 1px solid $filter-border-color);
  @include rem(border-radius, $filter-border-radius);
  @include rem(font-size, $base-font-size);
  background-color: $filter-background-color;

  &:last-of-type {
    @include rem(margin-bottom, $filter-padding * 2);
  }

  @include element('field') {
    display: table-cell;
  }

  @include mixie('label') {
    font-weight: $bold;
    margin-top: 0;
  }

  @include mixie('remove') {
    display: table-cell;
    width: 1%;
    @include rem(padding-left, $filter-padding);
    white-space: nowrap;
    text-align: right;
    vertical-align: bottom;

    @include modifier('align-top') {
      vertical-align: top;
    }
  }

  /**
   * Add filter:
   *
   * `.filter--add`
   *
   * A new filter.
   *
   *   @example
   *   include ./code-samples/filter-add.pug
   */
  @include modifier('add') {
    background-color: $add-filter-background-color;
    border-color: $add-filter-border-color;

    @include element('field') {
      > * {
        @include rem(margin-top, $filter-padding * 0.75);
      }

      // Remove top margin from label and first field
      .filter__label,
      .filter__label + * {
        margin-top: 0;
      }
    }
  }

  /**
   * Read-only filter:
   *
   * `.filter--read-only`
   *
   * A read-only filter where the content of the filter is shown, but cannot
   * be edited (for example, where it has been applied via a modal).
   *
   *   @example
   *   include ./code-samples/read-only-filter.pug
   */
  @include modifier('read-only') {
    background-color: $add-filter-background-color;
    border-color: $add-filter-border-color;

    @include element('field') {
      vertical-align: middle;
    }

    @include element('label') {
      margin-bottom: 0;
    }

    @include element('remove') {
      vertical-align: middle;
    }
  }
}
