/***
 * # Carousels
 *
 * A carousel is a horizontal slider that contains hidden content that a user can navigate to by clicking on directional buttons.
 * Carousels allow multiple pieces of content to occupy a single space.
 *
 * ## When to use carousels
 *
 *  - To display a lot of content in a small space
 *
 * ## When not to use carousels
 *
 * The use of carousels should be carefully considered, as users can easily miss the directional buttons.
 * Carousels should not be used in 'busy' interfaces or small spaces,
 * where there is a higher chance that the user will miss the directional buttons.
 *
 * Do not use a carousel to preview documents - use the document previewer instead.
 *
 * ## Position and style
 *
 * Carousels can be positioned anywhere in the UI, however it is best to use them for large spaces.
 *
 * ## Initialization
 *
 * <div class="alert alert--danger top-margin top-margin--small">
 *   <p>This component will auto-instantiate unless it has the <code>[data-no-auto]</code> attribute at page load (DOMContentLoaded) or on <code>init.pdui</code> event recept on an ancestor.<p>
 *   <p>If the component is added to the page dynamically, trigger the <code>init.pdui</code> event on a parent node to instantiate it.</p>
 *   <p>If the component is not instantiated, it will auto-instantiate as soon as a method of that component is called programmatically, regardless of the presence of <code>[data-no-auto]</code>.</p>
 *   <p>If you don't set the component to auto-instantiate and you wish to instantiate it manually (pass options to it etc.) do: <br><code>$(el).carousel();</code></p>
 * </div>
 *
 * ## Options
 *
 * The carousel component needs to know the path to the SVG icons. You have two ways to declare the icons path on the component level:
 * - Using the `iconsPath` option at initialization
 * - Setting the `data-icons-path` attribute on the element `.carousel`
 * If none of the above is found, the carousel component will use the path from the `data-icons-path` attribute on the `<body>` element.
 *
 */

$carousel-dot-size:           15px;
$carousel-dot-color:          color(grey, 5);
$carousel-dots-top-margin:    15px;
$carousel-dot-margin:         0 5px;
$carousel-dots-height:        $carousel-dot-size + $carousel-dots-top-margin;

$carousel-nav-size:           35px;
$carousel-nav-icon-size:      16px;
$carousel-nav-distance:       10px;
$carousel-icon-offset-y:      ($carousel-nav-size - $carousel-nav-icon-size) / 2;
$carousel-icon-offset-x:      $carousel-icon-offset-y + 1; // This value helps to visually center the arrow horizontally
$carousel-icon-color:         color(grey, 3);

/**
 * Basic carousel:
 *
 * `.carousel`
 *
 * A carousel.
 *
 *   @example
 *   include ./code-samples/basic-carousel.pug
 */
@include block('carousel') {
  position: relative;
  display: none;
  width: 100%;
  z-index: index($z-index-scale, carousel);
  text-align: center;
  @include rem(font-size, $base-font-size);

  @include element('dots') {
    display: block;
    margin-top: $carousel-dots-top-margin;
  }

  @include element('dot') {
    @include rem(width, $carousel-dot-size);
    @include rem(height, $carousel-dot-size);
    @include rem(border, 1px solid color(grey, 4));
    @include rem(margin, $carousel-dot-margin);
    padding: 0;
    border-radius: 50%;
    background-color: transparent;
    transition: border-color 0.1s linear, background-color 0.2s linear;

    &.active, // TODO make it a BEM modifier
    &:focus {
      background-color: color(primary, dark);
      border-color: color(primary, dark);
    }

    &:hover {
      background-color: color(primary, mid);
      border-color: color(primary, dark);
    }

    &:focus,
    &:active {
      @include focus-outline-pseudo-element($gap: 4px, $border-radius: 50%);
    }
  }

  @include element('navs') {
    @include clearfix;
    width: 100%;

    &.disabled { // TODO make it a BEM modifier
      display: none;
    }
  }

  @include element('nav') {
    @include std-button;
    @include rem(width, $carousel-nav-size);
    @include rem(height, $carousel-nav-size);
    padding: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    @include rem(margin-top, -$carousel-dots-height); // Exclude dots when calculating nav position
    z-index: index($z-index-scale, carousel);

    &:hover,
    &:focus {
      @include std-hover;
    }

    &:focus,
    &:active {
      @include focus-outline-pseudo-element($position: absolute, $border-radius: 50%);
    }

    &:active {
      @include std-active;
    }

    &.disabled { // TODO make it a BEM modifier
      display: none;
    }

    @include modifier('previous') {
      left: $carousel-nav-distance;
    }

    @include modifier('next') {
      right: $carousel-nav-distance;
    }
  }

  &[data-dots='false'] {
    .carousel__nav { // BEM mixin doesn't work here :(
      transform: translateY(50%); // Center nav when there are no dots
    }
  }

  @include element('nav-icon') {
    @include rem(width, $carousel-nav-icon-size);
    @include rem(height, $carousel-nav-icon-size);

    @include modifier('previous') {
      @include rotate-clockwise;
    }

    @include modifier('next') {
      @include rotate-counterclockwise;
    }
  }

  @include element('stage') {
    @include clearfix;
    position: relative;
  }

  @include element('item') {
    @include rem(min-height, 1px);
    position: relative;
    float: left;
    user-select: none;

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      transform-style: preserve-3d;
    }
  }

  @include modifier('loaded') {
    display: block;
  }

  @include modifier('loading') {
    opacity: 0;
    display: block;
  }

  @include modifier('drag') {
    cursor: grab;
  }

  @include modifier('grab') {
    cursor: grabbing;
  }

  /*
    Dynamically generated wrapper container.
    The JS plugin we are using does not allow us to modify this class name,
    thus we are stuck with their non-BEM default :(
  */
  .owl-stage-outer {
    position: relative;
    overflow: hidden;
  }
}
